import {
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
} from 'shared/analytics/analytics.types';

export enum GA4LinksEventNames {
  click = 'click',
}

export enum GA4LinksElementTitles {
  toggle = 'Toggle notification on release',
}

export type GA4LinksEventParams = {
  workspaceId: string;
  linkUrl: string;
  active: boolean;
  artistId?: string;
  linkId?: string;
};

export type GALinksSubproduct = GA4SubProducts.links;

export type GA4LinksTrackingEventConfig = GA4MKPProductTrackingEvent<
  {
    eventName: GA4LinksEventNames.click;
    elementTitle: GA4LinksElementTitles.toggle;
    subProduct: GALinksSubproduct;
  } & GA4LinksEventParams
>;

export type BacklinkLifetimeAnalytics = {
  createdAt: string;
  updatedAt: string;
  backlinkId: string;
  visitedPostRelease: number;
  visitedPreRelease: number;
  preSaved: number;
  subscribed: number;
  clickedToStore: number;
};
