import React from 'react';

import styled from '@emotion/styled';

import { Divider, MenuList, Tooltip } from '@mui/material';

import { transientOptions } from 'shared/utils/emotion-interop';

import MenuItem from './MenuItem';

export const StyledMenuList = styled(MenuList)`
  & > li:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(-1)};
  }
  & > li:last-child {
    margin-bottom: ${({ theme }) => theme.spacing(-1)};
  }
`;

type StyledMenuItemProps = { $iconOnly?: boolean };
export const StyledMenuItem = styled(
  MenuItem,
  transientOptions,
)<StyledMenuItemProps>`
  ${({ theme, $iconOnly }) => {
    const dimensions = $iconOnly
      ? `
        min-width: ${theme.spacing(4)};
        min-height: ${theme.spacing(4)};
        justify-content: center;
      `
      : `
        min-width: ${theme.spacing(12.5)};
        ${theme.breakpoints.down('md')} {
          min-width: 0;
        }
        border-radius: 4px;
      `;
    return `
      ${dimensions}
    `;
  }}

  /* force pointer-events on disabled items
  to force showing a tooltip on hover
  which is not the default behaviour with disabled elements in Mui
  the recommended workaround is to add an html element between the tooltip & the item
  but I don't want to do this
  so I simply disable the onClick handler for the disabled items and enable pointer-events here */
  &.MuiButtonBase-root.Mui-disabled {
    pointer-events: all;
  }
`;

export const MenuDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(1.5)} 0;
`;

export const MenuTitle = styled.h3`
  margin-bottom: 0;
`;

/** styled Tooltip
 *  @see: https://github.com/mui-org/material-ui/issues/11467#issuecomment-423845900
 */
export const MenuTooltip = styled((props) => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltip: 'tooltip',
      tooltipPlacementRight: 'tooltipPlacementRight',
    }}
    {...props}
  />
))`
  & .tooltip {
    font-size: 0.8125rem;
  }
  & .tooltipPlacementRight {
    margin-left: 0;
    ${({ theme }) =>
      `${theme.breakpoints.up('sm')} {
         margin-left: ${theme.spacing(-(1 / 2))};
    }`};
  }
`;
