import React from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import AlbumIcon from '@mui/icons-material/Album';
import AudioIcon from '@mui/icons-material/Audiotrack';
import VideoIcon from '@mui/icons-material/Videocam';
import { Card, Typography } from '@mui/material';

import { Thumbnail } from 'shared/components/Thumbnail';
import {
  Product,
  ProductTypeEnum,
} from 'shared/entities/product/product.types';
import { theme } from 'shared/themes/legacy';

import * as BacklinkEditorStyled from '../BacklinkSettingsDataEditor/components/styled';

export type Props = {
  product: Product;
  className?: string;
  preTitle?: React.ReactNode;
  postTitle?: React.ReactNode;
};

const ProductCard: React.FC<Props> = ({
  product,
  className,
  preTitle,
  postTitle,
}) => {
  const { t } = useTranslation();

  const ProductTypeIcon =
    product.productType === ProductTypeEnum.VIDEO ? VideoIcon : AudioIcon;

  return product ? (
    <Card
      variant="outlined"
      role="group"
      aria-label={t(
        'ui.component.backlink_settings_data_editor.product_card.label',
        'Product Card',
      )}
      className={className}
      css={(theme) => css`
        background-color: ${theme.palette.grey[300]};
        border: none;
      `}
    >
      <BacklinkEditorStyled.CardHeader
        disableTypography
        title={
          <>
            {preTitle}

            <Thumbnail
              variant="square"
              alt={`${product.title} - ${product.artistName}`}
              src={product.thumbArtworkUrl || ''}
              width="56px"
              height="56px"
              $borderRadius="4px"
              $backgroundColor={theme.palette.grey[500]}
              css={(theme) => css`
                margin-left: ${theme.spacing(0.5)};
                margin-right: ${theme.spacing(1)};
              `}
            >
              <AlbumIcon fontSize="large" />
            </Thumbnail>
            <Typography>
              <strong>{product.titleWithProductVersion}</strong>
              <br />
              {product.artistName}
            </Typography>

            {postTitle}
          </>
        }
        action={
          <Typography
            css={(theme) => css`
              display: flex;
              align-items: center;
              > * {
                margin-right: ${theme.spacing(0.5)};
              }
            `}
          >
            <ProductTypeIcon fontSize="small" />
            {product.productType}
          </Typography>
        }
        css={(theme) => css`
          padding-right: ${theme.spacing(2)};
        `}
      ></BacklinkEditorStyled.CardHeader>
    </Card>
  ) : null;
};

export default ProductCard;
