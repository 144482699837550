import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormLabel,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from '@mui/material';

import ConfirmDialog from 'shared/components/ConfirmDialog';
import { RelatedBacklinkSettingsMode } from 'shared/entities/backlinkSettings/backlinkSettings.constants';

type Props = {
  isSwitching: boolean;
  mode: RelatedBacklinkSettingsMode;
  onChange: (value: RelatedBacklinkSettingsMode) => void;
};

const BacklinkSettingsModeSwitch = ({ isSwitching, mode, onChange }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [value, setValue] = useState<RelatedBacklinkSettingsMode>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [proceed, setProceed] = useState(false);

  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirm = () => {
    setProceed(true);
    setConfirmDialogOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setValue(value as RelatedBacklinkSettingsMode);
    if (value === RelatedBacklinkSettingsMode.DEFAULT) {
      setConfirmDialogOpen(true);
      return;
    }
    setProceed(true);
  };

  useEffect(() => {
    if (value && proceed) {
      onChange(value);
      setProceed(false);
    }
  }, [proceed, value, onChange]);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ marginBottom: theme.spacing(1) }}>
          <>
            <span>
              {t(
                'ui.component.backlink_settings_mode_switch.options.title',
                'When creating new links:',
              )}
            </span>
            {isSwitching && (
              <span>
                <CircularProgress
                  size={14}
                  sx={{ marginLeft: theme.spacing(1) }}
                />
              </span>
            )}
          </>
        </FormLabel>
        <RadioGroup
          aria-label={t(
            'ui.component.backlink_settings_mode_switch.options.group.label',
            'Backlink Settings mode options',
          )}
          name="backlinkSettingsSelect"
          value={mode}
          onChange={handleChange}
        >
          <FormControlLabel
            value={RelatedBacklinkSettingsMode.DEFAULT}
            control={<Radio color="primary" />}
            label={
              t(
                'ui.component.backlink_settings_mode_switch.options.default_settings.label',
                'Use Believe default settings',
              ) as string
            }
          />
          <FormControlLabel
            value={RelatedBacklinkSettingsMode.CUSTOM}
            control={<Radio color="primary" />}
            label={
              t(
                'ui.component.backlink_settings_mode_switch.options.custom_settings.label',
                'Use custom settings',
              ) as string
            }
          />
        </RadioGroup>
      </FormControl>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={t(
          'ui.component.backlink_settings_mode_switch.dialog.title',
          'Switch to default settings?',
        )}
        content={t(
          'ui.component.backlink_settings_mode_switch.dialog.content',
          'You will lose all your link custom settings?',
        )}
        cancelButtonText={t(
          'ui.component.backlink_settings_mode_switch.dialog.button.cancel',
          'Keep custom settings',
        )}
        confirmButtonText={t(
          'ui.component.backlink_settings_mode_switch.dialog.button.confirm',
          'Switch to default',
        )}
        onClose={handleCancel}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default BacklinkSettingsModeSwitch;
