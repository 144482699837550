import { DSPActionResponsePayload } from '../../dspAction.api.types';
import {
  DSPActionScheduleType,
  DSPActionStatus,
  DSPActionType,
} from '../../dspAction.constants';

export const dspActions: DSPActionResponsePayload[] = [
  {
    id: 'bb671e5c-47ab-4f55-9e93-3f4e6311ec1a',
    createdAt: '2021-06-23T08:45:31.171Z',
    updatedAt: '2021-06-23T09:49:11.264Z',
    artistId: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
    targetName: 'An aural symbiotic mystery',
    type: DSPActionType.ADD_ALBUM,
    scheduleType: DSPActionScheduleType.SCHEDULED_WITH_DATE,
    schedulableOn: '2021-06-23T22:00:00.000Z',
    config: {
      platforms: {
        spotify: {
          targetUrl: 'https://open.spotify.com/artist/6d24kC5fxHFOSEAmjQPPhc',
          targetId: '6d24kC5fxHFOSEAmjQPPhc',
        },
      },
      selectedTimezone: 'Europe/Paris',
    },
    isActive: true,
    status: DSPActionStatus.SCHEDULED,
  },
  {
    id: '5a139c6b-827e-4999-a58d-3e05dc9ce915',
    createdAt: '2024-04-02T10:20:29.387Z',
    updatedAt: '2024-04-02T10:20:29.387Z',
    artistId: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
    targetName: 'Pépin le Kyiv',
    type: DSPActionType.FOLLOW_ARTIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    schedulableOn: null,
    config: {
      platforms: {
        deezer: {
          targetUrl: 'https://www.deezer.com/artist/328651',
          targetId: '328651',
        },
      },
      useBacklinkRelease: false,
    },
    isActive: true,
    status: DSPActionStatus.SCHEDULED,
  },
  {
    id: '3c7b3b3b-1b3b-4b3b-8b3b-3b3b3b3b3b3b',
    createdAt: '2024-04-02T10:20:29.387Z',
    updatedAt: '2024-04-02T10:20:29.387Z',
    artistId: 'f8636c09-9948-402c-a481-03ab5748451a',
    targetName: 'Charlemagne Palestine Playlist',
    type: DSPActionType.ADD_PLAYLIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    schedulableOn: null,
    config: {
      platforms: {
        spotify: {
          targetUrl: 'https://open.spotify.com/playlist/328651',
          targetId: '328651',
        },
      },
      useBacklinkRelease: false,
    },
    isActive: true,
    status: DSPActionStatus.DELIVERED,
  },
  {
    id: '123aed23-1b3b-4b3b-8b3b-3b3b3b3b3b3b',
    createdAt: '2024-04-02T10:20:29.387Z',
    updatedAt: '2024-04-02T10:20:29.387Z',
    artistId: 'f8636c09-9948-402c-a481-03ab5748451a',
    targetName: 'An aural symbiotic mystery (live version)',
    type: DSPActionType.ADD_ALBUM,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    schedulableOn: null,
    config: {
      platforms: {
        appleMusic: {
          targetUrl: 'https://music.apple.com/album/3412312',
          targetId: '3412312',
        },
      },
      useBacklinkRelease: false,
    },
    isActive: true,
    status: DSPActionStatus.SCHEDULED,
  },
  {
    id: 'c19c7cce-6340-4e99-ba24-8a621701bb11',
    createdAt: '2024-08-22T12:25:58.401Z',
    updatedAt: '2024-08-22T12:25:58.401Z',
    artistId: '942e9cc8-5c72-45a4-b4ff-ee34c2e423e5',
    targetName: 'Charlemagne Palestine',
    type: DSPActionType.FOLLOW_ARTIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    schedulableOn: null,
    config: {
      platforms: {
        deezer: {
          targetUrl: 'https://www.deezer.com/artist/7799750',
          targetId: '7799750',
        },
        spotify: {
          targetUrl: 'https://open.spotify.com/artist/7e4AmALFiKR69Xra2EksPU',
          targetId: '7e4AmALFiKR69Xra2EksPU',
        },
      },
      useBacklinkRelease: false,
    },
    isActive: true,
    status: DSPActionStatus.ACTION_REQUIRED,
  },
];

export const getDSPActionById = (
  id: string,
): DSPActionResponsePayload | undefined => {
  return dspActions.find((action) => action.id === id);
};
