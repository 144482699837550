import styled from '@emotion/styled';

import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDivider from '@mui/material/Divider';
import MuiTextField from '@mui/material/TextField';

export const TextField = styled(MuiTextField)`
  && .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }
`;

export const Divider = styled(MuiDivider)`
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const StoreType = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DialogContentText = styled(MuiDialogContentText)`
  margin-top: 24px;
  margin-bottom: 18px;
`;

export const Container = styled.div`
  margin-top: 34px;
`;
