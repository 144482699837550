import { AuthUser, authUserIsAdmin } from 'shared/entities/auth/auth.types';

import { useAppContext } from '../state';

export const useAppState = <U extends AuthUser>() => {
  const { state: appState } = useAppContext<U>();
  return appState;
};

export const useIsAuthenticatedSelector = () => {
  const { state: appState } = useAppContext();
  return !!appState.user?.userId;
};

export const useIsAdminSelector = () => {
  const { state: appState } = useAppContext();
  return authUserIsAdmin(appState.user);
};
