import { DSPActionType } from '../dspAction/dspAction.constants';

export enum TargetType {
  ALBUM = 'album',
  SOURCE = 'source',
  PLAYLIST = 'playlist',
  USER = 'user',
  ARTIST = 'artist',
}

export const heartbeatTargetTypeToDspActionTargetType = {
  album: DSPActionType.ADD_ALBUM,
  source: DSPActionType.ADD_TRACK,
  artist: DSPActionType.FOLLOW_ARTIST,
  profile: DSPActionType.FOLLOW_USER,
  playlist: DSPActionType.ADD_PLAYLIST,
};

export const dspActionTargetTypeToHeartbeatTargetType = {
  [DSPActionType.ADD_ALBUM]: TargetType.ALBUM,
  [DSPActionType.ADD_TRACK]: TargetType.SOURCE,
  [DSPActionType.FOLLOW_ARTIST]: TargetType.ARTIST,
  [DSPActionType.FOLLOW_USER]: TargetType.USER,
  [DSPActionType.ADD_PLAYLIST]: TargetType.PLAYLIST,
};
