import React from 'react';

import { useTranslation } from 'react-i18next';

import TextFieldsIcon from '@mui/icons-material/TextFields';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import {
  REPLACEMENT_TOKENS_ENUM,
  isReplacementTokenKey,
} from 'shared/entities/backlinkSettings/backlinkSettings.constants';
import { makeReplacementToken } from 'shared/entities/backlinkSettings/backlinkSettings.utils';

type Props = {
  onChange: (value: string) => void;
  className?: string;
};

const ReplacementTokenSelect: React.FC<Props> = ({ onChange, className }) => {
  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId="replacement-tokens-keys-menu">
      {(popupState) => {
        const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
          const tokenKey = event.currentTarget.dataset.value;
          if (tokenKey && isReplacementTokenKey(tokenKey)) {
            // ... but pass a fully formed token to the outside world so it can be used as is
            onChange(makeReplacementToken(tokenKey));
          }
          popupState.close();
        };

        return (
          <>
            <IconButton
              {...bindTrigger(popupState)}
              title={t(
                'ui.component.backlink_settings_data_editor.replacement_token_select.button.title',
                'Insert a replacement token',
              )}
              className={className}
            >
              <TextFieldsIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {Object.keys(REPLACEMENT_TOKENS_ENUM).map((token) => (
                <MenuItem
                  key={token}
                  onClick={handleItemClick}
                  data-value={token}
                >
                  {t(
                    `ui.component.backlink_settings_data_editor.replacement_token_select.item.${token}`,
                    REPLACEMENT_TOKENS_ENUM[token] as string,
                  )}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      }}
    </PopupState>
  );
};
export default ReplacementTokenSelect;
