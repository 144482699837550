import React from 'react';

import i18n from 'shared/i18n';

import {
  ReleaseStep,
  ReleaseStepProps,
  DesignStep,
  PrereleaseLandingStep,
  PrereleaseLandingStepProps,
  PrereleasePresaveStep,
  PrereleasePresaveStepProps,
  PreReleaseFormStep,
  PrereleaseFormStepProps,
  PrereleaseAutonotifyStep,
  PrereleaseAutonotifyStepProps,
  PostreleaseLandingStep,
  PostreleaseLandingStepProps,
  SharingStep,
  StepComponentProps,
} from '../components/steps';

/** a set list of possible steps */
export enum StepKey {
  Release = 'releaseStep',
  Design = 'designStep',
  PrereleaseLanding = 'prereleaseLandingStep',
  PrereleasePreSave = 'prereleasePresaveStep',
  PrereleaseForm = 'prereleaseFormStep',
  PrereleaseAutonotify = 'prereleaseAutonotifyStep',
  PostreleaseLanding = 'postreleaseLandingStep',
  Sharing = 'sharingStep',
}

export const getBacklinkStepCategories = () =>
  [
    i18n.t(
      'ui.component.backlink_settings_data_editor.category.setup',
      'Setup',
    ) as 'Setup',
    i18n.t(
      'ui.component.backlink_settings_data_editor.category.pre-release',
      'Pre-release',
    ) as 'Pre-release',
    i18n.t(
      'ui.component.backlink_settings_data_editor.category.post-release',
      'Post-release',
    ) as 'Post-release',
    i18n.t(
      'ui.component.backlink_settings_data_editor.category.general',
      'General',
    ) as 'General',
  ] as const; // 'as const' is needed to treat each element as a type

/** extracting the possible types for a step category from the categories array */
export type BacklinkSettingsEditorStepCategory = ReturnType<
  typeof getBacklinkStepCategories
>[number];

export interface BacklinkSettingsEditorStepConfig {
  title: string;
  category: BacklinkSettingsEditorStepCategory;
  key: string;
  component:
    | React.FC<StepComponentProps>
    | React.FC<ReleaseStepProps>
    | React.FC<PrereleaseLandingStepProps>
    | React.FC<PrereleasePresaveStepProps>
    | React.FC<PrereleaseFormStepProps>
    | React.FC<PrereleaseAutonotifyStepProps>
    | React.FC<PostreleaseLandingStepProps>;
  disabled?: boolean;
}

export type BacklinkSettingsEditorStepsConfig = {
  [key in StepKey]: BacklinkSettingsEditorStepConfig;
};

export const getBacklinkSettingsEditorStepsConfig = (): BacklinkSettingsEditorStepsConfig =>
  ({
    releaseStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.release.menu_title',
        'Release',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.setup',
        'Setup',
      ),
      key: 'releaseStep',
      component: ReleaseStep,
    },
    designStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.design.menu_title',
        'Design',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.setup',
        'Setup',
      ),
      key: 'designStep',
      component: DesignStep,
    },
    prereleaseLandingStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.landing.menu_title',
        'Landing',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.pre-release',
        'Pre-release',
      ),
      key: 'prereleaseLandingStep',
      component: PrereleaseLandingStep,
    },
    prereleasePresaveStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.pre-save.menu_title',
        'Pre-save',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.pre-release',
        'Pre-release',
      ),
      key: 'prereleasePresaveStep',
      component: PrereleasePresaveStep,
    },
    prereleaseFormStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.form.menu_title',
        'Collect fans',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.pre-release',
        'Pre-release',
      ),
      key: 'prereleaseFormStep',
      component: PreReleaseFormStep,
    },
    prereleaseAutonotifyStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.autonotify.menu_title',
        'Fan Notification',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.pre-release',
        'Pre-release',
      ),
      key: 'prereleaseAutonotifyStep',
      component: PrereleaseAutonotifyStep,
    },
    postreleaseLandingStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.landing.menu_title',
        'Landing',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.post-release',
        'Post-release',
      ),
      key: 'postreleaseLandingStep',
      component: PostreleaseLandingStep,
    },
    sharingStep: {
      title: i18n.t(
        'ui.component.backlink_settings_data_editor.step.sharing.menu_title',
        'Sharing',
      ),
      category: i18n.t(
        'ui.component.backlink_settings_data_editor.category.general',
        'General',
      ),
      key: 'sharingStep',
      component: SharingStep,
    },
  } as const);
