import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
  }
`;

export const ArtistName = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
`;
