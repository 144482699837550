import { useCallback } from 'react';

import { WorkspaceEmployee } from 'shared/entities/workspace/workspace.types';

import { setPersistedWorkspaceId } from 'apps/MarketingSuite/helpers/user';

import { ActionsTypes } from '../state/actions';
import { useMarketingSuiteAppContext } from '../state/context';

/**
 * a custom hook to manage Workspaces
 */
export const useWorkspace = () => {
  const { state: appState, dispatch } = useMarketingSuiteAppContext();
  const user = appState.user;
  const workspaceId = user.workspaceId;
  const workspaces = user.workspaces;
  const switchWorkspace = useCallback(
    (workspace: WorkspaceEmployee) => {
      dispatch({
        type: ActionsTypes.UserWorkspaceSwitchRequested,
        payload: workspace.id,
      });
      setPersistedWorkspaceId(user, workspace.id);
    },
    [dispatch, user],
  );
  return { workspaceId, workspaces, switchWorkspace };
};
