import { styled } from '@mui/material';

import Menu from 'shared/components/Menu';
import MenuItem from 'shared/components/Menu/MenuItem';
import { MenuTitle } from 'shared/components/Menu/styled';
import { transientOptions } from 'shared/utils/emotion-interop';

type SecondaryMenuProps = {
  $disabled?: boolean;
};

// prettier-ignore
export const SecondaryMenu = styled(Menu, transientOptions)<SecondaryMenuProps>`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ $disabled }) => $disabled && `opacity: 0.25;`}
  ${MenuTitle} {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing(1 / 2)};
  }
  .MuiList-root {
    margin: 0;
    padding: 0;
    & > li {
      min-height: 2rem;
    }
    & > li:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
    & > li:last-child {
      margin-bottom: 0;
    }
  }
  .MuiMenuItem-gutters {
    padding-left: 4px;
    padding-right: 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const SecondaryMenuItem = styled(MenuItem)`
  min-height: 2rem;

  // wrap long lines and top align text horizontally on the icon
  white-space: normal;
  align-items: flex-start;

  &.MuiMenuItem-root {
    margin-top: 0;
    margin-bottom: 0;
  }
  &.MuiMenuItem-root:hover,
  &.Mui-selected {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiMenuItemIcon-root {
    /* override generic style set in theme */
    color: inherit;
  }

  // the icon
  .MuiListItemIcon-root {
    position: relative;
    top: -1px;
  }
`;

SecondaryMenuItem.defaultProps = {
  disableRipple: true,
};
