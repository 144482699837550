import styled from '@emotion/styled';

import { AppBar } from '@mui/material';

export const minHeight = '80px';

export const CustomAppBar = styled(AppBar)`
  min-height: ${minHeight};
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  .MuiToolbar-gutters {
    padding-top: ${({ theme }) => theme.spacing(1 / 2)};
    padding-bottom: ${({ theme }) => theme.spacing(1 / 2)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
  }
  > .MuiToolbar-root > * {
    margin-right: ${({ theme }) => theme.spacing(1)};
    &:last-child {
      margin-right: ${({ theme }) => theme.spacing(0)};
    }
  }
`;

export const PreTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  > * {
    margin: 4px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  > * {
    margin: 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  > * {
    margin-left: ${({ theme }) => theme.spacing(1 / 2)};
  }
`;
