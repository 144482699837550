import Cookies from 'js-cookie';

import { AuthUser, AuthUserEmployee } from 'shared/entities/auth/auth.types';

/**
 * logic to get the workspace id that will be used as the user logs in
 * note: private use only */
export function getWorkspaceId(user: AuthUserEmployee) {
  let workspaceId: string | undefined;

  // user has a preferred Workspace? (stored in a cookie)
  const persistedWorkspaceId = getPersistedWorkspaceId(user);
  if (
    persistedWorkspaceId &&
    // we want the persisted ws to be part of the list the server returns (it holds the truth)
    user.workspaces.find((ws) => ws.id === persistedWorkspaceId)
  ) {
    workspaceId = persistedWorkspaceId;
  }

  if (!workspaceId && user.workspaces?.length) {
    workspaceId = user.workspaces[0].id;
    // store the workspace id client side
    setPersistedWorkspaceId(user, workspaceId);
  }

  return workspaceId;
}

export function makeWorkspaceIdCookieKey(user: Partial<AuthUser>) {
  return `ams-workspaceId_${user.userId}`;
}

export function getPersistedWorkspaceId(user: Partial<AuthUser>) {
  return Cookies.get(makeWorkspaceIdCookieKey(user));
}

export function setPersistedWorkspaceId(
  user: Partial<AuthUser>,
  workspaceId: string,
) {
  return Cookies.set(makeWorkspaceIdCookieKey(user), workspaceId);
}
