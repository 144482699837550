/**
 * styled components to be used wherever a BacklinkSettingsDataEditor is embeded in its parent entity editor/container
 */
import styled from '@emotion/styled';

import { Grid } from '@mui/material';

import SecondaryHeader from 'shared/layouts/components/SecondaryHeader';
import { minHeight as headerHeight } from 'shared/layouts/components/SecondaryHeader/styled';

export const Container = styled.div`
  height: 100%;
`;

export const Header = styled(SecondaryHeader)`
  position: relative;
  z-index: 2;
  height: ${headerHeight};
`;

export const Content = styled(Grid)`
  height: calc(100% - ${headerHeight});
  overflow-x: hidden;
  overflow-y: scroll; // set to 'scroll' to avoid horizontal shifting of panels when switching Steps
`;

Content.defaultProps = {
  container: true,
  justifyContent: 'center',
};

export const ContentLeft = styled(Grid)`
  justify-content: flex-end;
  display: flex;
  && > * {
    width: auto;
  }
`;

ContentLeft.defaultProps = {
  item: true,
};

export const ContentRight = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(2.5)};
  position: sticky;
  top: 0;
  align-self: flex-start;
`;

ContentRight.defaultProps = {
  item: true,
};
