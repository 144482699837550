import {
  FetchBacklinksPayload,
  PaginatedBacklinksResponse,
} from '../../backlink.api.types';
import { backlinks as allBacklinks } from './backlink.fixture.all';

export const paginatedBacklinks: PaginatedBacklinksResponse = {
  backlinks: allBacklinks,
  totalCount: allBacklinks.length,
};

export function getPaginatedBacklinks({
  page,
  limit,
  params,
  sortBy,
  sortDirection,
}: {
  page: number;
  limit: number;
  params?: FetchBacklinksPayload;
  sortBy?: string;
  sortDirection?: string;
}) {
  if (sortBy && sortDirection) {
    sortBacklinks(sortBy, sortDirection);
  }
  const { artistIds, query } = params || {};
  const backlinks = (query ? searchBacklinks(query) : allBacklinks).filter(
    (backlink) => {
      if (artistIds && !!artistIds.length && backlink.artist) {
        return artistIds.includes(backlink.artist?.id);
      }
      return true;
    },
  );
  const paginatedBacklinks = backlinks.slice(page * limit, (page + 1) * limit);
  return {
    backlinks: paginatedBacklinks,
    totalCount: backlinks.length,
  };
}

function sortBacklinks(sortBy: string, sortDirection: string) {
  return allBacklinks.sort((a, b) => {
    if (sortBy === 'visits' && a.analytics && b.analytics) {
      return sortDirection === 'asc'
        ? a.analytics.visited - b.analytics.visited
        : b.analytics.visited - a.analytics.visited;
    }
    if (sortBy === 'optins' && a.analytics && b.analytics) {
      return sortDirection === 'asc'
        ? a.analytics.optins - b.analytics.optins
        : b.analytics.optins - a.analytics.optins;
    }
    if (sortBy === 'preSaved' && a.analytics && b.analytics) {
      return sortDirection === 'asc'
        ? a.analytics.preSaved - b.analytics.preSaved
        : b.analytics.preSaved - a.analytics.preSaved;
    }
    if (sortBy === 'clickedToStore' && a.analytics && b.analytics) {
      return sortDirection === 'asc'
        ? a.analytics.clickedToStore - b.analytics.clickedToStore
        : b.analytics.clickedToStore - a.analytics.clickedToStore;
    }
    if (sortBy === 'createdAt' && a.createdAt && b.createdAt) {
      return sortDirection === 'asc'
        ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
    if (
      sortBy === 'digitalReleaseDate' &&
      a.settingsData.releaseStep.release.digitalReleaseDate &&
      b.settingsData.releaseStep.release.digitalReleaseDate
    ) {
      return sortDirection === 'asc'
        ? new Date(
            a.settingsData.releaseStep.release.digitalReleaseDate,
          ).getTime() -
            new Date(
              b.settingsData.releaseStep.release.digitalReleaseDate,
            ).getTime()
        : new Date(
            b.settingsData.releaseStep.release.digitalReleaseDate,
          ).getTime() -
            new Date(
              a.settingsData.releaseStep.release.digitalReleaseDate,
            ).getTime();
    }
    return 0;
  });
}

function searchBacklinks(query) {
  return allBacklinks.filter((backlink) => {
    var regex = new RegExp(query, 'i');
    const matches =
      !!backlink.settingsData.releaseStep.release.title?.match(regex) ||
      !!backlink.artist?.name?.match(regex);
    return matches;
  });
}
