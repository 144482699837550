// This file is never used, it merely prevents breaking index.ts
import * as monitoring from '../monitoring';

// See review.ts.tpl for the actual file used in review environmentss
export const rvw = {
  marketingSuiteHostname: 'ams-frontend-$RVW_ENV_SLUG.ams-sta.blv.cloud',
  backstageMarketingHostname: 'ams-backstage-$RVW_ENV_SLUG.ams-sta.blv.cloud',
  backstageMarketingResourcesHostname: 'resources.backstage.ams-sta.blv.cloud',
  marketingSuiteApiBaseUrl:
    'https://frontend-api-$RVW_ENV_SLUG.ams-sta.blv.cloud',
  backstageMarketingApiBaseUrl:
    'https://backstage-api-$RVW_ENV_SLUG.ams-sta.blv.cloud',
  imageBaseUrl: 'https://images.ams-sta.blv.cloud',
  assetsBaseUrl: 'https://assets.ams-sta.blv.cloud',
  imageBucketName: 'images-sta-kjrm9487sdctb5vi',
  backlinkBaseUrl: 'https://backlink-ssr-$RVW_ENV_SLUG.ams-sta.blv.cloud',
  gaTrackers: {
    marketingSuite: {
      ga4Trackers: [
        {
          trackingId: 'G-6D9C2ZTKMG',
        },
      ],
    },
    backstageMarketing: {
      ga4Trackers: [
        {
          trackingId: 'G-QF0CSVZ9LZ',
        },
      ],
    },
  },
  backstageBaseUrl: 'http://producer-03.test.believe.fr',
  artistsResourcesWordpressUrl:
    'https://wordpress-artist-resources.ams-sta.blv.cloud',
  backstageMarketingOriginUrl:
    'http://producer-03.test.believe.fr/promotion/links',
  backstageMarketingResourcesOriginUrl:
    'http://producer-03.test.believe.fr/promotion/artistresources',
  gitlabFeatureFlagsProxy: {
    url: 'https://features.ams-sta.blv.cloud/ams-frontend',
    clientKey: process.env
      .REACT_APP_GITLAB_FEATURE_FLAGS_PROXY_CLIENT_KEY as string,
    // Refresh interval is 6 hours in seconds
    refreshInterval: 60 * 60 * 6,
    appName: 'staging',
    environment: 'staging',
  },
  creativeMediasGqlClientConfig: {
    env: 'sta',
    uri: 'https://promokit-api.ams-sta.blv.cloud/v1/graphql',
  },
  zendeskGlobalMarketingKey: '7847394b-3ddb-430e-b375-06bf4b7240b5',
  zendeskEditorialMarketingProductKey: 'af26ef05-998c-4482-a49d-12dcfb3e8237',
  zendeskCustomerHelpCenterKey: '1e50c9e7-c0eb-40f3-a5e2-c3db3bddef8e',
  salesForceBelieveBaseUrl: 'https://believe-group.my.salesforce.com',
  salesForceBelieveHelpCenterUrl:
    'https://believe-group.my.site.com/HelpCenter',
  salesForceBelieveKey: '00D3X000003K7VS',
  salesForceBackstageMarketingKey: 'BackStage',
  salesForceMarketingSuiteKey: '',
  intranetBaseUrl: 'https://intra-03.test.believe.fr',
  teamsChatBaseUrl: 'https://teams.microsoft.com/l/chat/0/0',
  legacyBackstageAdsUrl: 'https://staging.backstageads.com',
  monitoring: {
    datadog: {
      ...monitoring.datadog,
      env: 'rvw',
    },
  },
} as const;
