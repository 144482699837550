import { createPortal } from 'react-dom';

type Props = {
  children: React.ReactElement;
  domContainer?: Element;
  domContainerSelector?: string;
};

export const Portal = ({
  children,
  domContainer,
  domContainerSelector,
}: Props) => {
  const _domContainer =
    domContainer || document.querySelector(domContainerSelector || 'body');
  return _domContainer ? createPortal(children, _domContainer) : children;
};
