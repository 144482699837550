/**
 * i18n configuration
 * - automatic language detection & client side storage of user language
 *   default detection order is: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag']
 * - automatic locales files loading from configured loadPath via an i18n backend plugin
 *
 * note: it is in a separate file to be able to import it safely in the unit tests setup
 * and avoid triggering the main i18next init sequence (which uses a backend strategy to load locales resources, which causes trouble ...)
 */
import { AllowedLocale } from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';

import { allowedLocales, DEFAULT_LOCALE, defaultNamespace } from './constants';

/**
 * we might have to use a specific translation file for a given locale
 * e.g. we want to use the zh-Hans translation file for the 'zh' locale and avoid having 2 files that hold the same content
 *
 * we modify the AllowedLocale type because some locales codes will have been transformed by the time we want to use them as lookup keys with this map
 */
type TranslationOverridesAllowedLocale =
  | Exclude<AllowedLocale, 'zh-sg' | 'zh-tw'>
  | 'zh-SG'
  | 'zh-TW';
const translationFilesOverridesMap: Partial<
  Record<TranslationOverridesAllowedLocale, TranslationOverridesAllowedLocale>
> = {
  zh: 'zh-Hans',
  'zh-SG': 'zh-Hans',
  'zh-TW': 'zh-Hant',
};

export const i18nextConfig = {
  // we want to force 'en' for e2e tests (having a 'lng' key defined bypasses the locale detection)
  // note: we could use 'cimode' and assert on the translation keys but we don't want to update all the existing tests for now
  // and kind of prefer also testing that we do have a translation occuring
  lng: window.Cypress !== undefined ? DEFAULT_LOCALE : undefined,
  fallbackLng: DEFAULT_LOCALE,
  supportedLngs: allowedLocales,
  nonExplicitSupportedLngs: true,
  interpolation: {
    escapeValue: false,
  },
  ns: ['common', 'mkt_suite', 'backstage'],
  defaultNS: defaultNamespace,
  debug: false,
  detection: {
    // you can use ?locale=aUserLocale to test a language
    lookupQuerystring: 'locale',
  },
  backend: {
    loadPath: function (locales: string[], namespaces: string[]) {
      // here, as the i18next lowerCaseLng option has not been activated, the compound locale codes will have their region part upper cased
      // e.g. zh-sg -> zh-SG
      // this will work with the phrase.com json files name formats (zh-SG.json)
      const locale = locales[0];

      if (
        locale &&
        Object.keys(translationFilesOverridesMap).includes(locale)
      ) {
        return `/locales/${translationFilesOverridesMap[locale]}/{{ns}}.json`;
      }
      return '/locales/{{lng}}/{{ns}}.json';
    },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'li'],
  },
  translationsStats: {
    sourceLng: 'en',
  },
  postProcess: ['phraseInContextEditor'],
};
