/* eslint-disable react/function-component-definition */
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { StyledIconButton } from './styled';

type Props = {
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
} & DialogProps;
const ContentDialog: React.FC<Props> = ({
  title = 'Are you sure?',
  children,
  onClose,
  ...dialogProps
}) => (
  <Dialog onClose={onClose} {...dialogProps}>
    <DialogTitle variant="h4">
      <Box>{title}</Box>
      {onClose ? (
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
);

export default ContentDialog;
