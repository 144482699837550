import {
  DSPActionStatus,
  DSPActionType,
  DSPActionOptionsByType,
  DSP,
} from './dspAction.constants';

/**
 * Those DSPActionTypes are specific to the frontend
 * and allow to differentiate between actions that are of the same type
 * but require a different treatment
 * e.g.
 * - follow main artist
 * - add main playlist
 */
export enum FrontendDSPActionType {
  ADD_ALBUM = DSPActionType.ADD_ALBUM,
  ADD_TRACK = DSPActionType.ADD_TRACK,
  FOLLOW_MAIN_ARTIST = 'FOLLOW_MAIN_ARTIST',
  FOLLOW_ARTIST = DSPActionType.FOLLOW_ARTIST,
  ADD_MAIN_PLAYLIST = 'ADD_MAIN_PLAYLIST',
  ADD_PLAYLIST = DSPActionType.ADD_PLAYLIST,
  FOLLOW_USER = DSPActionType.FOLLOW_USER,
}

export const frontendDSPActionOptionsByType: Record<
  FrontendDSPActionType,
  typeof DSPActionOptionsByType[keyof typeof DSPActionOptionsByType]
> = {
  ...DSPActionOptionsByType,
  FOLLOW_ARTIST: {
    displayName: 'Follow artist (other)',
  },
  ADD_PLAYLIST: {
    displayName: 'Add playlist (other)',
  },
  FOLLOW_MAIN_ARTIST: {
    displayName: 'Follow artist',
  },
  ADD_MAIN_PLAYLIST: {
    displayName: 'Add playlist',
  },
};

export enum FrontendDSPActionStatus {
  DRAFT = 'DRAFT',
  DELIVERED = DSPActionStatus.DELIVERED,
  ONGOING = DSPActionStatus.ONGOING,
  SCHEDULED = DSPActionStatus.SCHEDULED,
  IN_PROGRESS = DSPActionStatus.IN_PROGRESS,
  FAILED = DSPActionStatus.FAILED,
  ACTION_REQUIRED = DSPActionStatus.ACTION_REQUIRED,
}

/**
 * The available DSP actions for each store.
 * This is needed because some DSPs do not support all types of actions.
 */
export const availableDSPActionsByStore: Record<DSP, FrontendDSPActionType[]> =
  {
    [DSP.SPOTIFY]: [
      FrontendDSPActionType.ADD_ALBUM,
      FrontendDSPActionType.ADD_TRACK,
      FrontendDSPActionType.FOLLOW_MAIN_ARTIST,
      FrontendDSPActionType.FOLLOW_ARTIST,
      FrontendDSPActionType.ADD_MAIN_PLAYLIST,
      FrontendDSPActionType.ADD_PLAYLIST,
      FrontendDSPActionType.FOLLOW_USER,
    ],
    [DSP.APPLE_MUSIC]: [
      FrontendDSPActionType.ADD_ALBUM,
      FrontendDSPActionType.ADD_TRACK,
      FrontendDSPActionType.ADD_MAIN_PLAYLIST,
      FrontendDSPActionType.ADD_PLAYLIST,
    ],
    [DSP.DEEZER]: [
      FrontendDSPActionType.ADD_ALBUM,
      FrontendDSPActionType.FOLLOW_MAIN_ARTIST,
      FrontendDSPActionType.FOLLOW_ARTIST,
      FrontendDSPActionType.ADD_TRACK,
      FrontendDSPActionType.FOLLOW_USER,
      FrontendDSPActionType.ADD_MAIN_PLAYLIST,
      FrontendDSPActionType.ADD_PLAYLIST,
    ],
  };
