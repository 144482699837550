import { rest } from 'msw';

import config from 'shared/config';

import { StoreConfig } from '../../storeConfig.types';
import storesConfigsFixture from '../fixtures/storeConfig.fixture.all';

const storesConfigs = storesConfigsFixture;
const DELAY = 500;

const makeHandlers = (apiBaseUrl: string) => [
  rest.patch<StoreConfig, { storeName: string }>(
    `${apiBaseUrl}/stores/:storeName`,
    async (req, res, ctx) => {
      const payload = await req.json();
      storesConfigs[req.params.storeName] = payload;
      return res(ctx.delay(DELAY), ctx.status(200), ctx.json(payload));
    },
  ),
  rest.delete<string, { storeName: string }>(
    `${apiBaseUrl}/stores/:storeName`,
    (req, res, ctx) => {
      delete storesConfigs[req.params.storeName];
      return res(ctx.delay(DELAY), ctx.status(200));
    },
  ),
  rest.get(`${apiBaseUrl}/stores`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(storesConfigs));
  }),
  rest.post<StoreConfig>(`${apiBaseUrl}/stores`, async (req, res, ctx) => {
    const store = await req.json();
    storesConfigs[store.name] = store;
    return res(ctx.delay(DELAY), ctx.status(200), ctx.json(store));
  }),
];

const handlers = [
  ...makeHandlers(config.marketingSuiteApiBaseUrl),
  ...makeHandlers(config.backstageMarketingApiBaseUrl),
];

export default handlers;
