import { Dispatch, createContext, useContext, useReducer } from 'react';

import {
  ReleaseEditorContextProviderProps,
  ReleaseEditorState,
  ReleaseEditorStep,
} from '../types';
import { Action } from './actions';
import { reducer } from './reducer';

export const initialState: ReleaseEditorState = {
  currentStep: ReleaseEditorStep.UNINITIALIZED,
  productQuery: '',
  artistQuery: '',
};

const ReleaseEditorContext = createContext<{
  state: ReleaseEditorState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});
ReleaseEditorContext.displayName = 'ReleaseEditorContext';

export const ReleaseEditorContextProvider: React.FC<
  ReleaseEditorContextProviderProps
> = ({ children, releaseId, onReleaseCreated }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    releaseId,
    onReleaseCreated,
  });
  return (
    <ReleaseEditorContext.Provider value={{ state, dispatch }}>
      {children}
    </ReleaseEditorContext.Provider>
  );
};

export function useReleaseEditorContext() {
  const context = useContext(ReleaseEditorContext);
  if (!context) {
    throw new Error(
      '`useReleaseEditor` must be used inside ReleaseEditorContextProvider',
    );
  }
  return context;
}
