/**
 * an enum of all the possible status of an export job
 */
export enum ExportDataStatusEnum {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

// polling intervals
export const POLLING_DEFAULT_INTERVAL = 2000;
export const POLLING_LONG_INTERVAL = 30000;
