import { insertAt } from './string';

/**
 * a function that will return the passed HTMLInputElement value
 * with the passed in string inserted at the current caret position
 * @returns string
 */
export function insertAtCaretPositionAndGetNewValue({
  inputElement,
  stringToBeInserted,
}: {
  inputElement: HTMLInputElement | null;
  stringToBeInserted: string;
}) {
  if (!inputElement) return '';
  const position = inputElement.selectionStart;
  const newValue =
    position !== null
      ? insertAt({
          source: inputElement.value,
          position,
          value: stringToBeInserted,
        })
      : '';
  return newValue;
}

export function scrollElementIntoViewById(
  id: string,
  options: ScrollIntoViewOptions = {},
) {
  document.getElementById(id)?.scrollIntoView({ behavior: 'auto', ...options });
}
