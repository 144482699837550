import { AuthUserEmployee } from 'shared/entities/auth/auth.types';
import { ActionMap } from 'shared/state/types';

export enum ActionsTypes {
  UserInfosReceived = 'UserInfosReceived',
  UserWorkspaceSwitchRequested = 'UserWorkspaceSwitchRequested',
}

export type Payload = {
  [ActionsTypes.UserInfosReceived]: AuthUserEmployee;
  [ActionsTypes.UserWorkspaceSwitchRequested]: string;
};

/** actions with their correspnding payload shape */
export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];
