import React from 'react';

import { useTranslation } from 'react-i18next';

import { GA4SubProducts } from 'shared/analytics/analytics.types';
import {
  GA4LinksEventNames,
  GA4LinksElementTitles,
} from 'shared/entities/backlink/backlink.analytics.types';
import { GA4LinksTrackingEventConfig } from 'shared/entities/backlink/backlink.analytics.types';
import { getBacklinkUrlFromSettingsData } from 'shared/entities/backlink/backlink.utils';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useGATracking } from 'shared/hooks/useGATracking';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { useWorkspace } from 'apps/MarketingSuite/hooks/useWorkspace';

import { getReleaseMode } from '../../../../state';
import { useBacklinkSettingsDataEditorContext } from '../../../../state/context';
import { EditorSwitch } from '../../../fields/EditorSwitch';

import * as FieldsStyled from '../../../fields/styled';

/**
 * Pre-release Autonotify Step - Feature Toggle
 */
const AutonotifyToggle: React.FC = () => {
  const { t } = useTranslation();
  const { stepKey, getFieldProps, editorState, parentEntityInfos } =
    useBacklinkSettingsDataEditorContext();
  const { sendGA4TrackingEvent } = useGATracking();
  const { workspaceId } = useWorkspace();

  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : null;

  const isPreRelease = getReleaseMode(editorState) === 'pre-release';
  const isActivePath = `${stepKey}.isActive`;
  const { value: isActive, ...isActiveProps } = getFieldProps(isActivePath);
  const appName = getAppName() as AppName;

  return (
    <FieldsStyled.FieldRow>
      <FieldsStyled.EditorFormLabel id="is-autonotify-active">
        {t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.is_active',
          'Auto-send notification on release',
        )}
      </FieldsStyled.EditorFormLabel>
      <EditorSwitch
        value={isActive}
        {...isActiveProps}
        changeHandler={(isActive: boolean) => {
          sendGA4TrackingEvent<GA4LinksTrackingEventConfig>({
            eventName: GA4LinksEventNames.click,
            eventParams: {
              product: appName,
              subProduct: GA4SubProducts.links,
              elementTitle: GA4LinksElementTitles.toggle,
              workspaceId,
              artistId: backlink?.artist?.id,
              linkId: backlink?.id,
              linkUrl: getBacklinkUrlFromSettingsData(editorState),
              active: isActive,
            },
          });
          isActiveProps.changeHandler(isActive);
        }}
        inputProps={{ 'aria-labelledby': 'is-autonotify-active' }}
        disabled={!isPreRelease}
      />
    </FieldsStyled.FieldRow>
  );
};

export default AutonotifyToggle;
