import { FrontendDSPAction } from 'shared/entities/dspAction/dspAction.frontend.types';
import { DSP } from 'shared/entities/storeConfig/storeConfig.types';

import { dspActions } from '../../../dspAction/mocks/fixtures/dspAction.frontend.fixtures.all';
import { BacklinkMode } from '../../backlinkSettings.constants';
import { AllowedLocale } from '../../backlinkSettings.locale.types';
import { BacklinkSettingsData } from '../../backlinkSettings.types';
import { getLocalesCodes as getLocalesCodesFromSettingsV1 } from '../../backlinkSettings.utils';
import {
  BackgroundType,
  BacklinkSettingsData as BacklinkSettingsDataV2,
  FormFieldConfigWithMetas,
  GenericFormFieldConfig,
  LocalizedBacklinkSettings,
  LocalizedPostReleaseSettings,
  LocalizedPreReleaseSettings,
  PostReleaseSettings,
  PreReleaseSettings,
} from '../../v2/types';

export const transformBacklinkSettingsDataV1ToV2 = (
  settingsV1: BacklinkSettingsData,
): BacklinkSettingsDataV2 => {
  /**
   * Locales
   */
  const locales = getLocalesCodesFromSettingsV1(settingsV1);
  /** Design */
  const design = {
    background: {
      type: settingsV1.designStep.background.image?.imageId
        ? BackgroundType.IMAGE
        : BackgroundType.COLOR,
      color: {
        color01: settingsV1.designStep.background.color01,
        color02: settingsV1.designStep.background.color02,
        gradientAngle: settingsV1.designStep.background.direction,
      },
      image: {
        imageId: settingsV1.designStep.background.image?.imageId || '',
        blur: settingsV1.designStep.background.image?.blur || 0,
        overlay: {
          color:
            settingsV1.designStep.background.image?.overlay.color ||
            'transparent',
          opacity: settingsV1.designStep.background.image?.overlay.opacity || 0,
        },
      },
    },
  };

  /**
   * Localized PreRelease Settings
   */
  const localizedPreReleaseSettings = locales.reduce((acc, locale) => {
    const settingsForLocale: LocalizedPreReleaseSettings = {
      headline: {
        text: settingsV1.prereleaseLandingStep.locale[locale].richText,
      },
      form: {
        fields: settingsV1.prereleaseFormStep.locale[locale].form
          .fields as GenericFormFieldConfig[],
        optins: settingsV1.prereleaseFormStep.locale[locale].form
          .optins as FormFieldConfigWithMetas[],
        headline: {
          text: settingsV1.prereleaseFormStep.locale[locale].richText,
        },
        actions: {
          submit: {
            button: {
              label:
                settingsV1.prereleaseFormStep.locale[locale].form.submitButton
                  .label,
            },
          },
          skip: {
            button: {
              label:
                settingsV1.prereleaseFormStep.locale[locale].form.skipButton
                  .label,
            },
          },
        },
      },
      dspActions: {
        notification: {
          text: settingsV1.prereleasePresaveStep.locale[locale]
            .notificationText,
        },
      },
      stores: {
        list:
          locale === 'fr'
            ? ['spotify', 'deezer'] // simulate different stores list for fr - withit having to modify the v1 settings fixture which will break v1 e2e tests
            : settingsV1.prereleaseLandingStep.locale[locale].callToActions
                .stores.displayOrder,
        options: settingsV1.prereleaseLandingStep.locale[
          locale
        ].callToActions.stores.displayOrder.reduce(
          (acc, storeId): LocalizedPreReleaseSettings['stores']['options'] => {
            const storeOptionsV1 =
              settingsV1.prereleaseLandingStep.locale[locale].callToActions
                .stores.options[storeId];
            return {
              ...acc,
              [storeId]: {
                CTA: {
                  label: storeOptionsV1.label,
                  description: storeOptionsV1.text,
                },
              },
            };
          },
          {} as LocalizedPreReleaseSettings['stores']['options'],
        ),
      },
    };
    return {
      ...acc,
      [locale]: settingsForLocale,
    };
  }, {} as LocalizedBacklinkSettings<BacklinkMode.PRERELEASE>);

  /**
   * Localized PostRelease Settings
   */
  const localizedPostReleaseSettings = locales.reduce((acc, locale) => {
    const settingsForLocale: LocalizedPostReleaseSettings = {
      headline: {
        text: settingsV1.postreleaseLandingStep.locale[locale].richText,
      },
      fanNotification: {
        email: {
          subject: settingsV1.prereleaseAutonotifyStep.locale[locale].subject,
          body: settingsV1.prereleaseAutonotifyStep.locale[locale].body,
          title: settingsV1.prereleaseAutonotifyStep.locale[locale].title,
          cta: {
            label: settingsV1.prereleaseAutonotifyStep.locale[locale].ctaText,
          },
          footer: {
            text: settingsV1.prereleaseAutonotifyStep.locale[locale].footerText,
          },
          social: {
            title:
              settingsV1.prereleaseAutonotifyStep.locale[locale]
                .socialSectionTitle,
          },
        },
      },
      stores: {
        list: settingsV1.postreleaseLandingStep.locale[locale].callToActions
          .stores.displayOrder,
        options: settingsV1.postreleaseLandingStep.locale[
          locale
        ].callToActions.stores.displayOrder.reduce(
          (acc, storeId): LocalizedPostReleaseSettings['stores']['options'] => {
            const storeOptionsV1 =
              settingsV1.postreleaseLandingStep.locale[locale].callToActions
                .stores.options[storeId];
            return {
              ...acc,
              [storeId]: {
                CTA: {
                  label: storeOptionsV1.label,
                  description: storeOptionsV1.text,
                },
              },
            };
          },
          {} as LocalizedPreReleaseSettings['stores']['options'],
        ),
      },
    };
    return {
      ...acc,
      [locale]: settingsForLocale,
    };
  }, {} as LocalizedBacklinkSettings<BacklinkMode.POSTRELEASE>);

  /**
   * Transform V1 to V2
   */
  return {
    defaultValues: {
      locale: settingsV1.common.defaultLocale as AllowedLocale,
    },
    locales,
    preferences: {
      useSameStoresListForPreAndPostRelease: false,
      useLocalizedStoresList: false,
    },
    release: {
      digitalReleaseDate: settingsV1.releaseStep.release.digitalReleaseDate,
      selectedTimezone: settingsV1.releaseStep.release.selectedTimezone,
      title: settingsV1.releaseStep.release.title || '',
      artworkId: settingsV1.designStep.artworkId || '',
    },
    preview: settingsV1.preview,
    socialCard: {
      ...settingsV1.sharingStep.socialCard,
      imageId:
        settingsV1.sharingStep.socialCard.imageId ||
        settingsV1.designStep.artworkId ||
        '',
    },
    url: {
      slug: settingsV1.sharingStep.url?.slug || '',
      subDomain: settingsV1.sharingStep.url?.subDomain || '',
    },
    preRelease: {
      dspActionIds: settingsV1.prereleasePresaveStep.actionIds || [],
      stores: {
        list: settingsV1.prereleaseLandingStep.locale[
          settingsV1.common.defaultLocale
        ].callToActions.stores.displayOrder,
        options: settingsV1.prereleaseLandingStep.locale[
          settingsV1.common.defaultLocale
        ].callToActions.stores.displayOrder.reduce(
          (acc, storeName): PreReleaseSettings['stores']['options'] => {
            return {
              ...acc,
              [storeName]: {
                useConnect: Object.values(DSP).includes(storeName as DSP),
                useFanForm: settingsV1.prereleaseFormStep.isActive,
                redirectUserTo: 'artistProfile',
                defaultDSPActionId: Object.values(DSP).includes(
                  storeName as DSP,
                )
                  ? settingsV1.common.defaultActionId
                  : undefined,
                dspActions: Object.values(DSP).includes(storeName as DSP)
                  ? dspActions.reduce((acc, dspAction) => {
                      if (!dspAction || dspAction?.storeName !== storeName) {
                        return acc;
                      }
                      return [...acc, dspAction];
                    }, [] as FrontendDSPAction[])
                  : undefined,
              },
            };
          },
          {} as PreReleaseSettings['stores']['options'],
        ),
      },
      preferences: {
        showArtist: settingsV1.prereleaseLandingStep.showArtist,
        showArtwork: settingsV1.prereleaseLandingStep.showArtwork,
        showVideo: settingsV1.prereleaseLandingStep.showVideo,
        youtubeVideoId: settingsV1.prereleaseLandingStep.youtubeVideoId,
      },
      design,
      locale: localizedPreReleaseSettings,
    },
    postRelease: {
      stores: {
        list: settingsV1.postreleaseLandingStep.locale[
          settingsV1.common.defaultLocale
        ].callToActions.stores.displayOrder,
        options: settingsV1.postreleaseLandingStep.locale[
          settingsV1.common.defaultLocale
        ].callToActions.stores.displayOrder.reduce(
          (acc, storeId): PostReleaseSettings['stores']['options'] => {
            return {
              ...acc,
              [storeId]: {
                links: settingsV1.releaseStep.release.stores[storeId].links,
              },
            };
          },
          {} as PostReleaseSettings['stores']['options'],
        ),
      },
      preferences: {
        showArtist: settingsV1.postreleaseLandingStep.showArtist,
        showArtwork: settingsV1.postreleaseLandingStep.showArtwork,
        showVideo: settingsV1.postreleaseLandingStep.showVideo,
        youtubeVideoId: settingsV1.postreleaseLandingStep.youtubeVideoId,
      },
      design,
      fanNotification: {
        isActive: settingsV1.prereleaseAutonotifyStep.isActive,
        artistProfileStoreIds: settingsV1.prereleaseAutonotifyStep.stores,
        scheduledAt: settingsV1.prereleaseAutonotifyStep.scheduledAt || '',
        timezone:
          settingsV1.prereleaseAutonotifyStep.selectedTimezone || undefined,
      },
      locale: localizedPostReleaseSettings,
    },
  };
};
