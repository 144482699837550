import { DSPInfos } from 'shared/entities/storeConfig/storeConfig.types';
import { DSP } from 'shared/entities/storeConfig/storeConfig.types';

export const PROFILE_URLS_DSP_PLATFORMS = [DSP.SPOTIFY, DSP.DEEZER];

export const OFFICIAL_PLAYLIST_DSP_PLATFORMS = [
  DSPInfos[DSP.APPLE_MUSIC],
  DSPInfos[DSP.SPOTIFY],
  DSPInfos[DSP.DEEZER],
];

export const HIGHLIGHT_DOM_IDS = {
  ARTIST_URLS: 'artist-urls',
  ARTIST_SUBDOMAIN: 'artist-subdomain',
};
