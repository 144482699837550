import { BacklinkSettingsData } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { Actions, ActionsTypes } from './actions';
import {
  makeEditorNextState,
  addLocaleSettings,
  deleteLocaleSettings,
  syncAllCTAsWithReleaseStores,
} from './state';

export const reducer = (state: BacklinkSettingsData, action: Actions) => {
  switch (action.type) {
    case ActionsTypes.Init: {
      const { initialState } = action.payload;
      /**
       * ATM we only need to set the initial state
       * but this is where we might apply transforms
       */
      const nextState = initialState;
      return nextState as BacklinkSettingsData;
    }
    case ActionsTypes.UpdateSetting: {
      const { path, data, options } = action.payload;
      return makeEditorNextState(state, path, data, options);
    }
    case ActionsTypes.AddLocaleSettings: {
      const { locale, settings, storesConfigs } = action.payload;

      const stateWithAddedLocaleSettings = addLocaleSettings(
        state,
        locale,
        settings,
      );

      // we have just added locale settings
      // now sync all CTAs with release stores
      // we do it here to make sure we have the synced stores as early as possible
      return syncAllCTAsWithReleaseStores({
        state: stateWithAddedLocaleSettings,
        releaseStores: state.releaseStep.release.stores,
        storesConfigs,
      });
    }
    case ActionsTypes.DeleteLocaleSettings: {
      const { locale } = action.payload;
      return deleteLocaleSettings(state, locale);
    }
    case ActionsTypes.CTAsReleaseStoresSyncRequired: {
      const { storesConfigs } = action.payload;

      return syncAllCTAsWithReleaseStores({
        state,
        releaseStores: state.releaseStep.release.stores,
        storesConfigs,
      });
    }
    default:
      return state;
  }
};
