import styled from '@emotion/styled';

import { Grid } from '@mui/material';
import MuiCard, { CardProps } from '@mui/material/Card';
import { spacing, SpacingProps } from '@mui/system';

export const menuWidth = 200;
export const stepWidth = 480;
export const paddingTopSpacing = 2;

export const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => !['isExpanded', 'hasErrors'].includes(prop),
})<CardProps & { isExpanded?: boolean; hasErrors?: boolean }>`
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.palette.common.white : theme.palette.grey[100]};
  ${({ theme, hasErrors }) =>
    hasErrors ? `border-color: ${theme.palette.error.main}` : ''};
  > * {
    padding: 0 1rem;
  }
`;

export const Container = styled(Grid)<SpacingProps>`
  ${spacing}

  padding-top: ${({ theme }) => theme.spacing(paddingTopSpacing)};
`;

export const MenuContainer = styled(Grid)<SpacingProps>`
  position: sticky;
  top: ${({ theme }) => theme.spacing(paddingTopSpacing)};
  align-self: flex-start;
  width: ${menuWidth}px;
`;

export const StepContainer = styled(Grid)<SpacingProps>`
  width: ${stepWidth}px;
  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    width: calc(100vw - 2rem);
    padding-bottom: 4rem;
  }`}
`;
