import { AppNotificationTypes } from 'shared/constants';

export type AppNotification = {
  type: AppNotificationTypes;
  message: string;
};

export enum AppName {
  'marketingSuite' = 'marketingSuite',
  'backstageMarketing' = 'backstageMarketing',
  'backstageMarketingResources' = 'backstageMarketingResources',
}
