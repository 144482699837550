import React, { useState, useEffect, useMemo } from 'react';

import { css } from '@emotion/react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import produce from 'immer';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import {
  CallToActions,
  CTAOptions,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { isDefined } from 'shared/utils/typeguards';

import { ValidationErrors } from '../../../hooks/useValidation';
import { EditorFieldProps } from '../../types';
import CTAEditor from './CTAEditor';

import * as EditorStyled from '../../styled';
import { Container } from './styled';

/**
 * disable all development only warnings for react-beautiful-dnd library
 * it would complain (as a warning only) that:
 *  Droppable: unsupported nested scroll container detected.
 *  A Droppable can only have one scroll parent (which can be itself) Nested scroll containers are currently not supported.
 *
 * this does not seem to be a problem here for us though
 * TODO: investigate (sometime ...)
 *
 */
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

type CTAsManagerErrors = Record<
  string,
  { label: string | boolean; text: string | boolean }
>;
type Props = EditorFieldProps<CallToActions> & {
  id: string;
  changeHandler: (value: any) => void;
  errors: CTAsManagerErrors;
};

export const EditorCTAsManager: React.FC<Props> = ({
  id,
  value,
  changeHandler,
  errors,
}) => {
  const { t } = useTranslation();

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler],
  );

  /** manipulate the CTAs locally */
  const [CTAs, setCTAs] = useState(value);

  const updateCTAs = (storeName: string, options: CTAOptions) => {
    setCTAs(
      produce(CTAs, (draft) => {
        draft.stores.options[storeName] = options;
      }),
    );
  };

  const reorderCTAs = (startIndex: number, endIndex: number) => {
    setCTAs(
      produce(CTAs, (draft) => {
        const [removed] = draft.stores.displayOrder.splice(startIndex, 1);
        draft.stores.displayOrder.splice(endIndex, 0, removed);
      }),
    );
  };

  /**
   * This will update the whole fields configs array in the Editor State
   */
  useEffect(() => {
    debouncedChangeHandler(CTAs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CTAs]);

  /**
   * handle the list once we are finished with the DnD
   */
  const handleDragEnd = (result) => {
    if (result.source && result.destination) {
      const startIndex = result.source.index;
      const endIndex = result.destination.index;
      reorderCTAs(startIndex, endIndex);
    }
  };

  /** FormFieldEditor panel state */
  const [expandedCTAEditorId, setExpandedCTAEditorId] = useState<
    string | boolean
  >(false);
  const handleCTAEditorExpansion = (editorId: string) => {
    if (expandedCTAEditorId === editorId) {
      setExpandedCTAEditorId(false);
    } else {
      setExpandedCTAEditorId(editorId);
    }
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => (
          <Container
            ref={provided.innerRef}
            role="list"
            aria-label={t(
              'ui.component.backlink_settings_data_editor.ctas_manager.label',
              'Call To Actions',
            )}
            {...provided.droppableProps}
          >
            {CTAs.stores.displayOrder.map((storeName, index) => {
              const ctaOptions = CTAs.stores.options[storeName];
              return (
                isDefined(ctaOptions) && (
                  <Draggable
                    draggableId={storeName}
                    index={index}
                    key={storeName}
                  >
                    {(provided) => (
                      <EditorStyled.DraggableItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        {/* note:
                        - the drag handle is located outside of the CTAEditor to avoid having to pollute it with beautiful-dnd props (dragHandleProps)
                        - the inline styles are linked to the fact that the drag handler is absolutely positioned above the CTAEditor
                          and should override the defauts set in the styled component (thus the use of the style prop)
                          reasoning: it felt like they would be better suited here where the actual dragged item is located */}
                        <EditorStyled.DragHandle
                          {...provided.dragHandleProps}
                          style={{
                            top: '1rem',
                            left: '1rem',
                          }}
                        >
                          <EditorStyled.DragHandleIcon />
                        </EditorStyled.DragHandle>
                        <CTAEditor
                          css={css`
                            padding-left: 2.125rem; /* this style is inlined because it is here only to make room for the drag handle */
                          `}
                          storeName={storeName}
                          ctaOptions={ctaOptions}
                          changeHandler={updateCTAs}
                          isExpanded={storeName === expandedCTAEditorId}
                          expandHandler={handleCTAEditorExpansion}
                          errors={errors[storeName]}
                        />
                      </EditorStyled.DraggableItem>
                    )}
                  </Draggable>
                )
              );
            })}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

/** make an object that will be used in CTAsManager to show errors for the editable fields */
export function makeCTAsErrorsMap(
  path: string,
  stores: string[],
  errors: ValidationErrors,
): CTAsManagerErrors {
  return stores.reduce(
    (ctasErrors, storeName) => ({
      ...ctasErrors,
      [storeName]: {
        label: errors[`${path}.stores.options.${storeName}.label`],
        text: errors[`${path}.stores.options.${storeName}.text`],
      },
    }),
    {},
  );
}

export default EditorCTAsManager;
