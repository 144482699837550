import { ReleaseEditorState, ReleaseEditorStep } from '../types';
import { Action, ActionType } from './actions';
import { initialState } from './context';

export function reducer(
  state: ReleaseEditorState,
  action: Action,
): ReleaseEditorState {
  switch (action.type) {
    case ActionType.CHANGE_STEP: {
      const { currentStep } = action.payload;
      switch (currentStep) {
        case ReleaseEditorStep.ARTIST:
        case ReleaseEditorStep.SAVING: {
          return { ...state, currentStep };
        }
        case ReleaseEditorStep.PRODUCT: {
          /** reset artist when going back */
          return { ...state, currentStep, artist: undefined, artistQuery: '' };
        }
        /** UNINITIALIZED OR SAVED: reset state*/
        default: {
          return {
            ...initialState,
            releaseId: state.releaseId,
            onReleaseCreated: state.onReleaseCreated,
          };
        }
      }
    }
    case ActionType.UPDATE_ARTIST_QUERY: {
      const { artistQuery } = action.payload;
      return {
        ...state,
        artistQuery,
      };
    }
    case ActionType.UPDATE_ARTIST: {
      const { artist } = action.payload;
      return {
        ...state,
        artist,
      };
    }
    case ActionType.UPDATE_PRODUCT_QUERY: {
      const { productQuery } = action.payload;
      return {
        ...state,
        productQuery,
      };
    }
    case ActionType.UPDATE_PRODUCT: {
      const { product } = action.payload;
      return {
        ...state,
        product,
      };
    }
    default: {
      return state;
    }
  }
}
