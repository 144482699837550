import { DEFAULT_TIMEZONE } from 'shared/utils/date';

import { BACKLINK_OPTIN_COMPETITION_MIN_AGE } from '../backlink/backlink.constants';
import {
  WorkspaceSettingsEmployee,
  WorkspaceSettingsCustomer,
  CustomerServiceLevel,
} from './workspace.types';

export const workspaceSettingsEmployeeDefault: WorkspaceSettingsEmployee = {
  privacyPolicyUrl: '',
  legalNoticeUrl: '',
  producerIds: [],
  trackPitchTargetMarketIds: [],
  customerServiceLevels: [CustomerServiceLevel.AS],
  facebookPixelId: '',
  googleAdsId: '',
  xandrPixelId: '',
  competitionRulesTemplate: '',
  competitionMinimumAge: BACKLINK_OPTIN_COMPETITION_MIN_AGE,
  territory: '',
  defaultTimezone: DEFAULT_TIMEZONE,
};

export const workspaceSettingsCustomerDefault: WorkspaceSettingsCustomer = {
  userLocale: 'en',
  legalIdentity: '',
  contactEmail: '',
  privacyPolicyUrl: '',
  marketingServices: '',
  defaultTimezone: '',
};
