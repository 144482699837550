import React, { useState, useEffect } from 'react';

import { useAppContext } from '../../state';
import { AppNotification } from '../../types';
import AppNotificationBar from '../AppNotificationBar';

export const Notifier = () => {
  const { state: appState } = useAppContext();
  const [notification, setNotification] = useState<AppNotification>();
  useEffect(() => {
    // we want to display the last notification added
    setNotification([...appState.notifications].pop());
  }, [appState.notifications]);
  return (
    <>{notification && <AppNotificationBar notification={notification} />}</>
  );
};
