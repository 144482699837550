import styled from '@emotion/styled';

import { Card as CardBase, CardHeader as CardHeaderBase } from '../../styled';

export const CardHeader = styled(CardHeaderBase)`
  font-weight: normal;
`;

export const Card = styled(CardBase)`
  padding: 0 1.4rem;
  border: solid 1px ${({ theme }) => theme.palette.grey[500]};
  background-color: ${({ theme }) => theme.palette.common.white};
  > * {
    padding: 0;
  }
  ${({ theme, isExpanded }) => {
    if (isExpanded) {
      return `${CardHeader} {
        border-bottom: solid 1px ${theme.palette.grey[300]}
      }`;
    }
  }}
  .MuiCardHeader-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiCollapse-root {
    padding: 1rem 0 0 0;
  }
  .MuiFormLabel-root {
    flex: 0 1 60%;
    margin-bottom: 0;
  }
  .fields-container {
    padding: 0 0.7rem 0 1.4rem;
  }
`;

Card.defaultProps = {
  variant: 'elevation',
  elevation: 0,
};
