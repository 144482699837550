import { Moment } from 'moment';
import moment from 'moment-timezone';

export function getNotifyMinDateTime(
  digitalReleaseDate: string,
  timezone: string,
): Moment {
  return moment.tz(digitalReleaseDate, timezone).add(1, 'hour');
}

export function getNotifyMaxDateTime(
  digitalReleaseDate: string,
  timezone: string,
): Moment {
  return moment.tz(digitalReleaseDate, timezone).add(3, 'days');
}

export function isNotifyDateValid(
  notifyDate: string,
  digitalReleaseDate: string,
  timezone: string,
) {
  const localizedNotifyDate = moment.tz(notifyDate, timezone);
  return (
    localizedNotifyDate.isSameOrAfter(
      getNotifyMinDateTime(digitalReleaseDate, timezone),
    ) &&
    localizedNotifyDate.isSameOrBefore(
      getNotifyMaxDateTime(digitalReleaseDate, timezone),
    )
  );
}
