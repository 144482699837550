import styled from '@emotion/styled';

import { Button as MuiButton, Fab } from '@mui/material';

export const FlatFab = styled(Fab)`
  ${({ size }) => {
    if (size === 'small') {
      return `
      width: 50px;
      height: 50px;
    `;
    }
  }}
  box-shadow: none;
`;

export const Button = styled(MuiButton)`
  justify-content: flex-end;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  min-height: 56px;
  border-radius: 5px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
  .MuiButton-startIcon,
  .MuiButton-endIcon {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
  .MuiButton-startIcon {
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-right: 0;
  }
`;

Button.defaultProps = {
  disableElevation: true,
  variant: 'contained',
};

export const WideButton = styled(Button)`
  width: 430px;
  max-width: 100%;
`;

WideButton.defaultProps = {
  disableElevation: true,
  variant: 'contained',
};

export const CancelButton = styled(MuiButton)`
  background-color: #919cbb;
  border-color: #919cbb;
  color: ${({ theme }) => theme.palette.common.white};

  &:hover {
    background-color: '#69728a';
    border-color: '#69728a';
  }
`;

CancelButton.defaultProps = {
  variant: 'contained',
};
