import faker from 'faker';
import moment from 'moment';

import {
  BacklinkAnalyticsOverviewData,
  BacklinkAnalyticsVisitsData,
  BacklinkAnalyticsDevice,
  BacklinkAnalyticsStoreClicksByStoreData,
  BacklinkAnalyticsStoreClicksByStoreTypeData,
  BacklinkAnalyticsPresavesData,
  BacklinkAnalyticsOptinsData,
  BacklinkAnalyticsSourcesReferrer,
  ReferrerType,
  ChannelType,
  BacklinkAnalyticsSourcesChannelsData,
  BacklinkAnalyticsSourcesReferrersData,
  BacklinkAnalyticsFanNotificationData,
} from 'shared/entities/backlink/analytics/backlink.analytics.types';
import { StoreType } from 'shared/entities/storeConfig/storeConfig.types';

import { BACKLINK_ANALYTICS_DATE_FORMAT } from '../../analytics/backlink.analytics.constants';

export function generateBacklinkAnalyticsOverviewFixture({
  startDate = '2019-01-01',
  count = 10,
}: {
  startDate?: string;
  count?: number;
} = {}): BacklinkAnalyticsOverviewData {
  const startDateMoment = moment(startDate, BACKLINK_ANALYTICS_DATE_FORMAT);
  let nextDateMoment = startDateMoment;
  return {
    lastUpdate: '2021-01-21T17:47:26.853Z',
    dailyRows: [...Array(count)].map(() => {
      const dayRow = {
        date: nextDateMoment.format(BACKLINK_ANALYTICS_DATE_FORMAT),
        optins: faker.random.number({ min: 50, max: 1000, precision: 1 }),
        visited: faker.random.number({ min: 50, max: 1000, precision: 1 }),
        clickedToStore: faker.random.number({
          min: 50,
          max: 1000,
          precision: 1,
        }),
        preSaved: faker.random.number({ min: 50, max: 1000, precision: 1 }),
      };
      nextDateMoment = nextDateMoment.add(1, 'day');
      return dayRow;
    }),
    overview: {
      visited: faker.random.number({ min: 0, max: 10000, precision: 1 }),
      clickedToStore: faker.random.number({ min: 0, max: 2000, precision: 1 }),
      preSaved: faker.random.number({ min: 0, max: 2000, precision: 1 }),
      optins: faker.random.number({ min: 0, max: 2000, precision: 1 }),
    },
  };
}

export function generateBacklinkAnalyticsStoreClicksByStoreFixture(): BacklinkAnalyticsStoreClicksByStoreData {
  return {
    storesConversionClicks: [
      {
        clickedToStore: 1983,
        type: StoreType.Stream,
        storeName: 'deezer',
      },
      {
        clickedToStore: 10987,
        type: StoreType.Stream,
        storeName: 'hungama',
      },
      {
        clickedToStore: 2357,
        type: StoreType.Stream,
        storeName: 'spotify',
      },
      {
        clickedToStore: 2342,
        type: StoreType.Watch,
        storeName: 'youTube',
      },
      {
        clickedToStore: 4223,
        type: StoreType.Download,
        storeName: 'jiosaavn',
      },
      {
        clickedToStore: 6545,
        type: StoreType.Stream,
        storeName: 'appleMusic',
      },
      {
        clickedToStore: 3257,
        type: StoreType.Stream,
        storeName: 'amazonMusic',
      },
    ],
    totalStoresReach: 31694,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

const HITS_BASE = 31694 / 8;

export function generateBacklinkAnalyticsStoreClicksByStoreTypeFixture(): BacklinkAnalyticsStoreClicksByStoreTypeData {
  return {
    storesConversionTypes: [
      {
        clickedToStore: Math.round(HITS_BASE * 1),
        type: StoreType.Stream,
      },
      {
        clickedToStore: Math.round(HITS_BASE * 2),
        type: StoreType.Download,
      },
      {
        clickedToStore: Math.round(HITS_BASE * 5),
        type: StoreType.Buy,
      },
      {
        clickedToStore: Math.round(HITS_BASE * 5),
        type: StoreType.Watch,
      },
      {
        clickedToStore: Math.round(HITS_BASE * 5),
        type: StoreType.Follow,
      },
      {
        clickedToStore: Math.round(HITS_BASE * 5),
        type: StoreType.Other,
      },
    ],
    totalStoresReach: 31694,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export const generateBacklinkAnalyticsPresavesFixture =
  (): BacklinkAnalyticsPresavesData => {
    return {
      actionStoresConversionPresave: [
        {
          storeName: 'spotify',
          preSaved: Math.round(HITS_BASE * 1),
        },
        {
          storeName: 'appleMusic',
          preSaved: Math.round(HITS_BASE * 2),
        },
        {
          storeName: 'fnac',
          preSaved: Math.round(HITS_BASE * 5),
        },
      ],
      totalActionsStoresPresaved: 31694,
      lastUpdate: '2021-02-12T17:45:07.444Z',
    };
  };

export function generateBacklinkAnalyticsFanNotificationFixture(): BacklinkAnalyticsFanNotificationData {
  return {
    send: 186,
    open: 50,
    click: 7,
    bounce: 2,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticsOptinsFixture(): BacklinkAnalyticsOptinsData {
  const subscribersConversion = [
    {
      type: 'subscription',
      subscribers: 15768,
    },
  ];
  return {
    subscribersConversion,
    totalSubscribers: subscribersConversion.reduce(
      (accu, item) => (accu += item.subscribers),
      0,
    ),
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticssourcesByReferrerFixture(): BacklinkAnalyticsSourcesReferrersData {
  const referrersSources: BacklinkAnalyticsSourcesReferrer[] = [
    {
      referrerType: ReferrerType.referral,
      referrerSource: 'm.facebook.com',
      visited: 43210,
      clickedToStore: Math.round(43210 / 6),
      ratio: 1 / 6,
    },
    {
      referrerType: ReferrerType.cpc,
      referrerSource: 'tiktok.com',
      visited: 12345,
      clickedToStore: Math.round(12345 / 7),
      ratio: 1 / 7,
    },
    {
      referrerType: ReferrerType.other,
      referrerSource: '(direct)',
      visited: 34567,
      clickedToStore: Math.round(34567 / 3),
      ratio: 1 / 3,
    },
    {
      referrerType: ReferrerType.organic,
      referrerSource: 'facebook.com',
      visited: 34567,
      clickedToStore: Math.round(34567 / 3),
      ratio: 1 / 3,
    },
    {
      referrerType: ReferrerType.email,
      referrerSource: 'm.facebook.com',
      visited: 2123,
      clickedToStore: Math.round(2123 / 3),
      ratio: 1 / 3,
    },
    {
      referrerType: 'some_unexpected_medium' as ReferrerType,
      referrerSource: '(unexpected)',
      visited: 666,
      clickedToStore: Math.round(666 / 3),
      ratio: 1 / 3,
    },
  ];
  const totals = {
    totalVisited: referrersSources.reduce(
      (accu, item) => (accu += item.visited),
      0,
    ),
    totalClickedToStore: referrersSources.reduce(
      (accu, item) => (accu += item.clickedToStore),
      0,
    ),
  };
  return {
    referrersSources,
    ...totals,
    totalRatio:
      totals.totalVisited > 0
        ? totals.totalClickedToStore / totals.totalVisited
        : 0,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticssourcesByChannelFixture(): BacklinkAnalyticsSourcesChannelsData {
  const channelsSources = [
    {
      channelName: 'Instagram Story Ad',
      channelType: ChannelType.paid,
      visited: 43210,
      clickedToStore: Math.round(43210 / 6),
      ratio: 1 / 6,
    },
    {
      channelName: 'Facebook Ad',
      // we might receive channel types with a different casing than what we want to deal with, i.e. lowercases values
      channelType: 'Paid' as ChannelType,
      visited: 12345,
      clickedToStore: Math.round(12345 / 7),
      ratio: 1 / 7,
    },
    {
      channelName: 'TikTok',
      channelType: ChannelType.referral,
      visited: 34567,
      clickedToStore: Math.round(34567 / 3),
      ratio: 1 / 3,
    },
    {
      channelName: 'Unknown',
      channelType: 'unknown' as ChannelType,
      visited: 34567,
      clickedToStore: Math.round(34567 / 3),
      ratio: 1 / 3,
    },
  ];
  const totals = {
    totalVisited: channelsSources.reduce(
      (accu, item) => (accu += item.visited),
      0,
    ),
    totalClickedToStore: channelsSources.reduce(
      (accu, item) => (accu += item.clickedToStore),
      0,
    ),
  };
  return {
    channelsSources,
    ...totals,
    totalRatio:
      totals.totalVisited > 0
        ? totals.totalClickedToStore / totals.totalVisited
        : 0,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticsLocationsByCountryFixture() {
  const visits = [
    // the api might return visits without ISOCodeCountry
    {
      visited: 8765,
      clickedToStore: Math.round(8765 / 4),
      ratio: 1 / 4,
    },
    {
      ISOCodeCountry: 'NOT_A_VALID_CODE',
      visited: 43210,
      clickedToStore: Math.round(43210 / 6),
      ratio: 1 / 6,
    },
    {
      ISOCodeCountry: 'VN',
      visited: 12345,
      clickedToStore: Math.round(12345 / 7),
      ratio: 1 / 7,
    },
    {
      ISOCodeCountry: 'GB',
      visited: 34567,
      clickedToStore: Math.round(34567 / 3),
      ratio: 1 / 3,
    },
    // country exists in Countries list but not the corresponding flag in the flags image repo
    // we should handle this case
    {
      ISOCodeCountry: 'YT', // ATM this is the Mayotte code and no flag exists for this region
      visited: 452,
      clickedToStore: Math.round(452 / 3),
      ratio: 1 / 3,
    },
  ];
  const totals = {
    totalVisited: visits.reduce((accu, item) => (accu += item.visited), 0),
    totalClickedToStore: visits.reduce(
      (accu, item) => (accu += item.clickedToStore),
      0,
    ),
  };
  return {
    visits,
    ...totals,
    totalRatio:
      totals.totalVisited > 0
        ? totals.totalClickedToStore / totals.totalVisited
        : 0,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticsLocationsByCityFixture() {
  const visits = [
    // the api might return visits without ISOCodeCountry
    {
      cityName: 'Reykjavík',
      visited: 200,
      clickedToStore: 5,
      ratio: 5 / 200,
    },
    // the api might return visits without cityName
    {
      ISOCodeCountry: 'FR',
      visited: 200,
      clickedToStore: 5,
      ratio: 5 / 200,
    },
    // the api might return visits without ISOCodeCountry nor cityName
    {
      visited: 200,
      clickedToStore: 5,
      ratio: 5 / 200,
    },
    {
      cityName: 'Meudon',
      ISOCodeCountry: 'FR',
      visited: 200,
      clickedToStore: 5,
      ratio: 5 / 200,
    },
    {
      cityName: 'Sainom',
      ISOCodeCountry: 'FR',
      visited: 43210,
      clickedToStore: Math.round(43210 / 3),
      ratio: 1 / 3,
    },
    {
      cityName: 'Jaudon',
      ISOCodeCountry: 'GB',
      visited: 12345,
      clickedToStore: Math.round(12345 / 4),
      ratio: 1 / 4,
    },
    {
      cityName: 'Lordon',
      ISOCodeCountry: 'GB',
      visited: 34567,
      clickedToStore: Math.round(34567 / 6),
      ratio: 1 / 6,
    },
    {
      cityName: 'Cordon',
      ISOCodeCountry: 'DE',
      visited: 8765,
      clickedToStore: Math.round(8765 / 12),
      ratio: 1 / 12,
    },
  ];
  const totals = {
    totalVisited: visits.reduce((accu, item) => (accu += item.visited), 0),
    totalClickedToStore: visits.reduce(
      (accu, item) => (accu += item.clickedToStore),
      0,
    ),
  };
  return {
    visits,
    ...totals,
    totalRatio:
      totals.totalVisited > 0
        ? totals.totalClickedToStore / totals.totalVisited
        : 0,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}

export function generateBacklinkAnalyticsDevicesFixture(): BacklinkAnalyticsVisitsData<BacklinkAnalyticsDevice> {
  const visits = [
    {
      deviceName: 'Desktop',
      visited: 200,
      clickedToStore: 5,
      ratio: 5 / 200,
    },
    {
      deviceName: 'Mobile',
      visited: 43210,
      clickedToStore: Math.round(43210 / 3),
      ratio: 1 / 3,
    },
    {
      deviceName: 'Tablet',
      visited: 12345,
      clickedToStore: Math.round(12345 / 4),
      ratio: 1 / 4,
    },
  ];
  const totals = {
    totalVisited: visits.reduce((accu, item) => (accu += item.visited), 0),
    totalClickedToStore: visits.reduce(
      (accu, item) => (accu += item.clickedToStore),
      0,
    ),
  };
  return {
    visits,
    ...totals,
    totalRatio:
      totals.totalVisited > 0
        ? totals.totalClickedToStore / totals.totalVisited
        : 0,
    lastUpdate: '2021-02-12T17:45:07.444Z',
  };
}
