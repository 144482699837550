import config from 'shared/config';

// Available filters (to put in edits) here
// => https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/appendix-d.html
// Feel free to add new filters support in this function
// Test it here (does not include all available filtres):
//   => https://d28qxohyp04jy6.cloudfront.net/index.html
// Valid example of edits value:
// edits: {
//   resize: {
//     width: 150,
//     height: 3000,
//     fit: 'fill',
//     background: {
//        r: 119,
//        g: 119,
//        b: 119,
//        alpha: 1
//      }
//    },
//    flatten: true,
//    grayscale: true,
//    flip: true,
//    flop: true,
//    negate: true,
//    normalise: true,
//    blur: 15,
// },
export const buildImageUrl = ({
  imageId,
  edits,
}: {
  imageId: string;
  edits?: object;
}) => {
  const imageRequest = JSON.stringify({
    bucket: config.imageBucketName,
    key: imageId,
    edits,
  });
  const url = `${config.imageBaseUrl}/${btoa(imageRequest)}`;
  return url;
};
