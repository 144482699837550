import { rest } from 'msw';

import config from 'shared/config';

const baseUrl = config.gitlabFeatureFlagsProxy.url;

export const MOCKED_FEATURE_FLAGS: Record<string, boolean> = {
  ads_las: true,
  bsd_global: true,
  jimo: true,
  'vmo_rule-video-playlist-selector': true,
  'vmo_rule-has_good_standing_status-enabled': true,
  'vmo_rule-each_video_has_custom_thumbnail-has_autofix_all': true,
  'vmo_rule-each-playlist_title_references_db_channel_name-has_autofix_all':
    true,
  'vmo_rule-has_all_ads_format_activated-enabled': true,
  'vmo_rule-has_created_playlist_last_28_days-enabled': true,
  'vmo_rule-each_video_has_endscreen-enabled': true,
  'vmo_rule-each_video_has_endscreen-has_autofix_all': true,
  'vmo_rule-each_video_has_cards-enabled': true,
  'vmo_rule-each_video_has_cards-has_autofix_all': true,
  'vmo_rule-each_video_has_no_limited_monetization-enabled': true,
  'vmo_rule-each_video_has_good_thumbnail_click_through_rate-enabled': true, // there is no typo in the flag name - it must not be longer than 63 characters so we truncate the rule name @see the README.md inside the VMO section directory (YoutubeChannels)
  'vmo_feature-playlist-manager': true,
  'vmo_feature-playlist-selector-cta-new-playlist-enabled': true,
  vmo_advanced_search_filters: true,
  use_updated_mkt_suite_kb_url: true,
  backstage_zendesk: true,
  mkt_suite_zendesk: true,
  mkt_suite_salesforce: true,
  trackpitch_section_enabled: true,
  refresh_release_component_enabled: true,
  trackpitch_filter_enabled: true,
  new_ads_as: true,
  backlinks_performance_enabled: true,
  playerstats_section_enabled: true,
  players_section_enabled: true,
  artist_official_playlist_enabled: true,
  links_editor_v2_enabled: true,
  tpt_audioplayer_enabled: true,
  ads_new_dual_run_enabled: true,
  ads_new_dual_run_v2_enabled: true,
  promoplan_herocards: true,
  promoplan_artist_resources: true,
  'vmo_feature-dea-score': true,
  promoplan_optimization_banner: true,
  access_pricing_plan_enabled: false,
};

const handlers = [
  rest.get(baseUrl, (req, res, ctx) => {
    return res(
      ctx.json({
        toggles: Object.entries(MOCKED_FEATURE_FLAGS).map(
          ([name, enabled]) => ({
            enabled,
            name,
            variant: { name: 'TEST', enabled: !enabled },
          }),
        ),
      }),
    );
  }),
  rest.post(`${baseUrl}/client/metrics`, (req, res, ctx) => {
    // Do nothing (prevent the request from hitting the staging gateway)
    return res(ctx.status(200));
  }),
];

export default handlers;
