import styled from '@emotion/styled';

import Switcher from 'shared/components/Switcher';

export const StyledLocaleSwitcher = styled(Switcher)`
  .leftNavIcon {
    margin-left: -1.1rem;
  }
  .rightNavIcon {
    margin-right: -1.1rem;
  }
  .labelContainer {
    .icon {
      color: ${({ theme }) => theme.palette.grey[500]};
    }
    .MuiIconButton-root {
      margin: 0 ${({ theme }) => theme.spacing(1 / 3)};
    }
  }
`;
