/** External */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Hidden, useMediaQuery, useTheme } from '@mui/material';

import RouteLeavingGuard from 'shared/components/RouteLeavingGuard';
import { authUserIsAdmin } from 'shared/entities/auth/auth.types';
import {
  BacklinkSettingsData,
  BacklinkSettingsParentEntityInfos,
  BacklinkSettingsParentEntityType,
  StoreLink,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { syncStoreLinksListWithStoresMap } from 'shared/entities/backlinkSettings/backlinkSettings.utils';
import { useBeforeUnloadGuard } from 'shared/hooks/useBeforeUnloadGuard';
import { useAppContext, useAppWideData } from 'shared/state';

import EditorDebug from './components/EditorDebug';
import { EditorStepSwitcher } from './components/menus/EditorStepSwitcher';
import EditorMenu from './components/menus/EditorStepsMenu';
import { EDIT_PATH_REGEXP } from './constants';
import { useBacklinkSettingsEditorState } from './hooks/useBacklinkSettingsDataEditorState';
import useEditorLocale from './hooks/useEditorLocale';
import useEditorStep from './hooks/useEditorStep';
import useEditorSteps from './hooks/useEditorSteps';
import {
  BacklinkSettingsEditorStepCategory,
  StepKey,
  getReleaseMode,
} from './state';
import { ActionsTypes } from './state/actions';
import { BacklinkSettingsDataEditorContext } from './state/context';

import * as Styled from './styled';

export type RenderActionsParams = {
  editorState: BacklinkSettingsData;
  hasValidationErrors: boolean;
};

export type BaseProps<TEntityType extends BacklinkSettingsParentEntityType> = {
  /**
   * the backlinksSettings object we want to edit
   */
  initialState: BacklinkSettingsData;

  /**
   * helps branching code depending on the type of Entity the BacklinkSettingsData belongs to
   * and get properties from this entity when necessary
   *
   * note: we set a string 'type' to allow declaring a parent type without necessarily havig to pass its data
   */
  parentEntityInfos?: BacklinkSettingsParentEntityInfos<TEntityType>;

  /**
   * handles each state change
   * will allow to drive the Preview of a Backlink for instance
   */
  onStateChange?: (data: BacklinkSettingsData) => void;

  /**
   * handles step change
   */
  onStepChange?: (step: StepKey) => void;

  /**
   * handles locale change
   */
  onCurrentLocaleChange?: (locale: string) => void;

  /**
   * a render prop
   * allowing to custom render main actions for the editor
   * mainly: the Save button
   */
  renderActions: ({
    editorState,
    hasValidationErrors,
  }: RenderActionsParams) => React.ReactNode;
};

type DistributeEntityTypesOverProps<
  TEntityType extends BacklinkSettingsParentEntityType,
> = TEntityType extends BacklinkSettingsParentEntityType
  ? BaseProps<TEntityType>
  : never;

export type Props =
  DistributeEntityTypesOverProps<BacklinkSettingsParentEntityType>;

const BacklinkSettingsDataEditor = ({
  initialState,
  parentEntityInfos,
  onStateChange = (editorState) => null,
  onStepChange = (stepKey) => null,
  onCurrentLocaleChange = (locale) => null,
  renderActions,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const {
    state: { user },
  } = useAppContext();

  const { storesConfigs } = useAppWideData();

  const showDebug =
    (isLargeDesktop && authUserIsAdmin(user)) ||
    // we want to be able to also debug within BackstageMarketing - its users (Customer) do not have a role (ATM)
    window.location.search.match(/debug/);

  /** State */
  const {
    state: editorState,
    hasChanges: editorStateHasChanges,
    dispatch,
    getFieldProps,
    validation,
  } = useBacklinkSettingsEditorState({
    initialState,
    onStateChange,
    parentEntityInfos,
  });

  /** step */
  const { stepKey, stepsVisited, Step } = useEditorStep({
    onStepChange,
  });
  const { steps } = useEditorSteps();

  /** locales */
  const { locales, currentLocale, handleCurrentLocaleChange } = useEditorLocale(
    {
      editorState,
      onCurrentLocaleChange,
    },
  );

  const releaseStores = editorState.releaseStep.release.stores;

  useEffect(() => {
    if (releaseStores && storesConfigs) {
      dispatch({
        type: ActionsTypes.CTAsReleaseStoresSyncRequired,
        payload: {
          storesConfigs,
        },
      });
    }
  }, [dispatch, releaseStores, locales, storesConfigs]);

  /**
   * Synchronize prerelease redirect urls with master stores
   * TODO: migrate to editor state reducer
   */
  useEffect(() => {
    if (releaseStores) {
      const {
        value: storesRedirects = [] as StoreLink[],
        changeHandler: storesChangeHandler,
      } = getFieldProps('prereleaseFormStep.redirect.stores');
      const synchronizedStores: StoreLink[] = syncStoreLinksListWithStoresMap(
        releaseStores,
        storesRedirects,
      );
      storesChangeHandler(synchronizedStores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseStores]);

  /** prevent navigating away from the page when unsaved changes */
  useBeforeUnloadGuard(editorStateHasChanges);

  /**
   * muted categories are still reachable
   * but we want to indicate that they are 'secondary' in a given context
   */
  const mutedCategories: BacklinkSettingsEditorStepCategory[] =
    parentEntityInfos &&
    parentEntityInfos.type === 'Backlink' &&
    getReleaseMode(editorState) === 'post-release'
      ? [t('ui.component.backlink_settings_data_editor.category.pre-release')]
      : [];

  return (
    <BacklinkSettingsDataEditorContext.Provider
      value={{
        stepKey,
        editorState,
        dispatch,
        getFieldProps,
        parentEntityInfos,
        locales,
        currentLocale,
        handleCurrentLocaleChange,
      }}
    >
      <validation.Context.Provider
        value={{ errors: validation.errors, setErrors: validation.setErrors }}
      >
        <Styled.Container container>
          <Hidden lgDown>
            <Styled.MenuContainer item>
              <EditorMenu
                steps={steps}
                validationErrors={validation.errors}
                createMode={false}
                stepsVisited={stepsVisited}
                mutedCategories={mutedCategories}
              />
            </Styled.MenuContainer>
          </Hidden>
          <Styled.StepContainer item role="group" aria-label={stepKey}>
            <>
              <Step title={steps[stepKey].title} />
              {showDebug && <EditorDebug />}
            </>
            {/* in app navigation guard */}
            <RouteLeavingGuard
              when={editorStateHasChanges}
              shouldBlockNavigation={(location) =>
                !location.pathname.match(EDIT_PATH_REGEXP)
              }
            />
          </Styled.StepContainer>
          {/* Actions */}
          {renderActions({
            editorState,
            hasValidationErrors: validation.hasErrors,
          })}
          {/* mobile step nav */}
          <Hidden lgUp>
            <EditorStepSwitcher steps={Object.values(steps)} />
          </Hidden>
        </Styled.Container>
      </validation.Context.Provider>
    </BacklinkSettingsDataEditorContext.Provider>
  );
};

export default BacklinkSettingsDataEditor;
