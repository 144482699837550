import { StoreConfig, StoreType } from '../../storeConfig.types';

export const storesConfigs: Record<string, StoreConfig> = {
  amazonMusic: {
    name: 'amazonMusic',
    type: StoreType.Stream,
    displayName: 'Amazon Music',
    canUseAutoscan: true,
    releaseFallbackSearchUrlTemplate:
      'https://open.spotify.com/search/{releaseTitle}%20{artistName}',
    releaseFallbackSearchDesktopCompatible: true,
    releaseFallbackSearchMobileCompatible: true,
  },
  amazonStore: {
    name: 'amazonStore',
    type: StoreType.Buy,
    displayName: 'Amazon Store',
    canUseAutoscan: false,
  },
  anghami: {
    name: 'anghami',
    type: StoreType.Stream,
    displayName: 'Anghami',
    canUseAutoscan: true,
  },
  appleMusic: {
    name: 'appleMusic',
    type: StoreType.Stream,
    displayName: 'Apple Music',
    canUseAutoscan: true,
    hasConnect: true,
  },
  bandcamp: {
    name: 'bandcamp',
    type: StoreType.Stream,
    displayName: 'Bandcamp',
    canUseAutoscan: false,
  },
  beatport: {
    name: 'beatport',
    type: StoreType.Stream,
    displayName: 'Beatport',
    canUseAutoscan: false,
  },
  claroMusica: {
    name: 'claroMusica',
    type: StoreType.Stream,
    displayName: 'Claro Musica',
    canUseAutoscan: false,
  },
  cultura: {
    name: 'cultura',
    type: StoreType.Buy,
    displayName: 'Cultura',
    canUseAutoscan: false,
  },
  deezer: {
    name: 'deezer',
    type: StoreType.Stream,
    displayName: 'Deezer',
    canUseAutoscan: true,
    hasConnect: true,
  },
  fnac: {
    name: 'fnac',
    type: StoreType.Buy,
    displayName: 'Fnac',
    canUseAutoscan: false,
  },
  huaweiMusic: {
    name: 'huaweiMusic',
    type: StoreType.Stream,
    displayName: 'Huawei Music',
    canUseAutoscan: false,
  },
  hungama: {
    name: 'hungama',
    type: StoreType.Stream,
    displayName: 'Hungama',
    canUseAutoscan: false,
  },
  itunes: {
    name: 'itunes',
    type: StoreType.Download,
    displayName: 'Itunes',
    canUseAutoscan: false,
  },
  joox: {
    name: 'joox',
    type: StoreType.Stream,
    displayName: 'Joox',
    canUseAutoscan: false,
  },
  kkbox: {
    name: 'kkbox',
    type: StoreType.Stream,
    displayName: 'Kkbox',
    canUseAutoscan: false,
  },
  langitMusikMelon: {
    name: 'langitMusikMelon',
    type: StoreType.Stream,
    displayName: 'Langit Musik Melon',
    canUseAutoscan: false,
  },
  leclerc: {
    name: 'leclerc',
    type: StoreType.Buy,
    displayName: 'Leclerc',
    canUseAutoscan: false,
  },
  napster: {
    name: 'napster',
    type: StoreType.Stream,
    displayName: 'Napster',
    canUseAutoscan: false,
  },
  officialStore: {
    name: 'officialStore',
    type: StoreType.Buy,
    displayName: 'Official Store',
    canUseAutoscan: false,
  },
  qobuz: {
    name: 'qobuz',
    type: StoreType.Stream,
    displayName: 'Qobuz',
    canUseAutoscan: false,
  },
  qobuzStore: {
    name: 'qobuzStore',
    type: StoreType.Download,
    displayName: 'Qobuz Store',
    canUseAutoscan: false,
  },
  qqMusic: {
    name: 'qqMusic',
    type: StoreType.Stream,
    displayName: 'Qq Music',
    canUseAutoscan: false,
  },
  resso: {
    name: 'resso',
    type: StoreType.Stream,
    displayName: 'Resso',
    canUseAutoscan: false,
  },
  songkick: {
    name: 'songkick',
    type: StoreType.Buy,
    displayName: 'Songkick',
    canUseAutoscan: false,
  },
  soundcloud: {
    name: 'soundcloud',
    type: StoreType.Stream,
    displayName: 'SoundCloud',
    canUseAutoscan: false,
  },
  spotify: {
    name: 'spotify',
    type: StoreType.Stream,
    displayName: 'Spotify',
    canUseAutoscan: true,
    hasConnect: true,
  },
  ticketMaster: {
    name: 'ticketMaster',
    type: StoreType.Buy,
    displayName: 'Ticket Master',
    canUseAutoscan: false,
  },
  tidal: {
    name: 'tidal',
    type: StoreType.Stream,
    displayName: 'Tidal',
    canUseAutoscan: false,
  },
  tiktok: {
    name: 'tiktok',
    type: StoreType.Follow,
    displayName: 'Tiktok',
    canUseAutoscan: false,
  },
  vevo: {
    name: 'vevo',
    type: StoreType.Stream,
    displayName: 'Vevo',
    canUseAutoscan: false,
  },
  youtube: {
    name: 'youtube',
    type: StoreType.Watch,
    displayName: 'YouTube',
    canUseAutoscan: true,
  },
  youtubeMusic: {
    name: 'youtubeMusic',
    type: StoreType.Stream,
    displayName: 'YouTube Music',
    canUseAutoscan: false,
  },
  gaana: {
    name: 'gaana',
    displayName: 'Gaana',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  testService: {
    name: 'testService',
    displayName: 'test service',
    type: StoreType.Other,
    canUseAutoscan: false,
  },
  wynk: {
    name: 'wynk',
    displayName: 'Wynk Music',
    type: StoreType.Stream,
    canUseAutoscan: true,
  },
  jiosaavn: {
    name: 'jiosaavn',
    displayName: 'JioSaavn',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  vk: {
    name: 'vk',
    displayName: 'VK Music',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  pandora: {
    name: 'pandora',
    displayName: 'Pandora',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  boom: {
    name: 'boom',
    displayName: 'Boom',
    type: StoreType.Buy,
    canUseAutoscan: false,
  },
  yandexMusic: {
    name: 'yandexMusic',
    displayName: 'Yandex Music',
    type: StoreType.Buy,
    canUseAutoscan: false,
  },
  fridayOmusic: {
    name: 'fridayOmusic',
    displayName: 'Friday Omusic',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  myMusic: {
    name: 'myMusic',
    displayName: 'MyMusic',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
  netease: {
    name: 'netease',
    displayName: 'Netease',
    type: StoreType.Stream,
    canUseAutoscan: false,
  },
};

export default storesConfigs;
