import React from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Menu, MenuItem } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import {
  FormFieldConfig,
  FormFieldConfigRecord,
} from '../EditorFormFieldsManager';

import { EditorButton } from '../../../styled';

/**
 * for i18next parser
 *
 * t('ui.component.backlink_settings_data_editor.form_field_selector.firstname', 'First Name')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.lastname', 'Last Name')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.phonenumber', 'Phone Number')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.email', 'Email')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.country', 'Country')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.optin_competition', 'Competition opt-in')
 * t('ui.component.backlink_settings_data_editor.form_field_selector.optin_subscription', 'Subscrition opt-in')
 */

const styles = {
  menuItem: css`
    min-width: 188px;
    text-align: right;
  `,
};

type Props = {
  fieldsConfigs: FormFieldConfigRecord;
  label?: string;
  onSelect: (fieldConfig: FormFieldConfig) => void;
};

export const FormFieldConfigSelector = ({
  fieldsConfigs,
  label,
  onSelect,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <EditorButton
            startIcon={<AddCircleRoundedIcon />}
            disabled={!Object.keys(fieldsConfigs).length}
            {...bindTrigger(popupState)}
          >
            {label ||
              t(
                'ui.component.backlink_settings_data_editor.form_field_selector.default.label',
                'Add field',
              )}
          </EditorButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'center', horizontal: 'right' }}
          >
            {Object.keys(fieldsConfigs).map((configKey) => (
              <MenuItem
                css={styles.menuItem}
                key={configKey}
                onClick={() => {
                  onSelect(fieldsConfigs[configKey]);
                  popupState.close();
                }}
              >
                {t(
                  `ui.component.backlink_settings_data_editor.form_field_selector.${fieldsConfigs[configKey].name}`,
                  fieldsConfigs[configKey].label as string,
                )}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
};
