/* eslint-disable react/function-component-definition */
import React from 'react';

import { Link } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import Fullscreen, { Props as FullscreenProps } from '.';

type Props = FullscreenProps & {
  to?: string;
  onClose?: () => void;
  children?: React.ReactNode;
};

const FullscreenWithCloseLinkButton = ({
  to,
  onClose,
  children,
  ...props
}: Props) => {
  const CloseButton = (
    <IconButton
      component={to ? Link : 'button'}
      to={to}
      onClick={onClose}
      size="small"
      // @ts-ignore (legacy theme)
      color="inherit"
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <Fullscreen actions={CloseButton} {...props}>
      {children}
    </Fullscreen>
  );
};

export default FullscreenWithCloseLinkButton;
