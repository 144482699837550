import { AuthUser } from 'shared/entities/auth/auth.types';

import { Actions, ActionsTypes } from './actions';
import { initialState } from './state';
import { AppState, Reducer } from './types';

export const reducer: Reducer<AppState<AuthUser>> = (
  state,
  action: Actions,
) => {
  switch (action.type) {
    case ActionsTypes.AppWideEventOccured: {
      const nextState = {
        ...state,
        ...action.payload.data,
      };
      if (action.payload.notification) {
        return {
          ...nextState,
          // We only keep one notification for now as we only display one at a time
          notifications: [action.payload.notification],
        };
      }
      return nextState;
    }
    case ActionsTypes.RequestingUserInfos: {
      return {
        ...state,
        authenticating: true,
      };
    }
    case ActionsTypes.UserInfosRequestFailed: {
      return {
        ...state,
        authenticating: false,
      };
    }
    case ActionsTypes.UserInfosReceived: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        authenticating: false,
      };
    }
    case ActionsTypes.authUserHasBeenLoggedOut: {
      return {
        ...state,
        user: initialState.user,
      };
    }
    case ActionsTypes.OpenArtistSettingsPanel: {
      const { artist, profileOnly } = action.payload;
      return {
        ...state,
        artistSettingsPanel: {
          open: true,
          artist,
          profileOnly,
        },
      };
    }
    case ActionsTypes.CloseArtistSettingsPanel: {
      return {
        ...state,
        artistSettingsPanel: {
          open: false,
        },
      };
    }
    case ActionsTypes.OpenTrackPitchPanel: {
      return {
        ...state,
        trackPitchPanel: {
          open: true,
        },
      };
    }
    case ActionsTypes.CloseTrackPitchPanel: {
      return {
        ...state,
        trackPitchPanel: {
          open: false,
        },
      };
    }
    case ActionsTypes.GATrackersInitialized: {
      return {
        ...state,
        gaTrackersInitialized: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
