import React from 'react';

import { useTranslation } from 'react-i18next';

import { StoreLink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { buildStoreLogoLabelUri } from 'shared/services/assets.service';
import { useAppWideData } from 'shared/state';

import { EditorTextFieldProps } from '../../types';
import EditorUrlTextField from '../EditorUrlTextField';

import { Container, Label, Field } from './styled';

type Props = EditorTextFieldProps & {
  store: StoreLink;
  changeHandler: (value: string) => void;
  disabled: boolean;
};

const StoreUrlField = ({
  store,
  name,
  value,
  changeHandler,
  error,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const { storesConfigs } = useAppWideData();

  const labelImageUri = buildStoreLogoLabelUri(store.id);

  return (
    <Container>
      <Label xs={12} sm={4}>
        <img
          src={labelImageUri}
          alt={`${t(
            'ui.component.backlink_settings_data_editor.store_url_textfield.img.alt',
            'Redirect Url',
          )} - ${
            storesConfigs
              ? storesConfigs[store.id]?.displayName || store.id
              : ''
          }`}
          id="store-redirect-url-label"
        />
        <span>›</span>
      </Label>
      <Field>
        <EditorUrlTextField
          variant="outlined"
          name={name}
          value={value}
          changeHandler={(value) => changeHandler(value)}
          error={error}
          disabled={disabled}
          inputProps={{ 'aria-labelledby': 'store-redirect-url-label' }}
        />
      </Field>
    </Container>
  );
};

export default StoreUrlField;
