import React from 'react';

import { getAppName } from 'shared/utils/app';

import { useAppState } from '.';
import { useAppFeatureFlag } from './useAppFeatureFlag';

/** JIMO - surveys, polls ... usejimo.com */
function useJimo() {
  const { user } = useAppState();
  const [jimoScriptInserted, setJimoInserted] = React.useState(false);

  const isJimoEnabled =
    useAppFeatureFlag('jimo') && !!user.userId && !window.Cypress;

  React.useEffect(() => {
    if (isJimoEnabled) {
      (function (window) {
        window.jimo = [];
        var s = document.createElement('script');
        s.id = 'jimo-script';
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://undercity.usejimo.com/jimo-invader.js';
        window['JIMO_PROJECT_ID'] = '190050c7-165f-447f-b77a-176543843a7c';

        document.getElementsByTagName('head')[0].appendChild(s);
        setJimoInserted(true);
      })(window);
    }
  }, [isJimoEnabled]);

  // jimo segmentation
  React.useEffect(() => {
    const jimoScriptElement = document.getElementById('jimo-script');
    if (jimoScriptInserted && jimoScriptElement) {
      jimoScriptElement.addEventListener('load', () => {
        if (!!window.jimo) {
          window.jimo.push(['set', 'user:attributes', [{ app: getAppName() }]]);
        }
      });
    }
  }, [jimoScriptInserted]);
}

export default useJimo;
