import { AppGATrackers } from 'shared/analytics/analytics.types';
import { Artist } from 'shared/entities/artist/artist.types';
import { AuthUser } from 'shared/entities/auth/auth.types';

import { AppNotification } from '../types';
import { ActionMap } from './types';

export enum ActionsTypes {
  AppWideEventOccured = 'AppWideEventOccured',
  RequestingUserInfos = 'RequestingUserInfos',
  UserInfosReceived = 'UserInfosReceived',
  UserInfosRequestFailed = 'UserInfosRequestFailed',
  authUserHasBeenLoggedOut = 'authUserHasBeenLoggedOut',
  OpenArtistSettingsPanel = 'OpenArtistSettingsPanel',
  CloseArtistSettingsPanel = 'CloseArtistSettingsPanel',
  OpenTrackPitchPanel = 'OpenTrackPitchPanel',
  CloseTrackPitchPanel = 'CloseTrackPitchPanel',
  GATrackersInitialized = 'GATrackersInitialized',
}

export type OpenArtistSettingsPanelPayload = {
  artist: Artist;
  profileOnly?: boolean;
};

export type Payload = {
  [ActionsTypes.AppWideEventOccured]: {
    notification?: AppNotification;
    data?: Record<string, unknown>;
  };
  [ActionsTypes.RequestingUserInfos]: undefined;
  [ActionsTypes.UserInfosReceived]: AuthUser;
  [ActionsTypes.UserInfosRequestFailed]: undefined;
  [ActionsTypes.authUserHasBeenLoggedOut]: undefined;
  [ActionsTypes.OpenArtistSettingsPanel]: OpenArtistSettingsPanelPayload;
  [ActionsTypes.CloseArtistSettingsPanel]: undefined;
  [ActionsTypes.OpenTrackPitchPanel]: undefined;
  [ActionsTypes.CloseTrackPitchPanel]: undefined;
  [ActionsTypes.GATrackersInitialized]: AppGATrackers;
};

/** actions with their corresponding payload shape */
export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];
