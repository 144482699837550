import React from 'react';

import styled from '@emotion/styled';

import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import {
  Card as MuiCard,
  CardProps,
  CardHeader as MuiCardHeader,
  CardActions as MuiCardActions,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import { Button } from 'shared/components/Buttons/styled';

import { FieldRow } from './fields/styled';

export const Container = styled.div`
  > *  {
    margin-bottom: ${({ theme }) => theme.spacing(1 / 2)};
  }
`;
export const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => !['isExpanded', 'hasErrors'].includes(prop),
})<CardProps & { isExpanded?: boolean; hasErrors?: boolean }>`
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.palette.common.white : theme.palette.grey[100]};
  border: ${({ theme, isExpanded }) =>
    isExpanded ? `solid 1px ${theme.palette.grey[500]}` : 'none'};
  ${({ theme, hasErrors }) =>
    hasErrors ? `border: solid 1px ${theme.palette.error.main}` : ''};
  && > * {
    padding: 0 1rem;
  }

  ${FieldRow} {
    margin: ${({ theme }) => theme.spacing(1)} 0;
  }
`;

Card.defaultProps = {
  variant: 'elevation',
  elevation: 0,
};

export const CardHeader = styled(MuiCardHeader)`
  min-height: 56px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.grey[700]};
  .MuiCardHeader-content {
    display: flex;
    align-items: center;
    img {
      height: 32px;
      width: auto;
    }
  }
  .MuiAvatar-root {
    img {
      height: 56px;
    }
  }
  .MuiCardHeader-action {
    display: flex;
    align-items: center;
    > * {
      margin-left: ${({ theme }) => theme.spacing(1 / 2)};
    }
    > p {
      padding: ${({ theme }) => theme.spacing(1 / 2)};
    }
  }
  .MuiSvgIcon-root,
  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
  .MuiIconButton-root {
    padding: ${({ theme }) => theme.spacing(1 / 2)};
  }
`;

export const CardActions = styled(MuiCardActions)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
  .MuiButton-root {
    .MuiButton-label {
      > * {
        margin-right: 4px;
      }
    }
  }
`;

export const EditorButton = styled(Button)``;

EditorButton.defaultProps = {
  variant: 'contained',
  color: 'inherit',
  fullWidth: true,
};

export const WarningMessage = styled(Alert)`
  font-style: italic;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.palette.warning.contrastText};
  background-color: ${({ theme }) => theme.palette.warning.light};
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.warning.contrastText};
  }
  a {
    color: ${({ theme }) => theme.palette.warning.contrastText} !important;
    text-decoration: underline;
  }
`;

WarningMessage.defaultProps = {
  severity: 'warning',
};

export const InfoMessage = styled(Alert)`
  font-style: italic;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.light};
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main} !important;
    text-decoration: underline;
  }
`;

InfoMessage.defaultProps = {
  severity: 'info',
  icon: <InfoIcon />,
};

export const SuccessMessage = styled(Alert)`
  font-style: italic;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.palette.success.main};
  background-color: ${({ theme }) => theme.palette.success.light};
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.success.main};
  }
  a {
    color: ${({ theme }) => theme.palette.success.main} !important;
    text-decoration: underline;
  }
`;

SuccessMessage.defaultProps = {
  severity: 'success',
  icon: <SuccessIcon />,
};

export const ErrorMessage = styled(Alert)`
  font-style: italic;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.palette.error.main};
  background-color: ${({ theme }) => theme.palette.error.light};
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.error.main};
  }
  a {
    color: ${({ theme }) => theme.palette.error.main} !important;
    text-decoration: underline;
  }
`;

ErrorMessage.defaultProps = {
  severity: 'error',
  icon: <ErrorIcon />,
};

export const DragHandle = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const DraggableItem = styled.div`
  position: relative;
  > ${DragHandle} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export { DragHandleIcon };
