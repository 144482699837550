import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import { useStoreConfigs } from 'shared/hooks/entities/storeConfig';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { AuthUser, authUserIsCustomer } from '../entities/auth/auth.types';
import { Actions as BaseActions } from './actions';
import { augmentReducer } from './helpers';
import { reducer as baseReducer } from './reducer';
import { initialState as sharedInitialState } from './state';
import { AppState, Reducer } from './types';

type AppReducer<U extends AuthUser> = Reducer<AppState<U>>;

type ContextProviderProps<U extends AuthUser> = {
  children?: React.ReactNode;
  initialState: AppState<U>;
  additionalReducer?: AppReducer<U>;
};

export type AppContextType<U extends AuthUser = AuthUser, A = BaseActions> = {
  state: AppState<U>;
  dispatch: React.Dispatch<A | BaseActions>;
};
export const AppContext = createContext<AppContextType<any, any>>({
  state: sharedInitialState,
  dispatch: () => null,
});

export const AppContextProvider = <U extends AuthUser>({
  children,
  initialState,
  additionalReducer = (state, _action) => state,
}: ContextProviderProps<U>) => {
  // we want to 'process' the actions via the base app reducer but allow further treatments via a reducer set for the main app
  // we wrap the augmentReducer function in a useCallBack to avoid useReducer to re-run the initial seeding each time
  // the augmentReducer functon is created
  // TODO: can we do it in another way?
  const reducer = useCallback(
    (state: AppState<U>, action: any) =>
      augmentReducer<AppState<U>>(baseReducer, additionalReducer)(
        state,
        action,
      ),
    [additionalReducer],
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = <U extends AuthUser>() => {
  const context = useContext<AppContextType<U>>(AppContext);
  return context;
};

/**
 * a hook to fetch 'app wide' data like stores configurations, share extensions ...
 * the data we fetch here is considered 'static' and will not have to be refreshed during the user session
 *
 * note: to benefit from this hook, we need to use it every time we want to 'retrieve' the data from the cache
 *
 * e.g. like so:
 * const { storesConfigs } = useAppWideData();
 *
 * **/
export function useAppWideData() {
  const { state } = useAppContext<AuthUser>();

  const isAuthenticated = !!state.user.userId;

  /**
   * we need to make sure we only fetch in certain cases to avoid api errors
   */
  const shouldFetch =
    getAppName() !== AppName.backstageMarketingResources &&
    isAuthenticated &&
    (authUserIsCustomer(state.user) ? !!state.user.role?.isEditor : true);

  const queryOptions = {
    enabled: shouldFetch,
    // this option should make sure that we don't refetch the data and only use the cache
    staleTime: Infinity,
  };

  // the stores configurations
  const { storesConfigs } = useStoreConfigs(queryOptions);

  // the share extrensions
  const { data: shareExtensions } = useAppQuery(
    'shareExtensions',
    queryOptions,
  );

  return {
    storesConfigs,
    shareExtensions,
  };
}
