import { Reducer } from './types';

/**
 * simple function that will transform the given state via a 2-pass
 * through ...
 * @param firstReducer
 * @param secondReducer
 *
 * all we want to do is have the same state transformed by 2 reducers
 * one is the base App reducer and the other is an optional main app reducer
 * that can alter the state in its own way
 * note: can we use a generic 'combineReducers'? ... we don't want to aggregate multiple slices
 * but literally alter the state twice in different ways
 */
export const augmentReducer =
  <S extends Record<string, any>>(
    firstReducer: any,
    secondReducer: Reducer<S>,
  ) =>
  (state: S, action: any) =>
    secondReducer(firstReducer(state, action), action);
