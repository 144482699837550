import { useState } from 'react';

import { Contributor, Grid, Typography } from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, InfiniteData } from 'react-query';

import { ButtonBase, useTheme } from '@mui/material';

import { fetchArtists } from 'shared/entities/artist/artist.api';
import { PaginatedArtistsResponse } from 'shared/entities/artist/artist.api.types';
import { Artist } from 'shared/entities/artist/artist.types';
import { buildImageUrl } from 'shared/services/image.service';
import { getCurrentWorkspaceId, useAppContext } from 'shared/state';

import { ErrorScreen } from './ErrorScreen';
import { Loader } from './Loader';
import { ActionType } from './context/actions';
import { useReleaseEditorContext } from './context/context';
import { NewArtist } from './types';
import { artistIsCreated } from './types.utils';

import { ReleaseCardContainer } from './styled';

const InitialScreen = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.artist',
  });
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography
        justifyContent="center"
        textAlign="center"
        color="secondary"
        sx={{ padding: '84px', lineHeight: '1.125rem' }}
      >
        {t('search_hint', 'Search artists by name')}
      </Typography>
    </Grid>
  );
};
const ArtistSearchResults = ({
  availableOptions,
  artistQuery,
  artistSelected,
  isFetching,
  onArtistSelected,
  hasMore,
  loadMore,
}: {
  availableOptions: Array<Artist | NewArtist>;
  artistQuery: string;
  artistSelected: Artist | NewArtist | null;
  onArtistSelected: (artist: Artist | NewArtist) => void;
  loadMore: () => void;
  isFetching?: boolean;
  hasMore?: boolean;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.artist',
  });
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      sx={{ padding: '16px' }}
      aria-label={t('search_results', 'search results')}
    >
      {availableOptions.map((artist, index) => {
        return (
          <ReleaseCardContainer
            aria-label={
              artistIsCreated(artist)
                ? `${t('select_artist', 'Select artist')} “${artist.name}”`
                : `${t(
                    'create_new_artist',
                    'Create a new artist',
                  )} “${artistQuery}”`
            }
            key={artist.id}
            sx={{
              backgroundColor:
                artist === artistSelected
                  ? theme.palette.primary.light
                  : theme.palette.primary.contrastText,
            }}
            onClick={() => onArtistSelected(artist)}
          >
            <Contributor
              image={
                artistIsCreated(artist) && artist.data.profilePictureId
                  ? buildImageUrl({
                      imageId: artist.data.profilePictureId,
                    })
                  : undefined
              }
              title={
                artistIsCreated(artist)
                  ? artist.name
                  : `${t(
                      'create_new_artist',
                      'Create a new artist',
                    )} “${artistQuery}”`
              }
              fallbackText=""
              size="medium"
              shouldWrapTitle
            />
          </ReleaseCardContainer>
        );
      })}
      {hasMore && !isFetching && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <ButtonBase onClick={() => loadMore()}>
            <Typography color={theme.palette.secondary.dark}>
              {t('load_more', 'Load more')}
            </Typography>
          </ButtonBase>
        </Grid>
      )}
      {isFetching && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Loader />
        </Grid>
      )}
    </Grid>
  );
};
export const SelectArtist = () => {
  const { state: appState } = useAppContext();
  const {
    state: { artistQuery },
    dispatch,
  } = useReleaseEditorContext();
  const workspaceId = getCurrentWorkspaceId(appState);
  const { isFetching, hasNextPage, isError, refetch, fetchNextPage, data } =
    useInfiniteQuery<PaginatedArtistsResponse>(
      [
        'artists',
        {
          query: artistQuery,
          workspaceId,
        },
      ],
      ({ pageParam = 0 }) => {
        return fetchArtists({
          query: artistQuery,
          page: pageParam,
          limit: 3,
          workspaceId,
        });
      },
      {
        enabled: !!artistQuery,
        getNextPageParam: (lastPage: PaginatedArtistsResponse) => {
          // Calculate the index of the first item on the next page
          const nextItemIndex = (lastPage.page + 1) * lastPage.limit;
          // Check if there are more items beyond the current page
          const hasMore = nextItemIndex < lastPage.total;
          return hasMore ? lastPage.page + 1 : undefined;
        },
        onSuccess: (data: InfiniteData<PaginatedArtistsResponse>) => {
          const allResults = data.pages.reduce<Array<Artist | NewArtist>>(
            (
              acc: Array<Artist | NewArtist>,
              page: PaginatedArtistsResponse,
            ) => [...acc, ...page.results],
            [],
          );
          // Check if the artistQuery is not already in the aggregated results
          if (
            !allResults.some(
              (artist: Artist | NewArtist) =>
                'name' in artist && artist.name === artistQuery,
            )
          ) {
            allResults.push({
              id: 'new',
              isNew: true,
              newArtistName: artistQuery,
            });
          }
          // Use allResults to set available options
          setAvailableOptions(allResults);
        },
      },
    );

  const [artistSelected, setArtistSelected] = useState<
    Artist | NewArtist | null
  >(null);
  const [availableOptions, setAvailableOptions] = useState<Array<
    Artist | NewArtist
  > | null>(null);

  const isFirstPage = !data?.pages.length;

  if (!!artistQuery && isFetching && !!isFirstPage) {
    return <Loader />;
  }
  if (!!artistQuery && isError) {
    return <ErrorScreen refetch={refetch} />;
  }
  if (!!artistQuery && !isError && !!availableOptions) {
    return (
      <ArtistSearchResults
        availableOptions={availableOptions}
        artistQuery={artistQuery}
        artistSelected={artistSelected}
        onArtistSelected={(artist: Artist | NewArtist) => {
          setArtistSelected(artist);
          dispatch({
            type: ActionType.UPDATE_ARTIST,
            payload: { artist },
          });
        }}
        isFetching={isFetching}
        hasMore={hasNextPage}
        loadMore={fetchNextPage}
      />
    );
  }
  return <InitialScreen />;
};
