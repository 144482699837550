import { Backlink } from 'shared/entities/backlink/backlink.types';
import { BacklinkReleaseStore } from 'shared/entities/release/release.types';

import { Artist } from '../artist/artist.types';
import { StoreType } from '../storeConfig/storeConfig.types';
import {
  WorkspaceCustomer,
  WorkspaceEmployee,
} from '../workspace/workspace.types';
import { SlugValidationInfosResponse } from './backlinkSettings.api.types';
import { AllowedLocale } from './backlinkSettings.locale.types';

/**
 * BacklinkSettings might belong to another entity
 */

export type BacklinkSettingsParentEntityData = {
  Backlink: Partial<Backlink>;
  Workspace: Partial<WorkspaceEmployee> | Partial<WorkspaceCustomer>;
  Artist: Partial<Artist>;
};
export type BacklinkSettingsParentEntityType =
  keyof BacklinkSettingsParentEntityData;

export type BacklinkSettingsParentEntityInfos<
  TParentEntityType extends BacklinkSettingsParentEntityType,
> = {
  type: TParentEntityType;
  entity?: TParentEntityType extends BacklinkSettingsParentEntityType
    ? BacklinkSettingsParentEntityData[TParentEntityType]
    : never;
};

type BacklinkSettingsParentEntityTypesMap<
  M extends BacklinkSettingsParentEntityData,
> = {
  [Key in keyof M]: Key extends BacklinkSettingsParentEntityType
    ? BacklinkSettingsParentEntityInfos<Key>
    : never;
};

export type BacklinkSettingsParentEntityMappedTypes =
  BacklinkSettingsParentEntityTypesMap<BacklinkSettingsParentEntityData>[keyof BacklinkSettingsParentEntityTypesMap<BacklinkSettingsParentEntityData>];

export const parentEntityIsBacklink = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Backlink'> => {
  return typeof parentEntityInfos === 'undefined' || !parentEntityInfos?.entity
    ? false
    : parentEntityInfos.type === 'Backlink';
};

export const parentEntityIsWorkspace = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Workspace'> => {
  return typeof parentEntityInfos === 'undefined'
    ? false
    : parentEntityInfos && parentEntityInfos.type === 'Workspace';
};

export const parentEntityIsArtist = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Artist'> => {
  return typeof parentEntityInfos === 'undefined'
    ? false
    : parentEntityInfos && parentEntityInfos.type === 'Artist';
};

export type StoreLink = {
  id: string;
  url: string;
  displayName?: string;
};

/* Form */

export enum FormFieldName {
  Firstname = 'firstname',
  Lastname = 'lastname',
  Mobile = 'phonenumber',
  Email = 'email',
  Birthdate = 'birthdate',
  Address = 'address',
  ZipCode = 'zipCode',
  City = 'city',
  Country = 'country',
  OptinCompetition = 'optin_competition',
  OptinSubscription = 'optin_subscription',
}

export enum FormFieldType {
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Checkbox = 'checkbox',
  Country = 'country',
  Date = 'date',
}

export type FormFieldConfigBase = {
  type: FormFieldType;
  label: string;
  text?: string;
  required: boolean;
  dependentFields?: FormFieldName[];
};

export type GenericFormFieldConfig = FormFieldConfigBase & {
  name:
    | FormFieldName.Firstname
    | FormFieldName.Lastname
    | FormFieldName.Mobile
    | FormFieldName.Email
    | FormFieldName.Birthdate
    | FormFieldName.Address
    | FormFieldName.City
    | FormFieldName.ZipCode
    | FormFieldName.Country;
};

export type OptinSubscriptionConfig = FormFieldConfigBase & {
  name: FormFieldName.OptinSubscription;
  metas: {
    consentToNews: boolean;
    consentToOffers: boolean;
  };
};

export type OptinCompetitionConfig = FormFieldConfigBase & {
  name: FormFieldName.OptinCompetition;
  metas: {
    startDate: string;
    endDate: string;
    minimumAge: number | null;
    territory: string | null;
    winnersCount: number;
    prizes: string;
  };
};

export type FormFieldConfigWithMetas =
  | OptinSubscriptionConfig
  | OptinCompetitionConfig;

export type FormFieldConfig = GenericFormFieldConfig | FormFieldConfigWithMetas;

export function formFieldConfigHasMetas(
  formFieldConfig: FormFieldConfig,
): formFieldConfig is FormFieldConfigWithMetas {
  return (formFieldConfig as FormFieldConfigWithMetas).metas !== undefined;
}

export type FormFieldConfigRecord = Partial<
  Record<FormFieldName, FormFieldConfig>
>;

export type FormFieldsConfigsByLocale = Record<
  AllowedLocale,
  FormFieldConfigRecord
>;

/* Call To Action */

export enum PrereleaseCTAMode {
  DEFAULT = 'DEFAULT',
  DIRECT_LINK = 'DIRECT_LINK',
}
export enum PostReleaseCTAMode {
  DEFAULT = 'DEFAULT',
}
export type CTAOptions = {
  label: string;
  text: string;
  isDisplayed: boolean;
  mode?: PrereleaseCTAMode | PostReleaseCTAMode;
};

export type CTATemplateOptions = {
  label: Record<StoreType, string>;
  text: string;
  isDisplayed: boolean;
  mode?: PrereleaseCTAMode | PostReleaseCTAMode;
};

export type CallToActions = {
  stores: {
    displayOrder: string[];
    options: Record<string, CTAOptions>;
  };
};
export interface PrereleaseLandingSettingsByLocale {
  richText: string;
  callToActions: CallToActions;
}
export interface PrereleasePreSaveSettingsByLocale {
  notificationText: string;
}
export interface PrereleaseFormSettingsByLocale {
  richText: string;
  form: {
    fields: FormFieldConfig[];
    optins: FormFieldConfig[];
    submitButton: { label: string };
    skipButton: { label: string };
  };
}

export interface PrereleaseAutonotifyEmailSettingsByLocale {
  subject: string;
  title: string;
  body: string;
  ctaText: string;
  socialSectionTitle: string;
  footerText: string;
}

export interface PostreleaseLandingSettingsByLocale {
  richText: string;
  callToActions: CallToActions;
}

export type SlugValidationInfos = {
  subDomainConfigured: boolean;
  subDomainSelected: boolean;
} & SlugValidationInfosResponse;

export type DSPActionInfos = {
  id?: string;
  displayName: string;
  url: string;
  linkEnabled: boolean;
};

export type BacklinkSettingsResponse = Omit<Backlink['settingsData'], 'common'>;

export type BacklinkSettingsData = {
  preview?: {
    dspActionsInfosByPlatform?: Record<string, DSPActionInfos[]>;
  };
  common: {
    defaultLocale: string;
    defaultActionId?: string;
  };
  releaseStep: {
    release: {
      stores: Record<string, BacklinkReleaseStore>;
      selectedTimezone: string;
      digitalReleaseDate: string;
      title?: string;
    };
  };
  designStep: {
    artworkId?: string;
    artworkUrl?: string;
    theme: string;
    typography: {
      paragraph: {
        color: string;
      };
      link: {
        color: string;
      };
    };
    background: {
      direction: number;
      color01: string;
      color02?: string;
      image?: {
        blur: number;
        imageId: string;
        overlay: {
          color: string;
          opacity: number;
        };
      };
    };
  };
  prereleaseLandingStep: {
    showArtwork: boolean;
    showArtist: boolean;
    showVideo: boolean;
    youtubeVideoId: string;
    locale: {
      [iso: string]: PrereleaseLandingSettingsByLocale;
    };
  };
  prereleasePresaveStep: {
    locale: {
      [iso: string]: PrereleasePreSaveSettingsByLocale;
    };
    actionIds?: string[];
  };
  prereleaseFormStep: {
    isActive: boolean;
    locale: {
      [iso: string]: PrereleaseFormSettingsByLocale;
    };
    redirect: {
      default: { url: string };
      useStoresRedirects: boolean;
      stores: StoreLink[];
    };
  };
  prereleaseAutonotifyStep: {
    isActive: boolean;
    stores: string[];
    scheduledAt: string | null;
    selectedTimezone: string | null;
    locale: {
      [iso: string]: PrereleaseAutonotifyEmailSettingsByLocale;
    };
  };
  postreleaseLandingStep: {
    showArtwork: boolean;
    showArtist: boolean;
    showVideo: boolean;
    youtubeVideoId: string;
    locale: {
      [iso: string]: PostreleaseLandingSettingsByLocale;
    };
    social?: {
      isDisplayed: boolean;
      networks: {
        name: string;
        baseURL: string;
        artistId: string;
      }[];
    };
  };
  sharingStep: {
    url?: {
      slug: string;
      subDomain?: string;
    };
    [key: string]: any;
  };
};

export type BacklinkSettingsStepsTemplate = {
  prereleaseLandingStep: Backlink['settingsData']['prereleaseLandingStep']['locale'][string];
  prereleasePresaveStep: Backlink['settingsData']['prereleasePresaveStep']['locale'][string];
  prereleaseFormStep: Backlink['settingsData']['prereleaseFormStep']['locale'][string];
  prereleaseAutonotifyStep: Backlink['settingsData']['prereleaseAutonotifyStep']['locale'][string];
  postreleaseLandingStep: Backlink['settingsData']['postreleaseLandingStep']['locale'][string];
};

// backend types
export enum BacklinkSettingsType {
  GLOBAL_DEFAULT = 'global_default',
  DEFAULT_EMPLOYEE = 'default_employee',
  DEFAULT_CUSTOMER = 'default_customer',
  REL_BACKLINK = 'rel_backlink',
  REL_ARTIST = 'rel_artist',
  REL_WORKSPACE = 'rel_workspace',
}

export type EditableDefaultBacklinkSettingsType =
  | BacklinkSettingsType.DEFAULT_EMPLOYEE
  | BacklinkSettingsType.DEFAULT_CUSTOMER;

export type DefaultBacklinkSettingsMetasEmployee = {
  name: string;
};

export type DefaultBacklinkSettingsMetasCustomer = {
  customerLocale: string;
};

export type BacklinkSettings = {
  id: string;
  metas?:
    | DefaultBacklinkSettingsMetasEmployee
    | DefaultBacklinkSettingsMetasCustomer;
  type: BacklinkSettingsType;
  data: BacklinkSettingsData;
};

export type EditableDefaultBacklinkSettings = BacklinkSettings & {
  type: EditableDefaultBacklinkSettingsType;
};

export type DefaultBacklinkSettingsEmployee = Omit<
  BacklinkSettings,
  'metas'
> & {
  metas: DefaultBacklinkSettingsMetasEmployee;
};

export type DefaultBacklinkSettingsCustomer = Omit<
  BacklinkSettings,
  'metas'
> & {
  metas: DefaultBacklinkSettingsMetasCustomer;
};

export const isDefaultBacklinkSettingsEmployee = (
  backlinkSettings: BacklinkSettings,
): backlinkSettings is DefaultBacklinkSettingsEmployee => {
  return !!backlinkSettings.metas && 'name' in backlinkSettings.metas;
};

export const isDefaultBacklinkSettingsCustomer = (
  backlinkSettings: BacklinkSettings,
): backlinkSettings is DefaultBacklinkSettingsCustomer => {
  return !!backlinkSettings.metas && 'customerLocale' in backlinkSettings.metas;
};
