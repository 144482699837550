import {
  DefaultBacklinkSettingsEmployee,
  DefaultBacklinkSettingsCustomer,
  BacklinkSettingsType,
} from '../../backlinkSettings.types';
import {
  DefaultBacklinkSettingsEmployee as DefaultBacklinkSettingsEmployeeV2,
  DefaultBacklinkSettingsCustomer as DefaultBacklinkSettingsCustomerV2,
} from '../../v2/types';
import { transformBacklinkSettingsDataV1ToV2 } from './backlinkSettings.fixture.helpers';
import {
  common,
  releaseStep,
  designStep,
  prereleaseLandingStep,
  prereleasePresaveStep,
  prereleaseFormStep,
  prereleaseAutonotifyStep,
  postreleaseLandingStep,
  sharingStep,
} from './backlinkSettings.fixture.steps';

export const defaultBacklinkSettingsEmployee: DefaultBacklinkSettingsEmployee =
  {
    id: '977d5b0c-ef48-4c88-9ef4-19dc04fc1d73',
    type: BacklinkSettingsType.DEFAULT_EMPLOYEE,
    data: {
      common,
      releaseStep,
      designStep,
      prereleaseLandingStep,
      prereleasePresaveStep,
      prereleaseFormStep,
      prereleaseAutonotifyStep,
      postreleaseLandingStep,
      sharingStep,
    },
    metas: {
      name: 'default-employee',
    },
  };

export const defaultBacklinkSettingsEmployeeV2: DefaultBacklinkSettingsEmployeeV2[] =
  [
    {
      id: '977d5b0c-ef48-4c88-9ef4-19dc04fc1d73',
      type: BacklinkSettingsType.DEFAULT_EMPLOYEE,
      data: transformBacklinkSettingsDataV1ToV2(
        defaultBacklinkSettingsEmployee.data,
      ),
      metas: {
        name: 'default-employee',
      },
    },
  ];

export const defaultBacklinkSettingsCustomer: DefaultBacklinkSettingsCustomer =
  {
    id: '866d5b0c-ef48-4c88-9ef4-18dc04fc1d81',
    type: BacklinkSettingsType.DEFAULT_CUSTOMER,
    data: {
      common,
      releaseStep,
      designStep,
      prereleaseLandingStep,
      prereleasePresaveStep,
      prereleaseFormStep,
      prereleaseAutonotifyStep,
      postreleaseLandingStep,
      sharingStep,
    },
    metas: {
      customerLocale: 'en',
    },
  };

export const defaultBacklinkSettingsCustomerV2: DefaultBacklinkSettingsCustomerV2[] =
  [
    {
      id: '866d5b0c-ef48-4c88-9ef4-18dc04fc1d81',
      type: BacklinkSettingsType.DEFAULT_CUSTOMER,
      data: transformBacklinkSettingsDataV1ToV2(
        defaultBacklinkSettingsCustomer.data,
      ),
      metas: {
        customerLocale: 'en',
      },
    },
  ];
