import { DSPAction } from 'shared/entities/dspAction/dspAction.types';

import { api } from '../../api';
import { DSPActionResponsePayload } from '../../dspAction/dspAction.api.types';
import { FrontendDSPActionType } from '../../dspAction/dspAction.frontend.constants';
import {
  transformFrontendDSPActionToDSPActionPayload,
  transformDSPActionToFrontendDSPAction,
  transformAllDSPActionToFrontendDSPAction,
} from '../../dspAction/dspAction.frontend.helpers';
import { FrontendDSPAction } from '../../dspAction/dspAction.frontend.types';
import { backlinkBaseApiPath } from './backlink.api';
import {
  FetchBacklinkDSPActionPayload,
  FetchBacklinkDSPActionsPayload,
  CreateDSPActionPayload,
  UpdateDSPActionPayload,
} from './backlink.dspAction.api.types';

export const fetchBacklinkDSPActions = async ({
  backlinkId,
  dspActionIds,
  postProcessParams: { checkIsFollowMainArtistAction },
}: FetchBacklinkDSPActionsPayload & {
  postProcessParams: {
    checkIsFollowMainArtistAction: (dspAction: DSPAction) => boolean;
  };
}): Promise<FrontendDSPAction[]> => {
  const results = await api.get<DSPActionResponsePayload[]>(
    `${backlinkBaseApiPath}/${backlinkId}/actions`,
    {
      params: {
        dspActionIds,
      },
    },
  );

  return transformAllDSPActionToFrontendDSPAction(results.data, {
    helpers: { checkIsFollowMainArtistAction },
  });
};

export const fetchBacklinkDSPAction = async ({
  backlinkId,
  dspActionId,
  postProcessParams: { checkIsFollowMainArtistAction },
}: FetchBacklinkDSPActionPayload & {
  postProcessParams: {
    checkIsFollowMainArtistAction: (dspAction: DSPAction) => boolean;
  };
}): Promise<FrontendDSPAction | null> => {
  const results = await api.get(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${dspActionId}`,
  );

  return transformDSPActionToFrontendDSPAction(results.data, {
    isFollowMainArtist: checkIsFollowMainArtistAction(results.data),
  });
};

export const createBacklinkDSPAction = async ({
  backlinkId,
  dspAction,
}: CreateDSPActionPayload): Promise<FrontendDSPAction | null> => {
  const results = await api.post<DSPActionResponsePayload>(
    `${backlinkBaseApiPath}/${backlinkId}/actions`,
    transformFrontendDSPActionToDSPActionPayload(dspAction),
  );
  return transformDSPActionToFrontendDSPAction(results.data, {
    isFollowMainArtist:
      dspAction.type === FrontendDSPActionType.FOLLOW_MAIN_ARTIST,
    isAddMainPlaylist:
      dspAction.type === FrontendDSPActionType.ADD_MAIN_PLAYLIST,
  });
};

export const updateBacklinkDSPAction = async ({
  backlinkId,
  dspAction,
}: UpdateDSPActionPayload): Promise<FrontendDSPAction | null> => {
  const results = await api.patch<DSPActionResponsePayload>(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${dspAction.dspActionId}`,
    transformFrontendDSPActionToDSPActionPayload(dspAction),
  );
  return transformDSPActionToFrontendDSPAction(results.data, {
    isFollowMainArtist:
      dspAction.type === FrontendDSPActionType.FOLLOW_MAIN_ARTIST,
    isAddMainPlaylist:
      dspAction.type === FrontendDSPActionType.ADD_MAIN_PLAYLIST,
  });
};
