import React, { useState, useEffect, useMemo } from 'react';

import produce from 'immer';
import debounce from 'lodash/debounce';

import { StoreLink } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { useValidationContext } from '../../../hooks/useValidation';
import StoreUrlField from '../../fields/StoreUrlTextField';
import { EditorFieldProps, TextFieldOptions } from '../../types';

import { FieldRow } from '../../fields/styled';

type Props = EditorFieldProps<StoreLink[]> & {
  id: string;
  textFieldsOptions: Map<string, TextFieldOptions>;
};

/**
 * A component to update links for a list of stores
 */
export const EditorStoreLinksManager: React.FC<Props> = ({
  id,
  value,
  changeHandler,
  textFieldsOptions,
}) => {
  const { errors } = useValidationContext();

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler],
  );

  /** manipulate the store backlinks array locally */
  const [storeLinks, setStoreLinks] = useState(value);

  const updateStoreLinkUrl = (storeLinkUrl: string, index: number) => {
    setStoreLinks(
      produce(storeLinks, (draft) => {
        draft[index] = {
          ...draft[index],
          url: storeLinkUrl,
        };
      }),
    );
  };

  /**
   * update the whole array in the Editor State
   */
  useEffect(() => {
    debouncedChangeHandler(storeLinks);
  }, [debouncedChangeHandler, storeLinks]);

  return (
    <>
      {storeLinks.map((storeLink, index) => {
        return (
          <FieldRow key={storeLink.id}>
            <StoreUrlField
              store={storeLink}
              value={storeLink.url}
              error={!!errors[`${id}.${index}.url`]}
              changeHandler={(url) => updateStoreLinkUrl(url, index)}
              disabled={textFieldsOptions.get(storeLink.id)?.disabled === true}
            />
          </FieldRow>
        );
      })}
    </>
  );
};
