import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import { messages } from 'shared/entities/release/messages';
import * as releaseApi from 'shared/entities/release/release.api';
import {
  HotReleasesFetchPayload,
  HotReleasesTransformedResponse,
  UpdateReleasePayload,
} from 'shared/entities/release/release.api.types';
import { Release } from 'shared/entities/release/release.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';

export { default as useReleaseBacklinks } from './useReleaseBacklinks';
export { default as useReleaseCreativeMedias } from './useReleaseCreativeMedias';

export function useCreateRelease(options?: { shouldNotifyOnsuccess: boolean }) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const { shouldNotifyOnsuccess } = options || { shouldNotifyOnsuccess: true };

  const { mutate: createRelease, ...rest } = useMutation(
    releaseApi.createRelease,
    {
      onSuccess: (release) => {
        // use response data to avoid having to invalidate & refetch
        queryClient.setQueryData(['release', release.id], release);

        if (shouldNotifyOnsuccess) {
          notify({
            type: AppNotificationTypes.Success,
            message: messages.CREATE_SUCCESS,
          });
        }
      },
      onError: (error: AxiosError) => {
        notify(
          {
            type: AppNotificationTypes.Error,
            message: `An error occured while creating the release.`,
          },
          error,
        );
      },
    },
  );

  return {
    createRelease,
    ...rest,
  };
}

export function useFetchRelease(
  releaseId?: string,
  options?: UseQueryOptions<Release>,
) {
  const queryClient = useQueryClient();

  const [isRefreshingFromCAPI, setIsRefreshingFromCAPI] = useState(false);

  const { data: release, isFetching } = useAppQuery('release', {
    fetcherArgs: [releaseId as string],
    refetchOnWindowFocus: false,
    enabled: !!releaseId,
    ...options,
  });

  const refreshRelease = useCallback(async () => {
    if (!releaseId) return;
    setIsRefreshingFromCAPI(true);
    await releaseApi.refreshRelease(releaseId);
    setIsRefreshingFromCAPI(false);
    queryClient.invalidateQueries(['release', releaseId]);
  }, [queryClient, releaseId]);

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(['release', releaseId]);
  }, [queryClient, releaseId]);

  return {
    release,
    refreshRelease,
    isFetching,
    isRefreshingFromCAPI,
    invalidateQuery,
  };
}

export function useUpdateRelease() {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const { mutate: updateRelease, ...rest } = useMutation(
    ({
      releaseId,
      internalProductIds,
      workspaceId,
    }: { releaseId: string } & UpdateReleasePayload) =>
      releaseApi.updateRelease(releaseId, { internalProductIds, workspaceId }),
    {
      onSuccess: (release) => {
        // use response data to avoid having to invalidate & refetch
        queryClient.setQueryData(['release', release.id], release);

        notify({
          type: AppNotificationTypes.Success,
          message: messages.UPDATE_SUCCESS,
        });
      },
      onError: (error: AxiosError) => {
        // we rely on what the backend errors look like ATM
        // and try and display something meaningful
        const errorsMessages = error.response?.data.errors.join('\n');
        notify({
          type: AppNotificationTypes.Error,
          message: `An error occured while updating the release.\n${errorsMessages}`,
        });
      },
    },
  );

  return {
    updateRelease,
    ...rest,
  };
}

export function useRefreshRelease() {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const { mutate: refreshRelease, ...rest } = useMutation(
    releaseApi.refreshRelease,
    {
      onSuccess: (release) => {
        // use response data to avoid having to invalidate & refetch
        queryClient.setQueryData(['release', release.id], release);
        notify({
          type: AppNotificationTypes.Success,
          message: messages.REFRESH_SUCCESS,
        });
        // refresh the backlinks list to load the new data
        queryClient.invalidateQueries(['backlinks']);
      },
      onError: (error: AxiosError) => {
        notify({
          type: AppNotificationTypes.Error,
          message: `An error occured while refreshing the release.`,
        });
      },
    },
  );

  return {
    refreshRelease,
    ...rest,
  };
}

export function useFetchHotReleases(
  params?: HotReleasesFetchPayload,
  options?: UseQueryOptions<HotReleasesTransformedResponse>,
) {
  const { data, isFetching } = useAppQuery('hotReleases', {
    fetcherArgs: [params || {}],
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    hotReleases: data ? data.hotReleases : [],
    internalProductIds: data ? data.internalProductIds : [],
    isFetching,
  };
}
