import { StoreType } from 'shared/entities/storeConfig/storeConfig.types';

export enum DeviceType {
  'mobile' = 'mobile',
  'desktop' = 'desktop',
  'tablet' = 'tablet',
}

export const isDeviceType = (value: any): value is DeviceType => {
  return (
    typeof value === 'string' && DeviceType[value as DeviceType] !== undefined
  );
};

/**
 * those are the types of optins returned by the api
 * they do not necessarily reflect 'optins' per se
 * we can have a storeName that represents a 'connect' to a platform
 * or a generic 'subscription' type that represents form submissions by a user
 * regardless of whether email or mobile number was submitted
 * nnote: this is subject to change and is backlinked to how backlink analytics are bound to google anaytics ATM
 */
export enum OptinType {
  'subscription' = 'subscription',
}

export const isOptinType = (value: any): value is OptinType => {
  return (
    typeof value === 'string' && OptinType[value as OptinType] !== undefined
  );
};

export enum ReferrerType {
  'referral' = 'referral',
  'organic' = 'organic',
  'cpc' = 'cpc',
  'email' = 'email',
  'other' = 'other',
}

export const isReferrerType = (value: any): value is ReferrerType => {
  return (
    typeof value === 'string' &&
    ReferrerType[value as ReferrerType] !== undefined
  );
};

export enum ChannelType {
  'referral' = 'referral',
  'paid' = 'paid',
  'other' = 'other',
}

export const isChannelType = (value: any): value is ChannelType => {
  return (
    typeof value === 'string' && ChannelType[value as ChannelType] !== undefined
  );
};

export type AnalyticsSourceType = ReferrerType | ChannelType;
export const isSourceType = (value: any): value is AnalyticsSourceType => {
  return (
    (typeof value === 'string' &&
      ReferrerType[value as ReferrerType] !== undefined) ||
    ChannelType[value as ChannelType] !== undefined
  );
};

export type BacklinkAnalyticsDateRange = {
  startDate: string | null;
  endDate: string | null;
};

export type BacklinkAnalyticsDataBase = {
  lastUpdate: string;
};

/** Overview */

export type BacklinkAnalyticsOverviewKPI = {
  visited: number;
  clickedToStore: number;
  preSaved: number;
  optins: number;
};

export type BacklinkAnalyticsOverviewData = BacklinkAnalyticsDataBase & {
  dailyRows: (BacklinkAnalyticsOverviewKPI & {
    date?: string;
  })[];
  overview: BacklinkAnalyticsOverviewKPI & {
    date?: string;
  };
};

/** Visits */

export type BacklinkAnalyticsVisitsBase = {
  visited: number;
  clickedToStore: number;
  ratio: number;
};

export type BacklinkAnalyticsSourcesReferrer = BacklinkAnalyticsVisitsBase & {
  referrerType: ReferrerType;
  referrerSource: string;
};

export type BacklinkAnalyticsSourcesReferrersData =
  BacklinkAnalyticsDataBase & {
    referrersSources: BacklinkAnalyticsSourcesReferrer[];
    totalVisited: number;
    totalClickedToStore: number;
    totalRatio: number;
  };

export type BacklinkAnalyticsSourcesChannel = BacklinkAnalyticsVisitsBase & {
  channelType: ChannelType;
  channelName: string;
};

export type BacklinkAnalyticsSourcesChannelsData = BacklinkAnalyticsDataBase & {
  channelsSources: BacklinkAnalyticsSourcesChannel[];
  totalVisited: number;
  totalClickedToStore: number;
  totalRatio: number;
};

export type BacklinkAnalyticsVisitsData<
  T extends
    | BacklinkAnalyticsSourcesReferrer
    | BacklinkAnalyticsSourcesChannel
    | BacklinkAnalyticsLocationsCountry
    | BacklinkAnalyticsLocationsCity
    | BacklinkAnalyticsDevice,
> = BacklinkAnalyticsDataBase & {
  visits: T[];
  totalVisited: number;
  totalClickedToStore: number;
  totalRatio: number;
};

export type BacklinkAnalyticsLocationsCountry = BacklinkAnalyticsVisitsBase & {
  ISOCodeCountry: string;
};

export type BacklinkAnalyticsLocationsCity = BacklinkAnalyticsVisitsBase & {
  cityName: string;
  ISOCodeCountry: string;
};

export type BacklinkAnalyticsDevice = BacklinkAnalyticsVisitsBase & {
  deviceName: string;
};

/** Conversions */

export type BacklinkAnalyticsStoreClicksForStore = {
  storeName: string;
  type: StoreType;
  clickedToStore: number;
};

export type BacklinkAnalyticsStoreClicksByStoreData =
  BacklinkAnalyticsDataBase & {
    storesConversionClicks: BacklinkAnalyticsStoreClicksForStore[];
    totalStoresReach: number;
  };

export type BacklinkAnalyticsStoreClicksForStoreType = {
  type: string;
  clickedToStore: number;
};

export type BacklinkAnalyticsStoreClicksByStoreTypeData =
  BacklinkAnalyticsDataBase & {
    storesConversionTypes: BacklinkAnalyticsStoreClicksForStoreType[];
    totalStoresReach: number;
  };

export type BacklinkAnalyticsPresave = {
  storeName: string;
  preSaved: number;
};

export type BacklinkAnalyticsPresavesData = BacklinkAnalyticsDataBase & {
  actionStoresConversionPresave: BacklinkAnalyticsPresave[];
  totalActionsStoresPresaved: number;
};

export type BacklinkAnalyticsFanNotificationData = BacklinkAnalyticsDataBase & {
  open: number;
  send: number;
  bounce: number;
  click: number;
};

export type BacklinkAnalyticsOptin = {
  type: string;
  subscribers: number;
};

export type BacklinkAnalyticsOptinsData = BacklinkAnalyticsDataBase & {
  subscribersConversion: BacklinkAnalyticsOptin[];
  totalSubscribers: number;
};

/**
 * the payload for this api route takes the dateRange in the form of
 * - startDay
 * - endDay
 * to match what the server expects
 */
export type FetchBacklinkAnalyticsPayload = {
  backlinkId: string;
  startDay: string | null;
  endDay: string | null;
};
