import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { TextField, ClickAwayListener } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { StoreConfig } from 'shared/entities/storeConfig/storeConfig.types';
import { useStoreConfigs } from 'shared/hooks/entities/storeConfig';

import * as Styled from './styled';

type Props = {
  availableStoresConfigs?: StoreConfig[];
  onChange: (value: StoreConfig) => void;
  className?: string;
};

const StoreSelector = ({
  availableStoresConfigs = [],
  onChange,
  className,
}: Props) => {
  const { t } = useTranslation();

  const [autocompleteIsVisible, setAutocompleteIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // We don't want to fetch the stores configs if we're provided with some
  const shouldFetchStoresConfigs = availableStoresConfigs.length === 0;
  const { storesConfigs, isLoading } = useStoreConfigs({
    enabled: shouldFetchStoresConfigs,
    initialData: Object.fromEntries(
      availableStoresConfigs.map((store) => [store.name, store]),
    ),
  });
  // If we didn't fetch, we use the provided available stores configs
  const localStoresConfigs = shouldFetchStoresConfigs
    ? Object.values(storesConfigs ?? [])
    : availableStoresConfigs;

  return (
    <Styled.Container ref={containerRef} className={className}>
      <ClickAwayListener
        onClickAway={(event) => {
          /**
           * we should not close the autocomplete when clicking in the search field
           * so we check that we're clicking inside the container
           * clicking everywhere else should close the autocomplete
           */
          if (!containerRef.current?.contains(event.target as Node)) {
            setAutocompleteIsVisible(false);
          }
        }}
      >
        <Styled.AddStoreButton
          startIcon={
            <AddCircleRoundedIcon css={{ fontSize: '24px !important' }} />
          }
          onClick={() => setAutocompleteIsVisible(true)}
        >
          {t('ui.component.store_selector.button.add_store', 'Add store')}
        </Styled.AddStoreButton>
      </ClickAwayListener>
      {autocompleteIsVisible && (
        <Autocomplete
          open
          fullWidth
          /* the following is necessary to prevent TS complaining  about the typing of the onChange event ... */
          multiple={false}
          ListboxProps={{ style: { maxHeight: 200 } }}
          onOpen={() => setAutocompleteIsVisible(true)}
          getOptionLabel={(option) => option.displayName}
          options={localStoresConfigs}
          loading={isLoading}
          renderInput={(params) => {
            return (
              <TextField
                autoFocus
                fullWidth
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                InputLabelProps={params.InputLabelProps}
                label={
                  !isLoading ? (
                    t(
                      'ui.component.store_selector.select.label',
                      'Select a Store',
                    )
                  ) : (
                    <em>
                      {t(
                        'ui.component.store_selector.message.loading',
                        'Loading stores ...',
                      )}
                    </em>
                  )
                }
                variant="outlined"
              />
            );
          }}
          onChange={(event, store) => {
            if (store) {
              onChange(store);
              setAutocompleteIsVisible(false);
            }
          }}
          popupIcon={<AddCircleRoundedIcon />}
        />
      )}
    </Styled.Container>
  );
};

export default StoreSelector;
