import * as monitoring from '../monitoring';

export const production = {
  marketingSuiteHostname: 'marketing.believedigital.com',
  backstageMarketingHostname: 'backstage-marketing.com',
  backstageMarketingResourcesHostname: 'resources.backstage-marketing.com',
  marketingSuiteApiBaseUrl: 'https://api.marketing.believedigital.com',
  backstageMarketingApiBaseUrl: 'https://api.backstage-marketing.com',
  imageBaseUrl: 'https://images.ams-prd.blv.cloud',
  assetsBaseUrl: 'https://assets.ams-prd.blv.cloud',
  imageBucketName: 'images-prd-1o4uz9zbxwzp1ubm',
  backlinkBaseUrl: 'https://bfan.link',
  gaTrackers: {
    marketingSuite: {
      ga4Trackers: [
        {
          trackingId: 'G-SS4YS65MVY',
        },
      ],
    },
    backstageMarketing: {
      ga4Trackers: [
        {
          trackingId: 'G-2SKRP9HYBK',
        },
        /** Backstage Tracker */
        {
          trackingId: 'G-NDE76PCMG4',
        },
      ],
    },
  },
  backstageBaseUrl: 'https://www.believebackstage.com',
  artistsResourcesWordpressUrl:
    'https://artist-resources.backstage-marketing.com',
  backstageMarketingOriginUrl:
    'https://www.believebackstage.com/promotion/links',
  backstageMarketingResourcesOriginUrl:
    'https://www.believebackstage.com/promotion/artistresources',
  googleTagManagerContainerId: 'GTM-575RHDV',
  gitlabFeatureFlagsProxy: {
    url: 'https://features.marketing.believedigital.com/ams-frontend',
    clientKey: process.env
      .REACT_APP_GITLAB_FEATURE_FLAGS_PROXY_CLIENT_KEY as string,
    // Refresh interval is 6 hours in seconds
    refreshInterval: 60 * 60 * 6,
    appName: 'production',
    environment: 'production',
  },
  creativeMediasGqlClientConfig: {
    env: 'prd',
    uri: 'https://promokit-api.ams-prd.blv.cloud/v1/graphql',
  },
  zendeskGlobalMarketingKey: '7847394b-3ddb-430e-b375-06bf4b7240b5',
  zendeskCustomerHelpCenterKey: '1e50c9e7-c0eb-40f3-a5e2-c3db3bddef8e',
  zendeskEditorialMarketingProductKey: 'af26ef05-998c-4482-a49d-12dcfb3e8237',
  salesForceBelieveBaseUrl: 'https://believe-group.my.salesforce.com',
  salesForceBelieveHelpCenterUrl:
    'https://believe-group.my.site.com/HelpCenter',
  salesForceBelieveKey: '00D3X000003K7VS',
  salesForceBackstageMarketingKey: 'BackStage',
  salesForceMarketingSuiteKey: '',
  intranetBaseUrl: 'http://intranet.believedigital.com',
  teamsChatBaseUrl: 'https://teams.microsoft.com/l/chat/0/0',
  legacyBackstageAdsUrl: 'https://backstageads.com',

  monitoring: {
    datadog: {
      ...monitoring.datadog,
      env: 'prd',
    },
  },
} as const;
