import React from 'react';

import { useLocation, Navigate, RouteProps } from 'react-router-dom';

import { useAuth } from 'shared/hooks/useAuth';

/**
 * a Route that will only render its children if the user has been authenticated
 * it will redirect to the login page otherwise
 */
export const ProtectedRoute: React.FC<
  RouteProps & {
    adminOnly?: boolean;
    redirectPath?: string;
    bypassAuth?: boolean;
    children: React.ReactElement;
  }
> = ({ children, adminOnly, bypassAuth, redirectPath = '/login', ...rest }) => {
  /**
   * Auth
   * notes:
   * - makeRequest option needs to be true to trigger the call that will check if user is authenticated on the server
   * - we want to make the call only if we're trying to access a Protected Route, so we put the useAuth that will make the api call here
   */
  const { isAuthenticated, isAdmin } = useAuth();

  const isAllowed = isAuthenticated || bypassAuth;
  const location = useLocation();

  if (!isAllowed) {
    return (
      <Navigate
        replace
        to={{
          pathname: redirectPath,
          search: location.search,
        }}
        state={{
          from: location,
        }}
      />
    );
  }

  if ((adminOnly && isAdmin) || !adminOnly) {
    return children;
  }

  return <Navigate replace to={{ pathname: '/' }} />;
};
