export const development = {
  marketingSuiteHostname: 'ams-dev.blv.cloud',
  backstageMarketingHostname: 'backstage.ams-dev.blv.cloud',
  backstageMarketingResourcesHostname: 'resources.backstage.ams-dev.blv.cloud',
  marketingSuiteApiBaseUrl: 'https://api.ams-dev.blv.cloud',
  backstageMarketingApiBaseUrl: 'https://api.backstage.ams-dev.blv.cloud',
  imageBaseUrl: 'https://images.ams-sbx.blv.cloud',
  assetsBaseUrl: 'https://assets.ams-sta.blv.cloud',
  imageBucketName: 'images-dev-ov42dkcfjiy7l8l6',
  backlinkBaseUrl: 'https://backlink.ams-dev.blv.cloud',
  gaTrackers: {
    marketingSuite: {
      ga4Trackers: [
        {
          trackingId: 'G-028K4VPC5J',
        },
      ],
    },
    backstageMarketing: {
      ga4Trackers: [
        {
          trackingId: 'G-B7Q25N0B0W',
        },
      ],
    },
  },
  backstageBaseUrl: 'http://benjamin.bonny.producer.dev.believe.fr',
  artistsResourcesWordpressUrl:
    'https://wordpress-artist-resources.ams-sta.blv.cloud',
  backstageMarketingOriginUrl:
    'http://benjamin.bonny.producer.dev.believe.fr/promotion/links',
  backstageMarketingResourcesOriginUrl:
    'http://benjamin.bonny.producer.dev.believe.fr/promotion/artistresources',
  gitlabFeatureFlagsProxy: {
    url: 'https://features.ams-sta.blv.cloud/ams-frontend',
    clientKey: process.env
      .REACT_APP_GITLAB_FEATURE_FLAGS_PROXY_CLIENT_KEY as string,
    // Refresh interval is 6 hours in seconds
    refreshInterval: 60 * 60 * 6,
    appName: 'staging',
    environment: 'staging',
  },
  creativeMediasGqlClientConfig: {
    env: 'sbx',
    uri: 'https://promokit-api.ams-sbx.blv.cloud/v1/graphql',
  },
  zendeskGlobalMarketingKey: 'web_widget/globalmarketing.zendesk.com',
  zendeskEditorialMarketingProductKey: 'af26ef05-998c-4482-a49d-12dcfb3e8237',
  zendeskCustomerHelpCenterKey: '1e50c9e7-c0eb-40f3-a5e2-c3db3bddef8e',
  salesForceBelieveBaseUrl: 'https://believe-group.my.salesforce.com',
  salesForceBelieveHelpCenterUrl:
    'https://believe-group.my.site.com/HelpCenter',
  salesForceBelieveKey: '00D3X000003K7VS',
  salesForceBackstageMarketingKey: 'BackStage',
  salesForceMarketingSuiteKey: '',
  intranetBaseUrl: 'https://intra-03.test.believe.fr',
  teamsChatBaseUrl: 'https://teams.microsoft.com/l/chat/0/0',
  legacyBackstageAdsUrl: 'https://staging.backstageads.com',
} as const;
