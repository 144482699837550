import styled from '@emotion/styled';

import { Grid } from '@mui/material';

export const Container = styled(Grid)``;

Container.defaultProps = {
  container: true,
};

export const Label = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 180px;
  padding-right: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  img {
    height: 32px;
    width: auto;
  }
  span {
    display: none;
    font-size: 30px;
    color: ${({ theme }) => theme.palette.grey[600]};
  }
  ${({ theme }) =>
    `${theme.breakpoints.up('sm')} {
        margin-bottom: 0;
        span {
          display: inline;
        }
    }`};
`;

Label.defaultProps = {
  item: true,
};

export const Field = styled(Grid)`
  flex: 1;
  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

Field.defaultProps = {
  item: true,
};
