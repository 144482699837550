import React, { StrictMode } from 'react';

import { Helmet } from 'react-helmet';
import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom';

import ArtistSettingsPanel from 'shared/components/ArtistSettingsPanel';
import { AuthUserCustomer } from 'shared/entities/auth/auth.types';
import useClarity from 'shared/hooks/useClarity';
import useJimo from 'shared/hooks/useJimo';
import { useAppWideData } from 'shared/state';
import { AppState } from 'shared/state/types';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { initialState as backstageMarketingInitialState } from 'apps/BackstageMarketing/state';
import { initialState as marketingSuiteInitialState } from 'apps/MarketingSuite/state';
import { reducer as marketingSuiteReducer } from 'apps/MarketingSuite/state/reducer';

const MarketingSuite = React.lazy(
  () => import(/* webpackChunkName: "MarketingSuite" */ 'apps/MarketingSuite'),
);
const BackstageMarketing = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BackstageMarketing" */ 'apps/BackstageMarketing'
    ),
);
const BackstageMarketingResources = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BackstageMarketing" */ 'apps/BackstageMarketing'
    ),
);

export const appSetupMap = {
  [AppName.marketingSuite]: {
    component: MarketingSuite,
    initialState: marketingSuiteInitialState,
    reducer: marketingSuiteReducer,
  },
  [AppName.backstageMarketing]: {
    component: BackstageMarketing,
    initialState: backstageMarketingInitialState,
    reducer: (state: AppState<AuthUserCustomer>, _action) => state,
  },
  [AppName.backstageMarketingResources]: {
    component: BackstageMarketingResources,
    initialState: backstageMarketingInitialState,
    reducer: (state: AppState<AuthUserCustomer>, _action) => state,
  },
};

const Root = () => {
  // fetch app wide data - this will fill the react-query cache
  useAppWideData();

  // JIMO - user engagement app
  useJimo();

  // user analytic
  useClarity();

  /* No AppName No App */
  if (!getAppName()) {
    return null;
  }

  /**
   * select app main component
   * used to host sub-apps/sections
   */
  const AppComponent = appSetupMap[getAppName() as AppName].component;

  return (
    <StrictMode>
      <AppComponent />
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
      <ArtistSettingsPanel />
      <Helmet>
        <title>
          {getAppName() === AppName.marketingSuite
            ? 'Believe Marketing Suite'
            : 'Backstage Marketing'}
        </title>
      </Helmet>
    </StrictMode>
  );
};

const router = createBrowserRouter([{ path: '*', Component: Root }]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
