/* eslint-disable @typescript-eslint/no-redeclare */
import { CreativeGraphQLTypes } from '@creative/sdk';

import { Release } from '../release/release.types';

/** some aliases */

export type CreativeMedia = CreativeGraphQLTypes.MediaGraphQL;

// exporting both type and const for enums as it is not  possible to use the export { name as name2 } form because of the namespaced export from the creative-sdk;

export type CreativeMediaFormat = CreativeGraphQLTypes.Media_Format_EnumGraphQL;
export const CreativeMediaFormat =
  CreativeGraphQLTypes.Media_Format_EnumGraphQL;

export type CreativeMediaType = CreativeGraphQLTypes.Media_Type_EnumGraphQL;
export const CreativeMediaType = CreativeGraphQLTypes.Media_Type_EnumGraphQL;

export type CreativeMediaState = CreativeGraphQLTypes.Media_State_EnumGraphQL;
export const CreativeMediaState = CreativeGraphQLTypes.Media_State_EnumGraphQL;

/**
 * values are lowercase as they are passed to the backend which expects lowercase
 */
export enum CreativeMediaMilestone {
  'Teaser' = 'teaser',
  'Launch' = 'launch',
}

export type CreativeMediaListItem = {
  id: string;
  artistName: string;
  createdAt: Date;
  format: string;
  savedAt: Date;
  type: CreativeGraphQLTypes.Media_Type_EnumGraphQL;
  rawData: CreativeGraphQLTypes.MediaGraphQL;
  release?: Release;
};
export type CreativeMediaWithouReleaseListItem = Omit<
  CreativeMediaListItem,
  'release'
>;

export type CreativeMediaList = CreativeMediaListItem[];
export type CreativeMediaWithouReleaseList =
  CreativeMediaWithouReleaseListItem[];

export enum CreateMediaOrderByAttributes {
  creationDate = 'creationDate',
  updateDate = 'updateDate',
}
