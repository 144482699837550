import { api } from '../api';
import { CapiProductsResponse, Pagination } from './product.api.types';
import { CapiProductModel } from './product.model';
import { Product } from './product.types';

export const refreshProduct = async (productId): Promise<Product> => {
  const results = await api.post(`/products/${productId}/refresh`);
  return results.data;
};

export const fetchCAPIProductsByAlbumId = async ({
  albumId,
}): Promise<CapiProductsResponse> => {
  const results = await api.get(`/products/capi/album/${albumId}`);
  return CapiProductModel.transformAll(results.data);
};

export const fetchCAPIProducts = async ({
  query = '',
  page = 0,
  limit = 20,
}): Promise<CapiProductsResponse> => {
  const results = await api.get('/products/capi/search', {
    params: {
      query,
      page,
      limit,
    },
  });
  return CapiProductModel.transformAll(results.data);
};

export const getCAPIProducts = async ({
  query = '',
  page = 0,
  preferedLimit = 10,
  workspaceId,
}): Promise<{ capiProducts: Product[]; pagination: Pagination }> => {
  const results = await api.get('/products/capi/search', {
    params: {
      query,
      page,
      preferedLimit,
      workspaceId,
    },
  });
  return results.data;
};

export const fetchProductAdmin = async (productId): Promise<Product> => {
  const results = await api.get(`/products/admin/${productId}`);
  return results.data;
};

export const fetchCAPIProductsAdmin = async ({
  producerId,
  query = '',
  page = 0,
  limit = 20,
}): Promise<{ capiProducts: Product[]; pagination: Pagination }> => {
  const results = await api.get('/products/admin/capi/search', {
    params: {
      producerId,
      query,
      page,
      limit,
    },
  });
  return results.data;
};

export const fetchCAPIProductAdmin = async (
  productId: string,
  containers: string[],
): Promise<any> => {
  const results = await api.get(`/products/admin/capi/${productId}`, {
    params: { containers },
  });
  return results.data;
};

export const fetchProductsAdmin = async ({
  query = '',
  page = 0,
  limit = 20,
}): Promise<{ results: Product[]; total: number }> => {
  const results = await api.get('/products/admin', {
    params: {
      query,
      page,
      limit,
    },
  });
  return results.data;
};

export const syncExistingProductsAdmin = async (
  artistIds = [],
): Promise<Product> => {
  const results = await api.post('/products/admin/sync/syncExistingProducts', {
    artistIds,
  });
  return results.data;
};
