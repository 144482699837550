export const shareExtensionsFixture = [
  { name: 'Facebook Bio', type: 'Owned', extension: 'fbi' },
  { name: 'Facebook Post', type: 'Owned', extension: 'fpo' },
  { name: 'Website', type: 'Owned', extension: 'web' },
  { name: 'Emailing', type: 'Owned', extension: 'ema' },
  { name: 'SMS', type: 'Owned', extension: 'sms' },
  { name: 'YouTube Bio', type: 'Owned', extension: 'ybi' },
  { name: 'YouTube Post', type: 'Owned', extension: 'ypo' },
  { name: 'YouTube Description', type: 'Owned', extension: 'yde' },
  { name: 'Instagram Bio', type: 'Owned', extension: 'ibi' },
  { name: 'Instagram Story', type: 'Owned', extension: 'ist' },
  { name: 'Snapchat', type: 'Owned', extension: 'sna' },
  { name: 'Twitter Bio', type: 'Owned', extension: 'tbi' },
  { name: 'Twitter Post', type: 'Owned', extension: 'tpo' },
  { name: 'Facebook Story', type: 'Owned', extension: 'fst' },
  { name: 'App', type: 'Owned', extension: 'app' },
  { name: 'Fastory', type: 'Owned', extension: 'fas' },
  { name: 'TikTok', type: 'Owned', extension: 'tik' },
  { name: 'QR Code', type: 'Owned', extension: 'qrc' },
  { name: 'Facebook Ad', type: 'Paid', extension: 'fad' },
  { name: 'YouTube Ad', type: 'Paid', extension: 'yad' },
  { name: 'Instagram Ad', type: 'Paid', extension: 'iad' },
  { name: 'Facebook Story Ad', type: 'Paid', extension: 'fsa' },
  { name: 'Facebook Feed Ad', type: 'Paid', extension: 'ffe' },
  { name: 'Instagram Story Ad', type: 'Paid', extension: 'isa' },
  { name: 'Instagram Feed Ad', type: 'Paid', extension: 'ifa' },
  { name: 'Snapchat Ad', type: 'Paid', extension: 'sad' },
  { name: 'Klox', type: 'Paid', extension: 'klo' },
  { name: 'TikTok Ad', type: 'Paid', extension: 'tta' },
  { name: 'Twitter Ad', type: 'Paid', extension: 'twa' },
  { name: 'Influencers', type: 'Paid', extension: 'inf' },
  { name: 'Xandr', type: 'Paid', extension: 'xdr' },
  { name: 'Spotify VideoM Ad', type: 'Paid', extension: 'spvm' },
  { name: 'Spotify VideoD Ad', type: 'Paid', extension: 'spvd' },
  { name: 'Spotify Marquee Ad', type: 'Paid', extension: 'spmar' },
  { name: 'Spotify OverlayM Ad', type: 'Paid', extension: 'spom' },
  { name: 'Spotify OverlayD Ad', type: 'Paid', extension: 'spod' },
  { name: 'Spotify Audio Ad', type: 'Paid', extension: 'spau' },
  { name: 'Spotify Banner Ad', type: 'Paid', extension: 'spban' },
  { name: 'Spotify HPTO Ad', type: 'Paid', extension: 'sphpto' },
  { name: 'Deezer Audio Ad', type: 'Paid', extension: 'deea' },
  { name: 'Deezer VideoM Ad', type: 'Paid', extension: 'deevm' },
  { name: 'Deezer VideoD Ad', type: 'Paid', extension: 'deevd' },
  { name: 'Deezer DisplayM Ad', type: 'Paid', extension: 'deedm' },
  { name: 'Deezer DisplayT Ad', type: 'Paid', extension: 'deedt' },
  { name: 'Deezer DisplayD Ad', type: 'Paid', extension: 'deedd' },
  { name: 'Deezer SponsoredTrack Ad', type: 'Paid', extension: 'deest' },
  { name: 'Deezer Native Ad', type: 'Paid', extension: 'deen' },
  { name: 'Deezer Banner Ad', type: 'Paid', extension: 'deeB' },
  { name: 'Deezer Billboard Ad', type: 'Paid', extension: 'deeHPTO' },
];
