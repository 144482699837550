import React from 'react';

import ShareBacklinkDialog from 'shared/components/Backlink/components/ShareBacklinkDialog';

type UrlParts = { slug: string; subDomain: string };

function useShareBacklinkDialog() {
  const defaultUrlParts: UrlParts = { slug: '', subDomain: '' };
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [urlParts, setUrlParts] = React.useState<UrlParts>(defaultUrlParts);

  function openDialog(infos: UrlParts) {
    setUrlParts(infos);
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  const Dialog = (
    <ShareBacklinkDialog
      open={isDialogOpen}
      slug={urlParts.slug}
      subDomain={urlParts.subDomain}
      onClose={closeDialog}
    />
  );

  return {
    Dialog,
    openDialog,
    closeDialog,
  };
}

export default useShareBacklinkDialog;
