import { ProductTypeEnum } from 'shared/entities/product/product.types';

import { RawRelease } from '../../release.api.types';
import { ReleaseModel } from '../../release.model';
import { Release } from '../../release.types';

export const rawRelease: Release = {
  id: 'f28bee5a-2100-4412-a6ab-bf3b2e874ad7',
  createdAt: '2021-06-16T09:55:53.097Z',
  updatedAt: '2021-06-16T09:55:53.097Z',
  selectedTimezone: 'Europe/Paris',
  artistId: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
  productReleaseType: ProductTypeEnum.SINGLE,
  artist: {
    id: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
    createdAt: '2021-06-16T09:55:52.259Z',
    updatedAt: '2021-06-16T09:55:52.259Z',
    name: 'Charlemagne Palestine',
    data: {
      facebookId: 'jhsdf8KJ32834H',
      instagramId: 'jhsdf8KJ32834H',
      storeProfileLinks: {
        spotify: {
          url: 'https://open.spotify.com/artist/4MKrXiEHOd8zXvrxRsAJ8Q',
        },
      },
    },
    workspaceId: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
    customBacklinkSettingsId: null,
  },
  products: [
    {
      id: '66835f11-9fc9-4a0f-9a8e-2ffdd38fbf33',
      createdAt: '2021-06-16T09:55:52.269Z',
      updatedAt: '2021-06-16T09:55:53.073Z',
      internalProductId: '742713',
      lastSync: '2021-06-16T09:55:53.053Z',
      data: {
        summary: {
          container: 'summary',
          result: {
            product: 'music',
            mode: 'summary',
            items: [
              {
                type: 'summary-album',
                ref: '89359c1355d1ec94a5d21c1e146ea3b6',
                item_id: { id: '9872', state: 'final' },
                title: 'An aural symbiotic mystery',
                version: [],
                artists: ['Charlemagne Palestine', 'Tony Conrad'],
                release_dates: { digital: '2007-03-28T00:00:00+02:00' },
                content_status: 'sellable',
                resources: [
                  {
                    type: 'artwork',
                    urls: {
                      small:
                        '//covers.believedigital.com/150/3700368472019.jpg?token=%23blv002%23Zjk4M2VkZDRlNGgmM3JlNGI5NWkwZDQ5NNQ0MTz4MTMMOyM3YMQWZDkUZGYzxjgxNNU0M2j3YjM53DgxYMM3YzI1NggzNTMjMjcjN2Y3NxVhZGT2Mjkz4OTcYy',
                      medium:
                        '//covers.believedigital.com/450/3700368472019.jpg?token=%23blv002%23Zjk4N2VkZzRlNGEmM4JlNGI5N2kwZDQwNNQ0MTz4MTMQO0M3YMQWZDkUZGYzxjgxNNU0M2j3YjM53DgxYMM3YzI1NggzNTMjMjcjN2Y3NxVhZGT2MjIz2ODYYy',
                      large:
                        '//covers.believedigital.com/600/3700368472019.jpg?token=%23blv002%23Zjk4O2VkZTRlNGMmM0JlOGI5NGkwZDQyNMQ0MTT4MTMEO1M3YZQTZDkEZGYz2jgxNMU0M2z3Yjc5zDgxYOM3YDI1NMgyNTNjMjcjN2F3NlVhNGT2MjIz4MDEY%3D',
                    },
                  },
                ],
                item_references: {
                  owner_id: 'album-9872',
                  upc: '3700368472019',
                },
                total_supports: '1',
                total_tracks: '1',
              },
              {
                type: 'summary-track',
                ref: '7545809308b140e754a2dbc257d66b8d',
                item_id: { id: '125371', state: 'final' },
                title: 'An aural symbiotic mystery',
                version: [],
                artists: ['Charlemagne Palestine', 'Tony Conrad'],
                resources: [],
                support_number: '1',
                track_number: '1',
                item_references: {
                  owner_id: 'album-125371',
                  isrc: 'FR-6V8-06-22751',
                },
              },
            ],
            id: '742713',
          },
        },
        store: {
          container: 'store',
          result: {
            product: 'music',
            mode: 'store',
            items: [
              {
                type: 'store-album',
                platform_name: 'Spotify',
                links: {
                  believe: 'http://player.believe.fr/links/fr/204/9872/',
                  store: {
                    url: 'http://open.spotify.com/album/6Pmu34AfIwXuMTT7k2CxJM',
                    type: 'direct',
                  },
                },
              },
              {
                type: 'store-album',
                platform_name: 'Deezer',
                links: {
                  believe: 'http://player.believe.fr/links/fr/57/9872/',
                  store: {
                    url: 'http://www.deezer.com/album/47657',
                    type: 'direct',
                  },
                },
                store_ids: [
                  { type: 'commercial_link', position: '0', id: '47657' },
                ],
              },
              {
                type: 'store-album',
                platform_name: 'Itunes',
                links: {
                  believe: 'http://player.believe.fr/links/fr/0/9872/',
                  store: {
                    url: 'https://geo.itunes.apple.com/at/album/id250984979?at=1l3v9Tx&app=itunes',
                    type: 'direct',
                  },
                },
                store_ids: [
                  { type: 'commercial_link', position: '0', id: '250984979' },
                ],
              },
            ],
            id: '742713',
          },
        },
        dist: {
          container: 'dist',
          result: {
            product: 'music',
            mode: 'dist',
            items: [
              {
                type: 'dist-album',
                ref: '89359c1355d1ec94a5d21c1e146ea3b6',
                item_id: { id: '9872', state: 'final' },
                audio_ref_match_policy: 'monetize',
                release_time_mode: '',
                preview_allowed: '1',
                new_deliveries_allowed: '1',
                new_stores_allowed: 1,
                item_references: { owner_id: 'album-9872' },
                distribution_scope: { type: 'exclude', territories: [] },
                distribution_type: '1',
                approved_for_me: '0',
                price: { type: 'code', value: 'MIDFRONT' },
                block_download_us: '1',
                download_allowed: '1',
                streaming_allowed: '1',
              },
              {
                type: 'dist-track',
                ref: '7545809308b140e754a2dbc257d66b8d',
                item_id: { id: '125371', state: 'final' },
                item_references: { owner_id: 'album-125371' },
                streaming_allowed: '1',
                download_allowed: '1',
                price: { type: 'code', value: 'SINGLE2' },
              },
            ],
            id: '742713',
          },
          distributionType: '1',
        },
      },
      albumTitle: 'An aural symbiotic mystery',
      digitalReleaseDate: '2050-03-27T22:00:00.000Z',
      artworkUrl: 'https://covers.believedigital.com/600/3700368472019.jpg',
      thumbArtworkUrl: 'https://covers.believedigital.com/40/3700368472019.jpg',
      title: 'An aural symbiotic mystery',
      artistName: 'Charlemagne Palestine',
      productType: ProductTypeEnum.SINGLE,
      productVersion: 'Live',
      stores: {
        deezer: {
          platform: { displayName: 'Deezer', name: 'deezer' },
          storeIds: [{ type: 'commercial_link', position: '0', id: '47657' }],
          links: { url: 'http://www.deezer.com/album/47657', type: 'direct' },
        },
        appleMusic: {
          platform: { displayName: 'Apple Music', name: 'appleMusic' },
          storeIds: [
            { type: 'commercial_link', position: '0', id: '250984979' },
          ],
          links: {
            url: 'https://geo.itunes.apple.com/at/album/id250984979?at=1l3v9Tx&app=itunes',
            type: 'direct',
          },
        },
        spotify: {
          platform: { displayName: 'Spotify', name: 'spotify' },
          storeIds: [
            {
              type: 'commercial_link',
              position: '0',
              id: '6Pmu34AfIwXuMTT7k2CxJM',
            },
          ],
          links: {
            url: 'http://open.spotify.com/album/6Pmu34AfIwXuMTT7k2CxJM',
            type: 'direct',
          },
        },
      },
      intranetUrl:
        'http://intranet.believedigital.com/metadata/albums-viewAlbum.php?idAlbum=9872',
    },
  ],
  productIds: ['66835f11-9fc9-4a0f-9a8e-2ffdd38fbf33'],
  digitalReleaseDate: '2050-03-27T20:00:00.000Z',
  releaseDateTimeZone: 'Europe/Paris',
  artworkUrl: 'https://covers.believedigital.com/600/3700368472019.jpg',
  title: 'An aural symbiotic mystery',
  artistName: 'Charlemagne Palestine',
  stores: {
    deezer: {
      name: 'deezer',
      useAutoscan: true,
      url: 'http://www.deezer.com/album/47657',
      id: '47657',
    },
    appleMusic: {
      name: 'appleMusic',
      useAutoscan: true,
      url: 'https://geo.itunes.apple.com/at/album/id250984979?at=1l3v9Tx&app=itunes',
      id: '250984979',
    },
    spotify: {
      name: 'spotify',
      useAutoscan: true,
      url: 'http://open.spotify.com/album/6Pmu34AfIwXuMTT7k2CxJM',
      id: '6Pmu34AfIwXuMTT7k2CxJM',
    },
  },
  metadata: {
    initialResourcesCreated: {
      backlinks: true,
      suggestedCreativeMedia: false,
    },
    artworkResources: {
      uploadId: '0e37a0dd-c779-4b27-9cee-d17441603611',
    },
  },
};

export const release: Release = ReleaseModel.transform(rawRelease);

export const refreshedRawRelease: RawRelease = {
  ...rawRelease,
  artworkUrl: 'https://covers.believedigital.com/140/3661585593294.jpg',
  digitalReleaseDate: '2022-12-30T22:00:00.000Z',
  releaseDateTimeZone: 'Europe/Paris',
  title: 'An awesome new title',
  metadata: {
    ...rawRelease.metadata,
    artworkResources: undefined,
  },
  products: [
    {
      ...rawRelease.products[0],
      artworkUrl: 'https://covers.believedigital.com/140/3661585593294.jpg',
      digitalReleaseDate: '2022-12-30T22:00:00.000Z',
      thumbArtworkUrl:
        'https://covers.believedigital.com/140/3661585593294.jpg',
      title: 'An awesome new title',
    },
  ],
};

export const refreshedRelease: Release =
  ReleaseModel.transform(refreshedRawRelease);
