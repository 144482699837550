import React from 'react';

import { Location } from 'history';

import { RouteConfig } from 'shared/routes';

export type NavItemOptions = {
  path: string;
  title: string;
  icon?: React.ReactNode;
  color?: string;
  activePaths?: Array<string | RegExp>;
  disabled?: boolean;
  target?: '_blank' | '_self';
  key?: string;
};

export type NavItemOptionsByRoute = Record<string, Partial<NavItemOptions>>;

export const makeNavItemsFromRoutes = (
  routes: RouteConfig[],
  navItemsOptionsByRoute?: NavItemOptionsByRoute,
): (NavItemOptions | null)[] => {
  return navItemsOptionsByRoute
    ? routes
        .map((route) => {
          return navItemsOptionsByRoute[route.path]
            ? {
                ...route,
                ...navItemsOptionsByRoute[route.path],
              }
            : null;
        })
        .filter(Boolean)
    : routes;
};

/**
 * we want a menu item to be marked as active
 * whether it matches its actual pathname
 * or other paths when provided via the activePaths property
 * @param item MenuItemShape
 */
export const makeNavItemIsActiveMatcher = (
  item: NavItemOptions,
  location: Location,
) => {
  if (item.activePaths?.length) {
    return !!item.activePaths?.reduce(
      (pathMatches, pathMatcher) =>
        !!location.pathname.match(new RegExp(pathMatcher))?.length ||
        pathMatches,
      false,
    );
  }
  return !!location.pathname.match(new RegExp(item.path))?.length;
};

/**
 * the path we want to redirect the user to in some cases
 * e.g. when the user is on a Settings page and does not have access to the main navigation
 * but can only click on a 'back' button
 */
let internalReturnPath = '/';

/**
 *
 * @returns the path to redirect the user to
 */
export function getInternalReturnPath() {
  return internalReturnPath;
}
/**
 *
 * @param path the path to redirect the user to
 */
export function setInternalReturnPath(path: string) {
  internalReturnPath = path;
}
