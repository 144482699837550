import React, { useState } from 'react';

import { Interpolation } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Theme,
} from '@mui/material';

import { ShareExtension } from 'shared/entities/backlink/backlink.types';

import * as Styled from '../styled';

type Props = {
  shareExtensions?: ShareExtension[];
  handleButtonClick: (additionalPath?: string) => void;
  styles: Record<string, Interpolation<Theme>>;
};

const ChannelSelector = ({
  shareExtensions = [],
  handleButtonClick,
  styles,
}: Props) => {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState(shareExtensions);

  const getSearchResults = (value) => {
    if (value === '') return shareExtensions;
    return shareExtensions.filter(({ name }) => {
      return name.toLowerCase().includes(value.toLowerCase());
    });
  };

  return (
    <Box>
      <Styled.DialogContentText id="alert-dialog-description">
        {t(
          'section.bfan_links.dialog.share.tab.channel.description',
          'Add a channel to your link for better traffic source insights.',
        )}
      </Styled.DialogContentText>
      <Styled.TextField
        fullWidth
        placeholder={t('ui.form.fields.search.placeholder', 'Search')}
        type="search"
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchResults(getSearchResults(event.target.value));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TableContainer css={styles.tableContainer}>
        <Table aria-label="backlinks">
          <TableBody>
            {searchResults.map((sharingExtension) => (
              <TableRow key={sharingExtension.name}>
                <TableCell component="th" scope="row">
                  {sharingExtension.name}
                </TableCell>
                <TableCell align="right">{sharingExtension.type}</TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      t(
                        'section.bfan_links.dialog.share.tab.channel.copy_to_clipboard',
                        'Copy sharingExtension',
                      ) as string
                    }
                  >
                    <IconButton
                      onClick={() =>
                        handleButtonClick(`.${sharingExtension.extension}`)
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ChannelSelector;
