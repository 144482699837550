import {
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
} from 'shared/analytics/analytics.types';

export enum GA4BacklinkSettingsEventNames {
  /** the updateLinksTemplate naming is a business / UI naming as we only have the notion of BacklinkSettings */
  updateLinksTemplate = 'updateLinksTemplate',
}

export type GA4BacklinkSettingsSubproduct = GA4SubProducts.links;

export type GA4BacklinkSettingsEventParams = {
  workspaceId: string;
  artistId?: string;
};

export type GA4UpdateBacklinkSettingsEventParams =
  GA4BacklinkSettingsEventParams;

/**
 * Tracks when users update BacklinkSettings in a Template context,
 * specifically to monitor how much users are using the feature.
 */
export type GA4UpdateBacklinkSettingsTemplateSettingsTrackingEventConfig =
  GA4MKPProductTrackingEvent<
    {
      eventName: GA4BacklinkSettingsEventNames.updateLinksTemplate;
      subProduct: GA4BacklinkSettingsSubproduct;
    } & GA4UpdateBacklinkSettingsEventParams
  >;
