export function isNotNull<T>(arg: T | null): arg is T {
  return arg !== null;
}

export function isDefined<T>(arg: T | undefined): arg is T {
  return typeof arg !== 'undefined';
}

export function isDefinedAndNotNull<T>(arg: T | null | undefined): arg is T {
  return isDefined(arg) && isNotNull(arg);
}

export function isFunction<T>(arg: T | any): arg is T {
  return typeof arg === 'function';
}
