import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Popover,
  Stack,
  Typography,
  TextField,
  Box,
  PopoverProps,
  SxProps,
} from '@mui/material';

import { useCallbackRef } from 'shared/hooks/useCallbackRef';

/*********************************************************************/
// CONTEXT
/*********************************************************************/
type State = {
  anchorEl: HTMLButtonElement | null;
};

const initialState: State = {
  anchorEl: null,
};

const Context = React.createContext<{
  state: State;
  update: (nextState: Partial<State>) => void;
}>({ state: initialState, update: () => null });
Context.displayName = 'PopoverContext';

function useContext() {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      `${Context.displayName} context must be used inside its provider`,
    );
  }
  return context;
}

/*********************************************************************/
// ROOT
/*********************************************************************/
type RootProps = {
  children: React.ReactNode;
  onToggle?: (value: boolean) => void;
};

/**
 * The root component of the Popover. It provides the context for
 * all the children.
 */
export const Root = ({ onToggle = () => {}, children }: RootProps) => {
  const [state, setState] = React.useState<State>(initialState);

  function update(nextState: Partial<State>) {
    setState((prevState) => ({ ...prevState, ...nextState }));
  }

  const handleToggle = useCallbackRef(onToggle);
  const anchorElRef = React.useRef(state.anchorEl);
  React.useEffect(() => {
    if (state.anchorEl !== anchorElRef.current) {
      anchorElRef.current = state.anchorEl;
      handleToggle(state.anchorEl !== null);
    }
  }, [handleToggle, state.anchorEl]);

  return (
    <Context.Provider value={{ state, update }}>{children}</Context.Provider>
  );
}

/*********************************************************************/
// TRIGGER
/*********************************************************************/
/**
 * Will augment the button child with an onClick handler that will
 * open the popover.
 * If you provide an onClick handler of your own, it will also be called
 * after the popover is opened.
 */
export function Trigger({ children: child }: { children: React.ReactElement }) {
  const {
    state: { anchorEl },
    update,
  } = useContext();

  return React.cloneElement(child, {
    sx: {
      ...(!!anchorEl && { visibility: 'visible' }),
      ...child.props.sx,
    },
    onClick: (event) => {
      update({ anchorEl: event.currentTarget });
      child.props.onClick?.();
    },
  });
}

Trigger.displayName = 'Trigger';

/*********************************************************************/
// PANEL
/*********************************************************************/
/**
 * The actual popover panel that is rendered when opened. Can contain
 * whatever you want.
 */
export const Panel = ({
  children,
  sx,
  anchorOrigin,
  transformOrigin,
}: { children: React.ReactNode } & Partial<PopoverProps>) => {
  const {
    state: { anchorEl },
    update,
  } = useContext();

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => update({ anchorEl: null })}
      anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'right' }}
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      elevation={0}
      sx={{
        '.MuiPopover-paper': {
          width: 'fit-content',
          minWidth: 345,
          maxWidth: { xs: '100%', md: 500 },
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'grey.475',
          mt: 0.5,
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.05)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            pointerEvents: 'none',
            bottom: 0,
            left: 0,
            right: 0,
            height: '40px',
            background:
              'linear-gradient(180deg, hsl(360deg 100% 100% / 0%), hsl(360deg 100% 100% / 100%))',
          },
        },
        ...sx,
      }}
    >
      {children}
    </Popover>
  );
}

Panel.displayName = 'Content';

/*********************************************************************/
// HEADER
/*********************************************************************/
/**
 * A styled wrapper for the header of the popover.
 */
export const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={0.25}
      sx={{
        backgroundColor: 'grey.100',
        px: 1,
        py: 0.5,
        borderBottom: '1px solid',
        borderColor: 'grey.300',
      }}
    >
      {children}
    </Stack>
  );
}

Header.displayName = 'Header';

/*********************************************************************/
// HEADER TITLE
/*********************************************************************/
/**
 * A MUI typography wrapper for the header title.
 */
export const HeaderTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      variant="body1"
      sx={{ fontWeight: 700, color: 'grey.800', pr: 1, mr: 'auto' }}
    >
      {children}
    </Typography>
  );
}

HeaderTitle.displayName = 'HeaderTitle';

/*********************************************************************/
// HEADER SAVE BUTTON
/*********************************************************************/
/**
 * Will add default styles and an onClick handler that will close
 * the popover to the button provided in the children.
 * Will also call your onClick handler if provided.
 */
export function HeaderSaveButton({
  children: child,
}: {
  children: React.ReactElement;
}) {
  const { update } = useContext();

  return React.cloneElement(child, {
    // Default some props for styling purposes
    variant: child.props.variant ?? 'contained',
    color: child.props.color ?? 'primary',
    sx: {
      minHeight: 'unset',
      minWidth: 'unset',
      fontSize: `${12 / 16}rem`,
      ...child.props.sx,
    },
    onClick: () => {
      update({ anchorEl: null });
      child.props.onClick?.();
    },
  });
}

HeaderSaveButton.displayName = 'HeaderSaveButton';

/**********************************************************************************/
// CLOSE BUTTON - Same as Save Button, but unstyled (doesn't have to be a button)
// To be used anywhere to close the popover
/**********************************************************************************/
/**
 * Will add an onClick handler that will close the popover to the button provided
 * in the children.
 * Will also call your onClick handler if provided.
 */
export function CloseButton({
  children: child,
}: {
  children: React.ReactElement;
}) {
  const { update } = useContext();

  return React.cloneElement(child, {
    onClick: () => {
      update({ anchorEl: null });
      child.props.onClick?.();
    },
  });
}

CloseButton.displayName = 'CloseButton';

/*********************************************************************/
// SEARCH
/*********************************************************************/
type SearchProps = {
  value: string;
  onChange: (value: string) => void;
};

/**
 * Render a controlled, styled MUI TextField for search purposes.
 * You need to handle the search yourself.
 */
export const Search = ({ value, onChange }: SearchProps) => {
  return (
    <TextField
      value={value}
      onChange={(event) => onChange(event.target.value)}
      variant="standard"
      InputProps={{
        startAdornment: (
          <SearchIcon sx={{ color: '#656C82', mr: 0.5 }} fontSize="small" />
        ),
      }}
      placeholder="Search"
      fullWidth
      sx={{
        '.MuiInput-root': {
          pl: 0.75,
        },
        '.MuiInput-underline::before': {
          borderColor: 'grey.300',
        },
        '.MuiInputBase-input, .MuiInputBase-input::placeholder': {
          color: 'grey.600',
          py: '12px',
        },
      }}
    />
  );
}

Search.displayName = 'Search';

/*********************************************************************/
// CONTENT
/*********************************************************************/
type ContentProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

/**
 * Render the children within a styled, scrollable wrapper with a fixed height
 * and a linear gradient at the bottom.
 */
export const Content = ({ children, sx }: ContentProps) => {
  return (
    <Box
      sx={{
        flex: 1,
        maxHeight: 350,
        overflowY: 'auto',
        px: 1,
        pt: 0.5,
        pb: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

Content.displayName = 'Items';
