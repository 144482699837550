import * as yup from 'yup';

import i18n from 'shared/i18n';

import {
  OFFICIAL_PLAYLIST_DSP_PLATFORMS,
  PROFILE_URLS_DSP_PLATFORMS,
} from './ArtistProfile.constants';

export const getArtistProfileSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(
        i18n.t(
          'ui.component.artist_profile.form.field.name.validation.required.message',
          'The artist name is required.',
        ),
      ),
    socialHandles: yup.object().shape({
      instagramHandle: getSocialHandleValidationRule(),
      facebookHandle: getSocialHandleValidationRule(),
      twitterHandle: getSocialHandleValidationRule(),
      tiktokHandle: getSocialHandleValidationRule(),
      VK: getSocialHandleValidationRule(),
      youtubeChannelName: getSocialHandleValidationRule(),
    }),
    storeProfileLinks: yup.array(
      yup.object().shape({
        storeName: yup.string().required(),
        url: yup
          .string()
          .url(
            i18n.t(
              'ui.component.artist_profile.form.field.store_profile_link.url.validation.url.message',
              'The profile link must be a valid URL',
            ),
          )
          .required(
            i18n.t(
              'ui.component.artist_profile.form.field.store_profile_link.url.validation.required.message',
              'The profile link is required',
            ),
          ),
        id: yup.string().when('storeName', {
          is: (storeName) => PROFILE_URLS_DSP_PLATFORMS.includes(storeName),
          then: yup
            .string()
            .required(
              i18n.t(
                'ui.component.artist_profile.form.field.store_profile_link.id.validation.required.message',
                'The link must be a valid profile URL',
              ),
            ),
          otherwise: yup.string().nullable(), // 'nullable' because backend has assigned id: null to existing profileLinks
        }),
      }),
    ),
    storeOfficialPlaylists: yup.array(
      yup.object().shape({
        storeName: yup.string().required(),
        url: yup
          .string()
          .url(
            i18n.t(
              'ui.component.artist_profile.form.field.official_playlists.url.validation.url.message',
              'The official playlist link must be a valid URL',
            ),
          )
          .required(
            i18n.t(
              'ui.component.artist_profile.form.field.official_playlists.id.validation.required.message',
              'The link must be a valid official playlist URL',
            ),
          ),
        id: yup.string().when('storeName', {
          is: (storeName) =>
            OFFICIAL_PLAYLIST_DSP_PLATFORMS.includes(storeName),
          then: yup
            .string()
            .required(
              i18n.t(
                'ui.component.artist_profile.form.field.official_playlists.id.validation.required.message',
                'The link must be a valid official playlist URL',
              ),
            ),
          otherwise: yup.string().nullable(),
        }),
      }),
    ),
    subDomain: yup
      .string()
      .matches(
        /^(?:(?![()@:%_+.~#?&//=!$&*,;'[\]\s]).)*$/u,
        i18n.t(
          'ui.component.artist_profile.form.field.subdomain.validation.format.message',
          'This is not a valid subdomain.',
        ),
      ),
  });

const getSocialHandleValidationRule = () =>
  yup
    .string()
    .test(
      'is-profile-handle',
      i18n.t(
        'ui.component.artist_profile.form.field.social_handle.validation.format.message',
        'Please enter your profile name without "@" nor the rest of the URL.',
      ),
      (val) => !val?.includes('@') && !val?.includes('/'),
    );
