import { useCallback, useEffect, useState } from 'react';

import {
  Grid,
  Typography,
  SearchBar,
  MuiIcons,
} from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { ButtonBase, useTheme } from '@mui/material';

import { DEBOUNCE_TIME } from './constants';
import { ActionType } from './context/actions';
import { useReleaseEditorContext } from './context/context';
import { ReleaseEditorStep } from './types';

export const Header = () => {
  const {
    state: { currentStep, artistQuery, artist, productQuery },
    dispatch,
  } = useReleaseEditorContext();

  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor',
  });
  const theme = useTheme();

  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    currentStep === ReleaseEditorStep.PRODUCT && setLocalValue(productQuery);
    currentStep === ReleaseEditorStep.ARTIST && setLocalValue(artistQuery);
    // only update local state when currentStep changes or when an artist has been selected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, artist]);

  const handleQueryChange = useCallback(
    (queryValue) => {
      currentStep === ReleaseEditorStep.PRODUCT
        ? dispatch({
            type: ActionType.UPDATE_PRODUCT_QUERY,
            payload: { productQuery: queryValue },
          })
        : dispatch({
            type: ActionType.UPDATE_ARTIST_QUERY,
            payload: { artistQuery: queryValue },
          });
    },
    [currentStep, dispatch],
  );

  useDebounce(() => handleQueryChange(localValue), DEBOUNCE_TIME, [localValue]);

  return (
    <Grid
      aria-label={t('header.aria_label', 'dialog header')}
      container
      height={134}
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: 'relative',
      }}
    >
      {currentStep === ReleaseEditorStep.ARTIST && (
        <ButtonBase
          sx={{
            position: 'absolute',
            top: '30px',
            left: '24px',
            height: '20px',
            width: '20px',
            /** ensure back button is over dialog scrollable content on small screens */
            zIndex: 1,
          }}
          onClick={() =>
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: { currentStep: ReleaseEditorStep.PRODUCT },
            })
          }
          aria-label="back"
        >
          <MuiIcons.ArrowBack
            style={{
              height: '20px',
              width: '20px',
              color: '#919CBB',
            }}
          />
        </ButtonBase>
      )}
      <ButtonBase
        sx={{
          position: 'absolute',
          top: '30px',
          right: '24px',
          height: '20px',
          width: '20px',
          /** ensure close button is over dialog scrollable content on small screens */
          zIndex: 1,
        }}
        onClick={() =>
          dispatch({
            type: ActionType.CHANGE_STEP,
            payload: { currentStep: ReleaseEditorStep.UNINITIALIZED },
          })
        }
        aria-label="cancel"
      >
        <MuiIcons.Close
          style={{
            height: '20px',
            width: '20px',
            color: '#919CBB',
          }}
        />
      </ButtonBase>

      <Grid item>
        <Typography
          variant="h4"
          fontWeight={theme.typography.fontWeightBold}
          sx={{
            fontWeight: 700,
            fontSize: '1.125rem',
            marginBottom: '1.063rem',
            textAlign: 'center',
          }}
        >
          {currentStep === ReleaseEditorStep.PRODUCT
            ? t('step.release.title', 'Select a release')
            : t('step.artist.title', 'Select an artist')}
        </Typography>
      </Grid>
      <Grid item>
        <SearchBar
          type="text"
          inputProps={{
            'aria-label':
              currentStep === ReleaseEditorStep.PRODUCT
                ? t(
                    'search_input.step_release.aria_label',
                    'Search for a release',
                  )
                : t(
                    'search_input.step_artist.aria_label',
                    'Search for an artist',
                  ),
          }}
          autoFocus={!!localValue}
          placeholder={t('search_placeholder', 'Search')}
          value={localValue}
          onChange={(inputValue) => setLocalValue(inputValue)}
        />
      </Grid>
    </Grid>
  );
};
