import React from 'react';

import { useTranslation } from 'react-i18next';

import AlbumIcon from '@mui/icons-material/Album';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import { Thumbnail } from 'shared/components/Thumbnail';

import * as Styled from './styled';

type Props = {
  name?: string;
  picture?: string;
  thumbnailSize?: number;
  onEdit?: () => void;
};

const ArtistCard: React.FC<Props> = ({
  name,
  picture,
  thumbnailSize = 60,
  onEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Thumbnail
        src={picture}
        alt={t('ui.misc.artist_picture_with_name', '{{artist_name}} picture', {
          artist_name: name,
        })}
        width={thumbnailSize}
        height={thumbnailSize}
        variant="circular"
      >
        <AlbumIcon />
      </Thumbnail>
      {!!name && <Styled.ArtistName>{name}</Styled.ArtistName>}
      {!!onEdit && (
        <IconButton color="primary" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
    </Styled.Container>
  );
};

export default ArtistCard;
