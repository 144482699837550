import React from 'react';

import { Switch, SwitchProps } from '@mui/material';

import { EditorSwitchProps } from '../types';

export const EditorSwitch: React.FC<EditorSwitchProps & SwitchProps> = ({
  name,
  value,
  changeHandler,
  ...props
}) => {
  return (
    <>
      <Switch
        color="primary"
        onChange={(event) => changeHandler(!!event.target.checked)}
        checked={value}
        {...props}
      />
    </>
  );
};
