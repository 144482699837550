import React from 'react';

import { useParams, useLocation, useResolvedPath } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';

import {
  FormValidatedIcon,
  FormHasErrorsIcon,
  StepNotYetVisitedIcon,
} from 'shared/components/Icons';
import { MenuItemOptions } from 'shared/components/Menu';
import {
  SecondaryMenu,
  SecondaryMenuItem,
} from 'shared/components/Menu/SecondaryMenu';

import { ValidationErrors } from '../../../hooks/useValidation';
import {
  BacklinkSettingsEditorStepsConfig,
  getBacklinkStepCategories,
  StepKey,
  getStepHasErrors,
  BacklinkSettingsEditorStepCategory,
} from '../../../state';

type Props = {
  steps: BacklinkSettingsEditorStepsConfig;
  validationErrors: ValidationErrors;
  createMode?: boolean;
  stepsVisited: Set<string>;
  mutedCategories?: BacklinkSettingsEditorStepCategory[];
};

const EditorMenu = ({
  steps,
  validationErrors,
  createMode = false,
  stepsVisited,
  mutedCategories = [],
}: Props) => {
  const routeUrl = useResolvedPath('').pathname;
  const { pathname } = useLocation();
  const { step: stepKeyParam } = useParams<{ step?: string }>();
  const firstStepKey = StepKey.Release;

  return (
    <div role="navigation" aria-label="Link Settings Editor Menu">
      {getBacklinkStepCategories().map((category, index) => {
        const categoryIsMuted = mutedCategories.includes(category);
        // build steps navigation
        const categorySteps: MenuItemOptions[] = (
          Object.keys(steps) as StepKey[]
        )
          // retrieving steps for a given category of steps
          .filter((stepKey: StepKey) => steps[stepKey].category === category)
          // and building a menu for those steps
          .map((stepKey: StepKey) => {
            const stepHasErrors = getStepHasErrors(stepKey, validationErrors);
            const stepVisited = stepsVisited.has(stepKey);

            const path = !!routeUrl.match(/edit\/?$/)
              ? `${routeUrl}/${stepKey}`
              : routeUrl.replace(/[a-zA-Z]+(\/)?$/, stepKey);

            const currentStep =
              path === pathname ||
              (stepKey === firstStepKey && stepKeyParam === undefined);

            let icon;
            if (createMode && !stepVisited && !currentStep) {
              icon = <StepNotYetVisitedIcon />;
            } else {
              icon = stepHasErrors ? (
                <FormHasErrorsIcon />
              ) : currentStep ? (
                <EditIcon color="primary" />
              ) : (
                <FormValidatedIcon />
              );
            }
            return {
              ...steps[stepKey],
              path,
              activePaths: stepKey === firstStepKey ? [/(edit|new)\/?$/] : [],
              icon,
              key: stepKey,
            };
          });
        return (
          <SecondaryMenu
            key={category}
            items={categorySteps}
            title={index ? category : ''}
            menuItemComponent={SecondaryMenuItem}
            $disabled={categoryIsMuted}
          />
        );
      })}
    </div>
  );
};

export default React.memo(EditorMenu);
