/**
 * @returns the source string with the passed in string value inserted at the given position
 */
export function insertAt({
  source,
  position,
  value,
}: {
  source: string;
  position: number;
  value: string;
}) {
  const newValue = `${source.slice(0, position)}${value}${source.slice(
    position,
  )}`;
  return newValue;
}

export function trimRight(text: string, maxChars: number) {
  if (text.length > maxChars) {
    return `${text.slice(0, maxChars)}...`;
  }
  return text;
}
