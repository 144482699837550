import { api } from '../api';
import { BacklinkSettings } from '../backlinkSettings/backlinkSettings.types';
import {
  FetchArtistsPayload,
  PaginatedArtistsResponse,
  UpdateArtistBacklinkSettingsModePayload,
  UpdateArtistBacklinkSettingsPayload,
  UpdateArtistProfilePayload,
} from './artist.api.types';
import { Artist } from './artist.types';

export const fetchArtists = async ({
  query = '',
  page = 0,
  limit = 20,
  workspaceId = '',
  onlyBelieveArtists = true,
}: FetchArtistsPayload): Promise<PaginatedArtistsResponse> => {
  const results = await api.get('/artists', {
    params: {
      query,
      page,
      limit,
      workspaceId,
      onlyBelieveArtists,
    },
  });
  return results.data;
};

export const fetchArtistsAdmin = async ({
  query = '',
  queryMatchStrategy = 'contains',
  page = 0,
  limit = 20,
  onlyBelieveArtists = true,
}): Promise<{ results: Artist[]; total: number }> => {
  const results = await api.get('/artists/admin', {
    params: {
      query,
      queryMatchStrategy,
      page,
      limit,
      onlyBelieveArtists,
    },
  });
  return results.data;
};

export const downloadArtistsCsvAdmin = async () => {
  const results = await api.get('/artists/admin/csv');
  return results.data;
};

export const fetchArtistAdmin = async (artistId: string): Promise<Artist> => {
  const results = await api.get(`/artists/admin/${artistId}`);
  return results.data;
};

export const fetchArtist = async (artistId: string): Promise<Artist> => {
  const results = await api.get(`/artists/${artistId}`);
  return results.data;
};

export const createArtist = async (data): Promise<Artist> => {
  const results = await api.post('/artists', data);
  return results.data;
};

export const updateArtist = async (artistId: string, data): Promise<Artist> => {
  const results = await api.patch(`/artists/${artistId}`, data);
  return results.data;
};

export const updateArtistProfile = async ({
  artistId,
  data,
}: UpdateArtistProfilePayload): Promise<Artist> => {
  const results = await api.patch(`/artists/${artistId}/profile`, data);
  return results.data;
};

export const deleteArtist = async (artistId: string): Promise<void> => {
  await api.delete(`/artists/${artistId}`);
};

export const validateArtistBatch = async (jsonLines: any[]): Promise<any[]> => {
  const results = await api.post(`/artists/batch/validate`, jsonLines);
  return results.data;
};

export const saveArtistBatch = async (jsonLines: any[]): Promise<any[]> => {
  const results = await api.post(`/artists/batch`, jsonLines);
  return results.data;
};

export const updateBacklinkSettingsMode = async ({
  artistId,
  useDefault,
}: UpdateArtistBacklinkSettingsModePayload): Promise<BacklinkSettings> => {
  const results = await api.patch<BacklinkSettings>(
    `/artists/${artistId}/backlinkSettings/useDefault`,
    { useDefault },
  );
  return results.data;
};

export const fetchBacklinkSettings = async (
  artistId: string,
): Promise<BacklinkSettings> => {
  const results = await api.get<BacklinkSettings>(
    `/artists/${artistId}/backlinkSettings`,
  );
  return results.data;
};

export const updateBacklinkSettings = async ({
  artistId,
  backlinkSettings,
}: UpdateArtistBacklinkSettingsPayload) => {
  const results = await api.patch<BacklinkSettings>(
    `/artists/${artistId}/backlinkSettings`,
    {
      settingsData: backlinkSettings,
    },
  );
  return results.data;
};
