export enum StoreType {
  Stream = 'stream',
  Buy = 'buy',
  Download = 'download',
  Watch = 'watch',
  Follow = 'follow',
  Other = 'other',
}

export const isStoreType = (value: any): value is StoreType => {
  return (
    typeof value === 'string' &&
    Object.values(StoreType).includes(value as StoreType)
  );
};

export type StoreConfig = {
  name: string;
  displayName: string;
  type: StoreType;
  canUseAutoscan?: boolean;
  hasConnect?: boolean;
  releaseFallbackSearchUrlTemplate?: string;
  releaseFallbackSearchMobileCompatible?: boolean;
  releaseFallbackSearchDesktopCompatible?: boolean;
};

export type StoresConfigs = Record<string, StoreConfig>;

/**
 * Useful enum that contains some of the most used digital streaming platforms (DSP)
 */
export enum DSP {
  APPLE_MUSIC = 'appleMusic',
  DEEZER = 'deezer',
  SPOTIFY = 'spotify',
}

export const DSPInfos = {
  [DSP.APPLE_MUSIC]: {
    name: DSP.APPLE_MUSIC,
    displayName: 'Apple Music',
    baseUrlPlaylist: 'https://music.apple.com/playlist/',
    type: StoreType.Stream,
  },
  [DSP.DEEZER]: {
    name: DSP.DEEZER,
    displayName: 'Deezer',
    baseUrlPlaylist: 'https://deezer.com/playlist/',
    type: StoreType.Stream,
  },
  [DSP.SPOTIFY]: {
    name: DSP.SPOTIFY,
    displayName: 'Spotify',
    baseUrlPlaylist: 'https://spotify.com/playlist/',
    type: StoreType.Stream,
  },
};
