export const paginatedBacklinks = {
  backlinks: [
    {
      lifetimeAnalytics: {
        id: null,
        createdAt: null,
        updatedAt: null,
        backlinkId: null,
        visitedPostRelease: null,
        visitedPreRelease: null,
        preSaved: null,
        subscribed: null,
        clickedToStore: null,
      },
      workspace: {
        id: 'c955404a-18a1-44c1-b020-48e0cce04533',
        createdAt: '2021-04-28T08:39:45.897Z',
        updatedAt: '2021-11-16T13:21:39.789Z',
        name: 'Test Workspace',
        settings: {
          facebookPixelId: '741553889772280',
          tiktokPixelId: 'C5JA73NGE0M3SF4JQ69G',
          googleAdsId: '',
          xandrPixelId: '',
          privacyPolicyUrl:
            'https://www.believemusic.com/fr/privacy-policy-fr/',
          legalNoticeUrl: 'https://www.believemusic.com/fr/privacy-policy-fr/',
          producerIds: ['1234'],
          competitionRulesTemplate:
            '<p>You can insert the Competition Rules Template here.</p>\n<ul>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>&nbsp;</li>\n</ul>',
          competitionMinimumAge: 15,
          territory: 'France',
          defaultTimezone: 'Europe/Astrakhan',
        },
        isPrivate: false,
        customBacklinkSettingsId: 'd3617eae-cafa-47d0-8819-ffe8e29e5806',
      },
      actionSubsKPI: {},
      artist: {
        id: 'e0ff1d14-ea0b-4883-b0cb-64ec14ee1360',
        createdAt: '2022-01-25T16:58:44.291Z',
        updatedAt: '2022-01-25T16:58:44.291Z',
        name: 'Billie Holiday',
        data: {},
        workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 1,
        FOLLOW_USER: 3,
        ADD_PLAYLIST: 10,
        ADD_ALBUM: 0,
        ADD_TRACK: 30,
      },
      id: '09a3d10e-812c-4570-b93e-c5fb65ca11c4',
      createdAt: '2022-01-25T16:58:48.483Z',
      updatedAt: '2022-01-25T17:02:26.150Z',
      slug: 'gimme-me-shelter',
      digitalReleaseDate: '2022-01-23T23:00:00.000Z',
      releaseId: 'e68537ac-7754-49ac-a9d2-e0527ac230d5',
      workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '7ae077c9-e82c-42b9-89d6-6dcd1145c9e4',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              qobuz: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              officialStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              fnac: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              cultura: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              leclerc: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            selectedTimezone: 'Europe/Astrakhan',
            digitalReleaseDate: '2022-01-23T23:00:00Z',
            title: 'Gimme Me Shelter',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: 'ca8d509d-2124-480a-b612-9693416e09b2',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: 'ca8d509d-2124-480a-b612-9693416e09b2',
        },
        prereleaseLandingStep: {
          showArtwork: false,
          showArtist: false,
          showVideo: false,
          locale: {
            fr: {
              richText:
                '<h2>Nouveau single "{releaseTitle}"</h2>\n<p>Ne manque pas la sortie le {digitalReleaseDate} ! Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Télécharger',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2>New single "{releaseTitle}"</h2><p>Out on {digitalReleaseDate}! Select your favorite platform:',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Download',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            fr: {
              notificationText:
                '{releaseTitle} sera ajouté à ta librairie le {digitalReleaseDate}!',
            },
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            fr: {
              richText:
                '<h1 style="text-align: center;">Presque fini 🔥</h1>\n<p style="text-align: center;">Compl&egrave;te tes infos pour &ecirc;tre notifi&eacute;&middot;e &agrave; la sortie :</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      "J'accepte de recevoir news et offres de {artistName} par Believe. J'ai plus de 15 ans.",
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Valider',
                },
                skipButton: {
                  label: 'Passer',
                },
              },
            },
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 15 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Submit',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'qobuz',
              },
              {
                url: '',
                id: 'officialStore',
              },
              {
                url: '',
                id: 'fnac',
              },
              {
                url: '',
                id: 'amazonStore',
              },
              {
                url: '',
                id: 'cultura',
              },
              {
                url: '',
                id: 'leclerc',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: true,
          youtubeVideoId: 't4ITk0yrISc',
          locale: {
            fr: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2>\n<p style="text-align: center;">Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Télécharger',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Voir',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2><p style="text-align: center;">Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
          },
          socialCard: {
            imageId: 'ca8d509d-2124-480a-b612-9693416e09b2',
            title: '{artistName} — {releaseTitle}',
            description: 'Sortie le {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            fr: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '<p>{releaseName} is out now everywhere! Can’t wait for you to hear it.</p>',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2022-02-01T23:00:00.000Z',
          selectedTimezone: 'Europe/Astrakhan',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: '86dab165-285d-4576-89c8-66ee384f90a9',
        createdAt: '2022-01-19T15:00:01.378Z',
        updatedAt: '2022-01-26T09:00:01.169Z',
        backlinkId: 'dc2f7528-9d1f-4626-a64a-70f1bf12f8e7',
        visitedPostRelease: 1,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: 'c955404a-18a1-44c1-b020-48e0cce04533',
        createdAt: '2021-04-28T08:39:45.897Z',
        updatedAt: '2021-11-16T13:21:39.789Z',
        name: 'Test Workspace',
        settings: {
          facebookPixelId: '741553889772280',
          tiktokPixelId: 'C5JA73NGE0M3SF4JQ69G',
          googleAdsId: '',
          xandrPixelId: '',
          privacyPolicyUrl:
            'https://www.believemusic.com/fr/privacy-policy-fr/',
          legalNoticeUrl: 'https://www.believemusic.com/fr/privacy-policy-fr/',
          producerIds: ['1234'],
          competitionRulesTemplate:
            '<p>You can insert the Competition Rules Template here.</p>\n<ul>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>&nbsp;</li>\n</ul>',
          competitionMinimumAge: 15,
          territory: 'France',
          defaultTimezone: 'Europe/Astrakhan',
        },
        isPrivate: false,
        customBacklinkSettingsId: 'd3617eae-cafa-47d0-8819-ffe8e29e5806',
      },
      actionSubsKPI: {},
      artist: {
        id: '4176eba7-ea72-42fb-89de-86a8cbf1c968',
        createdAt: '2021-09-16T08:01:39.040Z',
        updatedAt: '2022-01-24T13:39:52.329Z',
        name: 'John Coltrane',
        data: {
          profilePictureId: '',
          instagramHandle: '',
          facebookHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
          VK: '',
          youtubeChannelName: '',
          subDomain: 'coltrane',
          storeProfileLinks: {
            appleMusic: {
              url: 'https://beatport.com',
              id: '',
            },
            joox: {
              url: 'https://beatport.com',
              id: '',
            },
            ticketMaster: {
              url: 'https://beatport.com',
              id: '',
            },
            deezer: {
              url: 'https://www.deezer.com/en/artist/384038',
              id: '384038',
            },
            deezerFollow: {
              url: 'https://www.deezer.com/en/artist/384038',
              id: '',
            },
            twitter: {
              url: 'https://beatport.com',
              id: '',
            },
          },
        },
        workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 10,
        FOLLOW_USER: 30,
        ADD_PLAYLIST: 100,
        ADD_ALBUM: 50,
        ADD_TRACK: 20,
      },
      id: 'dc2f7528-9d1f-4626-a64a-70f1bf12f8e7',
      createdAt: '2022-01-19T11:05:03.814Z',
      updatedAt: '2022-01-19T11:05:03.814Z',
      slug: 'new-release-21',
      digitalReleaseDate: '2022-01-19T11:05:03.744Z',
      releaseId: '8582f634-e262-457e-a6a5-e41a69879af5',
      workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '8baf8c8c-385c-416d-bec7-69cff72e6c1c',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              qobuz: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              officialStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              fnac: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              cultura: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              leclerc: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            selectedTimezone: 'Europe/Astrakhan',
            digitalReleaseDate: '2022-01-19T11:05:03.744Z',
            title: 'New Release',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: false,
          showArtist: false,
          showVideo: false,
          locale: {
            fr: {
              richText:
                '<h2>Nouveau single "{releaseTitle}"</h2>\n<p>Ne manque pas la sortie le {digitalReleaseDate} ! Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Télécharger',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2>New single "{releaseTitle}"</h2><p>Out on {digitalReleaseDate}! Select your favorite platform:',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Download',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            fr: {
              notificationText:
                '{releaseTitle} sera ajouté à ta librairie le {digitalReleaseDate}!',
            },
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            fr: {
              richText:
                '<h1 style="text-align: center;">Presque fini 🔥</h1>\n<p style="text-align: center;">Compl&egrave;te tes infos pour &ecirc;tre notifi&eacute;&middot;e &agrave; la sortie :</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      "J'accepte de recevoir news et offres de {artistName} par Believe. J'ai plus de 15 ans.",
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Valider',
                },
                skipButton: {
                  label: 'Passer',
                },
              },
            },
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 15 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Submit',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'qobuz',
              },
              {
                url: '',
                id: 'officialStore',
              },
              {
                url: '',
                id: 'fnac',
              },
              {
                url: '',
                id: 'amazonStore',
              },
              {
                url: '',
                id: 'cultura',
              },
              {
                url: '',
                id: 'leclerc',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: true,
          youtubeVideoId: 't4ITk0yrISc',
          locale: {
            fr: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2>\n<p style="text-align: center;">Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Télécharger',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Voir',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2><p style="text-align: center;">Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: 'coltrane',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'Sortie le {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            fr: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: ['amazonMusic', 'bandcamp', 'beatport'],
          scheduledAt: '2022-01-20T10:00:00.000Z',
          selectedTimezone: 'Europe/Astrakhan',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: null,
        createdAt: null,
        updatedAt: null,
        backlinkId: null,
        visitedPostRelease: null,
        visitedPreRelease: null,
        preSaved: null,
        subscribed: null,
        clickedToStore: null,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: '8cb6d009-4325-44fc-b03e-175da9a1e10d',
        createdAt: '2022-01-19T09:50:18.686Z',
        updatedAt: '2022-01-19T09:50:18.686Z',
        name: 'test01',
        data: {},
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 100,
        FOLLOW_USER: 3000,
        ADD_PLAYLIST: 130,
        ADD_ALBUM: 2500,
        ADD_TRACK: 5200,
      },
      id: '8137618f-8563-4e95-8e8b-01e87af5f56c',
      createdAt: '2022-01-19T09:50:20.822Z',
      updatedAt: '2022-01-19T09:50:20.822Z',
      slug: 'test01',
      digitalReleaseDate: '2022-01-19T09:50:20.755Z',
      releaseId: '63e8b2d7-f415-4768-9a2e-66d9b29c2001',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '2a0b18b4-0075-4c71-861d-2c281b0f989c',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2022-01-19T09:50:20.755Z',
            title: 'Test01',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-add',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtube: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-order',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    napster: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-save',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'amazonStore',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: true,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2022-01-20T10:00:00.000Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: null,
        createdAt: null,
        updatedAt: null,
        backlinkId: null,
        visitedPostRelease: null,
        visitedPreRelease: null,
        preSaved: null,
        subscribed: null,
        clickedToStore: null,
      },
      workspace: {
        id: 'c955404a-18a1-44c1-b020-48e0cce04533',
        createdAt: '2021-04-28T08:39:45.897Z',
        updatedAt: '2021-11-16T13:21:39.789Z',
        name: 'Test Workspace',
        settings: {
          facebookPixelId: '741553889772280',
          tiktokPixelId: 'C5JA73NGE0M3SF4JQ69G',
          googleAdsId: '',
          xandrPixelId: '',
          privacyPolicyUrl:
            'https://www.believemusic.com/fr/privacy-policy-fr/',
          legalNoticeUrl: 'https://www.believemusic.com/fr/privacy-policy-fr/',
          producerIds: ['1234'],
          competitionRulesTemplate:
            '<p>You can insert the Competition Rules Template here.</p>\n<ul>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>{operationName}</li>\n<li>{startDate}</li>\n<li>{endDate}</li>\n<li>{minimumAge}</li>\n<li>{operationUrl}</li>\n<li>{artistName}</li>\n<li>{winnersCount}</li>\n<li>{privacyPolicyUrl}</li>\n<li>{prizes}</li>\n<li>&nbsp;</li>\n</ul>',
          competitionMinimumAge: 15,
          territory: 'France',
          defaultTimezone: 'Europe/Astrakhan',
        },
        isPrivate: false,
        customBacklinkSettingsId: 'd3617eae-cafa-47d0-8819-ffe8e29e5806',
      },
      actionSubsKPI: {},
      artist: {
        id: '796e43f0-5f11-4937-ad6e-306a1597dba2',
        createdAt: '2021-08-13T10:07:11.776Z',
        updatedAt: '2021-08-18T09:04:40.328Z',
        name: 'Anne Sylvestre',
        data: {
          profilePictureId: '',
          instagramHandle: '',
          facebookHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
          VK: '',
          youtubeChannelName: '',
          subDomain: 'sylvestre',
          storeProfileLinks: {
            deezer: {
              url: 'https://www.deezer.com/us/artist/54128',
              id: '54128',
            },
            spotify: {
              url: 'https://open.spotify.com/artist/7EErtRkIXKEnllrCa1a7xR',
              id: '7EErtRkIXKEnllrCa1a7xR',
            },
          },
        },
        workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 100,
        FOLLOW_USER: 30,
        ADD_PLAYLIST: 10000,
        ADD_ALBUM: 20000,
        ADD_TRACK: 30000,
      },
      id: 'c479bc76-b29c-489b-a29e-62a5d8e0e7d5',
      createdAt: '2022-01-04T15:54:43.197Z',
      updatedAt: '2022-01-04T15:54:43.197Z',
      slug: 'new-release-20',
      digitalReleaseDate: '2022-01-04T15:54:43.128Z',
      releaseId: 'fd63baa8-d0f7-44e6-a098-c338106c0d0c',
      workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '276ce73a-5bd7-4785-9122-5e58481bd732',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              qobuz: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              officialStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              fnac: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              cultura: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              leclerc: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            selectedTimezone: 'Europe/Astrakhan',
            digitalReleaseDate: '2022-01-04T15:54:43.128Z',
            title: 'New Release',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: false,
          showArtist: false,
          showVideo: false,
          locale: {
            fr: {
              richText:
                '<h2>Nouveau single "{releaseTitle}"</h2>\n<p>Ne manque pas la sortie le {digitalReleaseDate} ! Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Télécharger',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Enregistrer',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Précommander',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2>New single "{releaseTitle}"</h2><p>Out on {digitalReleaseDate}! Select your favorite platform:',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'itunes',
                    'amazonMusic',
                    'tidal',
                    'youtube',
                    'napster',
                    'youtubeMusic',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Download',
                    },
                    youtube: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtubeMusic: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    napster: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    qobuz: {
                      isDisplayed: false,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    officialStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    fnac: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    amazonStore: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    cultura: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                    leclerc: {
                      isDisplayed: false,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Preorder',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            fr: {
              notificationText:
                '{releaseTitle} sera ajouté à ta librairie le {digitalReleaseDate}!',
            },
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: ['b2615289-2140-4db9-8112-7dabed3f6050'],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            fr: {
              richText:
                '<h1 style="text-align: center;">Presque fini 🔥</h1>\n<p style="text-align: center;">Compl&egrave;te tes infos pour &ecirc;tre notifi&eacute;&middot;e &agrave; la sortie :</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      "J'accepte de recevoir news et offres de {artistName} par Believe. J'ai plus de 15 ans.",
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Valider',
                },
                skipButton: {
                  label: 'Passer',
                },
              },
            },
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 15 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Submit',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'qobuz',
              },
              {
                url: '',
                id: 'officialStore',
              },
              {
                url: '',
                id: 'fnac',
              },
              {
                url: '',
                id: 'amazonStore',
              },
              {
                url: '',
                id: 'cultura',
              },
              {
                url: '',
                id: 'leclerc',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: true,
          youtubeVideoId: 't4ITk0yrISc',
          locale: {
            fr: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2>\n<p style="text-align: center;">Choisis ta plateforme préférée :</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Télécharger',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Voir',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Écouter',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Acheter',
                      text: '',
                    },
                  },
                },
              },
            },
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2><p style="text-align: center;">Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'deezer',
                    'appleMusic',
                    'youtube',
                    'napster',
                    'itunes',
                    'amazonMusic',
                    'youtubeMusic',
                    'tidal',
                    'qobuz',
                    'officialStore',
                    'fnac',
                    'amazonStore',
                    'cultura',
                    'leclerc',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    qobuz: {
                      isDisplayed: true,
                      label: 'Listen',
                      text: '',
                    },
                    officialStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    fnac: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    cultura: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                    leclerc: {
                      isDisplayed: true,
                      label: 'Buy',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: 'sylvestre',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'Sortie le {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            fr: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2022-01-05T09:54:43.128Z',
          selectedTimezone: 'Europe/Astrakhan',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: 'f9c8551a-8a2b-44e9-8c60-7801ad10f330',
        createdAt: '2021-12-08T15:00:01.416Z',
        updatedAt: '2022-01-26T09:00:01.146Z',
        backlinkId: 'dff6af3f-4a25-4d76-8e2a-be8f401fbec0',
        visitedPostRelease: 8,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: '5bff3732-5f73-48cc-9d2a-f4a6bf61ecaf',
        createdAt: '2021-09-10T07:56:37.499Z',
        updatedAt: '2022-01-20T17:02:53.242Z',
        name: '365daband',
        data: {
          profilePictureId: '',
          instagramHandle: '',
          facebookHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
          VK: '',
          youtubeChannelName: '',
          subDomain: '',
          storeProfileLinks: {
            amazonMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            anghami: {
              url: 'https://www.google.fr/',
              id: '',
            },
            bandcamp: {
              url: 'https://www.google.fr/',
              id: '',
            },
            claroMusica: {
              url: 'https://www.google.fr/',
              id: '',
            },
            huaweiMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            fnac: {
              url: 'https://www.google.fr/',
              id: '',
            },
            qqMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
          },
        },
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 500,
        FOLLOW_USER: 300,
        ADD_PLAYLIST: 100000,
        ADD_ALBUM: 250000,
        ADD_TRACK: 40000,
      },
      id: 'dff6af3f-4a25-4d76-8e2a-be8f401fbec0',
      createdAt: '2021-11-23T08:10:06.085Z',
      updatedAt: '2021-11-23T08:10:06.085Z',
      slug: 'jazz-compilation-robin',
      digitalReleaseDate: '2021-11-23T08:10:06.040Z',
      releaseId: '97a39a0f-7a8f-4539-94f6-a3b5be4eb9a3',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '5bb02f27-6a47-4e6e-9572-2bc3dda52433',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-11-23T08:10:06.040Z',
            title: 'Jazz Compilation Robin',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-add',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtube: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-order',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    napster: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-save',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'amazonStore',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-11-24T09:10:06.040Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: '184caa4f-b6c5-4582-8e13-0f8f3bf62a66',
        createdAt: '2021-12-08T15:00:01.417Z',
        updatedAt: '2022-01-26T09:00:01.145Z',
        backlinkId: '147afb79-e9ad-4444-996a-f91df6861e6b',
        visitedPostRelease: 7,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: '5bff3732-5f73-48cc-9d2a-f4a6bf61ecaf',
        createdAt: '2021-09-10T07:56:37.499Z',
        updatedAt: '2022-01-20T17:02:53.242Z',
        name: '365daband',
        data: {
          profilePictureId: '',
          instagramHandle: '',
          facebookHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
          VK: '',
          youtubeChannelName: '',
          subDomain: '',
          storeProfileLinks: {
            amazonMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            anghami: {
              url: 'https://www.google.fr/',
              id: '',
            },
            bandcamp: {
              url: 'https://www.google.fr/',
              id: '',
            },
            claroMusica: {
              url: 'https://www.google.fr/',
              id: '',
            },
            huaweiMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            fnac: {
              url: 'https://www.google.fr/',
              id: '',
            },
            qqMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
          },
        },
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 10000,
        FOLLOW_USER: 3000,
        ADD_PLAYLIST: 50000,
        ADD_ALBUM: 30000,
        ADD_TRACK: 70000,
      },
      id: '147afb79-e9ad-4444-996a-f91df6861e6b',
      createdAt: '2021-11-23T08:09:37.736Z',
      updatedAt: '2021-11-23T08:09:37.736Z',
      slug: 'one-two-three',
      digitalReleaseDate: '2021-11-23T08:09:37.660Z',
      releaseId: '6e1c5bcf-db6d-45d7-bcd2-3ed0e7eb6b68',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: 'f9d70c2a-0e1c-4800-8d8e-582014dcaacb',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-11-23T08:09:37.660Z',
            title: 'One Two Three',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-add',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtube: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-order',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    napster: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-save',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'amazonStore',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-11-24T09:09:37.660Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: 'fcda4de1-b18c-423e-9c69-ce4ff6fa0b43',
        createdAt: '2021-12-08T15:00:01.418Z',
        updatedAt: '2022-01-26T09:00:01.148Z',
        backlinkId: '9e81c27e-b8f7-4a87-810c-9915e192c36e',
        visitedPostRelease: 7,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: 'd379ad05-f3fe-4a40-b720-16bf1adf5780',
        createdAt: '2021-11-16T09:41:36.279Z',
        updatedAt: '2021-11-16T09:41:36.279Z',
        name: 'Fang',
        data: {},
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 10,
        FOLLOW_USER: 30,
        ADD_PLAYLIST: 100,
        ADD_ALBUM: 5000,
        ADD_TRACK: 10000,
      },
      id: '9e81c27e-b8f7-4a87-810c-9915e192c36e',
      createdAt: '2021-11-16T09:41:36.352Z',
      updatedAt: '2021-11-16T09:41:36.352Z',
      slug: 'fang-ft-hoodini-do-kraya-na-noshcha',
      digitalReleaseDate: '2021-11-16T09:41:36.318Z',
      releaseId: 'b457a6c8-ba09-48f5-8ea8-2329f8d009ca',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '6dd5dbf4-7cb6-4852-bcda-ae960cd2321f',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-11-16T09:41:36.318Z',
            title: 'Fang Ft. Hoodini - До края на ноща',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-add',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtube: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-order',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    napster: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-save',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'amazonStore',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-11-17T09:41:36.318Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: 'e10fac77-fd89-486d-8c02-19fafa0b130e',
        createdAt: '2021-12-08T15:00:01.420Z',
        updatedAt: '2022-01-26T09:00:01.162Z',
        backlinkId: 'fd6225d7-ccc5-4677-9c1f-51b65c54bb1a',
        visitedPostRelease: 6,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: '5bff3732-5f73-48cc-9d2a-f4a6bf61ecaf',
        createdAt: '2021-09-10T07:56:37.499Z',
        updatedAt: '2022-01-20T17:02:53.242Z',
        name: '365daband',
        data: {
          profilePictureId: '',
          instagramHandle: '',
          facebookHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
          VK: '',
          youtubeChannelName: '',
          subDomain: '',
          storeProfileLinks: {
            amazonMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            anghami: {
              url: 'https://www.google.fr/',
              id: '',
            },
            bandcamp: {
              url: 'https://www.google.fr/',
              id: '',
            },
            claroMusica: {
              url: 'https://www.google.fr/',
              id: '',
            },
            huaweiMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
            fnac: {
              url: 'https://www.google.fr/',
              id: '',
            },
            qqMusic: {
              url: 'https://www.google.fr/',
              id: '',
            },
          },
        },
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 20000,
        FOLLOW_USER: 4600,
        ADD_PLAYLIST: 100000,
        ADD_ALBUM: 200500,
        ADD_TRACK: 300012,
      },
      id: 'fd6225d7-ccc5-4677-9c1f-51b65c54bb1a',
      createdAt: '2021-11-16T09:41:19.436Z',
      updatedAt: '2021-11-16T09:41:19.436Z',
      slug: 'ddddf',
      digitalReleaseDate: '2021-11-16T09:41:19.383Z',
      releaseId: '11b519a3-b5a6-42b0-9ec5-eb4b1b17d434',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '3975c48f-08a9-4e2b-bf57-3eebbc2e94ff',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-11-16T09:41:19.383Z',
            title: 'ddddf',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-add',
                    },
                    deezer: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    youtube: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    itunes: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-order',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Buy',
                    },
                    napster: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    tidal: {
                      isDisplayed: true,
                      mode: 'DEFAULT',
                      text: '',
                      label: 'Pre-save',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      mode: 'DIRECT_LINK',
                      text: '',
                      label: 'Pre-save',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          isActive: true,
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          redirect: {
            useStoresRedirects: true,
            default: {
              url: '',
            },
            stores: [
              {
                url: '',
                id: 'spotify',
              },
              {
                url: '',
                id: 'appleMusic',
              },
              {
                url: '',
                id: 'deezer',
              },
              {
                url: '',
                id: 'youtube',
              },
              {
                url: '',
                id: 'itunes',
              },
              {
                url: '',
                id: 'youtubeMusic',
              },
              {
                url: '',
                id: 'napster',
              },
              {
                url: '',
                id: 'amazonMusic',
              },
              {
                url: '',
                id: 'tidal',
              },
              {
                url: '',
                id: 'amazonStore',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
            slug: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-11-17T09:41:19.383Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: 'f5c48568-642c-452f-b576-1b67fd7621a6',
        createdAt: '2021-12-08T15:00:01.416Z',
        updatedAt: '2022-01-26T09:00:01.147Z',
        backlinkId: '330a3123-c71d-4adf-bca0-b453c0dfb1b8',
        visitedPostRelease: 6,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: 'bc8ac3f0-283c-44a0-b0fe-79b1358897f8',
        createdAt: '2021-09-30T16:30:28.404Z',
        updatedAt: '2021-09-30T16:30:28.404Z',
        name: 'test ABC',
        data: {},
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 10000,
        FOLLOW_USER: 2000,
        ADD_PLAYLIST: 14521,
        ADD_ALBUM: 32000,
        ADD_TRACK: 56789,
      },
      id: '330a3123-c71d-4adf-bca0-b453c0dfb1b8',
      createdAt: '2021-09-30T16:30:28.499Z',
      updatedAt: '2021-10-01T07:55:19.515Z',
      slug: 'test-oac',
      digitalReleaseDate: '2021-04-19T22:00:00.000Z',
      releaseId: '1a51af6e-5ca2-4d3b-8d3f-16220b8ea629',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: 'c4866b22-49b5-4ce7-9323-567d31f4d3b6',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-04-19T22:00:00.000Z',
            title: 'test OAC',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-add',
                      mode: 'DEFAULT',
                    },
                    deezer: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    youtube: {
                      isDisplayed: true,
                      text: '',
                      label: 'Buy',
                      mode: 'DEFAULT',
                    },
                    itunes: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-order',
                      mode: 'DIRECT_LINK',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Buy',
                      mode: 'DEFAULT',
                    },
                    napster: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    tidal: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DIRECT_LINK',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          isActive: true,
          redirect: {
            default: {
              url: '',
            },
            useStoresRedirects: true,
            stores: [
              {
                id: 'spotify',
                url: '',
              },
              {
                id: 'appleMusic',
                url: '',
              },
              {
                id: 'deezer',
                url: '',
              },
              {
                id: 'youtube',
                url: '',
              },
              {
                id: 'itunes',
                url: '',
              },
              {
                id: 'youtubeMusic',
                url: '',
              },
              {
                id: 'napster',
                url: '',
              },
              {
                id: 'amazonMusic',
                url: '',
              },
              {
                id: 'tidal',
                url: '',
              },
              {
                id: 'amazonStore',
                url: '',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-04-20T09:00:00.000Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
    {
      lifetimeAnalytics: {
        id: '440375fb-0c1f-46d4-85d1-10ba76b5beef',
        createdAt: '2021-12-08T15:00:01.418Z',
        updatedAt: '2022-01-26T09:00:01.161Z',
        backlinkId: 'd12c2347-e8d1-4c3f-9895-846b1931aad4',
        visitedPostRelease: 6,
        visitedPreRelease: 0,
        preSaved: 0,
        subscribed: 0,
        clickedToStore: 0,
      },
      workspace: {
        id: '44050582-dcfa-47d9-aee9-d78cc5967355',
        createdAt: '2021-06-29T09:25:37.923Z',
        updatedAt: '2022-01-21T07:13:27.224Z',
        name: 'Workspace-Of-49482',
        settings: {
          userLocale: 'fr',
          defaultTimezone: 'Asia/Kolkata',
          legalIdentity: 'tet',
          contactEmail: 'test@test.com',
          marketingServices: '',
          privacyPolicyUrl: '',
          facebookPixelId: '123456789123456',
          googleAdsId: 'AW-348574831',
        },
        isPrivate: true,
        customBacklinkSettingsId: 'f70727e9-0843-402b-8567-e504796a3e5e',
      },
      actionSubsKPI: {},
      artist: {
        id: '767543aa-c596-474e-a090-404b4d9cbced',
        createdAt: '2021-09-29T12:18:29.741Z',
        updatedAt: '2021-09-29T12:18:29.741Z',
        name: 'Petit Biscuit with AID',
        data: {},
        workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
        customBacklinkSettingsId: null,
      },
      autonotify: {
        id: null,
        createdAt: null,
        updatedAt: null,
        status: null,
        backlinkId: null,
        schedulableOn: null,
        isArchived: null,
      },
      autonotifyFanNotifications: {
        completed: 0,
        failed: 0,
      },
      dspActionsCount: {
        FOLLOW_ARTIST: 30000,
        FOLLOW_USER: 15000,
        ADD_PLAYLIST: 267890,
        ADD_ALBUM: 12567,
        ADD_TRACK: 300986,
      },
      id: 'd12c2347-e8d1-4c3f-9895-846b1931aad4',
      createdAt: '2021-09-29T12:19:12.800Z',
      updatedAt: '2021-09-29T12:20:15.046Z',
      slug: 'petit-biscuit-tests-asset',
      digitalReleaseDate: '2021-03-24T00:00:00.000Z',
      releaseId: '8a76d97a-7d07-4f63-96e5-a62ed41e5791',
      workspaceId: '44050582-dcfa-47d9-aee9-d78cc5967355',
      isArchived: false,
      settingsData: {
        common: {
          defaultLocale: 'en',
          defaultActionId: '7691cbed-cbd5-4569-a66b-712b0eee2b48',
        },
        releaseStep: {
          release: {
            stores: {
              spotify: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              appleMusic: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              deezer: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtube: {
                useAutoscan: true,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              itunes: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              youtubeMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              napster: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonMusic: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              tidal: {
                useAutoscan: false,
                links: {
                  default: {
                    url: '',
                  },
                },
              },
              amazonStore: {
                useAutoscan: false,
                name: 'amazonStore',
                links: {
                  default: {
                    url: '',
                  },
                },
              },
            },
            digitalReleaseDate: '2021-03-24T00:00:00.000Z',
            title: 'petit biscuit - TESTS ASSET',
            selectedTimezone: 'Asia/Kolkata',
          },
        },
        designStep: {
          theme: 'backlinkTheme001',
          typography: {
            link: {
              color: '#ffffff',
            },
            paragraph: {
              color: '#ffffff',
            },
          },
          background: {
            direction: 135,
            color01: '#717A97',
            image: {
              imageId: '',
              overlay: {
                color: '#717A97',
                opacity: 0.7,
              },
              blur: 0.3,
            },
          },
          artworkId: '',
        },
        prereleaseLandingStep: {
          showArtwork: true,
          showArtist: true,
          showVideo: false,
          locale: {
            en: {
              richText:
                '<h2>New releaseeee "{releaseTitle}"</h2>\n<p>Out on {digitalReleaseDate}! Select your favorite platform:</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-add',
                      mode: 'DEFAULT',
                    },
                    deezer: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    youtube: {
                      isDisplayed: true,
                      text: '',
                      label: 'Buy',
                      mode: 'DEFAULT',
                    },
                    itunes: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-order',
                      mode: 'DIRECT_LINK',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Buy',
                      mode: 'DEFAULT',
                    },
                    napster: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    tidal: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DEFAULT',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      text: '',
                      label: 'Pre-save',
                      mode: 'DIRECT_LINK',
                    },
                  },
                },
              },
            },
          },
          youtubeVideoId: '',
        },
        prereleasePresaveStep: {
          locale: {
            en: {
              notificationText:
                '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
            },
          },
          actionIds: [],
        },
        prereleaseFormStep: {
          locale: {
            en: {
              richText:
                '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
              form: {
                fields: [
                  {
                    required: true,
                    name: 'email',
                    type: 'email',
                    label: 'Email',
                  },
                ],
                optins: [
                  {
                    required: false,
                    name: 'optin_subscription',
                    label:
                      'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
                    type: 'checkbox',
                    metas: {
                      consentToOffers: true,
                      consentToNews: true,
                    },
                  },
                ],
                submitButton: {
                  label: 'Continue',
                },
                skipButton: {
                  label: 'Skip',
                },
              },
            },
          },
          isActive: true,
          redirect: {
            default: {
              url: '',
            },
            useStoresRedirects: true,
            stores: [
              {
                id: 'spotify',
                url: '',
              },
              {
                id: 'appleMusic',
                url: '',
              },
              {
                id: 'deezer',
                url: '',
              },
              {
                id: 'youtube',
                url: '',
              },
              {
                id: 'itunes',
                url: '',
              },
              {
                id: 'youtubeMusic',
                url: '',
              },
              {
                id: 'napster',
                url: '',
              },
              {
                id: 'amazonMusic',
                url: '',
              },
              {
                id: 'tidal',
                url: '',
              },
              {
                id: 'amazonStore',
                url: '',
              },
            ],
          },
        },
        postreleaseLandingStep: {
          showArtwork: true,
          showArtist: false,
          showVideo: false,
          youtubeVideoId: '',
          locale: {
            en: {
              richText:
                '<h2 style="text-align: center;">{artistName}</h2><p style="text-align: center;">{releaseTitle}</p>',
              callToActions: {
                stores: {
                  displayOrder: [
                    'spotify',
                    'appleMusic',
                    'deezer',
                    'youtube',
                    'itunes',
                    'youtubeMusic',
                    'napster',
                    'amazonMusic',
                    'tidal',
                    'amazonStore',
                  ],
                  options: {
                    spotify: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    appleMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    deezer: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    youtube: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    itunes: {
                      isDisplayed: true,
                      label: 'Download',
                      text: '',
                    },
                    youtubeMusic: {
                      isDisplayed: true,
                      label: 'Watch',
                      text: '',
                    },
                    napster: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonMusic: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    tidal: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                    amazonStore: {
                      isDisplayed: true,
                      label: 'Play',
                      text: '',
                    },
                  },
                },
              },
            },
          },
        },
        sharingStep: {
          url: {
            subDomain: '',
          },
          socialCard: {
            imageId: '',
            title: '{artistName} — {releaseTitle}',
            description: 'New release out {digitalReleaseDateFull}',
          },
        },
        prereleaseAutonotifyStep: {
          isActive: false,
          locale: {
            en: {
              subject: '{releaseName} is out',
              title: '{releaseName} by {artistName} is now available!',
              body: '{releaseName} is out now everywhere! Can’t wait for you to hear it.',
              ctaText: 'Listen now',
              socialSectionTitle: 'Follow me',
              footerText:
                'You won’t receive other email from us, except if you subscribed to the newsletter.',
            },
          },
          stores: [],
          scheduledAt: '2021-03-25T09:00:00.000Z',
          selectedTimezone: 'Asia/Kolkata',
        },
      },
    },
  ],
  totalCount: 102,
};
