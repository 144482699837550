import { api } from '../api';
import { ImageUploadResponse } from './image.api.types';

export const uploadImage = async (
  payload: string,
): Promise<ImageUploadResponse> => {
  const results = await api.post<ImageUploadResponse>('/images/upload', {
    fileBinary: payload,
  });
  return results.data;
};
