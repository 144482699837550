import React, { useState } from 'react';

import { Interpolation } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Theme,
} from '@mui/material';

import { StoreConfig } from 'shared/entities/storeConfig/storeConfig.types';

import * as Styled from '../styled';

type Props = {
  storesConfigs: StoreConfig[];
  handleButtonClick: (additionalPath?: string) => void;
  styles: Record<string, Interpolation<Theme>>;
};

const DirectLinkSelector = ({
  storesConfigs,
  handleButtonClick,
  styles,
}: Props) => {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState(storesConfigs);

  const getSearchResults = (value) => {
    if (value === '') return storesConfigs;
    return storesConfigs.filter(({ name, displayName }) => {
      return (
        name.toLowerCase().includes(value.toLowerCase()) ||
        displayName.toLowerCase().includes(value.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Styled.DialogContentText id="alert-dialog-description">
        {t(
          'section.bfan_links.dialog.share.tab.direct_link.description',
          'Point fans to a specific store on pre-saves and smartlinks.',
        )}
      </Styled.DialogContentText>
      <Styled.TextField
        fullWidth
        placeholder={t('ui.form.fields.search.placeholder', 'Search')}
        type="search"
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchResults(getSearchResults(event.target.value));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TableContainer css={styles.tableContainer}>
        <Table aria-label="backlinks">
          <TableBody>
            {searchResults.map((store) => (
              <TableRow key={store.name}>
                <TableCell component="th" scope="row">
                  {store.displayName}
                </TableCell>
                <TableCell align="left">
                  <Styled.StoreType>{store.type}</Styled.StoreType>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      t(
                        'section.bfan_links.dialog.share.tab.direct_link.copy_to_clipboard',
                        'Copy link',
                      ) as string
                    }
                  >
                    <IconButton
                      onClick={() => handleButtonClick(`/${store.name}`)}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DirectLinkSelector;
