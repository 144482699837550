export const messages = {
  EDITOR_HAS_ERRORS: 'Please correct highlighted errors before saving.',
  COPY_LINK_TO_CLIPBOARD_TOOLTIP: 'Copy link',
  URL_COPIED_TO_CLIPBOARD: 'Copied!',
  URL_TO_CLIPBOARD_ERROR: 'Could not copy text: ',
  SLUG_NOT_AVAILABLE: 'Slug is not available',
  SLUG_NOT_VALID: 'Slug is not valid',
  SUBDOMAIN_NOT_VALID: 'Subdomain is not valid',
  SUBDOMAIN_NOT_SELECTED:
    'Slug is not available. Select the artist subdomain to use this page name.',
  SUBDOMAIN_NOT_CONFIGURED:
    'Slug is not available. Configure a subdomain in the artist settings, save and refresh this page to use this page name.',
};
