import { useCallback } from 'react';

import { AxiosError } from 'axios';

import { AppNotificationTypes } from '../constants';
import { isAxiosError } from '../entities/api';
import { useAppContext, ActionsTypes as AppActionsTypes } from '../state';
import { AppNotification } from '../types';
import { isDefined } from '../utils/typeguards';

export const useNotification = () => {
  const { dispatch: appDispatch } = useAppContext();
  /**
   * Notify application of an event, succes or failure of the api call
   * allow centralizing additional actions like logging
   */
  const notify = useCallback(
    (
      notification: AppNotification,
      error?: AxiosError | Error | string,
    ): void => {
      let { type: notificationType, message } = notification;
      if (isDefined(error) && isAxiosError(error)) {
        if (error.response?.data.errors) {
          const errors = error.response.data.errors.join('\n');
          message = `${message} \n ${errors}`;
        }
        if (error.response?.data.message) {
          message = `${message} \n ${error.response.data.message}`;
        }
      }
      appDispatch({
        type: AppActionsTypes.AppWideEventOccured,
        payload: { notification: { type: notificationType, message } },
      });
      if (notificationType === AppNotificationTypes.Error) {
        console.error(message);
      }
    },
    [appDispatch],
  );

  return { notify };
};
