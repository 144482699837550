import { ValidationError } from 'yup';

/**
 * a custom transformer for yup validation error object
 * @param error the error object returned when yup validation fails
 * @returns an object with the path to the property as key and the error message as value
 */
export const getCustomValidationErrors = (
  error: ValidationError,
): Record<string, string> | undefined => {
  const proceed = !!error.inner;
  if (!proceed) {
    return;
  }
  return Object.assign(
    {},
    ...error.inner.map((error) => {
      const key = error.path?.replace(/\[([0-9]+)\]/g, '.$1'); // we need to access the path as strings separated with . even for array elements
      return key ? { [key]: error.message } : undefined;
    }),
  );
};
