import React, { useEffect, useRef } from 'react';

import {
  Route,
  Routes,
  useNavigate,
  useResolvedPath,
  useParams,
} from 'react-router-dom';

import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useArtistSettingsPanel } from 'shared/hooks/useArtistSettingsPanel';
import { useInvalidateQueries } from 'shared/hooks/useInvalidateQueries';

type Props = {
  profileOnly?: boolean;
};

type ArtistEditRouteSetupProps = Omit<Props, 'artistId'> & {
  onArtistPanelClose?: () => void;
};

const ArtistEditor = ({ profileOnly }: Props) => {
  const { artistId = '' } = useParams();

  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [artistId],
  });

  const { open: openArtistPanel } = useArtistSettingsPanel();

  useEffect(() => {
    if (artist) {
      openArtistPanel(artist, profileOnly);
    }
  }, [artist, openArtistPanel, profileOnly]);

  return null;
};

/**
 * a component to be used on sections where we want to be able to
 * - open the artist's panel with a link to its path (so we can deep link to it)
 * - return to the section we have opened the artist panel on when closing the panel
 */
const ArtistEditRouteSetup = ({
  profileOnly,
  onArtistPanelClose,
}: ArtistEditRouteSetupProps) => {
  const returnUrl = useResolvedPath('').pathname;
  const navigate = useNavigate();
  const invalidateArtists = useInvalidateQueries('artists');

  const { isOpen: artistPanelIsOpen } = useArtistSettingsPanel();

  // we want to keep the url used as we land on the section where this component is used
  // this is to prevent 'updating' the url as the section is re-rendered when the artist's panel is mounted
  // sometimes resulting in the returnUrl to be 'wrong' (because of the section's route format - see Backlink > editor route where the step param is optional)
  const returnUrlRef = useRef(returnUrl);

  const artistPanelWasOpenRef = useRef(false);
  useEffect(() => {
    if (!artistPanelIsOpen && artistPanelWasOpenRef.current) {
      // Artist settings panel just closed:
      // execute onArtistPanelClose
      // go back to returnUrl
      // and invalidate artists query to update the list of artists
      navigate(returnUrlRef.current);
      invalidateArtists();
      onArtistPanelClose && onArtistPanelClose();
    }
    artistPanelWasOpenRef.current = artistPanelIsOpen;
  }, [artistPanelIsOpen, navigate, invalidateArtists, onArtistPanelClose]);

  return (
    <Routes>
      <Route
        path={`artist/:artistId/edit/*`}
        element={<ArtistEditor profileOnly={profileOnly} />}
      />
    </Routes>
  );
};

export default ArtistEditRouteSetup;
