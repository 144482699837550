import {
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
} from 'shared/analytics/analytics.types';

export enum GA4ArtistEventNames {
  updateArtistSettings = 'updateArtistSettings',
}

export type GA4ArtistSettingsSubproduct = GA4SubProducts.settings;

export type GA4ArtistEventParams = {
  workspaceId: string;
  artistId: string;
};

export type GA4UpdateArtistEventParams = GA4ArtistEventParams & {
  /**
   * Whether the artistUrls have been updated
   */
  artistUrls: boolean;
  /**
   * Whether the officialPlaylists have been updated
   */
  officialPlaylists: boolean;
  /**
   * Whether the subDomain has been updated
   */
  subDomain: boolean;
  /**
   * Whether the artistSocialHandles have been updated
   */
  artistSocialHandles: boolean;
};

export type GA4UpdateArtistSettingsTrackingEventConfig =
  GA4MKPProductTrackingEvent<
    {
      eventName: GA4ArtistEventNames.updateArtistSettings;
      subProduct: GA4ArtistSettingsSubproduct;
    } & GA4UpdateArtistEventParams
  >;
