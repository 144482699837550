import authHandlers from 'shared/entities/auth/mocks/handlers';
import { shareExtensionsHandlers } from 'shared/entities/backlink/mocks/handlers';
import storeConfigHandlers from 'shared/entities/storeConfig/mocks/handlers';
import i18nHandlers from 'shared/i18n/mocks/handlers';

import featureFlagsHandlers from './featureFlags';

const handlers = [
  ...authHandlers,
  ...storeConfigHandlers,
  ...shareExtensionsHandlers,
  ...i18nHandlers,
  ...featureFlagsHandlers,
];

export default handlers;
