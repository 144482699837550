import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as artistApi from 'shared/entities/artist/artist.api';
import { messages } from 'shared/entities/backlinkSettings/messages';
import { useNotification } from 'shared/hooks/useNotification';

type Params = { artistId: string };

const useUpdateArtistBacklinkSettingsMode = ({ artistId }: Params) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const { mutate: updateBacklinkSettingsMode, ...rest } = useMutation(
    artistApi.updateBacklinkSettingsMode,
    {
      onSuccess: () => {
        notify({
          type: AppNotificationTypes.Success,
          message: t(
            'entity.backlinkSettings.message.link_settings_mode_changed',
            messages.LINK_SETTINGS_MODE_CHANGED,
          ),
        });
        if (artistId) queryClient.invalidateQueries(['artist', artistId]);
      },
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'entity.backlinkSettings.message.link_settings_mode_changed_error',
            messages.LINK_SETTINGS_MODE_CHANGED_ERROR,
            { error },
          ),
        });
      },
    },
  );

  return {
    updateBacklinkSettingsMode,
    ...rest,
  };
};

export default useUpdateArtistBacklinkSettingsMode;
