import { SlugValidationInfosResponse } from '../../backlinkSettings.api.types';

/**
 * Release
 */
export type Release = {
  digitalReleaseDate: string;
  selectedTimezone: string;
  title: string;
  artworkId: string;
};

/**
 * Social Card
 */
export type SocialCard = {
  imageId: string;
  title: string;
  description: string;
};

/**
 * Url
 */
export type Url = {
  slug: string;
  subDomain: string;
};

/**
 * Slug
 */
export type SlugValidationInfos = {
  subDomainConfigured: boolean;
  subDomainSelected: boolean;
  errorMessage?: string;
} & SlugValidationInfosResponse;

/**
 * Design
 */

export enum BackgroundType {
  IMAGE = 'image',
  COLOR = 'color',
}

export type Design = {
  background: {
    type: BackgroundType;
    color: {
      color01: string;
      color02?: string;
      gradientAngle?: number;
    };
    image: {
      imageId: string;
      overlay: {
        color: string;
        opacity: number;
      };
      blur: number;
    };
  };
};

/**
 * Headline
 */
export type Headline = {
  text: string;
};

/**
 * Call To Action (CTA) Configuration
 */
export type CTAConfig = {
  label: string;
  description: string;
};

export type DSPActionsSettings = {
  notification: { text?: string };
};
