import { CreativeGraphQLTypes } from '@creative/sdk';

import { Release } from '../release/release.types';
import {
  CreativeMediaList,
  CreativeMediaState,
  CreativeMediaType,
} from './media.types';

export type CommonCreativeMediaFilterParameters = {
  releaseId?: string;
  productId?: string;
  state?: typeof CreativeMediaState;
  format?: CreativeGraphQLTypes.Media_Format_EnumGraphQL;
};

export enum CreativeMediaOrderByQueryParam {
  creationDateDesc = 'creationDateDesc',
  creationDateAsc = 'creationDateAsc',
  updateDateDesc = 'updateDateDesc',
  updateDateAsc = 'updateDateAsc',
  // TODO: check with 3550
  creationDateAscByFormatMilestone = 'creationDateAscByFormatMilestone',
}

export type CommonCreativeMediaSortParameters = {
  orderBy?: CreativeMediaOrderByQueryParam;
};

export type CommonMediaPaginationParameters = {
  limit?: number;
  offset?: number;
};

export type FetchCreativeMediasPayload = {
  releaseId?: string;
  orderBy?: CreativeMediaOrderByQueryParam;
  state?: Required<CommonCreativeMediaFilterParameters>['state'];
  format?: Required<CommonCreativeMediaFilterParameters>['format'];
  limit?: number;
  offset?: number;
  withRelease?: boolean;
};

// we derive a type from the FetchCreativeMediasPayload type
// for the GET request payload (params) - because typings in MSW handlers is finicky
// and requires all properties to be typed as strings
export type FetchCreativeMediasGetParams = {
  [K in keyof FetchCreativeMediasPayload]: string;
};

export type FetchCreativeMediasResponse = {
  creativeMedias: CreativeMediaList;
  release?: Release;
  totalCount: number;
};

export type RequestCreativeMediaListGenerationPayload = {
  mediaInfoListWithType: Array<RequestSingleCreativeMediaGenerationPayload>;
};

export type RequestSingleCreativeMediaGenerationPayload = {
  /**
   * id of the creative media
   */
  mediaId: string;
  /**
   * id of the Release associated with the media
   * will be used by the backend to get infos from the Release and use them to generate the creative media
   */
  releaseId: string;
  /**
   * tags for the creative media
   */
  tags?: string;
  /**
   * type of the creative media
   */
  type?: CreativeMediaType;
  /**
   * locale to generate media
   */
  locale: string;
};

export enum SpotlightType {
  CHART = 'CHART',
  PLAYLIST = 'PLAYLIST',
}

export type SpotlightDto = {
  productId: string;
  coverUrl: string;
  type: SpotlightType;
  releaseId: string;
  variables: {
    releaseName: string;
    releaseDate: string;
    artistId: string;
    artistName: string;
    dspName: string;
    trackName: string;
    playlistName?: string;
    chartName?: string;
    chartPosition?: string;
  };
};

export type SpotlightDtoList = {
  spotlights: Array<SpotlightDto>;
};

export type FetchCreativeSpotlightsResponse = SpotlightDtoList;

export enum SpotlightMilestone {
  PLAYLIST_ENTRY = 'playlist-entry',
  CHARTS_ENTRY = 'charts-entry',
  STREAM_MIILESTONE = 'stream-milestone',
}
