import React from 'react';

import {
  Button,
  theme as designSystemTheme,
  ThemeProvider as DesignSystemThemeProvider,
} from '@believe-front/react-components';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps as MuiDialogProps,
} from '@mui/material';

import { DialogContentText } from '../Backlink/components/ShareBacklinkDialog/styled';

type Props = {
  title: string;
  content?: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
} & MuiDialogProps;
const ConfirmDialog: React.FC<Props> = ({
  title = 'Are you sure?',
  content = '',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  open,
  onClose,
  onCancel,
  onConfirm,
}) => (
  <DesignSystemThemeProvider>
    <Dialog open={open} aria-labelledby="alert-dialog-title" onClose={onClose}>
      <DialogTitle
        id="alert-dialog-title"
        variant="h4"
        sx={{ paddingBottom: 1 }}
      >
        {title}
      </DialogTitle>
      {content && (
        <DialogContent sx={{ paddingBottom: 1 }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              /** TODO investigate why this is necessary */
              fontWeight: designSystemTheme.typography.fontWeightRegular,
              '@media (max-width:768px)': {
                fontWeight: designSystemTheme.typography.fontWeightRegular,
              },
            }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} color="danger" variant="text" autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  </DesignSystemThemeProvider>
);

export default ConfirmDialog;
