import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CircularProgress, IconButton, Tooltip } from '@mui/material';

import { Release } from 'shared/entities/release/release.types';
import { useRefreshRelease } from 'shared/hooks/entities/release';
import { useAppFeatureFlag } from 'shared/hooks/useAppFeatureFlag';
import { theme } from 'shared/themes/legacy';

const DEBOUNCED_TIME_TO_REFRESH_RELEASE = 1 * 1000;

/**
 * Display an icon button that refreshes, at click, the release
 *
 * Hidden if flag `refresh_release_component_enabled` disabled
 */
export const RefreshRelease = ({
  onSuccess = () => undefined,
  releaseId,
  icon,
}: {
  onSuccess?: (data: Release) => void;
  releaseId: string;
  icon: JSX.Element;
}) => {
  const shouldDisplayRefreshReleaseButton = useAppFeatureFlag(
    'refresh_release_component_enabled',
  );
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.refresh_release',
  });
  const { refreshRelease, isLoading } = useRefreshRelease();
  const debouncedRefreshRelease = debounce(
    refreshRelease,
    DEBOUNCED_TIME_TO_REFRESH_RELEASE,
  );

  const handleRefreshRelease = () => {
    debouncedRefreshRelease(releaseId, {
      onSuccess: (release) => onSuccess(release),
    });
  };

  if (!shouldDisplayRefreshReleaseButton) return null;

  return (
    <Tooltip title={t('tooltip', 'Refresh cover image and release date')}>
      <IconButton
        size="small"
        color="primary"
        onClick={handleRefreshRelease}
        sx={{
          background: theme.palette.common.white,
          borderRadius: '8px',
        }}
      >
        {isLoading ? <CircularProgress size={20} /> : icon}
      </IconButton>
    </Tooltip>
  );
};
