import styled from '@emotion/styled';

import { Select } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';

export const EditorFormLabel = styled(FormLabel)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(1 / 2)};
  strong {
    font-weight: normal;
    color: ${({ theme }) => theme.palette.grey[800]};
  }
  small {
    display: block;
    font-size: 0.825em;
    line-height: 1.4;
    margin: ${({ theme }) => theme.spacing(1 / 2)} 0 0;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  > div {
    flex: 1;
  }
`;

type FieldInfosProps = {
  color: AlertColor;
  importantColor: AlertColor;
};
export const FieldInfos = styled.div<FieldInfosProps>`
  color: ${({ theme, color }) => theme.palette[color].main};
  & .important {
    color: ${({ theme, importantColor }) => theme.palette[importantColor].main};
    font-weight: 600;
  }
  & .icon {
    position: relative;
    top: 4px;
    margin-right: ${({ theme, color }) => theme.spacing(1 / 3)};
  }
`;

export const IconSelect = styled(Select)`
  & .MuiSelect-outlined {
    ${({ theme }) => {
      return `
        padding-top: ${theme.spacing(1 / 2)};
        padding-bottom: ${theme.spacing(1 / 2)};
        padding-left: ${theme.spacing(1 / 2)};
    `;
    }}
  }
`;

IconSelect.defaultProps = {
  variant: 'outlined',
};
