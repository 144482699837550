import { useMutation, useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as artistApi from 'shared/entities/artist/artist.api';
import { useFetchArtist } from 'shared/hooks/entities/artist/useFetchArtist';
import { useNotification } from 'shared/hooks/useNotification';

import { useArtistProfileAnalytics } from './useArtistProfileAnalytics';

type Params = {
  artistId?: string;
};

const useUpdateArtistProfile = ({ artistId }: Params) => {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  // get the artist profile before any update as we will need to compare it with the updated one
  // to be able to send an event to GA4 with infos on what the user has changed
  const { data: currentArtist } = useFetchArtist(artistId);

  const { sendUpdateArtistProfileGA4TrackingEvent } =
    useArtistProfileAnalytics();

  const {
    mutate: updateArtistProfile,
    status: updateArtistProfileStatus,
    ...rest
  } = useMutation(artistApi.updateArtistProfile, {
    onSuccess: (data) => {
      notify({
        type: AppNotificationTypes.Success,
        message: `Artist updated`,
      });

      if (artistId) queryClient.setQueryData(['artist', artistId], data);
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: `An error occured while updating the artist profile. ${error}`,
      });
    },
    onMutate: (mutationPayload) => {
      /**
       * send an event to GA4 with infos on what the user wants to change
       * we want to do this even if the mutation fails as we record the user intent
       */
      if (currentArtist) {
        sendUpdateArtistProfileGA4TrackingEvent({
          currentArtist,
          updateArtistData: mutationPayload.data,
        });
      }
    },
  });

  return {
    updateArtistProfile,
    updateArtistProfileStatus,
    ...rest,
  };
};

export default useUpdateArtistProfile;
