import React from 'react';

import { Artist } from 'shared/entities/artist/artist.types';
import { ActionsTypes, useAppContext } from 'shared/state';

export function useArtistSettingsPanel() {
  const { state, dispatch } = useAppContext();

  const {
    artistSettingsPanel: { open: isOpen, artist, profileOnly },
  } = state;

  const open = React.useCallback(
    (artist: Artist, profileOnly = false) => {
      dispatch({
        type: ActionsTypes.OpenArtistSettingsPanel,
        payload: { artist, profileOnly },
      });
    },
    [dispatch],
  );

  function close() {
    dispatch({ type: ActionsTypes.CloseArtistSettingsPanel });
  }

  return {
    isOpen,
    artist,
    profileOnly,
    open,
    close,
  };
}
