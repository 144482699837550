import { useMediaQuery, useTheme } from '@mui/material';

export function useScreenSize() {
  const theme = useTheme();

  return {
    isMobile: useMediaQuery(theme.breakpoints.down('md')),
    isDesktop: useMediaQuery(theme.breakpoints.up('md')),
    isLargeDesktop: useMediaQuery(theme.breakpoints.up('lg')),
  };
}
