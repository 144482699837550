import React, { Suspense } from 'react';

import { Box, CircularProgress } from '@mui/material';

/**
 * a React Suspense loader for apps / sections / screens
 * works with React.lazy
 */
const ScreenLoader = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

export default ScreenLoader;
