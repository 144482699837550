import { api } from '../api';
import { BacklinkSettings } from '../backlinkSettings/backlinkSettings.types';
import {
  CreateWorkspaceEmployeePayload,
  UpdateWorkspaceBacklinkSettingsModePayload,
  UpdateWorkspaceBacklinkSettingsPayload,
  UpdateWorkspaceCustomerPayload,
  UpdateWorkspaceEmployeePayload,
} from './workspace.api.types';
import { WorkspaceCustomer, WorkspaceEmployee } from './workspace.types';

export const fetchWorkspaces = async (): Promise<WorkspaceEmployee[]> => {
  const results = await api.get('/workspaces');
  return results.data;
};

export const fetchWorkspace = async (
  workspaceId: string,
): Promise<WorkspaceEmployee> => {
  const results = await api.get(`/workspaces/${workspaceId}`);
  return results.data;
};

export const createWorkspace = async (
  payload: CreateWorkspaceEmployeePayload,
): Promise<WorkspaceEmployee> => {
  const results = await api.post('/workspaces', payload);
  return results.data;
};

export const updateWorkspace = async (
  workspaceId: string,
  payload: UpdateWorkspaceEmployeePayload,
): Promise<WorkspaceEmployee> => {
  const results = await api.patch(`/workspaces/${workspaceId}`, payload);
  return results.data;
};

export const deleteWorkspace = async (workspaceId: string): Promise<void> => {
  await api.delete(`/workspaces/${workspaceId}`);
};

export const addUserToWorkspace = async (
  workspaceId: string,
  userId: string,
): Promise<void> => {
  await api.put(`/workspaces/${workspaceId}/users/${userId}`);
};

export const removeUserFromWorkspace = async (
  workspaceId: string,
  userId: string,
): Promise<void> => {
  await api.delete(`/workspaces/${workspaceId}/users/${userId}`);
};

export const updateWorkspaceCustomer = async (
  payload: UpdateWorkspaceCustomerPayload,
): Promise<WorkspaceCustomer> => {
  const results = await api.patch('/workspaces', payload);
  return results.data;
};

export const updateBacklinkSettingsMode = async ({
  workspaceId,
  useDefault,
}: UpdateWorkspaceBacklinkSettingsModePayload): Promise<BacklinkSettings> => {
  const path = workspaceId
    ? `/workspaces/${workspaceId}/backlinkSettings/useDefault`
    : `/workspaces/backlinkSettings/useDefault`;

  const results = await api.patch<BacklinkSettings>(path, { useDefault });
  return results.data;
};

export const fetchBacklinkSettings = async (
  workspaceId?: string,
): Promise<BacklinkSettings> => {
  const path = workspaceId
    ? `/workspaces/${workspaceId}/backlinkSettings`
    : `/workspaces/backlinkSettings`;

  const results = await api.get<BacklinkSettings>(path);
  return results.data;
};

export const updateBacklinkSettings = async ({
  workspaceId,
  backlinkSettings,
}: UpdateWorkspaceBacklinkSettingsPayload) => {
  const path = workspaceId
    ? `/workspaces/${workspaceId}/backlinkSettings`
    : `/workspaces/backlinkSettings`;

  const result = await api.patch<BacklinkSettings>(path, {
    settingsData: backlinkSettings,
  });
  return result.data;
};
