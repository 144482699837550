import React from 'react';

import { DisabledContainer } from 'shared/components/DisabledContainer';
import ImageUploader from 'shared/components/ImageUploader';

import { EditorFieldProps } from '../../types';

type Props = EditorFieldProps<
  | {
      url?: string;
      id?: string;
    }
  | undefined
> & {
  label?: string;
  inputId?: string;
  thumbnailShape?: 'square' | 'circular';
  changeHandler: (id: string) => void;
  disabled?: boolean;
};

export const EditorImageUploader: React.FC<Props> = ({
  label = 'Image',
  inputId,
  value: imageData,
  changeHandler,
  disabled,
}) => {
  return (
    <DisabledContainer disabled={disabled}>
      <ImageUploader
        alt={label}
        changeHandler={changeHandler}
        inputId={inputId}
        value={imageData}
        inputGroupLabel={label}
      />
    </DisabledContainer>
  );
};
