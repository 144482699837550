import styled from '@emotion/styled';

import MuiToggleButton from '@mui/material/ToggleButton';

import { transientOptions } from 'shared/utils/emotion-interop';

// prettier-ignore
export const HeaderLinkInfoContainer = styled('div', transientOptions)<{ $color?: 'success' | 'warning' | 'error' }>`
  display: flex;
  align-items: center;
  font-weight: 400;
  ${({ $color, theme }) => $color && `color: ${theme.palette[$color].main};`}
  > * {
    margin-left: ${({ theme }) => theme.spacing(1 / 2)};
  }
`;

HeaderLinkInfoContainer.defaultProps = {
  'aria-label': 'store infos',
};

export const ToggleButton = styled(MuiToggleButton)`
  width: 50%;
  text-transform: none;

  ${({ selected, theme }) =>
    selected &&
    `
    background-color: #DAE8FF !important;
    color: ${theme.palette.primary.main} !important;
    font-weight: bold !important;
  `}
`;
