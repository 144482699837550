import {
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
  GA4MKPProductConfig,
} from 'shared/analytics/analytics.types';

export enum GA4CreativesEventNames {
  click = 'click',
  fileDownload = 'fileDownload',
}

export enum GA4CreativesFileFormat {
  story = 'story',
  post = 'post',
  canvas = 'canvas',
  spotlight = 'spotlight',
  all = 'all',
}

export enum GA4CreativesVideoUsed {
  fromBackstage = 'fromBackstage',
  upload = 'upload',
  pexel = 'pexel',
  none = 'none',
}

export type GA4CreativesFileDownloadEventParams = {
  product: string;
  subProduct: GA4SubProducts.creative;
  releaseId: string;
  artistId: string;
  fileExtension: string;
  fileName: string;
  fileFormat: string;
  videoUsed?: GA4CreativesVideoUsed;
};

export type GACreativesSubproduct = GA4SubProducts.creative;

export type GA4CreativesTrackingFileDownloadEventConfig =
  GA4MKPProductTrackingEvent<
    {
      eventName: GA4CreativesEventNames.fileDownload;
      subProduct: GA4SubProducts.creative;
    } & GA4MKPProductConfig<GA4SubProducts.creative> &
      GA4CreativesFileDownloadEventParams
  >;
