import * as adsApi from 'shared/entities/ads/ads.api';
import * as artistApi from 'shared/entities/artist/artist.api';
import * as autonotifyApi from 'shared/entities/autonotify/autonotify.api';
import * as backlinkApi from 'shared/entities/backlink/backlink.api';
import * as creativeMediaApi from 'shared/entities/creative/media.api';
import * as actionApi from 'shared/entities/dspAction/dspAction.api';
import * as productApi from 'shared/entities/product/product.api';
import * as releaseApi from 'shared/entities/release/release.api';
import * as storeConfigApi from 'shared/entities/storeConfig/storeConfig.api';
import * as trackPitchApi from 'shared/entities/trackPitch/trackPitch.api';
import * as workspaceApi from 'shared/entities/workspace/workspace.api';
import * as youTubeChannelApi from 'shared/entities/youTubeChannel/youTubeChannel.api';

import * as backlinkSettingsApi from './backlinkSettings/backlinkSettings.api';
import { BacklinkSettingsParentEntityType } from './backlinkSettings/backlinkSettings.types';

/**
 * A record of query keys associated with a fetcher
 */

export const fetchersByQueryKey = {
  artists: artistApi.fetchArtists,
  artist: artistApi.fetchArtist,
  release: releaseApi.fetchRelease,
  hotReleases: releaseApi.fetchHotReleases,
  storesConfigs: storeConfigApi.fetchStoresConfigs,
  backlinkSettingsData: ({
    parentEntityType,
    parentEntityId,
  }: {
    parentEntityType: BacklinkSettingsParentEntityType;
    parentEntityId?: string;
  }) =>
    backlinkSettingsApi.backlinkSettingsDataForParentEntityFetchers[
      parentEntityType
    ](parentEntityId),
  shareExtensions: backlinkApi.fetchShareExtensions,
  workspace: workspaceApi.fetchWorkspace,
  workspaces: workspaceApi.fetchWorkspaces,
  allDefaultBacklinkSettings:
    backlinkSettingsApi.fetchAllDefaultBacklinkSettings,
  CAPIProducts: productApi.fetchCAPIProducts,
  CAPIProductsByAlbumId: productApi.fetchCAPIProductsByAlbumId,
  getCAPIProducts: productApi.getCAPIProducts,
  DSPActionsEnginePoolKPIs: actionApi.fetchEnginePoolKPIs,
  autonotify: autonotifyApi.fetchAutonotify,
  autonotifyPreview: autonotifyApi.fetchAutonotifyPreview,
  // LINKS MANAGER
  backlink: backlinkApi.fetchBacklink,
  backlinkV2: backlinkApi.v2.fetchBacklink,
  backlinks: backlinkApi.fetchBacklinks,
  backlinksPerformance: backlinkApi.fetchBacklinksPerformance,
  backlinksAdmin: backlinkApi.fetchAdminBacklinks,
  // VMO
  youTubeChannels: youTubeChannelApi.fetchChannels,
  youTubeChannel: youTubeChannelApi.fetchChannel,
  youTubeChannelContexts: youTubeChannelApi.fetchContexts,
  youTubeChannelVideos: youTubeChannelApi.fetchChannelVideos,
  youTubeChannelPlaylist: youTubeChannelApi.fetchChannelPlaylist,
  youTubeChannelPlaylists: youTubeChannelApi.fetchChannelPlaylists,
  // Creative
  creativeMedias: creativeMediaApi.fetchCreativeMedias,
  creativeMediasApiToken: creativeMediaApi.fetchCreativeMediasApiToken,
  // ADS LAS
  adsInterests: adsApi.getReferentialInterests,
  adsStandardInterests: adsApi.getReferentialStandardInterests,
  adsLocations: adsApi.getReferentialLocations,
  adsFbCTAs: adsApi.getFbCTAs,
  adsAgeRanges: adsApi.getAgeRanges,
  adsAvailableCredits: adsApi.getAvailableCredits,
  adsAvailableChannels: adsApi.getReleaseAvailableChannels,
  adsReleaseAdCampaigns: adsApi.getReleaseAdCampaigns,
  adsReleaseAdCampaign: adsApi.getAdCampaignByKey,
  adsAdCampaignStats: adsApi.getAdCampaignStats,
  // TRACK PITCH TOOL
  trackPitches: trackPitchApi.fetchTrackPitches,
  trackPitch: trackPitchApi.fetchTrackPitch,
  trackPitchesExportStatus: trackPitchApi.getExportStatus,
  // ADS PLAN
  adsCreatePlan: adsApi.createAdsPlans,
  adsUpdatePlan: adsApi.updateAdsPlans,
  serviceCodes: adsApi.fetchServiceCodes,
  rechargeIds: adsApi.fetchRechargeIds,
  getAdsPlans: adsApi.fetchAdsPlans,
  getAdsPlan: adsApi.getAdsPlan,
  adsCreateCampaign: adsApi.createAdsCampaign,
  updateAdsCampaign: adsApi.updateCampaign,
  fetchBusinessUnit: adsApi.fetchBusinessUnitById,
};
