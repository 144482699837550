import { ReactNode } from 'react';

import { Box, Flag, theme, Typography } from '@believe-front/react-components';
import { getName as getCountryName } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

import {
  getCountryCodeFromLocale,
  getCountryCodeForFlagFromLocale,
  getSafeLocaleForI18nCountriesLib,
} from 'shared/utils/isoCodes';

type DefaultFlagWithLabel = {
  locale: string;
  onClick?: () => void;
};

type FlagWithPrefixAndCountryLabel = DefaultFlagWithLabel & {
  prefixLabel: string;
};

type FlagWithCustomLabel = DefaultFlagWithLabel & {
  children: ReactNode;
};

/**
 * Display a flag with a label to the right side
 * if children it's speicified, it displays the children as a label
 * if not, it displays the country name of the locale
 */
export const FlagWithLabel = ({
  locale,
  onClick,
  ...rest
}:
  | DefaultFlagWithLabel
  | FlagWithPrefixAndCountryLabel
  | FlagWithCustomLabel) => {
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'ui.country_flag_and_label',
  });

  const countryName = getCountryName(
    getCountryCodeFromLocale(locale),
    getSafeLocaleForI18nCountriesLib(i18n.language.substring(0, 2)),
  );
  const countryLabel = countryName || t('list_languages.unknown', 'Unknown');

  const children = 'children' in rest ? rest.children : undefined;
  const prefixLabel = 'prefixLabel' in rest ? rest.prefixLabel : undefined;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gap={theme.spacing(3)}
      onClick={onClick}
      sx={!onClick ? {} : { cursor: 'pointer' }}
    >
      <Box sx={{ width: 'fit-content' }}>
        <Flag isoCode={getCountryCodeForFlagFromLocale(locale)} size="small" />
      </Box>
      <Typography variant="body-medium" color="ButtonText">
        {children
          ? children
          : prefixLabel
          ? `${prefixLabel}${countryLabel}`
          : countryLabel}
      </Typography>
    </Box>
  );
};
