import { AuthUserEmployee } from 'shared/entities/auth/auth.types';
import { AppState } from 'shared/state/types';

import { getWorkspaceId } from '../helpers/user';
import { Actions, ActionsTypes } from './actions';

/**
 * this reducer is specific to the MarketingSuite app and will coe as an 'additional' reducer to the base app one
 * see shared/state/context
 */
export const reducer = (state: AppState<AuthUserEmployee>, action: Actions) => {
  switch (action.type) {
    /**
     * main auth user setup is done via the shared/base app reducer
     * but here, when the UserInfosReceived action/event is dispatched
     * we also want to add additional infos like the workspaceId that's been selected for the User
     */
    case ActionsTypes.UserInfosReceived: {
      return {
        ...state,
        user: {
          ...state.user,
          workspaceId: getWorkspaceId(action.payload),
        },
      };
    }
    /**
     * this is specific to the MarketingSuite as its Users can have (belong to) many workspaces
     */
    case ActionsTypes.UserWorkspaceSwitchRequested: {
      return {
        ...state,
        user: {
          ...state.user,
          workspaceId: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
