import styled from '@emotion/styled';

export type FieldSetVariant = 'standard' | 'condensed' | 'row';

type LegendProps = {
  variant?: FieldSetVariant;
};

export const Legend = styled.legend<LegendProps>`
  &:before {
    content: '';
    display: ${({ variant }) =>
      variant === 'condensed' ? 'inline-block' : 'none'};
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    margin-right: 12px;
  }
  font-size: ${({ variant }) =>
    variant === 'condensed' ? '1rem' : '1.125rem'};
  font-weight: ${({ variant }) => (variant === 'condensed' ? 600 : 400)};
`;

export const StyledFieldSet = styled.fieldset<{ variant?: FieldSetVariant }>`
  border: none; /* reset */
  ${({ theme, variant }) => `
    margin-bottom: ${
      variant === 'condensed' ? theme.spacing(1) : theme.spacing(2)
    };
    padding-left: ${
      variant === 'condensed' ? theme.spacing(1 / 2) : theme.spacing(0)
    };
    padding-right: ${
      variant === 'condensed' ? theme.spacing(1 / 2) : theme.spacing(0)
    };
    padding-bottom: ${
      variant === 'condensed' ? theme.spacing(1) : theme.spacing(3)
    };
    border-bottom: solid 1px ${theme.palette.grey[300]};
  `}

  display: ${({ hidden }) => (hidden ? 'none' : 'block')};

  > hr {
    margin: ${({ theme, variant }) =>
        variant === 'condensed' ? theme.spacing(2) : theme.spacing(3)}
      0;
  }
`;

export const FieldSetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const FieldSetDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(-1.9375)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.grey[600]};
`;

type FieldSetContentProps = {
  variant?: FieldSetVariant;
};
export const FieldSetContent = styled.div<FieldSetContentProps>`
  && > * {
    margin-bottom: ${({ theme, variant }) => {
      switch (variant) {
        case 'condensed':
        case 'row':
          return theme.spacing(1);
        default:
          return theme.spacing(2.5);
      }
    }};
    margin-right: ${({ theme, variant }) => {
      switch (variant) {
        case 'row':
          return theme.spacing(1);
        default:
          return theme.spacing(0);
      }
    }};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
