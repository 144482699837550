import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import config from '../config';

const redirectUrlByAppName = {
  [AppName.backstageMarketing]: `${config.backstageMarketingApiBaseUrl}/auth/login`,
  [AppName.backstageMarketingResources]:
    config.backstageMarketingResourcesOriginUrl,
};

export const redirectAfterLoginFail = async (returnPathname?: string) => {
  let redirectUrl = redirectUrlByAppName[getAppName()];

  if (!redirectUrl) return;
  if (returnPathname) redirectUrl += '?path=' + encodeURI(returnPathname);
  window.location.assign(redirectUrl);
  // Reject error after waiting 20 seconds so we ensure that the window.location.assign
  // has been executed and that we are not in the app anymore
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('redirectAfterLoginFailTimeout'));
    }, 20000);
  });
};
