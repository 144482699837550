import { useState, Dispatch, SetStateAction } from 'react';

import { Status } from 'shared/constants';

/**
 * a hook to manage loading status
 * @param initialStatus
 */
export function useStatus(
  initialStatus = Status.Idle,
): [Status, Dispatch<SetStateAction<Status>>] {
  const [status, setStatus] = useState<Status>(initialStatus);
  // note: for TS we need to type the returned value (a tuple: https://www.reddit.com/r/reactjs/comments/ap88h6/being_explicit_with_your_own_react_hook/eg6ff0a/)
  return [status, setStatus];
}

export { Status };
