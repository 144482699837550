import {
  authUserHasManyWorkspaces,
  authUserHasOneWorkspace,
} from 'shared/entities/auth/auth.types';
import { WorkspaceEmployee } from 'shared/entities/workspace/workspace.types';
import { DEFAULT_TIMEZONE } from 'shared/utils/date';

import { AppState } from './types';

export const initialState: AppState = {
  authenticating: false,
  user: {
    userId: '',
  },
  notifications: [],
  artistSettingsPanel: {
    open: false,
  },
  trackPitchPanel: {
    open: false,
  },
};

/**
 * a User can have one or many workspaces
 * we pick the current workspace via a workspaceId when it has many
 * and directly get the workspace when it has only one
 */
export const getCurrentWorkspace = (state: AppState) => {
  const user = state.user;
  if (authUserHasOneWorkspace(user)) {
    return user.workspace;
  } else if (authUserHasManyWorkspaces(user)) {
    const currentWorkspaceId = user.workspaceId;
    const workspace = user.workspaces.find(
      (workspace) => workspace.id === currentWorkspaceId,
    );
    return workspace as WorkspaceEmployee;
  } else {
    throw new Error(
      `a User must have at least one Workspace. ${user} does not seem to fullfill this requirement.`,
    );
  }
};

export const getCurrentWorkspaceId = (state: AppState) => {
  const workspace = getCurrentWorkspace(state);
  return workspace && 'id' in workspace ? workspace.id : undefined;
};

export const getWorkspaceTerritory = (state: AppState) => {
  const currentWorkspace = getCurrentWorkspace(state)?.settings;
  return currentWorkspace && 'territory' in currentWorkspace
    ? currentWorkspace.territory || ''
    : undefined;
};

export const getWorkspaceDefaultTimezone = (state: AppState) => {
  return (
    getCurrentWorkspace(state)?.settings.defaultTimezone || DEFAULT_TIMEZONE
  );
};

export const getCompetitionMinimumAge = (state: AppState) => {
  const currentWorkspace = getCurrentWorkspace(state)?.settings;
  return currentWorkspace && 'competitionMinimumAge' in currentWorkspace
    ? currentWorkspace.competitionMinimumAge || ''
    : undefined;
};
