import styled from '@emotion/styled';

import IconButton from '@mui/material/IconButton';

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${({ theme }) => theme.palette.grey[800]};
`;
