import { AuthUserEmployee } from 'shared/entities/auth/auth.types';
import { initialState as sharedInitialState } from 'shared/state';
import { AppState } from 'shared/state/types';

export type MarketingSuiteAppState = AppState<AuthUserEmployee>;

export const initialState: MarketingSuiteAppState = {
  ...sharedInitialState,
  user: {
    ...sharedInitialState.user,
    email: '',
    role: { isAdmin: false },
    workspaceId: '',
    workspaces: [],
    backlinkSettingsId: '',
  },
  notifications: [],
  artistSettingsPanel: {
    open: false,
  },
};
