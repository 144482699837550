import React, { useState } from 'react';

import styled from '@emotion/styled';

import BugReportIcon from '@mui/icons-material/BugReport';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

import { useValidationContext } from '../hooks/useValidation';
import { useBacklinkSettingsDataEditorContext } from '../state/context';

const StyledToggleDebugInfosPanelButton = styled(IconButton)`
  position: fixed;
  z-index: 667;
  bottom: ${({ theme }) => theme.spacing(1)};
  left: ${({ theme }) => theme.spacing(1)};
`;
const StyledPanel = styled.div`
  position: fixed;
  z-index: 666;
  height: 80vh;
  width: calc(100vw - ${({ theme }) => theme.spacing(4)});
  ${({ theme }) => `${theme.breakpoints.up('sm')} {
    width: 33%;
  }`}

  left: ${({ theme }) => theme.spacing(2)};
  bottom: ${({ theme }) => theme.spacing(4)};

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 1rem 1rem 0 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  > .header {
    height: 80px;
    padding-left: 2rem;
  }

  > .data {
    height: calc(100% - 100px);
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    overflow: auto;
  }
`;

const StyledCloseDebugInfosPanelButton = styled(CloseIcon)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
`;

const EditorDebug: React.FC = () => {
  const { stepKey, editorState } = useBacklinkSettingsDataEditorContext();
  const { errors } = useValidationContext();

  const [showDebugInfos, setShowDebugInfos] = useState(false);

  const handleToggleDebugInfosButtonClick = () => {
    setShowDebugInfos(!showDebugInfos);
  };

  const handleCloseDebugInfosButtonClick = () => {
    setShowDebugInfos(false);
  };

  return (
    <>
      <StyledToggleDebugInfosPanelButton
        onClick={handleToggleDebugInfosButtonClick}
      >
        <BugReportIcon />
      </StyledToggleDebugInfosPanelButton>
      {showDebugInfos && (
        <StyledPanel>
          <div className="header">
            <h3>Editor State raw data</h3>
            <em>
              Current step first, scroll down to see the whole editor state
            </em>
            <StyledCloseDebugInfosPanelButton
              onClick={handleCloseDebugInfosButtonClick}
            >
              <BugReportIcon />
            </StyledCloseDebugInfosPanelButton>
          </div>
          <div className="data">
            {!!Object.keys(errors).length && (
              <Box p={1}>
                <h3>Errors</h3>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
              </Box>
            )}
            <h4>{stepKey}</h4>
            <pre>
              {JSON.stringify(
                {
                  [stepKey]: editorState[stepKey],
                },
                null,
                2,
              )}
            </pre>
            <hr />
            <h4>Editor state</h4>
            <pre>{JSON.stringify(editorState, null, 2)}</pre>
          </div>
        </StyledPanel>
      )}
    </>
  );
};

export default EditorDebug;
