import { useEffect, useState } from 'react';

import { validateSlug } from 'shared/entities/backlink/backlink.api';
import { SlugValidationInfos } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { messages } from '../messages';
import { useValidationContext } from './useValidation';

type Params = {
  subDomain: string;
  slug: string;
  slugPath: string;
  backlinkId: string;
  isSubDomainConfigured: boolean;
  isSubDomainSelected: boolean;
  enabled?: boolean;
};

const useValidateUrl = ({
  subDomain,
  slug,
  slugPath,
  backlinkId,
  isSubDomainConfigured,
  isSubDomainSelected,
  enabled = true,
}: Params) => {
  const [slugValidationInfos, setSlugValidationInfos] =
    useState<SlugValidationInfos>();
  const { setErrors } = useValidationContext();

  useEffect(() => {
    const shouldValidate = enabled && slug && backlinkId;
    const doValidateSlug = async () => {
      const slugValidationInfos = await validateSlug({
        subDomain,
        slug,
        backlinkId,
      });
      setSlugValidationInfos((_) => ({
        ...slugValidationInfos,
        subDomainConfigured: isSubDomainConfigured,
        subDomainSelected: isSubDomainSelected,
      }));

      const getErrorMessage = () => {
        if (slugValidationInfos.isValid && slugValidationInfos.isAvailable)
          return '';

        if (!slugValidationInfos.isValid) return messages.SLUG_NOT_VALID;
        if (!isSubDomainConfigured) return messages.SUBDOMAIN_NOT_CONFIGURED;
        if (!isSubDomainSelected) return messages.SUBDOMAIN_NOT_SELECTED;

        return messages.SLUG_NOT_AVAILABLE;
      };

      setErrors((errors) => {
        return {
          ...errors,
          [slugPath]: getErrorMessage(),
        };
      });
    };
    if (shouldValidate) {
      doValidateSlug();
    }
  }, [
    backlinkId,
    setErrors,
    subDomain,
    slug,
    slugPath,
    isSubDomainConfigured,
    isSubDomainSelected,
    enabled,
  ]);

  return { urlValidationInfos: slugValidationInfos };
};

export default useValidateUrl;
