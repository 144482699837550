import { rest } from 'msw';

import config from 'shared/config';
import { getBacklink } from 'shared/entities/backlink/mocks/fixtures/backlink.fixture.all';
import { transformBacklinkSettingsDataV1ToV2 } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.helpers';
import {
  DSPActionStatus,
  DSPActionType,
} from 'shared/entities/dspAction/dspAction.constants';
import { DSPAction } from 'shared/entities/dspAction/dspAction.types';
import {
  dspActions,
  getDSPActionById,
} from 'shared/entities/dspAction/mocks/fixtures/dspAction.fixtures.all';

import {
  FetchBacklinkDSPActionsPayload,
  UpdateBacklinkPayload,
} from '../../../backlink.api.types';

const backlinkBaseApiPath = '/v2/backlinks';

export const DEFAULT_BACKLINKS_COUNT_FOR_A_RELEASE = 1;

/**
 * Refreshing backlink also refreshes the release in the backend
 * To reproduce this behavior, we export a variable
 * to know if the backlink has been refreshed
 */
export let backlinkRefreshed = false;
export const makeHandlers = (apiBaseUrl: string) => [
  // GET - read backlink settings v2
  rest.get<never, { backlinkId: string }>(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId`,
    (req, res, ctx) => {
      let backlink = getBacklink(req.params.backlinkId);

      if (!backlink) {
        return res(ctx.status(404));
      }

      // once we have fetched the backlink once via this endpoint
      // it becomes a v2 backlink
      const settingsDataV2 = backlink.isV2
        ? backlink.settingsDataV2
        : transformBacklinkSettingsDataV1ToV2(backlink.settingsData);

      backlink.settingsDataV2 = settingsDataV2;
      backlink.isV2 = true;

      return res(ctx.status(200), ctx.json(backlink));
    },
  ),
  // PATCH - update backlink
  rest.patch<
    Omit<UpdateBacklinkPayload, 'settingsData'>,
    { backlinkId: string }
  >(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId`,
    async (req, res, ctx) => {
      const payload = await req.json<
        Omit<UpdateBacklinkPayload, 'settingsData'>
      >();
      const settingsDataV2 = payload?.settingsDataV2;

      const backlink = getBacklink(req.params.backlinkId);

      if (backlink) {
        backlink.settingsDataV2 = settingsDataV2;
        return res(ctx.status(200), ctx.json(backlink));
      } else {
        return res(ctx.status(404));
      }
    },
  ),

  /** Actions */
  rest.get<never, { backlinkId: string; dspActionId: string }>(
    `${apiBaseUrl}/backlinks/:backlinkId/actions/:dspActionId`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getDSPActionById(req.params.dspActionId)),
      );
    },
  ),
  // GET - read backlink DSP Actions
  rest.get<
    Pick<FetchBacklinkDSPActionsPayload, 'dspActionIds'>,
    { backlinkId: string }
  >(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/actions`,
    (req, res, ctx) => {
      // for now we use the same dspActions for all backlinks
      // but we get only the ones that are related to the backlink using the passed in dspActionIds
      const dspActionIds = req.url.searchParams.getAll('dspActionIds[]');
      const backlinkDspActions = !!dspActionIds?.length
        ? dspActions.filter((dspAction) => dspActionIds.includes(dspAction.id))
        : dspActions;

      return res(ctx.status(200), ctx.delay(200), ctx.json(backlinkDspActions));
    },
  ),
  // POST - create backlink DSP Action
  rest.post<DSPAction, { backlinkId: string }>(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/actions`,
    async (req, res, ctx) => {
      const payload: DSPAction = await req.json();
      const backlink = getBacklink(req.params.backlinkId);
      const followMainArtistActionExists = dspActions.some((dspAction) => {
        return (
          dspAction.type === DSPActionType.FOLLOW_ARTIST &&
          dspAction.artistId === backlink?.artist?.id &&
          Object.keys(payload.config.platforms)[0] ===
            Object.keys(dspAction.config.platforms)[0]
        );
      });
      const dspAction = {
        ...payload,
        id: window.crypto.randomUUID(),
        // we want to create a FOLLOW_MAIN_ARTIST action if it does not exist
        artistId:
          !followMainArtistActionExists && backlink?.artist?.id
            ? backlink.artist.id
            : window.crypto.randomUUID(),
        status: DSPActionStatus.SCHEDULED,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      dspActions.push(dspAction);
      return res(ctx.status(200), ctx.json(dspAction));
    },
  ),
  // PATCH - update backlink DSP Action
  rest.patch<
    DSPAction,
    {
      backlinkId: string;
      dspActionId: string;
    }
  >(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/actions/:dspActionId`,
    async (req, res, ctx) => {
      const payload: DSPAction = await req.json();
      const dspActionId = req.params.dspActionId;
      const dspAction = {
        ...payload,
        id: dspActionId,
        updatedAt: new Date().toISOString(),
      };
      // replace the dspAction in the array of dspActions in the fixture
      const index = dspActions.findIndex(
        (dspAction) => dspAction.id === dspActionId,
      );
      if (index !== -1) {
        dspActions[index] = {
          ...dspActions[index],
          ...dspAction,
        };
      }
      return res(ctx.status(200), ctx.json(dspAction));
    },
  ),
];

const handlers = [
  ...makeHandlers(config.marketingSuiteApiBaseUrl),
  ...makeHandlers(config.backstageMarketingApiBaseUrl),
];

export default handlers;
