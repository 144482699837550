import {
  WorkspaceEmployee,
  WorkspaceCustomer,
} from '../workspace/workspace.types';

export enum UserEmployeePrograms {
  Links = 'backlink',
  Fans = 'fan',
  Ads = 'ads',
  AdsCredits = 'adscredits',
  CreativeHub = 'creativehub',
  TrackPitch = 'trackpitch',
  VMO = 'vmo',
  Planning = 'planning',
  Newsletters = 'newsletters',
  Assets = 'assets',
  Reports = 'reports',
  VideosManager = 'videos_manager',
  b_Rights = 'b_rights',
  CM_Reports = 'cm_reports',
  CM_Trends = 'cm_trends',
  DM_Reports = 'dm_reports',
}

export enum BackstageProducerPrograms {
  Players = 'promotion/player',
  PlayerStats = 'analytics/player',
  AMFA = 'promotion/artistcertification',
}

export enum BackstagePrograms {
  Ads = 'ads',
}

/**
 * a base AuthUser of the main app
 *
 * more spefific types of AuthUser will be used for the different Believe environments
 */
export type AuthBaseUser = {
  userId: string;
};

/**
 * User types based on a User reationship to Workspace(s)
 *
 * we want to be able to branch code based on the fact that a User has one or many workspaces
 * ATM the distinction effectively separates a UserCustomer (1 ws) & a UserEmployee (many ws)
 * but it is important to distinguish the 2 ways of separating Users to be semantically precise
 * and to avoid being tied to the current user taxonomy within the Believe ecosystem of apps
 */
type AuthUserWithOneWorkspace = AuthBaseUser & {
  workspace: WorkspaceCustomer;
};
type AuthUserWithManyWorkspaces = AuthBaseUser & {
  workspaceId: string;
  workspaces: WorkspaceEmployee[];
};

type AuthUserIsAdmin = AuthBaseUser & {
  role: { isAdmin: true };
};

export type AuthUserEmployee = AuthUserWithManyWorkspaces &
  Partial<{
    email: string;
    backlinkSettingsId: string | null;
    MPFToken?: string;
    zendeskToken?: string;
    role: { isAdmin?: boolean; isEditor?: boolean; isGuest?: boolean };
    programs: Array<UserEmployeePrograms> | null;
  }>;

export type AuthUserCustomer = AuthUserWithOneWorkspace & {
  externalId: string;
  legalIdentity: string;
  hasAcceptedCgu?: boolean;
  role: { isEditor?: boolean; isGuest?: boolean };
  producerBackstagePrograms?: Array<BackstageProducerPrograms>;
  programs?: Array<BackstagePrograms>;
};

export type AuthUser = AuthBaseUser | AuthUserEmployee | AuthUserCustomer;

/**
 * type guards
 * they will allow easy branching and correct typings
 */

export const authUserHasOneWorkspace = (
  user: AuthUser,
): user is AuthUserWithOneWorkspace =>
  typeof (user as AuthUserWithOneWorkspace).workspace !== 'undefined';

export const authUserIsEmployee = (user: AuthUser): user is AuthUserEmployee =>
  typeof (user as AuthUserEmployee).workspaces !== 'undefined';

export const authUserIsCustomer = (user: AuthUser): user is AuthUserCustomer =>
  typeof (user as AuthUserCustomer).workspace?.settings !== 'undefined';

export const authUserHasManyWorkspaces = (
  user: AuthUser,
): user is AuthUserWithManyWorkspaces =>
  typeof (user as AuthUserWithManyWorkspaces).workspaceId !== 'undefined' &&
  typeof (user as AuthUserWithManyWorkspaces).workspaces !== 'undefined';

export const authUserIsAdmin = (user: AuthUser): user is AuthUserIsAdmin => {
  const userWithRole = user as AuthUserEmployee;
  return (
    typeof userWithRole.role !== 'undefined' &&
    userWithRole.role.isAdmin === true
  );
};
