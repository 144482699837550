import { useContext, createContext } from 'react';

import { BacklinkSettingsData } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { StepKey } from '../config';
import { useBacklinkSettingsEditorState } from '../hooks/useBacklinkSettingsDataEditorState';
import useEditorLocale from '../hooks/useEditorLocale';
import type { Props as BacklinkSettingsEditorDataProps } from '../index';

type editorStateHookReturnTypes = ReturnType<
  typeof useBacklinkSettingsEditorState
>;
type localeHookReturnTypes = ReturnType<typeof useEditorLocale>;

export type BacklinkSettingsDataEditorContextValue = {
  stepKey: StepKey;
  editorState: BacklinkSettingsData;
  dispatch: editorStateHookReturnTypes['dispatch'];
  getFieldProps: editorStateHookReturnTypes['getFieldProps'];
  parentEntityInfos?: BacklinkSettingsEditorDataProps['parentEntityInfos'];
  locales: localeHookReturnTypes['locales'];
  currentLocale: localeHookReturnTypes['currentLocale'];
  handleCurrentLocaleChange: localeHookReturnTypes['handleCurrentLocaleChange'];
};

export const BacklinkSettingsDataEditorContext = createContext<
  BacklinkSettingsDataEditorContextValue | undefined
>(undefined);

export const useBacklinkSettingsDataEditorContext = () => {
  const context = useContext(BacklinkSettingsDataEditorContext);
  if (!context)
    throw new Error(
      `useBacklinkSettingsEditorContext must be used inside BacklinkSettingsEditorContext Provider`,
    );
  return context;
};
