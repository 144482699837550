import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { StepKey } from '../config';
import useEditorSteps from './useEditorSteps';

type Params = {
  onStepChange: (step: StepKey) => void;
};

const useEditorStep = ({ onStepChange }: Params) => {
  // we need to keep a ref to the previous step to be able to compare with the current one
  // & broadcast that we have changed step
  const stepKeyRef = useRef<StepKey>();
  const { step: stepParam } = useParams<{ step: string }>();
  if (stepKeyRef.current && stepParam && stepParam !== stepKeyRef.current) {
    const currentStepKey = Object.values(StepKey).find(
      (stepKey) => stepKey === stepParam,
    );
    if (currentStepKey) {
      onStepChange(currentStepKey);
    }
  }
  const firstStepKey = StepKey.Release;
  stepKeyRef.current =
    (Object.values(StepKey).find(
      (stepKey) => stepParam === stepKey,
    ) as StepKey) || firstStepKey;

  const stepKey = stepKeyRef.current;

  /** keep track of which Steps have been seen in this edit session */
  const [stepsVisited, setStepsVisited] = useState<Set<StepKey>>(new Set());

  /** add current Step to the visited steps list */
  useEffect(() => {
    setStepsVisited((stepsVisited) => stepsVisited.add(stepKey));
  }, [stepKey]);

  /** Step component */
  const { steps } = useEditorSteps();
  const Step = steps[stepKey].component;

  return { stepKey, stepParam, stepsVisited, Step };
};

export default useEditorStep;
