import * as React from 'react';

import i18next from 'i18next';
import { compact } from 'lodash';
import ReactGA4 from 'react-ga4';

import { sendGA4TrackingEvent, trackMenuItemClick } from 'shared/analytics';
import {
  AppGATrackers,
  GA4InitOptions,
  GA4MKPProductConfig,
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
  GAUserProperties,
} from 'shared/analytics/analytics.types';
import config from 'shared/config';
import {
  AuthUser,
  authUserIsCustomer,
  authUserIsEmployee,
} from 'shared/entities/auth/auth.types';
import { ActionsTypes, useAppContext } from 'shared/state';
import { getAppName } from 'shared/utils/app';

import { useAppState } from '.';

const initializeGATrackers = (ga4UserProperties: GAUserProperties) => {
  const appName = getAppName();
  const trackersToInitialize: AppGATrackers = {
    ga4Trackers: [],
  };

  // we don't want to trigger analytics in cypress e2e tests
  if (window.Cypress) {
    return trackersToInitialize;
  }

  const ga4Trackers = compact<GA4InitOptions>(
    config.gaTrackers[appName].ga4Trackers,
  );

  ga4Trackers[0].gtagOptions = { user_properties: ga4UserProperties };

  if (ga4Trackers.length >= 1) {
    trackersToInitialize.ga4Trackers = ga4Trackers;
    ReactGA4.initialize(ga4Trackers);
  }
  return trackersToInitialize;
};

const getGA4UserProperties = (user: AuthUser) => {
  if (!user.userId) {
    return;
  }
  let ga4UserProperties: GAUserProperties = {
    userId: user.userId,
    language: i18next.language,
  };
  if (authUserIsCustomer(user)) {
    ga4UserProperties.legalIdentity = user.legalIdentity;
    ga4UserProperties.producerId = user.externalId;
  } else if (authUserIsEmployee(user)) {
    ga4UserProperties.userPrograms = user.programs || [];
  }
  return ga4UserProperties;
};

export const useGATracking = () => {
  const { gaTrackersInitialized: appGATrackers, user } = useAppState();
  const { dispatch } = useAppContext();
  const ga4UserProperties = React.useMemo(
    () => getGA4UserProperties(user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.userId],
  );

  const trackersInitializedRef = React.useRef<AppGATrackers | null>(null);

  // initialize trackers if not already initialized
  React.useEffect(() => {
    if (appGATrackers) {
      trackersInitializedRef.current = appGATrackers;
    }
    if (!trackersInitializedRef.current && ga4UserProperties) {
      trackersInitializedRef.current = initializeGATrackers(ga4UserProperties);
      dispatch({
        type: ActionsTypes.GATrackersInitialized,
        payload: trackersInitializedRef.current,
      });
    }
  }, [appGATrackers, dispatch, ga4UserProperties]);

  /**
   * note: tried to use the Parameters<typeof sendGA4TrackingEvent> type here
   * but had issues with passing the generic type to the function
   * */
  const _sendGA4TrackingEvent = React.useCallback(
    <
      TrackingEvent extends GA4MKPProductTrackingEvent<
        GA4MKPProductConfig<GA4SubProducts>
      >,
    >(
      params: Omit<TrackingEvent, 'gaTrackers'>,
    ) => {
      if (trackersInitializedRef.current) {
        sendGA4TrackingEvent<TrackingEvent>({
          ...params,
          gaTrackers: trackersInitializedRef.current,
        });
      }
    },
    [trackersInitializedRef],
  );

  const _trackMenuItemClick = React.useCallback(
    (params: Omit<Parameters<typeof trackMenuItemClick>[0], 'gaTrackers'>) => {
      if (trackersInitializedRef.current) {
        trackMenuItemClick({
          ...params,
          gaTrackers: trackersInitializedRef.current,
        });
      }
    },
    [trackersInitializedRef],
  );

  return {
    gaTrackers: trackersInitializedRef.current,
    sendGA4TrackingEvent: _sendGA4TrackingEvent,
    trackMenuItemClick: _trackMenuItemClick,
  };
};
