import { AxiosResponse } from 'axios';

import { getStoresOptionsWithFrontEndOnlyDSPActions } from 'shared/entities/backlinkSettings/v2/helpers/backlinkSettingsV2.stores.helpers';
import { RequiredProperty } from 'shared/types/utils';

import { api } from '../../api';
import { transformBacklinkSettingsDataV1ToV2 } from '../../backlinkSettings/mocks/fixtures/backlinkSettings.fixture.helpers';
import { UpdateBacklinkPayload } from '../backlink.api.types';
import { Backlink } from '../backlink.types';
import backlink from '../mocks/fixtures/backlink.fixture.one';

/**
 * Backlink DSP Actions related methods
 */
export * from './backlink.dspAction.api';

/**
 * Backlink related methods
 */
export const backlinkBaseApiPath = '/v2/backlinks';

export const fetchBacklink = async (backlinkId: string): Promise<Backlink> => {
  /**
   * mocks for backlink
   * used while working b4 the backend integration is done
   *
   * We need different artist and release Ids for staging and review environments
   * so that the api calls made with those ids do not fail and can access existing entities on those environments
   *
   * TODO: delete when back is done
   */

  // both envs are considered 'staging'
  const isStaging = new RegExp('ams-sta').test(window.location.host);
  // but the review env is different
  const isReview = new RegExp('rvw-').test(window.location.host);

  const artistId = isReview
    ? '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'
    : 'e6ded64a-b883-4540-a914-7e88c5af4eb3';

  const releaseId = isReview
    ? 'f28bee5a-2100-4412-a6ab-bf3b2e874ad7'
    : '6ec0b3ad-ba01-4998-8104-90b23609d735';

  if (isStaging) {
    return {
      ...backlink,
      artist: backlink.artist && {
        ...backlink.artist,
        id: artistId,
      },
      releaseId,
      settingsDataV2: transformBacklinkSettingsDataV1ToV2(
        backlink.settingsData,
      ),
    };
  }

  const results = await api.get<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
  );
  return results.data;
};

export const updateBacklink = async ({
  backlinkId,
  settingsDataV2,
}: RequiredProperty<
  Omit<UpdateBacklinkPayload, 'settingsData'>,
  'settingsDataV2'
>): Promise<Backlink | AxiosResponse> => {
  // Prepare settings data to be sent to the backend
  // We only want to keep frontend only DSP actions as the actual DSP actions are stored in the backend as separate entities
  // we consider the backend to be the source of truth for DSP actions and never want to save DSPActions entities with the backlink settings
  // all we want to save with the settings are the DSPActionIds & 'frontendOnly' DSPActions
  const preparedSettingsDataV2: typeof settingsDataV2 = {
    ...settingsDataV2,
    preRelease: {
      ...settingsDataV2.preRelease,
      stores: {
        ...settingsDataV2.preRelease.stores,
        options: getStoresOptionsWithFrontEndOnlyDSPActions(
          settingsDataV2.preRelease.stores.options,
        ),
      },
    },
  };

  const response = await api.patch<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
    {
      backlinkId,
      settingsDataV2: preparedSettingsDataV2,
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return response;
};
