import React, { useMemo } from 'react';

import { Maybe } from '@creative/sdk/lib/cjs/shared/api/graphql/common';
import i18next from 'i18next';

import { GA4SubProducts } from 'shared/analytics/analytics.types';
import {
  GA4CreativesTrackingFileDownloadEventConfig,
  GA4CreativesEventNames,
} from 'shared/entities/creative/creative.analytics.types';
import {
  CreativeMediaListItem,
  CreativeMediaState,
} from 'shared/entities/creative/media.types';
import { Release } from 'shared/entities/release/release.types';
import { useGATracking } from 'shared/hooks/useGATracking';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import {
  useRequestSingleCreativeMediaGeneration,
  useDownloadCreativeMedia,
} from '.';

type Params = {
  media: CreativeMediaListItem;
  release?: Release;
};

export default function useSingleCreativeMediaGenerationAndDownload({
  media,
  release,
}: Params) {
  const [mediaState, setMediaState] = React.useState(media.rawData.state);

  const [downloadHasBeenRequested, setDownloadHasBeenRequested] =
    React.useState(false);

  const {
    requestSingleCreativeMediaGeneration,
    isSuccess: generationRequestSuccessful,
  } = useRequestSingleCreativeMediaGeneration();

  const { downloadCreativeMedia } = useDownloadCreativeMedia();

  const { sendGA4TrackingEvent } = useGATracking();
  const appName = getAppName() as AppName;

  const fileName = useMemo(() => {
    return release
      ? `${media.type}_${release.artist.name}_${release.title}_${media.id}`.replaceAll(
          ' ',
          '',
        )
      : `creativeMedia_${media.id}`;
  }, [media.id, media.type, release]);

  function downloadMedia(deducedType?: Maybe<string>) {
    const extension = deducedType === 'video' ? 'mp4' : 'jpg';
    const filename = `${fileName}.${extension}`;

    downloadCreativeMedia({
      mediaId: media.id,
      filename,
    });

    setDownloadHasBeenRequested(false);
  }

  function handleMediaStateChanged({
    id,
    deducedType,
    state,
  }: {
    id: string;
    deducedType?: Maybe<string>;
    state?: CreativeMediaState | null;
  }) {
    if (
      downloadHasBeenRequested &&
      generationRequestSuccessful &&
      state === CreativeMediaState.DoneGraphQL
    ) {
      downloadMedia(deducedType);
      return;
    }
    setMediaState(state);
  }

  function handleDownloadRequest(deducedType?: Maybe<string>) {
    setDownloadHasBeenRequested(true);

    sendGA4TrackingEvent<GA4CreativesTrackingFileDownloadEventConfig>({
      eventName: GA4CreativesEventNames.fileDownload,
      eventParams: {
        product: appName,
        subProduct: GA4SubProducts.creative,
        releaseId: release?.id || '',
        artistId: release?.artistId || '',
        fileExtension: deducedType === 'video' ? 'mp4' : 'jpg',
        fileName,
        fileFormat: media.format,
      },
    });

    // media is ready -> download it
    if (mediaState === CreativeMediaState.DoneGraphQL) {
      downloadMedia(deducedType);
      return;
    }
    // media is not ready -> generate it
    release &&
      requestSingleCreativeMediaGeneration({
        mediaId: media.id,
        releaseId: release?.id,
        type: media.type,
        locale: i18next.language,
      });
  }

  return {
    handleDownloadRequest,
    handleMediaStateChanged,
    downloadHasBeenRequested,
  };
}
