import { isEqual } from 'lodash';

import { GA4SubProducts } from 'shared/analytics/analytics.types';
import {
  GA4ArtistEventNames,
  GA4UpdateArtistSettingsTrackingEventConfig,
} from 'shared/entities/artist/artist.analytics.types';
import { UpdateArtistProfilePayload } from 'shared/entities/artist/artist.api.types';
import { Artist } from 'shared/entities/artist/artist.types';
import { useGATracking } from 'shared/hooks/useGATracking';
import { AppName } from 'shared/types';

import { isSocialHandleKey } from '../../../components/ArtistSettingsPanel/tabs/ArtistProfile/ArtistProfile.helpers';

export const useArtistProfileAnalytics = () => {
  const { sendGA4TrackingEvent } = useGATracking();
  const sendUpdateArtistProfileGA4TrackingEvent = ({
    currentArtist,
    updateArtistData,
  }: {
    currentArtist: Artist;
    updateArtistData: UpdateArtistProfilePayload['data'];
  }) => {
    // it might happen that the Artist storeProfileLinks or storeOfficialPlaylists have no ids
    // whereas the same objects for the updatedArtist payload has always ids (because of how it is set in the form's onSubmit handler), even if they are set to undefined
    // we need to normalize the data to be able to compare the two objects
    const normalizedCurrentArtistStoreProfileLinks = Object.fromEntries(
      Object.entries(currentArtist.data.storeProfileLinks || {}).map(
        ([storeName, { url, id }]) => [storeName, { url, id }],
      ),
    );
    const normalizedCurrentArtistStoreOfficialPlaylists = Object.fromEntries(
      Object.entries(currentArtist.data.storeOfficialPlaylists || {}).map(
        ([storeName, { url, id }]) => [storeName, { url, id }],
      ),
    );

    /**
     * social handles
     */
    const currentArtistSocialHandles = Object.fromEntries(
      Object.entries(currentArtist.data).filter(([key]) =>
        isSocialHandleKey(key),
      ),
    );

    const updateArtistDataSocialHandles = Object.fromEntries(
      Object.entries(updateArtistData.data).filter(([key]) =>
        isSocialHandleKey(key),
      ),
    );

    const hasArtistSocialHandlesChanged = !isEqual(
      currentArtistSocialHandles,
      updateArtistDataSocialHandles,
    );

    const updateArtistSettingsGAEvent: GA4UpdateArtistSettingsTrackingEventConfig =
      {
        eventName: GA4ArtistEventNames.updateArtistSettings,
        eventParams: {
          product: AppName.backstageMarketing,
          subProduct: GA4SubProducts.settings,
          workspaceId: currentArtist.workspaceId,
          artistId: currentArtist.id,
          artistUrls: !isEqual(
            normalizedCurrentArtistStoreProfileLinks,
            updateArtistData.data.storeProfileLinks,
          ),
          officialPlaylists: !isEqual(
            normalizedCurrentArtistStoreOfficialPlaylists,
            updateArtistData.data.storeOfficialPlaylists,
          ),
          subDomain:
            currentArtist.data.subDomain !== updateArtistData.data.subDomain,
          artistSocialHandles: hasArtistSocialHandlesChanged,
        },
      };

    sendGA4TrackingEvent(updateArtistSettingsGAEvent);
  };

  return { sendUpdateArtistProfileGA4TrackingEvent };
};
