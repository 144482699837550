import config from 'shared/config';

import { BacklinkSettingsData } from '../backlinkSettings/backlinkSettings.types';
import { VALID_SUBDOMAIN_REGEXP } from './backlink.constants';

const clearHttps = (url: string) => url.replace(/^https:\/\//, '');

export const BASE_BACKLINK_URL_WITHOUT_HTTPS = clearHttps(
  config.backlinkBaseUrl,
);

/**
 * format domain to user : enrich domain to display a full url
 */
export const getDomainLabel = (subDomain: string) => {
  // getBacklinkUrl used with subDomain only to display domain url
  return (
    clearHttps(getBacklinkUrl(subDomain)) || BASE_BACKLINK_URL_WITHOUT_HTTPS
  );
};

export const getBacklinkUrl = (subDomain: string, slug?: string) => {
  let url = clearHttps(config.backlinkBaseUrl);
  if (subDomain) url = `${subDomain}.${url}`;
  if (slug) url = `${url}/${slug}`;

  return `https://${url}`;
};

export const getBacklinkUrlFromSettingsData = (
  backlinkSettingsData?: BacklinkSettingsData,
) => {
  return backlinkSettingsData
    ? getBacklinkUrl(
        backlinkSettingsData.sharingStep.url?.subDomain || '',
        backlinkSettingsData.sharingStep.url?.slug,
      )
    : '';
};

export const validateDomain = (domain) => {
  if (!domain) return false;
  if (!domain.includes(BASE_BACKLINK_URL_WITHOUT_HTTPS)) return false;
  let subDomain = domain.replace(BASE_BACKLINK_URL_WITHOUT_HTTPS, '');
  subDomain = subDomain.replace(/\.$/, ''); // remove potential trailing dot (heuss.bfan.link -> heuss. -> heuss)
  if (!subDomain || !subDomain.length) return true;
  if (VALID_SUBDOMAIN_REGEXP.test(subDomain)) return true;
  return false;
};
