import styled from '@emotion/styled';

export const Container = styled.div`
  > *  {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StoreCardsContainer = styled.div`
  > *  {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
