import React from 'react';

import styled from '@emotion/styled';

import { FormLabel, FormLabelProps } from '@mui/material';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled((props) => (
  <FormLabel component="legend" {...props} />
))<FormLabelProps>`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
