import { getBacklinkSettingsEditorStepsConfig } from '../config';

/**
 * Can be used to disable specific steps with feature flags
 */
export default function useEditorSteps() {
  const steps = getBacklinkSettingsEditorStepsConfig();

  // Example - Fan Notifications Step
  // const isAutonotifyEnabled = useAppFeatureFlag('fan_notifications_step');
  // if (!isAutonotifyEnabled) {
  //   // @ts-ignore
  //   delete steps.prereleaseAutonotifyStep;
  // }

  return { steps };
}
