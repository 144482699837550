import {
  StoreConfig,
  StoresConfigs,
} from 'shared/entities/storeConfig/storeConfig.types';

import { api } from '../api';

const baseUrl = '/stores';

export const fetchStoresConfigs = async (): Promise<StoresConfigs> => {
  const results = await api.get(`${baseUrl}`);
  return results.data;
};

export const updateStoreConfig = async (
  store: StoreConfig,
): Promise<StoreConfig> => {
  const results = await api.patch(`${baseUrl}/${store.name}`, store);
  return results.data;
};

export const deleteStoreConfig = async (name: string): Promise<StoreConfig> => {
  const results = await api.delete(`${baseUrl}/${name}`);
  return results.data;
};

export const createStoreConfig = async (
  store: StoreConfig,
): Promise<StoreConfig> => {
  const results = await api.post(`${baseUrl}`, store);
  return results.data;
};
