import { LogsInitConfiguration } from '@datadog/browser-logs';

export const datadog: LogsInitConfiguration = {
  env: '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.eu',
  service: 'mkp-backoffice',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  version: process.env.REACT_APP_DATADOG_APP_VERSION || undefined,
};
