import {
  FormFieldName,
  FormFieldsConfigsByLocale,
  FormFieldType,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT } from '../../backlink/backlink.constants';

export const formFieldsDisplayNames = {
  [FormFieldName.Firstname]: 'First name',
  [FormFieldName.Lastname]: 'Last name',
  [FormFieldName.Mobile]: 'Phone number',
  [FormFieldName.Email]: 'Email',
  [FormFieldName.Birthdate]: 'Birth Date',
  [FormFieldName.Address]: 'Address',
  [FormFieldName.City]: 'City',
  [FormFieldName.ZipCode]: 'Zip Code',
  [FormFieldName.Country]: 'Country',
  [FormFieldName.OptinCompetition]: 'Competition',
  [FormFieldName.OptinSubscription]: 'Subscription',
};

/**
 *
 * We want to be able to set some fields as having some other fields as dependent fields
 * meaning they'll be added to the form config if the parent field is added
 *
 * we don't need to include the dependent fields config in the form fields configs
 * as they're not subject to change per backlink or locale
 */
export const dependentFieldsMap = {
  [FormFieldName.Address]: [
    FormFieldName.City,
    FormFieldName.ZipCode,
    FormFieldName.Country,
  ],
};

export const fields: FormFieldsConfigsByLocale = {
  en: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'First Name',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Last Name',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Phone number',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Country',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  fr: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Prénom',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nom',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Numéro de téléphone',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Pays',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  ar: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'الاسم الاول',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'الكنية',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'رقم الهاتف',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'البريد الإلكتروني',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'دولة',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  de: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Vorname',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nachname',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Telefonnummer',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Land',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  es: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nombre',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Apellido(s)',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Número de teléfono',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Correo electrónico',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'País',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  fil: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Pangalan',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Apelyido',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Numero ng telepono',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Bansa',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  hi: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'पहला नाम',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'उपनाम',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'फ़ोन नंबर',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'ईमेल',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'देश',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  id: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nama depan',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nama Belakang',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Nomor Telpon',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Negara',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  it: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nome',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Cognome',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Numero di telefono',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Nazione',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  ja: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'ファーストネーム',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '苗字',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '電話番号',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Eメール',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '国',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  jv: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Jeneng ngarep',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Jeneng mburi',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Nomer telpon',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Negara',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  pl: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Imię',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nazwisko',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Numer telefonu',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'E-mail',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Kraj',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  pt: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nome',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Sobrenome',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Número de telefone',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'E-mail',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'País',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  ru: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Имя',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Фамилия',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Номер телефона',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Email',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Страна',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  su: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nami payun',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nami pengker',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Nomer telepon',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Surélék',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Nagara',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  th: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'ชื่อจริง',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'นามสกุล',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'เบอร์โทรศัพท์',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'อีเมล์',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'ประเทศ',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  tr: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'İsim',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Soyad',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Telefon Numarası',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'E-posta',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Ülke',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  uk: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: "Ім'я",
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Прізвище',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Номер телефону',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'Електронна пошта',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Країна',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  vi: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Tên đầu tiên',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Họ',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Số điện thoại',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'E-mail',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Quốc gia',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  'zh-Hans': {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: '名字',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '姓氏',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '电话号码',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: '电子邮件',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '国家',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  'zh-Hant': {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: '名字',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '姓氏',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '電話號碼',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: '電子郵件',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '國家',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  'zh-sg': {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: '名字',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '姓氏',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '电话号码',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: '电子邮件',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '国家',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  'zh-tw': {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: '名字',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '姓氏',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '电话号码',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: '电子邮件',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '国家',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  zh: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: '名字',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: '姓氏',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: '电话号码',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: '电子邮件',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: '国家',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
  ms: {
    [FormFieldName.Firstname]: {
      name: FormFieldName.Firstname,
      type: FormFieldType.Text,
      label: 'Nama pertama',
      required: false,
    },
    [FormFieldName.Lastname]: {
      name: FormFieldName.Lastname,
      type: FormFieldType.Text,
      label: 'Nama terakhir',
      required: false,
    },
    [FormFieldName.Mobile]: {
      name: FormFieldName.Mobile,
      type: FormFieldType.Tel,
      label: 'Nombor telefon',
      required: true,
    },
    [FormFieldName.Email]: {
      name: FormFieldName.Email,
      type: FormFieldType.Email,
      label: 'E-mel',
      required: true,
    },
    [FormFieldName.Address]: {
      name: FormFieldName.Address,
      type: FormFieldType.Text,
      label: 'Address',
      required: false,
    },
    [FormFieldName.City]: {
      name: FormFieldName.City,
      type: FormFieldType.Text,
      label: 'City',
      required: false,
    },
    [FormFieldName.ZipCode]: {
      name: FormFieldName.ZipCode,
      type: FormFieldType.Text,
      label: 'ZipCode',
      required: false,
    },
    [FormFieldName.Country]: {
      name: FormFieldName.Country,
      type: FormFieldType.Country,
      label: 'Negara',
      required: false,
    },
    [FormFieldName.Birthdate]: {
      name: FormFieldName.Birthdate,
      type: FormFieldType.Date,
      label: 'Birthdate',
      required: false,
    },
  },
};

export const optins: FormFieldsConfigsByLocale = {
  en: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: 'I agree to the Sweepstakes <a href="/?screen=rules">T&Cs.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
            <li>
              1 prize of rank 1: [Description] - [Value]
            </li>
            <li>
              1 prize of rank 2: [Description] - [Value]
            </li>
            <li>
              1 prize of rank 3: [Description] - [Value]
            </li>
          </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Send me future news and offers of {artistName} from Believe. I am over 16 y.o.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  fr: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'J\'accepte le <a href="/?screen=rules">Règlement</a> du jeu-concours.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: 15,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 prix de rang 1 : [Description] - [Valeur]
             </li>
             <li>
               1 prix de rang 2 : [Description] - [Valeur]
             </li>
             <li>
               1 prix de rang 3 : [Description] - [Valeur]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        "J'accepte de recevoir news et offres de {artistName} par Believe. J'ai plus de 15 ans.",
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  ar: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'أوافق على <a href="/؟screen=rules"> بنود وشروط مسابقة يانصيب. </a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               الجائزة الأولى من المرتبة 1: [الوصف] - [القيمة]
             </li>
             <li>
               الجائزة الأولى من المرتبة 2: [الوصف] - [القيمة]
             </li>
             <li>
               الجائزة الأولى من الرتبة 3: [الوصف] - [القيمة]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'أرسل لي الأخبار والعروض المستقبلية لـ {artistName} من Believe. عمري أكثر من 16 سنة',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  de: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Ich stimme den <a href="/?screen=rules">Geschäftsbedingungen</a> zu.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 Preis von Rang 1: [Beschreibung] - [Wert]
             </li>
             <li>
               1 Preis von Rang 2: [Beschreibung] - [Wert]
             </li>
             <li>
               1 Preis von Rang 3: [Beschreibung] - [Wert]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Ich bin damit einverstanden, Neuigkeiten und Angebote über {artistName} zu erhalten. Ich bin über 16 Jahre alt.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  es: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Acepto los <a href="/?screen=rules">Términos y condiciones</a> de esta activación.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 premio de rango 1: [Descripción] - [Valor]
             </li>
             <li>
               1 premio de rango 2: [Descripción] - [Valor]
             </li>
             <li>
               1 premio de rango 3: [Descripción] - [Valor]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Acepto recibir noticias y ofertas sobre {artistName}. Tengo más de 14 años.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  fil: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Sumasang-ayon ako sa <a href="/?screen=rules">Mga Tuntunin at Kundisyon ng Mga Sweepstake</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 gantimpala ng ranggo 1: [Paglalarawan] - [Halaga]
             </li>
             <li>
               1 gantimpala ng ranggo 2: [Paglalarawan] - [Halaga]
             </li>
             <li>
               1 gantimpala ng ranggo 3: [Paglalarawan] - [Halaga]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Magpadala sa akin ng mga darating na balita at alok ng {artistName} mula sa Believe. Lampas na ako sa 16 y.o.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  hi: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'मैं स्वीपस्टेक्स <a href="/?screen=rules">नियम और शर्तों</a> से सहमत हूं',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               रैंक 1 का पहला पुरस्कार: [विवरण] - [मूल्य]
             </li>
             <li>
               रैंक 2 का पहला पुरस्कार: [विवरण] - [मूल्य]
             </li>
             <li>
               रैंक 3 का पहला पुरस्कार: [विवरण] - [मूल्य]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Believe से मुझे भविष्य के समाचार और {artistName} के प्रस्ताव भेजें। मैं 16 वर्ष से अधिक का हूं।',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  id: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Saya setuju dengan <a href="/?screen=rules">Syarat & Ketentuan</a>.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 hadiah peringkat 1: [Deskripsi] - [Nilai]
             </li>
             <li>
               1 hadiah peringkat 2: [Deskripsi] - [Nilai]
             </li>
             <li>
               1 hadiah peringkat 3: [Deskripsi] - [Nilai]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Saya sudah berusia di atas 18 tahun, dan setuju untuk dikirimkan berita dan penawaran terkait {artistName}',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  it: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Accetto i <a href="/?screen=rules">Termini e condizioni</a> della lotteria.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 premio di grado 1: [Descrizione] - [Valore]
             </li>
             <li>
               1 premio di grado 2: [Descrizione] - [Valore]
             </li>
             <li>
               1 premio di grado 3: [Descrizione] - [Valore]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Accetto di ricevere notizie e offerte su {artistName}. Ho più di 14 anni.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  ja: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: 'コンテストの<a href="/?screen=rules">ルール</a>に同意します。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               ランク1の1つの賞：[説明]-[値]
             </ li>
             <li>
               ランク2の1つの賞：[説明]-[値]
             </ li>
             <li>
               ランク3の1つの賞：[説明]-[値]
             </ li>
           </ ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        '{artistName}に関するニュースやオファーを受け取ることに同意します。 私は18歳以上です。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  jv: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Aku setuju karo Kontes <a href="/?screen=rules">Syarat & Ketentuan.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 hadiah rangking 1: [Katrangan] - [Nilai]
             </li>
             <li>
               1 hadiah rangking 2: [Katrangan] - [Nilai]
             </li>
             <li>
               1 hadiah rangking 3: [Katrangan] - [Nilai]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Kirimake kabar lan tawaran saka {artistName} saka Believe. Aku luwih saka 16 y.o.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  pl: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: 'Akceptuję <a href="/?screen=rules">Zasady</a> konkursu.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 nagroda rangi 1: [Opis] - [Wartość]
             </li>
             <li>
               1 nagroda rangi 2: [Opis] - [Wartość]
             </li>
             <li>
               1 nagroda rangi 3: [Opis] - [Wartość]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Wyrażam zgodę na otrzymywanie informacji i ofert dotyczących {artistName}. Mam ponad 16 lat.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  pt: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Estou de acordo com <a href="/?screen=rules">Termos & Condições</a>.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 prêmio de classificação 1: [Descrição] - [Valor]
             </li>
             <li>
               1 prêmio de classificação 2: [Descrição] - [Valor]
             </li>
             <li>
               1 prêmio da classificação 3: [Descrição] - [Valor]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Concordo em receber notícias e ofertas sobre {artistName}. Tenho mais de 13 anos de idade.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  ru: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Я согласен с <a href="/?screen=rules">Условиями использования</a> конкурса.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 приз ранга 1: [Описание] - [Значение]
             </li>
             <li>
               1 приз 2-го ранга: [Описание] - [Значение]
             </li>
             <li>
               1 приз 3-го ранга: [Описание] - [Значение]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Я согласен (-сна) получать новости и предложения об артисте {artistName}. Подтверждаю, что я старше 18 лет.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  su: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Kuring satuju kana Sweepstakes <a href="/?screen=rules">Sarat & Kaayaan.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 hadiah pangkat 1: [Pedaran] - [Nilai]
             </li>
             <li>
               1 hadiah pangkat 2: [Pedaran] - [Nilai]
             </li>
             <li>
               1 hadiah pangkat 3: [Pedaran] - [Nilai]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Kirimkeun ka kuring warta sareng tawaran ti {artistName} ti Believe. Abdi langkung ti 16 y.o.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  th: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        '<a href="/?screen=rules">ฉันยอมรับข้อกำหนดและเงื่อนไขของเกมส์</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               รางวัลที่ 1 อันดับ 1: [รายละเอียด] - [มูลค่า]
             </li>
             <li>
               รางวัลที่ 1 อันดับ 2: [รายละเอียด] - [มูลค่า]
             </li>
             <li>
               รางวัลที่ 1 อันดับ 3: [รายละเอียด] - [มูลค่า]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: 'รับข่าวสารของ {artistName} ฉันอายุมากกว่า 18 ปี',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  tr: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Yarışmanın <a href="/?screen=rules">Kurallarını</a> kabul ediyorum.',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1. seviye 1 ödül: [Açıklama] - [Değer]
             </li>
             <li>
               1. seviye 2 ödülü: [Açıklama] - [Değer]
             </li>
             <li>
               1. derece 3 ödülü: [Açıklama] - [Değer]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        '{artistName} ile ilgili haberleri ve teklifleri almayı kabul ediyorum. 18 yaşının üzerindeyim',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  uk: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Я погоджуюся з <a href="/?screen=rules">Умовами розіграшу лотерей.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 приз рангу 1: [Опис] - [Значення]
             </li>
             <li>
               1 приз 2-го рангу: [Опис] - [Значення]
             </li>
             <li>
               1 приз 3-го рангу: [Опис] - [Значення]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Надсилайте мені майбутні новини та пропозиції від {artistName} від Believe. Мені більше 16 років.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  vi: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Tôi đồng ý với <a href="/?screen=rules">Điều khoản & Điều kiện của Rút thăm trúng thưởng.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 giải hạng 1: [Mô tả] - [Giá trị]
             </li>
             <li>
               1 giải hạng 2: [Mô tả] - [Giá trị]
             </li>
             <li>
               1 giải hạng 3: [Mô tả] - [Giá trị]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Gửi cho tôi tin tức trong tương lai và các ưu đãi của {artistName} từ Believe. Tôi trên 16 tuổi.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  'zh-Hans': {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: '我接受比赛的<a href="/?screen=rules">规则</a>。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               一等奖1个：【说明】-【价值】
             </li>
             <li>
               二等奖1名：【说明】-【价值】
             </li>
             <li>
               三等奖1个：【说明】-【价值】
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: '我同意收到{artistName}的最新动态以及优惠活动，我已年满 14 周岁。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  'zh-Hant': {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: '我接受比賽的<a href="/?screen=rules">規則</a>。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               一等獎1個：【說明】-【價值】
             </li>
             <li>
               二等獎1名：【說明】-【價值】
             </li>
             <li>
               三等獎1個：【說明】-【價值】
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: '我同意收到{artistName}的最新動態以及優惠活動，我已年滿 14 周歲。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  'zh-sg': {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: '我接受比赛的<a href="/?screen=rules">规则</a>。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               一等奖1个：【说明】-【价值】
             </li>
             <li>
               二等奖1名：【说明】-【价值】
             </li>
             <li>
               三等奖1个：【说明】-【价值】
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: '我同意收到{artistName}的最新动态以及优惠活动，我已年满 14 周岁。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  'zh-tw': {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: '我接受比赛的<a href="/?screen=rules">规则</a>。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               一等奖1个：【说明】-【价值】
             </li>
             <li>
               二等奖1名：【说明】-【价值】
             </li>
             <li>
               三等奖1个：【说明】-【价值】
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: '我同意收到{artistName}的最新动态以及优惠活动，我已年满 14 周岁。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  zh: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label: '我接受比赛的<a href="/?screen=rules">规则</a>。',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               一等奖1个：【说明】-【价值】
             </li>
             <li>
               二等奖1名：【说明】-【价值】
             </li>
             <li>
               三等奖1个：【说明】-【价值】
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label: '我同意收到{artistName}的最新动态以及优惠活动，我已年满 14 周岁。',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
  ms: {
    [FormFieldName.OptinCompetition]: {
      name: FormFieldName.OptinCompetition,
      type: FormFieldType.Checkbox,
      label:
        'Saya bersetuju dengan <a href="/?screen=rules">Terma dan Syarat Undian.</a>',
      required: true,
      metas: {
        startDate: new Date().toISOString(),
        endDate: '',
        minimumAge: null,
        territory: null,
        winnersCount: BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
        prizes: `<ul>
             <li>
               1 hadiah pangkat 1: [Penerangan] - [Nilai]
             </li>
             <li>
               1 hadiah pangkat 2: [Penerangan] - [Nilai]
             </li>
             <li>
               1 hadiah pangkat 3: [Penerangan] - [Nilai]
             </li>
           </ul>`,
      },
    },
    [FormFieldName.OptinSubscription]: {
      name: FormFieldName.OptinSubscription,
      type: FormFieldType.Checkbox,
      label:
        'Hantarkan kepada saya berita dan tawaran masa depan {artistName} dari Believe. Saya berumur lebih dari 16 tahun.',
      required: false,
      metas: {
        consentToNews: true,
        consentToOffers: true,
      },
    },
  },
};
