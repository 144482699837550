import { isValid, Alpha2Code } from 'i18n-iso-countries';

import { DEFAULT_LOCALE } from 'shared/i18n/constants';

const localeCodeToCountryCode: Record<
  string,
  Partial<Lowercase<Alpha2Code>>
> = {
  en: 'gb',
  fil: 'ph',
  hi: 'in',
  jv: 'my',
  ja: 'jp',
  ms: 'bn',
  qc: 'ca',
  su: 'my',
  uk: 'ua',
  vi: 'vn',
  zh: 'cn',
  'zh-hans': 'cn',
  'zh-hant': 'cn',
  'zh-tw': 'cn',
  'zh-sg': 'cn',
};

const localeCodeToCountryCodeForFlag: Record<string, string> = {
  en: 'gb',
  fil: 'ph',
  hi: 'in',
  jv: 'my',
  ja: 'jp',
  ms: 'bn',
  qc: 'ca',
  re: 'fr',
  su: 'my',
  uk: 'ua',
  vi: 'vn',
  zh: 'cn',
  'zh-hans': 'cn',
  'zh-hant': 'cn',
  'zh-tw': 'cn',
  'zh-sg': 'cn',
};

const localeCodeToSupportedLocalesByLib: Record<string, string> = {
  qc: 'fr',
  re: 'fr',
  zh: 'cn',
  'zh-hans': 'cn',
  'zh-hant': 'cn',
  'zh-tw': 'cn',
  'zh-sg': 'cn',
};

/**
 * Given an ISO 639-1 code (locale code),
 * returns an ISO 3166-1 (country code)
 *
 * As locale codes mainly match country codes,
 * the default case is to return the entry code
 */
export const getCountryCodeFromLocale = (
  localeCode: string | undefined = DEFAULT_LOCALE,
) => {
  return localeCodeToCountryCode[localeCode.toLowerCase()] || localeCode;
};

/**
 * Given an ISO 639-1 code (locale code),
 * returns an ISO 3166-1 (country code) to display flag
 */
export const getCountryCodeForFlagFromLocale = (localeCode?: string) => {
  // Flag from @believe-front/react-components does not allow undefined
  if (!localeCode) return '';

  return localeCodeToCountryCodeForFlag[localeCode.toLowerCase()] || localeCode;
};

/**
 * Given an ISO 639-1 code (locale code),
 * returns an ISO 639-1 code (locale code) supported by i18n-iso-countries
 * full list: https://www.npmjs.com/package/i18n-iso-countries#supported-languages-iso-639-1
 */
export const getSafeLocaleForI18nCountriesLib = (
  localeCode: string | undefined = DEFAULT_LOCALE,
) => {
  if (isValid(localeCode)) return localeCode;

  return localeCodeToSupportedLocalesByLib[localeCode.toLowerCase()] ?? 'en';
};
