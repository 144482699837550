import styled from '@emotion/styled';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

export const Container = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

Container.defaultProps = {
  container: true,
};

export const LeftColumn = styled(Grid)`
  padding-right: ${({ theme }) => theme.spacing(0.25)};
`;

LeftColumn.defaultProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const ProfilePictureLabel = styled(FormLabel)`
  font-size: 13px;
`;
