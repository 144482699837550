import React from 'react';

import { CONFIG_ENV } from 'shared/config';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

/** Microsoft Clarity - analytic heatmap */
function useClarity() {
  const clarityId =
    getAppName() === AppName.backstageMarketing ? 'dkbvqrd2zv' : 'd8llc82oll';

  // enabled on every env except in local
  const isClarityEnabled = CONFIG_ENV !== 'development';

  React.useEffect(() => {
    if (!isClarityEnabled || window.Cypress) {
      return;
    }

    (function (c, l, a, r, i, t: any, y: any) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
      return '';
    })(window, document, 'clarity', 'script', clarityId, null, null);
  }, [isClarityEnabled, clarityId]);
}

export default useClarity;
