import React from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import FieldSet from 'shared/components/FieldSet';

import { useValidationContext } from '../../hooks/useValidation';
import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import { EditorRichTextField } from '../fields/EditorRichTextField';
import { EditorSwitch } from '../fields/EditorSwitch';
import EditorTextField from '../fields/EditorTextField';
import {
  EditorCTAsManager,
  makeCTAsErrorsMap,
} from '../managers/EditorCTAsManager';
import EditorLocaleManager from '../managers/EditorLocaleManager';
import { PostreleaseLandingStepProps } from './types';

import { FieldRow, EditorFormLabel } from '../fields/styled';
import { StepContent } from './styled';

export const PostreleaseLandingStep: React.FC<
  PostreleaseLandingStepProps
> = () => {
  const { t } = useTranslation();

  const { stepKey, getFieldProps, currentLocale } =
    useBacklinkSettingsDataEditorContext();
  const { errors } = useValidationContext();

  const richTextPath = `${stepKey}.locale.${currentLocale}.richText`;

  const showArtworkPath = `${stepKey}.showArtwork`;
  const { value: showArtwork = true } = getFieldProps(showArtworkPath);

  const showArtistPath = `${stepKey}.showArtist`;
  const { value: showArtist = true } = getFieldProps(showArtistPath);

  const showVideoProps = getFieldProps(`${stepKey}.showVideo`);
  const youtubeVideoIdProps = getFieldProps(`${stepKey}.youtubeVideoId`);

  /**
   * Call To Actions per Store
   */
  const CTAsPath = `${stepKey}.locale.${currentLocale}.callToActions`;
  const { value: CTAs, changeHandler: CTAsChangeHandler } = getFieldProps(
    CTAsPath,
    { replace: true },
  );
  const CTAsErrors = makeCTAsErrorsMap(
    CTAsPath,
    CTAs.stores.displayOrder,
    errors,
  );

  return (
    <>
      <StepContent>
        <EditorLocaleManager />
        <FieldSet
          css={(theme) => css`
            margin-top: ${theme.spacing(2.5)};
          `}
          variant="condensed"
          title={t(
            'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.fieldset.promote_release.title',
            'Tease your release',
          )}
        >
          <FieldRow
            css={css`
              margin-bottom: 0;
            `}
          >
            <EditorFormLabel id="show-release-artwork-on-smartphones-label">
              <strong>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.field.show_artwork.label',
                  'Show artwork',
                )}
              </strong>
            </EditorFormLabel>
            <EditorSwitch
              {...getFieldProps(showArtworkPath)}
              value={showArtwork}
              inputProps={{
                'aria-labelledby': 'show-release-artwork-on-smartphones-label',
              }}
            />
          </FieldRow>
          <FieldRow
            css={css`
              margin-top: 0;
              margin-bottom: 0;
            `}
          >
            <EditorFormLabel id="show-artist-widget-label">
              <strong>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.field.show_artist.label',
                  'Show artist',
                )}
              </strong>
            </EditorFormLabel>
            <EditorSwitch
              {...getFieldProps(showArtistPath)}
              value={showArtist}
              inputProps={{
                'aria-labelledby': 'show-artist-widget-label',
              }}
            />
          </FieldRow>
          <FieldRow
            css={css`
              margin-top: 0;
              margin-bottom: 0;
            `}
          >
            <EditorFormLabel id="show-music-video-label">
              <strong>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.field.show_video.label',
                  'Show music video',
                )}
              </strong>
            </EditorFormLabel>
            <EditorSwitch
              {...showVideoProps}
              changeHandler={(value) => {
                showVideoProps.changeHandler(value);
              }}
              inputProps={{ 'aria-labelledby': 'show-music-video-label' }}
            />
          </FieldRow>
          {showVideoProps.value && (
            <FieldRow
              css={(theme) => css`
                && {
                  margin-top: ${theme.spacing(0.5)};
                }
              `}
            >
              <EditorTextField
                label={t(
                  'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.field.youtube_video_id.label',
                  'YouTube Video Id',
                )}
                name={`${stepKey}.youtubeVideoId`}
                placeholder="E.g. KMU0tzLwhbE"
                {...youtubeVideoIdProps}
                showErrorMessage={true}
                errorMessage={errors[`${stepKey}.youtubeVideoId`]}
              />
            </FieldRow>
          )}
          <FieldRow
            css={(theme) => css`
              && {
                margin-top: ${theme.spacing(2.5)};
                margin-bottom: ${theme.spacing(2.5)};
              }
            `}
          >
            <EditorRichTextField
              label={t(
                'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.field.rich_text.label',
                'Rich Text',
              )}
              key={richTextPath}
              id={richTextPath}
              {...getFieldProps(richTextPath, { replace: true })}
              options={{
                withReplacementTokenSelect: true,
              }}
            />
          </FieldRow>
        </FieldSet>
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.postreleaseLandingStep.form.fieldset.call_to_actions.title',
            'Call to actions',
          )}
          variant="condensed"
        >
          <EditorCTAsManager
            id={CTAsPath}
            key={CTAsPath}
            value={CTAs}
            changeHandler={CTAsChangeHandler}
            errors={CTAsErrors}
          />
        </FieldSet>
      </StepContent>
    </>
  );
};
