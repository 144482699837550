import { useCallback, useRef, useEffect, useReducer } from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { BacklinkSettingsData } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import type { Props as BacklinkSettingsEditorDataProps } from '../index';
import { BacklinkSettingsDataUpdateSettingOptions } from '../state';
import { ActionsTypes } from '../state/actions';
import { reducer } from '../state/reducer';
import { useValidation } from './useValidation';

type Params<T> = {
  initialState: T;
  parentEntityInfos?: BacklinkSettingsEditorDataProps['parentEntityInfos'];
  onStateChange?: (state: T) => void;
};

/**
 * State management for the BacklinkSettingsData Editor
 */
function useBacklinkSettingsDataEditorState({
  initialState,
  parentEntityInfos,
  onStateChange = (state) => state,
}: Params<BacklinkSettingsData>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const parentEntityType = parentEntityInfos?.type;

  /** Validation */
  const validation = useValidation({
    parentEntityType: parentEntityInfos?.type,
  });
  const { validate, clearErrors } = validation;

  useEffect(() => {
    clearErrors();
    validate(state);
  }, [state, clearErrors, validate]);

  const updateSetting = useCallback(
    async (path: string, data: any, options?: any) => {
      dispatch({
        type: ActionsTypes.UpdateSetting,
        payload: { path, data, options },
      });
    },
    [],
  );

  /**
   * returns editor fields props
   * - value
   * - change handler
   * - error
   */
  const getFieldProps = useCallback(
    (
      path: string,
      updateOptions?: BacklinkSettingsDataUpdateSettingOptions,
    ) => {
      const fieldValue = get(state, path);
      return {
        value: fieldValue,
        changeHandler: (value: typeof fieldValue) =>
          updateSetting(path, value, updateOptions),
        error: !!validation.errors[path],
        errorMessage: validation.errors[path],
      };
    },
    [state, updateSetting, validation],
  );

  /**
   * will be used to store the initial editor state
   * so we can compare this 'snapshot' with the current editor state
   * and check whether some changes have been made
   */
  const stateSnapshotRef = useRef(initialState);

  useEffect(() => {
    // sync snapshot with incoming 'new' state
    // note: this new state is the one that we get from the backend
    stateSnapshotRef.current = initialState;
  }, [initialState]);

  /**
   * will be used to store whether the editor has changes
   */
  const hasChangesRef = useRef(false);
  const hasChanges = hasChangesRef.current;

  useEffect(() => {
    onStateChange(state);
    hasChangesRef.current = !isEqual(state, stateSnapshotRef.current);
  }, [state, onStateChange]);

  useEffect(() => {
    dispatch({
      type: ActionsTypes.Init,
      payload: { initialState, parentEntityType },
    });
  }, [initialState, parentEntityType]);

  return {
    state,
    dispatch,
    hasChanges,
    validation,
    getFieldProps,
  };
}

export { useBacklinkSettingsDataEditorState as useBacklinkSettingsEditorState };
