import { StepKey } from '.';
import { ValidationErrors } from '../hooks/useValidation';

/**
 * build a map of stepKeys and errors count for each step that has errors
 * @param errors
 */
function getStepsErrorsCountMap(
  errors: ValidationErrors,
): Record<string, number> {
  const stepsErrorsCount: Record<string, number> = {};
  Object.keys(errors).forEach((errorKey) => {
    const errorPath = errorKey.split('.');
    const stepKey = errorPath[0];
    if (errors[errorKey]) {
      stepsErrorsCount[stepKey] = stepsErrorsCount[stepKey]
        ? stepsErrorsCount[stepKey] + 1
        : 1;
    }
  });
  return stepsErrorsCount;
}

/**
 * indicates whether at least one error has been reported for the given editor step
 * @param stepKey
 * @param errors
 */
export function getStepHasErrors(
  stepKey: StepKey,
  errors: ValidationErrors,
): boolean {
  const stepsErrorsCount = getStepsErrorsCountMap(errors);
  return !!stepsErrorsCount[stepKey];
}
