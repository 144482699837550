import { Artist } from 'shared/entities/artist/artist.types';
import { Product } from 'shared/entities/product/product.types';
import { Release } from 'shared/entities/release/release.types';

export type NewArtist = { id: string; isNew: boolean; newArtistName: string };

export enum ReleaseEditorStep {
  UNINITIALIZED = 'UNINITIALIZED',
  PRODUCT = 'PRODUCT',
  ARTIST = 'ARTIST',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
}

export type ReleaseEditorState = {
  currentStep: ReleaseEditorStep;
  artistQuery: string;
  productQuery: string;
  /** artist selected */
  artist?: Artist | NewArtist;
  /** product selected */
  product?: Product;
  /** set in provider */
  releaseId?: string;
  /** set in provider */
  onReleaseCreated?: (createdRelease: Release) => Promise<void>;
};

export type ReleaseEditorContextProviderProps = {
  children?: React.ReactNode;
  releaseId?: string;
  onReleaseCreated?: (createdRelease: Release) => Promise<void>;
};
