import {
  bottomNavigationActionClasses,
  buttonBaseClasses,
  buttonClasses,
  inputLabelClasses,
  listItemClasses,
  menuItemClasses,
  PaletteColorOptions,
} from '@mui/material';
import * as muiLocales from '@mui/material/locale';
import { createTheme, darken, ThemeOptions } from '@mui/material/styles';

import i18next from 'shared/i18n';

/**
 * a Material UI theme
 * that we'll also be able to use in non Mui components
 */

/** merge original Theme & DeprecatedThemeOptions interface w/ our augmented version */
declare module '@mui/material/styles' {
  interface Theme {
    layout: {
      main: {
        sidebar: {
          spacing: number;
        };
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    layout?: {
      main: {
        sidebar: {
          spacing: number;
        };
      };
    };
  }

  interface Palette {
    analytics: {
      visited: string;
      clickedToStore: string;
      preSaved: string;
      optins: string;
    };
    pastel: { main: string; light: string };
    highlight: string;
    section: {
      backlinks: { main: string; light: string };
      creatives: { main: string; light: string };
      audience: { main: string; light: string };
      players: { main: string };
      playerStats: { main: string };
      ads: { main: string };
      smartDiscovery: { main: string };
    };
  }
  interface PaletteOptions {
    analytics: {
      visited: string;
      clickedToStore: string;
      preSaved: string;
      optins: string;
    };
    warning?: PaletteColorOptions | undefined;
    pastel: { main: string; light: string };
    highlight: string;
    section: {
      backlinks: { main: string; light: string };
      creatives: { main: string; light: string };
      audience: { main: string; light: string };
      players: { main: string };
      playerStats: { main: string };
      ads: { main: string };
      smartDiscovery: { main: string };
    };
  }
}

// TableCell variant prop
declare module '@mui/material/TableCell' {
  interface TableCellPropsVariantOverrides {
    trackpitch: true;
  }
}
/**
 * Custom spacing transform
 */
const spacing = (factor: number) => `${factor * 1.25}rem`;

const primaryColorMain = '#2699FB';

const greyScale = {
  // comments are for reference to the names in Sketch
  100: '#F6F8FF', // Gray 5 #F6F8FF
  300: '#EDEEF5', //  Divider #EDEEF5
  400: '#EBEFFB', // Gray 4 #EBEFFB
  450: '#EEEBFB',
  475: '#DDDDEB',
  480: '#C1CADD', // Gray 7 (Disabled)
  500: '#C1C8DC', // Gray 3 #C1C8DC
  600: '#7C808C', // Gray 2 #7C808C
  700: '#545861', // Gray 1 #545861
  750: '#4E5772',
  800: '#1F1F28', // Dark #1F1F28
} as const;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    // contrastThreshold: 2.9, // we want to keep some text 'light' even as we have lightened the primary color, so we lower the threshold from its default value of 3
    primary: { main: primaryColorMain, light: '#EBF4FF', contrastText: '#fff' },
    secondary: { main: '#FF414E' },
    info: { main: '#777791' },
    success: { main: '#47C2A9', light: '#edfaf0' },
    error: { main: '#FF0055', light: '#ffecee' },
    warning: { main: '#FFAA15', light: '#FFF6EE', contrastText: '#FF8000' },
    grey: greyScale,
    text: {
      primary: greyScale[800],
      secondary: greyScale[600],
    },
    analytics: {
      visited: primaryColorMain,
      clickedToStore: '#A200FF',
      preSaved: '#FF53BF',
      optins: '#FEBA79',
    },
    pastel: { main: '#00AFAF', light: '#bfebea' },
    highlight: '#dce9ff',
    section: {
      backlinks: { main: '#07BEB8', light: '#C6FCFA' },
      creatives: { main: '#906AE5', light: '#B39EE3' },
      audience: { main: '#08415C', light: '#FF9595' },
      players: { main: '#F8C155' },
      playerStats: { main: '#D4B06C' },
      ads: { main: '#D8CFAF' },
      smartDiscovery: { main: '#FF8484' },
    },
  },
  spacing,
  layout: {
    main: {
      sidebar: {
        spacing: 1,
      },
    },
  },
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
    h1: {
      fontSize: '1.75rem',
      lineHeight: '1.5',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '1.334',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1rem',
      lineHeight: '1.0625',
      fontWeight: 600,
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: '1',
      fontWeight: 600,
    },
    h5: {
      fontSize: `${14 / 16}rem`,
      lineHeight: '1.36',
      fontWeight: 700,
    },
    body1: {
      fontSize: `${12 / 16}rem`,
    },
    subtitle1: {
      fontSize: '0.875rem',
      color: greyScale[600],
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiTableCell: {
      variants: [
        {
          props: { variant: 'trackpitch' },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '13px',
            color: greyScale[600],
          },
        },
      ],
    },
  },
});

theme.typography = {
  ...theme.typography,
  body1: {
    ...theme.typography.body1,
    [theme.breakpoints.up('md')]: {
      fontSize: `${14 / 16}rem`,
    },
  },
};

const components: ThemeOptions['components'] = {
  MuiTypography: {
    styleOverrides: {
      subtitle1: {
        small: { fontSize: '0.875em', color: theme.palette.grey[500] },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        borderRadius: 12,
        boxShadow: '0 7px 12px 0 rgba(0,0,0,0.04)',
        padding: spacing(1 / 2),
        marginTop: '10px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[800],
        fontWeight: 600,
        borderRadius: 8,
        [`&.${menuItemClasses.selected}`]: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.grey[100],
        },
        [`&.${menuItemClasses.selected}:hover`]: {
          backgroundColor: theme.palette.grey[100],
        },
        [`&.${menuItemClasses.disabled}`]: {
          opacity: 1,
        },
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.grey[100],
        },
        [theme.breakpoints.down('md')]: {
          borderRadius: 0,
          '&&:hover': {
            background: 'none',
          },
        },
      },
      gutters: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        paddingTop: spacing(0.65),
        paddingBottom: spacing(0.65),
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        [`&.${buttonBaseClasses.disabled}`]: {
          opacity: 1,
          color: theme.palette.grey[500],
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minHeight: '36px',
        borderRadius: '40px',
        fontWeight: 600,
        padding: '6px 16px',
        minWidth: '120px',
        [theme.breakpoints.down('md')]: {
          padding: '6px 14px',
        },
        textTransform: 'none' as const,
        textAlign: 'center',
        lineHeight: 1.4,
        '&, &:hover': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '6px 1.25rem',
        borderWidth: '2px',
        [`&.${buttonClasses.disabled}`]: {
          borderWidth: '2px',
        },
      },
      contained: {
        fontWeight: 600,
      },
      containedInherit: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[100],
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      },
      containedPrimary: {
        '&&, & .MuiSvgIcon-root': {
          color: theme.palette.common.white,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        height: 'auto',
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[800],
        minWidth: 0,
        [`&.${bottomNavigationActionClasses.iconOnly}`]: {
          paddingTop: theme.spacing(1.5),
        },
      },
      label: {
        whiteSpace: 'nowrap' as 'nowrap', // workaround discrepancy between Mui Theme and style-components Theme typings,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.grey[300],
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      square: {
        borderRadius: '5%',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      action: {
        alignSelf: 'center',
        marginTop: 0,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[700],
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        color: theme.palette.grey[700],
        fontWeight: 600,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        '.MuiInputBase-inputTypeSearch + .MuiOutlinedInput-notchedOutline': {
          borderRadius: 18,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.grey[500],
        borderRadius: 10,
        '.MuiInputBase-inputTypeSearch + .MuiOutlinedInput-notchedOutline': {
          borderRadius: 18,
        },
        '& input::placeholder': {
          color: theme.palette.grey[500],
          opacity: 1,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        [`&.${inputLabelClasses.focused}`]: {
          // increase the specificity for the pseudo class
          // see https://github.com/mui-org/material-ui/issues/11244#issuecomment-386792253
          color: darken(theme.palette.primary.main, 0.4),
        },
        [`&.${inputLabelClasses.shrink}`]: {
          color: theme.palette.grey[600],
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        [`&.${listItemClasses.selected}`]: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.grey[100],
        },
        [`&.${listItemClasses.selected}:hover`]: {
          backgroundColor: theme.palette.grey[100],
        },
        [`&.${listItemClasses.disabled}`]: {
          opacity: 1,
        },
      },
      gutters: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
      },
      button: {
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: theme.spacing(2),
        color: 'inherit',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[800],
      },
    },
  },
  MuiTouchRipple: {
    styleOverrides: {
      rippleVisible: {
        backgroundColor: theme.palette.grey[400],
      },
      child: {
        backgroundColor: theme.palette.grey[600],
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: greyScale[700],
        fontWeight: 600,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        borderColor: greyScale[400],
      },
      head: {
        color: greyScale[750],
        textTransform: 'uppercase',
        fontSize: '0.6875rem',
        lineHeight: 1.4,
        fontWeight: 700,
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&:focus': {
          outline: 'none',
        },
        '&.Mui-active': {
          color: greyScale[700],
        },
      },
      icon: {
        '.Mui-active &&': { color: theme.palette.primary.main },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '&& *': { fontWeight: 600 },
      },
      displayedRows: {
        color: greyScale[600],
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '8px !important',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[600],
        fontSize: '0.75rem',
        fontWeight: 600,
        height: '22px',
      },
      label: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  },
};

export const getLocalizedTheme = () => {
  const locale = i18next.language;

  let localizedTheme = createTheme(theme, { components });

  const muiLocaleKey = Object.keys(muiLocales).find((muiLocaleKey) => {
    return muiLocaleKey.match(locale);
  });

  if (muiLocaleKey) {
    localizedTheme = createTheme(localizedTheme, muiLocales[muiLocaleKey]);
  }

  return localizedTheme;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { ...theme, components };
