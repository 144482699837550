import { User } from 'shared/entities/user/user.types';

export type WorkspaceSettingsEmployee = {
  producerIds: string[];
  /**
   * the level of service Believe provides to a Customer
   * will mainly be used to filter track pitches for a User (EMP)
   */
  customerServiceLevels: CustomerServiceLevel[] | null;
  trackPitchTargetMarketIds: string[] | null;
  facebookPixelId?: string;
  tiktokPixelId?: string;
  googleAdsId?: string;
  xandrPixelId?: string;
  privacyPolicyUrl: string;
  competitionRulesTemplate?: string;
  competitionMinimumAge?: number;
  territory?: string;
  legalNoticeUrl: string;
  defaultTimezone: string;
};

export type WorkspaceSettingsCustomer = {
  userLocale: string;
  legalIdentity: string;
  contactEmail: string;
  privacyPolicyUrl: string;
  marketingServices: string;
  defaultTimezone?: string;
  userReturnUrl?: string;
  facebookPixelId?: string;
  googleAdsId?: string;
};

export type BaseWorkspace = {
  useDefaultLinkSettings: boolean;
};

export type WorkspaceEmployee = BaseWorkspace & {
  id: string;
  name: string;
  users: User[];
  defaultBacklinkSettingsId: string;
  settings: WorkspaceSettingsEmployee;
};

export type WorkspaceCustomer = BaseWorkspace & {
  settings: WorkspaceSettingsCustomer;
};

export type Workspace = WorkspaceEmployee | WorkspaceCustomer;

export type WorkspaceLegalCguSettingsCustomer = Omit<
  WorkspaceSettingsCustomer,
  'defaultTimezone'
>;

export const isWorkspaceEmployee = (
  workspace: Workspace,
): workspace is WorkspaceEmployee => {
  return 'id' in workspace;
};

export const isWorkspaceCustomer = (
  workspace: Workspace,
): workspace is WorkspaceCustomer => {
  return !('id' in workspace);
};

/**
 * a level of service for Believe customers
 */
export enum CustomerServiceLevel {
  AS = 'AS', // Artist Services - the more advanced services (Believe direct management of Artists)
  LAS = 'LAS', // Labels & Artists Services
}
