import styled from '@emotion/styled';

import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

export const Tabs = styled(MuiTabs)<{ hideUnderline?: boolean }>`
  position: relative;

  .MuiTabs-indicator {
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: ${({ hideUnderline }) => (hideUnderline ? '0' : '100%')};
    height: 2px;
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

export const Tab = styled(MuiTab)`
  min-width: 0;
  padding: 0 ${({ theme }) => theme.spacing(1)};
  font-size: 12px;
  font-weight: 600;
`;
