import produce from 'immer';

import { AllowedLocale } from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';
import {
  FormFieldConfig,
  FormFieldName,
  FormFieldsConfigsByLocale,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { dependentFieldsMap } from './templates/backlinkFormFields.template';

/**
 * check if a given field has dependentFields
 * if so, check if the dependentFields already are in the fields configs array
 * if not add them to the passed in fields configs array & return a new configs array
 * TODO: check for nested dependentFields
 */
export function addDependentFields({
  fieldConfig,
  fieldsConfigs,
  fieldsConfigsTemplates,
}: {
  fieldConfig: FormFieldConfig;
  fieldsConfigs: FormFieldConfig[];
  fieldsConfigsTemplates: FormFieldsConfigsByLocale[AllowedLocale];
}) {
  const fieldConfigIndex = fieldsConfigs.findIndex(
    (f) => f.name === fieldConfig.name,
  );

  const dependentFieldsConfigs: FormFieldConfig[] = [];

  if (dependentFieldsMap[fieldConfig.name]) {
    dependentFieldsMap[fieldConfig.name].forEach((dependentFieldName) => {
      if (!fieldsConfigs.find((field) => field.name === dependentFieldName)) {
        const fieldConfig = fieldsConfigsTemplates[dependentFieldName];
        if (fieldConfig) {
          dependentFieldsConfigs.push(fieldConfig);
        }
      }
    });
  }

  // add the dependentFields to the fieldsConfigs
  return produce(fieldsConfigs, (draft) => {
    draft.splice(fieldConfigIndex + 1, 0, ...dependentFieldsConfigs);
  });
}

/**
 * check whether a field is dependent on another form configuration field
 */
export function isDependentFromAFieldInFormConfig({
  fieldName,
  fieldsConfigs,
}: {
  fieldsConfigs: FormFieldConfig[];
  fieldName: FormFieldName;
}): boolean {
  return (Object.keys(dependentFieldsMap) as FormFieldName[]).some(
    (dependentFieldsFor) => {
      const dependentFields = dependentFieldsMap[dependentFieldsFor];
      return (
        fieldsConfigs.find((fc) => fc.name === dependentFieldsFor) &&
        dependentFields.includes(fieldName)
      );
    },
  );
}
