import React from 'react';

import { useTranslation } from 'react-i18next';

import FieldSet from 'shared/components/FieldSet';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import EditorBacklinkUrlFields from '../fields/EditorBacklinkUrlFields';
import { EditorImageUploader } from '../fields/EditorImageUploader';
import { EditorTextField } from '../fields/EditorTextField';
import { StepComponentProps } from './index';

import { FieldRow } from '../fields/styled';
import { StepTitle, StepDescription, StepHeader, StepContent } from './styled';

export const SharingStep: React.FC<StepComponentProps> = ({ title }) => {
  const { t } = useTranslation();

  const { stepKey, getFieldProps, parentEntityInfos } =
    useBacklinkSettingsDataEditorContext();

  /** for Backlink */
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : null;

  const backlinkOnly =
    parentEntityInfos && parentEntityInfos.type === 'Backlink';

  const socialCardTitlePath = `${stepKey}.socialCard.title`;
  const socialCardDescriptionPath = `${stepKey}.socialCard.description`;

  const imageIdPath = `${stepKey}.socialCard.imageId`;
  const { value: imageId, changeHandler: imageIdChangeHandler } =
    getFieldProps(imageIdPath);

  return (
    <>
      <StepHeader>
        <StepTitle>{title}</StepTitle>
        <StepDescription>
          {t(
            'ui.component.backlink_settings_data_editor.steps.sharingStep.title',
            'Customize your backlink sharing settings.',
          )}
        </StepDescription>
      </StepHeader>
      <StepContent>
        {backlink?.id && backlink?.artist && (
          <EditorBacklinkUrlFields
            backlinkId={backlink.id}
            artist={backlink.artist}
          />
        )}
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.sharingStep.form.fieldset.social_cards.title',
            'Social cards',
          )}
          description={t(
            'ui.component.backlink_settings_data_editor.steps.sharingStep.form.fieldset.social_cards.description',
            'Configure how your Link looks on social media.',
          )}
          variant="condensed"
        >
          <FieldRow>
            <EditorTextField
              label={t(
                'ui.component.backlink_settings_data_editor.steps.sharingStep.form.field.social_card_title.label',
                'Title',
              )}
              name={socialCardTitlePath}
              {...getFieldProps(socialCardTitlePath)}
              options={{
                withReplacementTokenSelect: true,
              }}
            />
          </FieldRow>
          <FieldRow>
            <EditorTextField
              label={t(
                'ui.component.backlink_settings_data_editor.steps.sharingStep.form.field.social_card_description.label',
                'Description',
              )}
              name={socialCardDescriptionPath}
              {...getFieldProps(socialCardDescriptionPath)}
              options={{
                withReplacementTokenSelect: true,
              }}
            />
          </FieldRow>
          <FieldRow>
            <EditorImageUploader
              value={{
                id: imageId,
              }}
              changeHandler={imageIdChangeHandler}
              disabled={!backlinkOnly}
            />
          </FieldRow>
        </FieldSet>
      </StepContent>
    </>
  );
};
