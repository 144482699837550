import React, { useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash/debounce';

import { Editor } from '@tinymce/tinymce-react';
import 'tinymce';
import { Editor as TinyMCEEditor } from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver';

import { EditorRichTextFieldProps } from '../../types';
import ReplacementTokenSelect from '../ReplacementTokenSelect';

import * as Styled from './styled';

const baseConfig = {
  base_url: '/tinymce', // some tinymce resources are only available via a public url - this sets the base path in the /public folder where they must be placed
  height: 220,
  width: '100%',
  menubar: false,
  plugins: ['link'],
  toolbar: `bold italic | link | removeformat`,
  toolbar_mode: 'wrap',
  branding: false,
  content_style: `body {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
  }`,
  entity_encoding: 'raw',
};

const configs: Record<Required<EditorRichTextFieldProps>['mode'], any> = {
  minimal: {
    ...baseConfig,
    plugins: ['lists code link'],
    toolbar: `bold italic bullist | link | removeformat code`,
  },
  full: {
    ...baseConfig,
    plugins: ['lists code paste link'],
    toolbar: `formatselect link | bold italic forecolor backcolor | link
              undo redo pastetext | alignleft aligncenter alignright alignjustify |
              bullist numlist outdent indent | removeformat code`,
  },
  // Custom mode for the Fan Notification step
  basic: {
    ...baseConfig,
    plugins: ['paste link'],
    toolbar: `formatselect link | bold italic forecolor backcolor removeformat | undo redo pastetext`,
  },
};

export const EditorRichTextField: React.FC<EditorRichTextFieldProps> = ({
  id,
  value,
  changeHandler,
  mode = 'full',
  error,
  options = {
    withReplacementTokenSelect: false,
  },
  disabled = false,
}) => {
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 200),
    [changeHandler],
  );

  const [localValue, setLocalValue] = useState(value);

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleChange = (newValue) => {
    setLocalValue(newValue);
    if (editorRef.current?.isDirty()) {
      debouncedChangeHandler(newValue);
    }
  };

  return (
    <Styled.Container $error={error}>
      <Editor
        id={id}
        textareaName="richTextEditor"
        value={localValue}
        init={configs[mode]}
        onEditorChange={handleChange}
        onInit={(_, editor) => {
          editorRef.current = editor;
        }}
        disabled={disabled}
      />
      {options?.withReplacementTokenSelect && (
        <ReplacementTokenSelect
          onChange={(token) => {
            if (editorRef.current) editorRef.current.insertContent(token);
          }}
          css={css`
            position: absolute;
            bottom: 24px;
            right: 8px;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.04);
            opacity: 0.4;
          `}
        />
      )}
    </Styled.Container>
  );
};

export default EditorRichTextField;
