import styled from '@emotion/styled';

import Avatar from '@mui/material/Avatar';
import { spacing, SpacingProps, sizing, SizingProps } from '@mui/system';

import { transientOptions } from 'shared/utils/emotion-interop';

type Props = SpacingProps &
  SizingProps & {
    /**
     * @deprecated
     * legacy prop
     * used before Mui v5 that added the sx (system) prop support
     */
    $borderRadius?: string;
    /**
     * @deprecated
     * legacy prop
     * used before Mui v5 that added the sx (system) prop support
     */
    $backgroundColor?: string;
  };
export const Thumbnail = styled(Avatar, transientOptions)<Props>`
  ${spacing};
  ${sizing};
  ${({ $borderRadius }) =>
    !!$borderRadius ? `border-radius: ${$borderRadius}` : ''};
  ${({ $backgroundColor }) =>
    !!$backgroundColor ? `background-color: ${$backgroundColor}` : ''};
  background-color: ${({ theme }) => theme.palette.grey[475]};
`;
