import { useEffect, useCallback, useRef } from 'react';

import { useUnleashContext } from '@unleash/proxy-client-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { authUserIsCustomer } from 'shared/entities/auth/auth.types';
import { AppName } from 'shared/types';
import { redirectAfterLoginFail } from 'shared/utils/redirect';

import { useIsAuthenticatedSelector, useIsAdminSelector } from '.';
import { AppNotificationTypes } from '../constants';
import { isAxiosError } from '../entities/api';
import { getMe, logout as logoutFromApi } from '../entities/auth/auth.api';
import { useNotification } from '../hooks/useNotification';
import { useAppContext, ActionsTypes, Actions } from '../state';
import { getAppName } from '../utils/app';

function waitForCypressAUTWindowCallback() {
  return new Promise<void>((resolve) => {
    const poll = () => {
      if (window.cypressAUTWindowReady) {
        resolve();
      } else {
        // Check again after a delay
        setTimeout(poll, 100); // Check every 100ms
      }
    };

    poll();
  });
}

const searchParams = new URLSearchParams(window.location.search);
window.waitForCypressAUTWindowCallback =
  searchParams.get('waitForCypressAUTWindowCallback') === 'true';

const appName = getAppName();

type AuthOptions = {
  makeRequest: boolean;
  postAuthAction?: Actions;
  shouldHandleCustomRedirectAfterLoginFail?: boolean;
};

/**
 * a custom hook to manage Auth processes
 * and get Auth related state values
 */
export const useAuth = (
  options: AuthOptions = {
    makeRequest: false,
    shouldHandleCustomRedirectAfterLoginFail: false,
  },
) => {
  const location = useLocation();
  const { notify } = useNotification();
  const { dispatch } = useAppContext();
  const { i18n } = useTranslation();
  const isAuthenticated = useIsAuthenticatedSelector();
  const isAdmin = useIsAdminSelector();

  // Store value of current url path so we can forward it to backstage when user is not logged
  // We store the value here because when it is detected that user is not logged the
  // pathname has already been changed to /login (on redirectAfterLoginFail call)
  const currentPathname = useRef(location.pathname);

  const {
    shouldHandleCustomRedirectAfterLoginFail,
    makeRequest,
    postAuthAction,
  } = options;

  // using a ref to make sure this function is stable (not recreated on each render)
  // it seemed like the function returned by useUnleashContext was recreated on each render
  // causing the useEffect below to be called twice
  const updateUnleashContextRef = useRef(useUnleashContext());
  const updateUnleashContext = updateUnleashContextRef.current;

  // using a ref to make sure this value is stable (not recreated on each render)
  // this value is used in the useEffect below to avoid calling the requestUserInfos function twice
  const authenticatingRef = useRef(false);

  const requestUserInfos = useCallback(
    async (postAction?: AuthOptions['postAuthAction']) => {
      try {
        dispatch({ type: ActionsTypes.RequestingUserInfos });

        authenticatingRef.current = true;

        const params = Object.fromEntries(new URLSearchParams(location.search));

        if (window.Cypress && window.waitForCypressAUTWindowCallback) {
          await waitForCypressAUTWindowCallback();
        }

        const user = await getMe(params);
        if (!user) return;

        const localeFromUrl = params.locale;
        // change app language using User locale info if available
        if (authUserIsCustomer(user)) {
          // the userLocale from the Believe Backstage user sometimes need to be mapped to a locale the MKP i18n understands
          const userLocale =
            {
              sg: 'ms',
              tw: 'zh-tw',
            }[user.workspace.settings.userLocale] ||
            user.workspace.settings.userLocale ||
            'en';

          // the language in query parameter should override all other set language
          const nextLanguage =
            localeFromUrl ||
            (i18n.options.supportedLngs &&
              i18n.options.supportedLngs.find(
                (supportedLanguage) => supportedLanguage === userLocale,
              )) ||
            userLocale;

          if (nextLanguage !== i18n.language) {
            i18n.changeLanguage(nextLanguage);
          }
        }

        // dispatch user infos
        dispatch({
          type: ActionsTypes.UserInfosReceived,
          payload: user,
        });

        if (postAction) {
          dispatch(postAction);
        }

        // done authenticating
        authenticatingRef.current = false;

        // For marketing suite we use email for feature flag activation
        // and for backstage marketing we use externalId (a.k.a producerId)
        if (
          appName === AppName.marketingSuite &&
          user.hasOwnProperty('email')
        ) {
          updateUnleashContext({
            userId: (user as { email: string }).email,
          });
        } else if (
          appName === AppName.backstageMarketing &&
          user.hasOwnProperty('externalId')
        ) {
          updateUnleashContext({
            userId: (user as { externalId: string }).externalId,
          });
        }
      } catch (error: any) {
        // Special case in which we want to add a pathname into redirect url querystring
        // instead of redirecting user in the axios interceptor we redirect him here
        // Not the best way to handle it I guess
        if (
          error.response?.status === 401 &&
          shouldHandleCustomRedirectAfterLoginFail
        ) {
          await redirectAfterLoginFail(currentPathname.current);
        }

        dispatch({ type: ActionsTypes.UserInfosRequestFailed });

        // only notify of an error if the reason is NOT that we can not be authenticated (401 > unauthorized)
        // as we want to manage the 'not authenticated' case differently > display a message on the Login page ... (?)
        if (isAxiosError(error) && error.response?.status !== 401) {
          notify(
            {
              type: AppNotificationTypes.Error,
              message: error.message,
            },
            error,
          );
        }
      }
    },
    [
      dispatch,
      shouldHandleCustomRedirectAfterLoginFail,
      notify,
      i18n,
      updateUnleashContext,
      location,
    ],
  );

  /** LOGIN */
  useEffect(() => {
    const login = async () => {
      if (makeRequest && !authenticatingRef.current && !isAuthenticated) {
        await requestUserInfos(postAuthAction);
      }
    };
    login();
  }, [makeRequest, postAuthAction, requestUserInfos, isAuthenticated]);

  /** LOGOUT */
  const logout = useCallback(async () => {
    try {
      const logoutResponse = await logoutFromApi();
      if (logoutResponse === 'OK') {
        dispatch({ type: ActionsTypes.authUserHasBeenLoggedOut });
      }
    } catch (error: any) {
      notify(
        {
          type: AppNotificationTypes.Error,
          message: error.message,
        },
        error,
      );
    }
  }, [dispatch, notify]);

  const refreshUser = useCallback(async () => {
    await requestUserInfos();
  }, [requestUserInfos]);

  return {
    isAuthenticated,
    isAdmin,
    logout,
    refreshUser,
  };
};
