import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import {
  FormFieldConfig,
  OptinSubscriptionConfig,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { ValidationErrors } from '../../../hooks/useValidation';

import { FieldRow, EditorFormLabel } from '../../fields/styled';

type Props = {
  fieldConfig: OptinSubscriptionConfig;
  onChange: (fieldConfig: FormFieldConfig) => void;
  errors: ValidationErrors;
};
const OptinSubscriptionMetasFields: React.FC<Props> = ({
  fieldConfig,
  onChange,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FieldRow>
        <EditorFormLabel>
          <strong>
            {t(
              'ui.component.backlink_settings_data_editor.form_field_editor.optin_subscription.subscribe_fans_to',
              'Subscribe fans to:',
            )}
          </strong>
        </EditorFormLabel>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!fieldConfig.metas.consentToNews}
                />
              }
              label={
                t(
                  'ui.component.backlink_settings_data_editor.form_field_editor.optin_subscription.metas.consent_to_news',
                  'News (e.g. New Album, Tour dates, etc.)',
                ) as string
              }
              labelPlacement="end"
              onChange={(event: React.ChangeEvent<{}>, checked: boolean) => {
                onChange({
                  ...fieldConfig,
                  metas: {
                    ...fieldConfig.metas,
                    consentToNews: checked,
                  },
                });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!fieldConfig.metas.consentToOffers}
                />
              }
              label={
                t(
                  'ui.component.backlink_settings_data_editor.form_field_editor.optin_subscription.metas.consent_to_offers',
                  'Special Offers (e.g. Merch sale, discounts, etc.)',
                ) as string
              }
              labelPlacement="end"
              onChange={(event: React.ChangeEvent<{}>, checked: boolean) => {
                onChange({
                  ...fieldConfig,
                  metas: {
                    ...fieldConfig.metas,
                    consentToOffers: checked,
                  },
                });
              }}
            />
          </FormGroup>
        </FormControl>
      </FieldRow>
    </>
  );
};

export default OptinSubscriptionMetasFields;
