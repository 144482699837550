import ReactGA4 from 'react-ga4';

import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { externalKnowledgeBaseDefaultUrl as marketingSuiteKnowledgeBaseDefaultUrl } from 'apps/MarketingSuite/constants';

import {
  GA4MKPProductConfig,
  GA4MKPProductTrackingEvent,
  GA4SubProducts,
  GA4TrackingEvent,
  TrackMenuItemClick,
  GA4GlobalTrackingEventConfig,
} from './analytics.types';

export const sendGA4TrackingEvent = <
  TrackingEvent extends GA4MKPProductTrackingEvent<
    GA4MKPProductConfig<GA4SubProducts>
  >,
>({
  gaTrackers,
  eventName,
  eventParams,
  sendOnlyToFirstGA4Tracker: sendToFirstGA4Tracker = false,
}: GA4TrackingEvent<TrackingEvent>) => {
  const gtagEventParams = sendToFirstGA4Tracker
    ? { ...eventParams, send_to: gaTrackers?.ga4Trackers[0].trackingId }
    : eventParams;
  ReactGA4.gtag('event', eventName, gtagEventParams);
};

/**
 * returns the page name used in Analytics for the given pathname
 */
export function getPageNameForAnalytics(pathname: string) {
  /**
   * a map of routes regexps to page names
   */
  const pathToPageNameMap = new Map([
    [/ad-campaign/, 'Ad Campaign'],
    [/releases$/, 'Releases'],
    [/releases\/[a-z0-9-]+\/creatives\/(.*)$/, 'Creatives Editor'],
    [/resources\/(.*)/, 'Resources'],
    [/releases\/[a-z0-9-]+/, 'Release'],
    [/campaigns\/links$/, 'Links'],
    [/campaigns\/links\/[a-z0-9-]+\/analytics\/[a-z]+/, 'Link Stats'],
    [/campaigns\/links\/[a-z0-9-]+\/edit$/, 'Link Editor'],
    [/settings\/link$/, 'Workspace Links Settings'],
    [/settings\/link\/edit$/, 'Workspace Custom Links Settings'],
    [
      /settings\/artists\/[a-z0-9-]+\/edit\/backlinkSettings\/edit/,
      'Custom Links Settings',
    ],
    [/campaigns\/fans/, 'Fans'],
    [/campaigns\/optimizations/, 'Catalog Optimization'],
    [/settings\/artists/, 'Artists'],
    [/artist\/[a-z0-9-]+\/edit/, 'Artist'],
    [/settings\/legal/, 'Legal'],
    [new RegExp(marketingSuiteKnowledgeBaseDefaultUrl), 'Learn'],
  ]);

  for (const [regex, pageName] of pathToPageNameMap.entries()) {
    if (regex.test(pathname)) {
      return pageName;
    }
  }
}

/**
 * track clicks for a given pathname
 */
export function trackMenuItemClick({
  gaTrackers,
  pathname,
}: TrackMenuItemClick) {
  const pageName = getPageNameForAnalytics(pathname);
  const appName = getAppName() as AppName;

  // only track pathnames that are registered in the getPageNameForAnalytics function
  if (pageName) {
    sendGA4TrackingEvent<GA4GlobalTrackingEventConfig>({
      gaTrackers,
      eventName: 'Menu clicked',
      eventParams: { product: appName, elementTitle: pageName },
    });
  }
}
