import React from 'react';

import produce from 'immer';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { useValidationContext } from 'shared/components/BacklinkSettingsDataEditor/hooks/useValidation';
import { useBacklinkSettingsDataEditorContext } from 'shared/components/BacklinkSettingsDataEditor/state/context';
import FieldSet from 'shared/components/FieldSet';
import {
  authUserIsAdmin,
  authUserIsCustomer,
} from 'shared/entities/auth/auth.types';
import { BACKLINK_OPTIN_COMPETITION_MIN_AGE } from 'shared/entities/backlink/backlink.constants';
import {
  FormFieldConfig,
  FormFieldConfigWithMetas,
  FormFieldName,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { optins as optinsConfigsTemplates } from 'shared/entities/backlinkSettings/templates/backlinkFormFields.template';
import {
  getCompetitionMinimumAge,
  getWorkspaceTerritory,
  useAppContext,
} from 'shared/state';

import EditorRichTextField from '../../fields/EditorRichTextField';
import { EditorSwitch } from '../../fields/EditorSwitch';
import OptinCompetitionMetasFields from './OptinCompetitionMetasFields';
import OptinSubscriptionMetasFields from './OptinSubscriptionMetasFields';

import * as StyledFields from '../../fields/styled';
import * as EditorStyled from '../../styled';

const OptinsManager = () => {
  const { t } = useTranslation();
  const { state: appState } = useAppContext();
  const { editorState } = useBacklinkSettingsDataEditorContext();

  const workspaceTerritory = getWorkspaceTerritory(appState) || '';
  const minimumAge =
    getCompetitionMinimumAge(appState) || BACKLINK_OPTIN_COMPETITION_MIN_AGE;
  const { user } = appState;
  const canEditOptins = authUserIsAdmin(user) || !authUserIsCustomer(user);

  /**
   * Read data from settings
   */
  const { stepKey, getFieldProps, currentLocale } =
    useBacklinkSettingsDataEditorContext();

  const formOptinsPath = `${stepKey}.locale.${currentLocale}.form.optins`;
  const { value: formOptins = [], changeHandler } =
    getFieldProps(formOptinsPath);
  const { errors } = useValidationContext();
  const validationError = errors[formOptinsPath];

  const subscriptionOptinIdx = formOptins.findIndex(
    (optin: FormFieldConfigWithMetas) =>
      optin.name === FormFieldName.OptinSubscription,
  );
  const competitionOptinIdx = formOptins.findIndex(
    (optin: FormFieldConfigWithMetas) =>
      optin.name === FormFieldName.OptinCompetition,
  );

  /**
   * force default values for some fields
   * for instance: the competition optin should be required & have some meta fields set to values we can't set in the 'field template'
   * and need to be saved in the Editor State  when first loading the field and requiring user interaction
   * Note: setting dynamic default values for the editor fields should be done when creating the backlink instead of in the field component like we do here
   */
  function getNewOptinFromTemplate(template: FormFieldConfigWithMetas) {
    return template.name === FormFieldName.OptinSubscription
      ? template
      : {
          ...template,
          required: true,
          metas: {
            ...template.metas,
            startDate: template.metas.startDate || new Date().toISOString(),
            endDate:
              template.metas.endDate ||
              editorState.releaseStep.release.digitalReleaseDate ||
              new Date().toISOString(),
            minimumAge:
              template.metas.minimumAge &&
              template.metas.minimumAge >= minimumAge
                ? template.metas.minimumAge
                : minimumAge,
            territory: template.metas.territory || workspaceTerritory,
          },
        };
  }

  /**
   * Generate props for the editor based on the optin index and its template
   */
  const getOptinEditorProps = (
    index: number,
    template: FormFieldConfigWithMetas,
  ) => ({
    fieldConfig: index >= 0 ? formOptins[index] : undefined,
    path: `${formOptinsPath}.${index}`,
    onChange: (newFieldConfig) =>
      changeHandler(
        produce(formOptins, (draft) => {
          draft[index] = newFieldConfig;
        }),
      ),
    onRemove: () =>
      changeHandler(
        produce(formOptins, (draft) => {
          draft.splice(index, 1);
        }),
      ),
    onAdd: () =>
      changeHandler([...formOptins, getNewOptinFromTemplate(template)]),
  });

  return (
    <>
      {validationError && (
        <Typography color="error" variant="body1">
          {validationError}
        </Typography>
      )}
      <OptinEditor
        key={`${currentLocale}-optin-subscription`}
        {...getOptinEditorProps(
          subscriptionOptinIdx,
          optinsConfigsTemplates[currentLocale]
            .optin_subscription as FormFieldConfigWithMetas,
        )}
        options={{
          useIsRequired: true,
          title: t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.opt-ins.subscription.title',
            'Artist newsletter opt-in',
          ),
          description: t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.opt-ins.subscription.description',
            'Allows you to collect fans emails/phone numbers to send them future news and updates.',
          ),
          legalNotice: t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.opt-ins.subscription.legal-notice',
            'We pre-filled with the minimum legal age and mandatory legal information (who send the mail and what is it about (new, offers…)). You can customise, but be sure to provide these info.',
          ),
        }}
      />
      {canEditOptins && (
        <OptinEditor
          key={`${currentLocale}-optin-competition`}
          {...getOptinEditorProps(
            competitionOptinIdx,
            optinsConfigsTemplates[currentLocale]
              .optin_competition as FormFieldConfigWithMetas,
          )}
          options={{
            useIsRequired: false,
            title: t(
              'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.opt-ins.competition.title',
              'Competition opt-in',
            ),
            description: t(
              'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.opt-ins.competition.description',
              'Organize a contest and customize Terms and Conditions.',
            ),
          }}
        />
      )}
    </>
  );
};

export default OptinsManager;

type OptinEditorProps = {
  fieldConfig: FormFieldConfig | undefined;
  path: string;
  onChange: (newFieldConfig: FormFieldConfig) => void;
  onRemove: () => void;
  onAdd: () => void;
  options: {
    useIsRequired: boolean;
    title: string;
    description: string;
    legalNotice?: string;
  };
};

const OptinEditor = ({
  fieldConfig,
  path,
  onChange,
  onRemove,
  onAdd,
  options: { useIsRequired, title, description, legalNotice },
}: OptinEditorProps) => {
  const { t } = useTranslation();

  const { errors } = useValidationContext();
  const fieldErrors = Object.keys(errors).reduce((accu, errorKey) => {
    if (errorKey.match(path)) {
      const fieldErrorKey = errorKey.split(`${path}.`)[1];
      accu[fieldErrorKey] = errors[errorKey];
    }
    return accu;
  }, {});

  return (
    <FieldSet
      title={title}
      description={description}
      variant="condensed"
      actions={
        <EditorSwitch
          value={typeof fieldConfig !== 'undefined'}
          changeHandler={(newValue: boolean) =>
            newValue ? onAdd() : onRemove()
          }
          inputProps={{
            'aria-label': `Toggle ${title}`,
          }}
        />
      }
    >
      {typeof fieldConfig !== 'undefined' ? (
        <>
          {useIsRequired && (
            <StyledFields.FieldRow>
              <StyledFields.EditorFormLabel>
                <Trans i18nKey="ui.component.backlink_settings_data_editor.form_field_editor.required_field_switch.label">
                  <strong>Required</strong>
                  <small>
                    Activate only for fields vital to your activity.
                  </small>
                </Trans>
              </StyledFields.EditorFormLabel>
              <EditorSwitch
                value={fieldConfig.required}
                changeHandler={(value: boolean) =>
                  onChange({ ...fieldConfig, required: value })
                }
                inputProps={{
                  'aria-label': t(
                    'ui.component.backlink_settings_data_editor.form_field_editor.required_field_switch.a11y.label',
                    'Required',
                  ),
                }}
              />
            </StyledFields.FieldRow>
          )}
          <StyledFields.FieldRow>
            <EditorRichTextField
              mode="minimal"
              value={fieldConfig.label}
              changeHandler={(value: string) =>
                onChange({ ...fieldConfig, label: value })
              }
              error={Boolean(fieldErrors['label'])}
              options={{
                withReplacementTokenSelect: true,
              }}
            />
          </StyledFields.FieldRow>
          {legalNotice && (
            <EditorStyled.InfoMessage>{legalNotice}</EditorStyled.InfoMessage>
          )}
          {fieldConfig.name === FormFieldName.OptinSubscription &&
            fieldConfig.metas && (
              <OptinSubscriptionMetasFields
                fieldConfig={fieldConfig}
                onChange={onChange}
                errors={fieldErrors}
              />
            )}
          {fieldConfig.name === FormFieldName.OptinCompetition &&
            fieldConfig.metas && (
              <OptinCompetitionMetasFields
                fieldConfig={fieldConfig}
                onChange={onChange}
                errors={fieldErrors}
              />
            )}
        </>
      ) : null}
    </FieldSet>
  );
};
