import React from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

import Switcher from 'shared/components/Switcher';

import { BacklinkSettingsEditorStepConfig } from '../../../state';
import { useActiveStep } from './useActiveStep';

const styles = {
  paper: (theme) => css`
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    border-radius: 0;
    border-top: solid 1px ${theme.palette.grey[300]};
  `,
  switcher: css`
    border: none;
    border-radius: 0;
    width: 95%;
    height: 100%;
  `,
};

type Props = {
  steps: BacklinkSettingsEditorStepConfig[];
};

export const EditorStepSwitcher: React.FC<Props> = ({ steps }) => {
  const { t } = useTranslation();

  const { activeStep, navigate } = useActiveStep(steps);

  const onPrevButtonClick = () => {
    navigate('prev');
  };
  const onNextButtonClick = () => {
    navigate('next');
  };

  const stepTitle = `${
    activeStep?.category !==
      t('ui.component.backlink_settings_data_editor.category.setup') &&
    activeStep?.category !==
      t('ui.component.backlink_settings_data_editor.category.general')
      ? `${activeStep?.category} `
      : ''
  }${activeStep?.title}`;

  return (
    <Paper elevation={2} css={styles.paper}>
      <Switcher
        css={styles.switcher}
        onPrevButtonClick={onPrevButtonClick}
        onNextButtonClick={onNextButtonClick}
        label={stepTitle}
      />
    </Paper>
  );
};
