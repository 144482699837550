import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button, TextField } from '@mui/material';

import { useBacklinkSettingsDataEditorContext } from 'shared/components/BacklinkSettingsDataEditor/state/context';
import { AppNotificationTypes } from 'shared/constants';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useSendTestMail } from 'shared/hooks/entities/autonotify';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';
import i18next from 'shared/i18n/index';
import { buildImageUrl } from 'shared/services/image.service';

const getSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email(
        i18next.t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.fields.email.validation.email_invalid',
          'Please enter a valid email address.',
        ),
      )
      .required(),
  });

type FormValues = {
  email: string;
};

const SendTestMailForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(getSchema()),
  });

  const { parentEntityInfos, editorState, currentLocale } =
    useBacklinkSettingsDataEditorContext();
  const backlinkEntity = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : null;
  const artworkId = editorState.designStep.artworkId;
  const releaseId = backlinkEntity?.releaseId || '';
  const { data: release } = useAppQuery('release', {
    fetcherArgs: [releaseId],
    refetchOnWindowFocus: false,
    enabled: !!releaseId,
  });

  const { sendTestMail, isSending } = useSendTestMail();
  const { notify } = useNotification();

  function onSubmit(data: FormValues) {
    if (!release || !backlinkEntity || !backlinkEntity.id) {
      console.error('Error while trying to send test mail: missing data', {
        release,
        backlinkEntity,
      });
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.notifications.error',
          {
            defaultValue:
              'An error occurred while sending the test email to {{sendTo}}',
            sendTo: data.email,
          },
        ),
      });
      return;
    }

    sendTestMail({
      releaseData: {
        releaseCover: artworkId && buildImageUrl({ imageId: artworkId }),
        artistName: release?.artistName as string,
        releaseName: editorState.releaseStep.release?.title as string,
        digitalReleaseDate: editorState.releaseStep.release
          ?.digitalReleaseDate as string,
        digitalReleaseDateFull: editorState.releaseStep.release
          ?.digitalReleaseDate as string,
      },
      emailData: editorState.prereleaseAutonotifyStep.locale[currentLocale],
      stores: editorState.prereleaseAutonotifyStep.stores,
      sendTo: data.email,
      backlinkId: backlinkEntity.id,
      locale: currentLocale,
      selectedTimezone: editorState.releaseStep.release.selectedTimezone,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id="email-address"
        variant="outlined"
        fullWidth
        label={t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.fields.email.label',
          'Email address',
        )}
        placeholder={t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.fields.email.placeholder',
          'E.g. john@gm...',
        )}
        error={!!errors.email}
        helperText={errors.email?.message}
        InputLabelProps={{ shrink: true }}
        sx={{ marginBottom: 1 }}
        {...register('email', { required: true })}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSending}
      >
        {t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.buttons.submit.label',
          'Send test email',
        )}
      </Button>
    </form>
  );
};

export default SendTestMailForm;
