export function downloadFileAtUrl(url: string) {
  var element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', url);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export async function downloadFileFromBinaryResponse(
  data: any,
  filename: string = 'file',
) {
  const blob = new Blob([data], { type: 'application/stream' });
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
