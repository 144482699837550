// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DSPInfos as StoreConfigDSPInfos } from '../storeConfig/storeConfig.types';

/**
 * The DSP enum represents the different DSPs that are supported by the application.
 * note: there is a {@link StoreConfigDSPInfos similar enum in the storeConfig module}, but it is not used in the same context.
 * TODO: see if we can merge the two enums
 */
export enum DSP {
  SPOTIFY = 'spotify',
  DEEZER = 'deezer',
  APPLE_MUSIC = 'appleMusic',
}

/**
 * The DSPInfos object contains useful infos (e.g. the display names) for the different DSPs.
 * note: there is a {@link StoreConfigDSPInfos similar object in the storeConfig module}, but it is not used in the same context.
 * TODO: see if we can merge the two objects
 */
export const DSPInfos = {
  [DSP.APPLE_MUSIC]: {
    displayName: 'Apple Music',
  },
  [DSP.DEEZER]: {
    displayName: 'Deezer',
  },
  [DSP.SPOTIFY]: {
    displayName: 'Spotify',
  },
};

export enum DSPActionType {
  FOLLOW_ARTIST = 'FOLLOW_ARTIST',
  FOLLOW_USER = 'FOLLOW_USER',
  ADD_ALBUM = 'ADD_ALBUM',
  ADD_TRACK = 'ADD_TRACK',
  ADD_PLAYLIST = 'ADD_PLAYLIST',
}

export enum DSPActionScheduleType {
  SCHEDULED_NOW = 'SCHEDULED_NOW',
  SCHEDULED_WITH_DATE = 'SCHEDULED_WITH_DATE',
  SCHEDULED_ON_BACKLINK_RELEASE_DATE = 'SCHEDULED_ON_BACKLINK_RELEASE_DATE',
}

export const DSPActionOptionsByType: Record<
  DSPActionType,
  {
    displayName: string;
  }
> = {
  [DSPActionType.FOLLOW_ARTIST]: {
    displayName: 'Follow artist',
  },
  [DSPActionType.ADD_PLAYLIST]: {
    displayName: 'Follow playlist',
  },
  [DSPActionType.FOLLOW_USER]: {
    displayName: 'Follow user',
  },
  [DSPActionType.ADD_ALBUM]: {
    displayName: 'Save release',
  },
  [DSPActionType.ADD_TRACK]: {
    displayName: 'Like track',
  },
};

/**
 * The various statuses of a DSP (Digital Streaming Platform) Action.
 */
export enum DSPActionStatus {
  DELIVERED = 'DELIVERED',
  ONGOING = 'ONGOING',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

/**
 * The various statuses of a DSP Action trigger.
 * the triggers are the requests from the fans to the actions
 * @TODO clarify this with the backend team
 */
export enum DSPActionTriggerStatus {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

/**
 * a typeguard to check if a given string is a DSP
 */
export function isDSP(storeName: string): storeName is DSP {
  return Object.values(DSP).includes(storeName as DSP);
}
