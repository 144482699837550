import React, { Suspense } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { shim as shimReplaceAll } from 'string.prototype.replaceall';

import { Box, CircularProgress } from '@mui/material';

import config, { CONFIG_ENV } from 'shared/config';

/** i18n init */
import 'shared/i18n';

import App from './App';
import AppProviders from './AppProviders';

/**
 * we need to support browsers that do not have this function
 * note: I do not undertand why this is not polyfilled by babel given the browserslist we provide
 */
shimReplaceAll();

// GOOGLE TAG MANAGER
const shouldUseGtm = !!config.googleTagManagerContainerId;
if (shouldUseGtm) {
  const tagManagerArgs = {
    gtmId: config.googleTagManagerContainerId,
    // Data layers can be configured if needed: https://github.com/alinemorelli/react-gtm
  };
  TagManager.initialize(tagManagerArgs);
}

// Mock Service Worker (MSW) config
if (
  CONFIG_ENV === 'development' &&
  process.env.REACT_APP_USE_MOCKS === 'true'
) {
  const { worker } = require('shared/mocks/browser');
  worker.start({
    onUnhandledRequest(req) {
      // cleanup the noise
      if (req.url.href.match('static|google-analytics|images|picsum')) {
        return;
      }
      console.warn(
        'Found an unhandled %s request to %s',
        req.method,
        req.url.href,
        req, // it is convenient to have the whole req object to inspect in some cases
      );
    },
  });
}

/**
 * Datadog logs init
 */
if (config.monitoring?.datadog) {
  datadogLogs.init(config.monitoring?.datadog);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Suspense
    fallback={
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    }
  >
    <AppProviders>
      <App />
    </AppProviders>
  </Suspense>,
);
