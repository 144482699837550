import { localesInfos } from './backlinkSettings.locale';

export type AllowedLocale =
  | 'en'
  | 'fr'
  | 'de'
  | 'it'
  | 'es'
  | 'pt'
  | 'ru'
  | 'ar'
  | 'zh'
  | 'zh-Hans'
  | 'zh-Hant'
  | 'hi'
  | 'id'
  | 'jv'
  | 'su'
  | 'fil'
  | 'uk'
  | 'th'
  | 'vi'
  | 'tr'
  | 'pl'
  | 'ja'
  | 'zh-tw'
  | 'zh-sg'
  | 'ms';

export const isAllowedLocale = (locale: string): locale is AllowedLocale => {
  return locale in localesInfos;
};

export type LocaleInfos = {
  code: AllowedLocale;
  displayName: string;
};

export type LocaleInfosList = Record<AllowedLocale, LocaleInfos>;
