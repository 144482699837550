import { useEffect, useRef } from 'react';

import { Box } from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

export const Loader = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.loader',
  });
  const loaderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    loaderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <Box
      sx={{ width: '100%', height: '100%' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      ref={loaderRef}
    >
      <Box
        role="status"
        aria-live="polite"
        sx={{
          //hidden from screen but still readable by screen readers (accessibility)
          width: 0,
          height: 0,
          overflow: 'hidden',
        }}
      >
        {t('loading', 'loading search results')}
      </Box>
      <CircularProgress />
    </Box>
  );
};
