import { setupWorker, rest } from 'msw';

import { haveSectionHandlersBeenAdded } from './constants';
import handlers from './handlers';

// This configures a Service Worker with the given request handlers.
const worker = setupWorker(...handlers);

export { worker };

window.msw = {
  worker,
  rest,
  haveSectionHandlersBeenAdded,
};
