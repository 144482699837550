import React, { useState } from 'react';

import { css } from '@emotion/react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { MenuItem, Slider } from '@mui/material';

import FieldSet from 'shared/components/FieldSet';

import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import { EditorColorPicker } from '../fields/EditorColorPicker';
import { EditorImageUploader } from '../fields/EditorImageUploader';
import { StepComponentProps } from './index';

import { EditorFormLabel, IconSelect, FieldRow } from '../fields/styled';
import { StepContent } from './styled';

export const DesignStep: React.FC<StepComponentProps> = () => {
  const { t } = useTranslation();

  const { stepKey, getFieldProps, parentEntityInfos, editorState } =
    useBacklinkSettingsDataEditorContext();

  const backlinkOnly =
    parentEntityInfos && parentEntityInfos.type === 'Backlink';

  const artworkIdPath = `${stepKey}.artworkId`;
  const { value: artworkId, changeHandler: artworkIdChangeHandler } =
    getFieldProps(artworkIdPath);
  const artworkUrl = get(editorState, `${stepKey}.artworkUrl`);

  const paragraphColorPath = `${stepKey}.typography.paragraph.color`;
  const linkColorPath = `${stepKey}.typography.link.color`;
  const bgColor01Path = `${stepKey}.background.color01`;
  const bgColor02Path = `${stepKey}.background.color02`;

  const bgDirectionsInDegrees = [0, 45, 90, 135, 180, 225, 270, 315];
  const bgDirectionPath = `${stepKey}.background.direction`;
  const {
    value: bgDirection = bgDirectionsInDegrees[0],
    changeHandler: bgDirectionChangeHandler,
  } = getFieldProps(bgDirectionPath);

  const imageIdPath = `${stepKey}.background.image.imageId`;
  const { value: imageId, changeHandler: imageIdChangeHandler } =
    getFieldProps(imageIdPath);

  const imageBlurRatioPath = `${stepKey}.background.image.blur`;
  const {
    value: imageBlurRatio = 0.3,
    changeHandler: imageBlurRatioChangeHandler,
  } = getFieldProps(imageBlurRatioPath);
  const [imageBlurRatioLocal, setImageBlurRatioLocal] = useState(
    imageBlurRatio * 100,
  );

  const imageOverlayColorPath = `${stepKey}.background.image.overlay.color`;

  const imageOverlayOpacityPath = `${stepKey}.background.image.overlay.opacity`;
  const {
    value: imageOverlayOpacity,
    changeHandler: imageOverlayOpacityChangeHandler,
  } = getFieldProps(imageOverlayOpacityPath);
  const [imageOverlayOpacityLocal, setImageOverlayOpacityLocal] = useState(
    imageOverlayOpacity * 100,
  );

  return (
    <>
      <StepContent>
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.designStep.release_artwork',
            'Release artwork',
          )}
          variant="condensed"
        >
          <EditorImageUploader
            inputId="artwork-upload"
            label="artwork image uploader"
            value={{
              url: artworkUrl,
              id: artworkId,
            }}
            changeHandler={artworkIdChangeHandler}
          />
        </FieldSet>
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.fieldset.texts.title',
            'Texts',
          )}
          variant="condensed"
        >
          <FieldRow>
            <EditorFormLabel id="paragraphs-color-label">
              {t(
                'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.paragraphs.color.label',
                'Paragraphs',
              )}
            </EditorFormLabel>
            <EditorColorPicker
              allowNoColor={false}
              {...getFieldProps(paragraphColorPath)}
              buttonLabelId="paragraphs-color-label"
              id="paragraphs-color-picker"
            />
          </FieldRow>

          <FieldRow>
            <EditorFormLabel id="links-label">
              {t(
                'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.links.color.label',
                'Links',
              )}
            </EditorFormLabel>
            <EditorColorPicker
              allowNoColor={false}
              {...getFieldProps(linkColorPath)}
              buttonLabelId="links-label"
              id="links-color-picker"
            />
          </FieldRow>
        </FieldSet>
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.fieldset.background.title',
            'Background',
          )}
          variant="condensed"
        >
          <FieldRow>
            <EditorFormLabel id="background-color-label">
              {t(
                'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.background.color.label',
                'Color',
              )}
            </EditorFormLabel>
            <div
              css={(theme) => css`
                & > * {
                  margin-left: ${theme.spacing(1 / 2)};
                }
              `}
            >
              <EditorColorPicker
                allowNoColor={false}
                {...getFieldProps(bgColor01Path)}
                buttonLabelId="background-color-label"
                id="background-color01-color-picker"
              />
              <EditorColorPicker
                {...getFieldProps(bgColor02Path)}
                buttonLabelId="background-color-label"
                id="background-color02-color-picker"
              />
              <IconSelect
                css={(theme) => css`
                  color: ${theme.palette.grey[600]};
                `}
                value={bgDirection}
                onChange={(event: any) =>
                  bgDirectionChangeHandler(event.target.value)
                }
              >
                {bgDirectionsInDegrees.map((direction) => (
                  <MenuItem
                    key={direction}
                    value={direction}
                    css={(theme) => css`
                      color: ${theme.palette.grey[600]};
                    `}
                  >
                    <ArrowUpwardIcon
                      css={css`
                        transform: rotate(${direction}deg);
                      `}
                    />
                  </MenuItem>
                ))}
              </IconSelect>
            </div>
          </FieldRow>
          <FieldRow>
            <EditorImageUploader
              inputId="background-upload"
              label="background image uploader"
              value={{
                id: imageId,
              }}
              changeHandler={imageIdChangeHandler}
              disabled={!backlinkOnly}
            />
          </FieldRow>
          {imageId && (
            <>
              <FieldRow>
                <EditorFormLabel id="blur-label">
                  {t(
                    'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.background.blur.label',
                    'Blur',
                  )}
                </EditorFormLabel>
                <Slider
                  css={css`
                    max-width: 50%;
                  `}
                  size="small"
                  value={imageBlurRatioLocal}
                  onChange={(event, value) =>
                    setImageBlurRatioLocal(value as number)
                  }
                  onChangeCommitted={(event, value) =>
                    imageBlurRatioChangeHandler((value as number) / 100)
                  }
                  aria-labelledby="blur-label"
                />
              </FieldRow>
              <FieldRow>
                <EditorFormLabel id="image-overlay-tint-color-label">
                  {t(
                    'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.background.tint.label',
                    'Tint',
                  )}
                </EditorFormLabel>
                <EditorColorPicker
                  allowNoColor={false}
                  {...getFieldProps(imageOverlayColorPath)}
                  buttonLabelId="image-overlay-tint-color-label"
                  id="image-overlay-tint-color-picker"
                />
              </FieldRow>
              <FieldRow>
                <EditorFormLabel id="tint-opacity-label">
                  {t(
                    'ui.component.backlink_settings_data_editor.steps.designStep.tabs.customize.form.field.background.tint_opacity.label',
                    'Tint Opacity',
                  )}
                </EditorFormLabel>
                <Slider
                  css={css`
                    max-width: 50%;
                  `}
                  size="small"
                  value={imageOverlayOpacityLocal}
                  onChange={(event, value) =>
                    setImageOverlayOpacityLocal(value as number)
                  }
                  onChangeCommitted={(event, value) =>
                    imageOverlayOpacityChangeHandler((value as number) / 100)
                  }
                  aria-labelledby="tint-opacity-label"
                />
              </FieldRow>
            </>
          )}
        </FieldSet>
      </StepContent>
    </>
  );
};
