import React, { useCallback } from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';

import ArtistBacklinkSettingsEditor from 'shared/components/BacklinkSettingsEditorsForRelatedEntity/ArtistBacklinkSettingsEditor';
import BacklinkSettingsModeSwitch from 'shared/components/BacklinkSettingsModeSwitch';
import { Button } from 'shared/components/Buttons/styled';
import { RelatedBacklinkSettingsMode } from 'shared/entities/backlinkSettings/backlinkSettings.constants';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import * as Fullscreen from 'shared/layouts/Fullscreen';
import * as EditorContainerStyled from 'shared/layouts/components/ContentPanels';

import useUpdateArtistBacklinkSettingsMode from './useUpdateArtistBacklinkSettingsMode';

type Props = {
  artistId: string;
};

const ArtistBacklinkSettings: React.FC<Props> = ({ artistId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [artistId],
  });

  const {
    updateBacklinkSettingsMode,
    isLoading: isSwitchingBacklinkSettingsMode,
  } = useUpdateArtistBacklinkSettingsMode({ artistId });

  const customBacklinkSettingsId = artist?.customBacklinkSettingsId;

  const useCustomBacklinkSettings = !!customBacklinkSettingsId;

  const relatedBacklinkSettingsMode = useCustomBacklinkSettings
    ? RelatedBacklinkSettingsMode.CUSTOM
    : RelatedBacklinkSettingsMode.DEFAULT;

  const handleRelatedBacklinkSettingsModeChange = useCallback(
    (value: RelatedBacklinkSettingsMode) => {
      const useDefault = value === RelatedBacklinkSettingsMode.DEFAULT;
      updateBacklinkSettingsMode({ artistId, useDefault });
    },
    [artistId, updateBacklinkSettingsMode],
  );

  return (
    <>
      <Box mt={2}>
        <BacklinkSettingsModeSwitch
          isSwitching={isSwitchingBacklinkSettingsMode}
          mode={relatedBacklinkSettingsMode}
          onChange={handleRelatedBacklinkSettingsModeChange}
        />
      </Box>
      {useCustomBacklinkSettings && (
        <Button
          // @ts-ignore (legacy theme)
          color="inherit"
          startIcon={<EditIcon />}
          onClick={() =>
            navigate(`/settings/artists/${artistId}/edit/backlinkSettings/edit`)
          }
        >
          {t(
            'ui.component.artist_settings_panel.tabs.tab.profile.button.edit_artist_link_settings',
            'Edit this Artist Link Settings',
          )}
        </Button>
      )}
      <Routes>
        <Route
          path={`settings/artists/:artistId/edit/backlinkSettings/edit/*`}
          element={
            <Fullscreen.WithCloseLinkButton
              to={`/settings/artists/${artistId}/edit`}
              actionsContainerStyles={css`
                top: 25px;
              `}
            >
              <ArtistBacklinkSettingsEditor
                artistId={artistId}
                css={(theme) => css`
                  // we want to make room for the fullscreen layout close button
                  ${EditorContainerStyled.Header} {
                    padding-left: ${theme.spacing(2)};
                  }
                `}
              />
            </Fullscreen.WithCloseLinkButton>
          }
        />
      </Routes>
    </>
  );
};

export default ArtistBacklinkSettings;
