import { CreativeMediaOrderByQueryParam } from 'shared/entities/creative/media.api.types';
import {
  useFetchCreativeMedias,
  useRequestSuggestedCreativeMediasCreation,
} from 'shared/hooks/entities/creative';
import { useFetchRelease } from 'shared/hooks/entities/release';

/**
 * this hook packages the logic to fetch a Release and its Creative medias
 * it will allow to check whether the Release has had its suggested Creative medias created
 * and if not, it will request them to be created then force a refetch of both the Release and its Creative medias
 */
export default function useReleaseCreativeMedias({
  releaseId,
}: {
  releaseId: string;
}) {
  // get function to request creation of suggested medias
  const {
    requestSuggestedCreativeMediasCreation,
    isLoading: isCreatingSuggestedCreativeMedias,
    isError: errorCreatingSuggestedCreativeMedias,
  } = useRequestSuggestedCreativeMediasCreation();

  // Creative medias for Release
  const {
    creativeMedias,
    invalidateQuery: invalidateCreativeMediasQuery,
    isLoading: isLoadingCreativeMedias,
  } = useFetchCreativeMedias({
    releaseId,
    limit: 100, // fetching a large amount as we don't have pagination yet
    orderBy: CreativeMediaOrderByQueryParam.creationDateAscByFormatMilestone,
  });

  // check if Release has had its suggested Creative medias created
  const { release } = useFetchRelease(releaseId, {
    onSuccess: (release) => {
      // request creation of suggested medias
      if (!release.metadata.initialResourcesCreated.suggestedCreativeMedia) {
        requestSuggestedCreativeMediasCreation(
          { releaseId: release.id },
          {
            onSuccess: () => {
              invalidateCreativeMediasQuery();
            },
          },
        );
      }
      invalidateCreativeMediasQuery();
    },
  });

  return {
    release,
    creativeMedias,
    isLoadingCreativeMedias,
    invalidateCreativeMediasQuery,
    isCreatingSuggestedCreativeMedias,
    errorCreatingSuggestedCreativeMedias,
  };
}
