import { AxiosResponse } from 'axios';

import { api } from '../api';
import { SlugValidationInfosResponse } from '../backlinkSettings/backlinkSettings.api.types';
import { DSPAction } from '../dspAction/dspAction.types';
import {
  FetchBacklinksPayload,
  CreateBacklinkPayload,
  UpdateBacklinkPayload,
  FetchBacklinkActionPayload,
  CreateBacklinkActionPayload,
  UpdateBacklinkActionPayload,
  FetchAdminBacklinksPayload,
  ExportAdminBacklinksPayload,
  ExportAdminBacklinksResponse,
  ExportAdminBacklinksStatusPayload,
  FetchBacklinksPerformancePayload,
  BacklinksPerformanceResponse,
  ValidateSlugPayload,
} from './backlink.api.types';
import { Backlink, BacklinkWithOptions } from './backlink.types';

export * as v2 from './v2/backlink.api';

export const backlinkBaseApiPath = '/backlinks';

export const createBacklink = async (
  payload: CreateBacklinkPayload,
): Promise<Backlink> => {
  const response = await api.post<Backlink>(backlinkBaseApiPath, payload);
  return response.data;
};

export const updateBacklink = async ({
  backlinkId,
  settingsData,
}: UpdateBacklinkPayload): Promise<Backlink | AxiosResponse> => {
  const response = await api.patch<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
    {
      backlinkId,
      settingsData,
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return response;
};

export const fetchBacklinks = async (
  requestPayload: FetchBacklinksPayload = {
    page: 0,
  },
): Promise<{
  backlinks: Backlink[];
  totalCount: number;
}> => {
  const results = await api.get<{
    backlinks: Backlink[];
    totalCount: number;
  }>(backlinkBaseApiPath, {
    params: requestPayload,
  });

  return results.data;
};

export const fetchBacklink = async (backlinkId: string): Promise<Backlink> => {
  const results = await api.get<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
  );
  return results.data;
};

export const deleteBacklink = async (
  backlinkId: string,
): Promise<AxiosResponse> => {
  const results = await api.delete<number>(
    `${backlinkBaseApiPath}/${backlinkId}`,
  );
  return results;
};

export const validateSlug = async (
  params: ValidateSlugPayload,
): Promise<SlugValidationInfosResponse> => {
  const results = await api.get(`${backlinkBaseApiPath}/slugIsAvailable`, {
    params,
  });
  return results.data;
};

export const fetchShareExtensions = async (): Promise<any[]> => {
  const results = await api.get(`${backlinkBaseApiPath}/shareExtensions`);
  return results.data;
};

export const fetchBacklinkAction = async ({
  backlinkId,
  actionId,
}: FetchBacklinkActionPayload): Promise<DSPAction> => {
  const results = await api.get(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${actionId}`,
  );
  return results.data;
};

export const createBacklinkAction = async ({
  backlinkId,
  action,
}: CreateBacklinkActionPayload): Promise<DSPAction> => {
  const results = await api.post<DSPAction>(
    `${backlinkBaseApiPath}/${backlinkId}/actions`,
    action,
  );
  return results.data;
};

export const updateBacklinkAction = async ({
  backlinkId,
  actionId,
  action,
}: UpdateBacklinkActionPayload): Promise<DSPAction> => {
  const results = await api.patch<DSPAction>(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${actionId}`,
    action,
  );
  return results.data;
};

/**
 * Synchronize backlink from database
 */
export const refreshBacklink = async ({
  backlinkId,
}: {
  backlinkId: string;
}): Promise<Backlink | undefined> => {
  if (!backlinkId) return;
  const results = await api.put(
    `${backlinkBaseApiPath}/${backlinkId}/release/metadata/refresh`,
  );
  return results.data;
};

export const fetchAdminBacklinks = async (
  requestPayload: FetchAdminBacklinksPayload,
): Promise<{
  backlinks: BacklinkWithOptions[];
  totalCount: number;
}> => {
  const results = await api.get<{
    backlinks: any[];
    totalCount: number;
  }>(`${backlinkBaseApiPath}/admin`, {
    params: requestPayload,
  });
  return results.data;
};

/**
 * Backlinks Export (Admin)
 *
 * it is comprised of 2 routes:
 *
 * one to trigger the export job & get the download url
 * the other one to poll the export job status
 *
 * when the status route indicates the job has COMPLETED
 * the main route must be called again to get the download url
 */

/**
 * POST
 * will trigger a job to export all backlinks filtered by the request payload
 */
export const exportAdminBacklinks = async (
  requestPayload: ExportAdminBacklinksPayload,
): Promise<ExportAdminBacklinksResponse> => {
  const response = await api.post('/exports/admin/backlinks', requestPayload);
  return response.data;
};

/**
 * GET status
 * will get the status of a given export job
 */
export const exportAdminBacklinksStatus = async (
  params: ExportAdminBacklinksStatusPayload,
): Promise<ExportAdminBacklinksResponse> => {
  const response = await api.get('/exports/admin/backlinks/status', {
    params,
  });
  return response.data;
};

export const fetchBacklinksPerformance = async (
  requestPayload: FetchBacklinksPerformancePayload,
): Promise<BacklinksPerformanceResponse> => {
  const results = await api.get(`${backlinkBaseApiPath}/performance`, {
    params: requestPayload,
  });
  return results.data;
};
