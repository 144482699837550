import React from 'react';

import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  BASE_OFFSET,
  HIGHLIGHT_DURATION,
  HIGHLIGHT_START_DELAY,
} from './constants';

/**
 * Adds an highlight around the children if the id matches the search param provided.
 */

type Props = {
  id: string;
  marginOffsets?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  shouldScrollIntoView?: boolean;
  /** use this to set anchor higher than the highlight id, expects a css value such as 100px */
  scrollAnchorOffsetTop?: string;
  children: JSX.Element;
};

const Highlight = ({
  id,
  marginOffsets = { left: '-30px', bottom: '0px' },
  children,
  scrollAnchorOffsetTop = '150px',
}: Props) => {
  const location = useLocation();
  const highlight = new URLSearchParams(location.search).get('highlight');

  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const scrollIntoViewRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    let timeout;
    if (highlight && highlight === id) {
      timeout = setTimeout(() => {
        setIsHighlighted(true);
        timeout = setTimeout(() => {
          setIsHighlighted(false);
        }, HIGHLIGHT_DURATION);
      }, HIGHLIGHT_START_DELAY);
    }
    // if there is a highlighRef matching searchParam, scroll it into view
    highlight &&
      scrollIntoViewRef?.current?.id === highlight &&
      scrollIntoViewRef?.current?.scrollIntoView();
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [highlight, id, scrollIntoViewRef]);

  // Margins need to have a slight offset so the highlighted background doesn't stick to the edge of the box.
  const margins = {
    left: marginOffsets?.left || BASE_OFFSET,
    right: marginOffsets?.right || BASE_OFFSET,
    top: marginOffsets?.top || BASE_OFFSET,
    bottom: marginOffsets?.bottom || BASE_OFFSET,
  };
  // To cancel the margins offset and prevent layout shifts, a positive padding must be set
  const paddings = Object.fromEntries(
    Object.entries(margins).map(([key, value]) => [
      key,
      value.replace('-', ''),
    ]),
  );

  return (
    <>
      <div
        id={id}
        ref={scrollIntoViewRef}
        style={{
          marginTop: scrollAnchorOffsetTop ? `-${scrollAnchorOffsetTop}` : '',
          marginBottom: scrollAnchorOffsetTop ? scrollAnchorOffsetTop : '',
        }}
      />
      <Box
        sx={{
          transition: 'background 0.5s ease-in-out',
          borderRadius: '15px',
          margin: `${margins.top} ${margins.right} ${margins.bottom} ${margins.left}`,
          padding: `${paddings.top} ${paddings.right} ${paddings.bottom} ${paddings.left}`,
          backgroundColor: isHighlighted ? 'highlight' : 'initial',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Highlight;
