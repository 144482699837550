import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Typography,
  IconButton,
  Collapse,
  TextField,
  Switch,
} from '@mui/material';

import EditorFieldSet from 'shared/components/FieldSet';
import {
  FormFieldName,
  FormFieldConfig,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { formFieldsDisplayNames } from 'shared/entities/backlinkSettings/templates/backlinkFormFields.template';

import { ValidationErrors } from '../../../../hooks/useValidation';
import { EditorRichTextField } from '../../../fields/EditorRichTextField';

import { FieldRow, EditorFormLabel } from '../../../fields/styled';
import { Card, CardHeader } from '../../../styled';
import { FormFieldEditorContent } from './styled';

export type FormFieldEditorProps = {
  path: string;
  fieldConfig: FormFieldConfig;
  isExpanded: boolean;
  errors: ValidationErrors;
  useRichTextEditor?: boolean;
  className?: string;
  onChange: (fieldConfig: FormFieldConfig) => void;
  onRemove?: () => void;
  onToggle?: (editorName: string) => void;
};

export const FormFieldEditor = ({
  path,
  fieldConfig,
  isExpanded,
  errors,
  useRichTextEditor = false,
  className,
  onChange,
  onRemove,
  onToggle,
}: FormFieldEditorProps) => {
  const { t } = useTranslation();

  const { name, label, required } = fieldConfig;

  const fieldErrors = Object.keys(errors).reduce((accu, errorKey) => {
    if (errorKey.match(path)) {
      const fieldErrorKey = errorKey.split(`${path}.`)[1];
      accu[fieldErrorKey] = errors[errorKey];
    }
    return accu;
  }, {});

  const hasErrors = !!Object.keys(fieldErrors).length;

  /**
   * Note: [ts] we can not rely on extracting the fieldConfig types to boolean variables to simplify code
   * we have to use a typeGuard or a direct comparison: fieldConfig.name === 'TheFieldConfigName'
   * see https://github.com/microsoft/TypeScript/issues/12184
   */

  const hideRequiredInput = fieldConfig.name === FormFieldName.OptinCompetition;

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <Card
      isExpanded={isExpanded}
      hasErrors={hasErrors}
      className={className}
      role="group"
      aria-label={t(
        'ui.component.backlink_settings_data_editor.form_field_editor.form_field_card',
        'Form field card - {{name}}',
        {
          name: formFieldsDisplayNames[name] || name,
        },
      )}
      aria-expanded={isExpanded}
      aria-invalid={hasErrors}
    >
      <CardHeader
        disableTypography
        title={
          <Typography variant="subtitle1">
            {t(
              `ui.component.backlink_settings_data_editor.form_field_selector.${name}`,
              formFieldsDisplayNames[name],
            )}
          </Typography>
        }
        action={
          <>
            {!required && (
              <Typography variant="body1">
                {t(
                  `ui.component.backlink_settings_data_editor.form_field_selector.optional`,
                  'Optional',
                )}
              </Typography>
            )}
            {!!onRemove && (
              <IconButton onClick={onRemove} aria-label="remove" size="large">
                <RemoveCircleIcon />
              </IconButton>
            )}
            {!!onToggle && (
              <IconButton
                onClick={() => {
                  onToggle && onToggle(name);
                }}
                aria-label="expand"
                aria-expanded={isExpanded}
                size="large"
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
          </>
        }
      />
      <Collapse in={isExpanded} timeout={100} unmountOnExit>
        <FormFieldEditorContent>
          <EditorFieldSet variant="condensed">
            {!hideRequiredInput && (
              <FieldRow>
                <EditorFormLabel>
                  <Trans i18nKey="ui.component.backlink_settings_data_editor.form_field_editor.required_field_switch.label">
                    <strong>Required</strong>
                    <small>
                      Activate only for fields vital to your activity.
                    </small>
                  </Trans>
                </EditorFormLabel>

                <Switch
                  color="primary"
                  onChange={(event) =>
                    onChange({
                      ...fieldConfig,
                      required: event.target.checked,
                    })
                  }
                  checked={required}
                  inputProps={{
                    'aria-label': t(
                      'ui.component.backlink_settings_data_editor.form_field_editor.required_field_switch.a11y.label',
                      'Required',
                    ),
                  }}
                />
              </FieldRow>
            )}
            <FieldRow>
              {useRichTextEditor ? (
                <EditorRichTextField
                  mode="minimal"
                  value={label}
                  changeHandler={(value: string) =>
                    onChange({
                      ...fieldConfig,
                      label: value,
                    })
                  }
                  error={fieldErrors['label']}
                  options={{
                    withReplacementTokenSelect: true,
                  }}
                />
              ) : (
                <TextField
                  fullWidth
                  id={fieldConfig.name}
                  name={`.label`}
                  label={t(
                    'ui.component.backlink_settings_data_editor.form_field_editor.field_label.label',
                    'Field label',
                  )}
                  variant="outlined"
                  onChange={(event) =>
                    onChange({
                      ...fieldConfig,
                      label: event.target.value,
                    })
                  }
                  value={label}
                  error={Boolean(fieldErrors['label'])}
                />
              )}
            </FieldRow>
          </EditorFieldSet>
        </FormFieldEditorContent>
      </Collapse>
    </Card>
  );
}
