import React, { ComponentProps, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import ConfirmDialog from 'shared/components/ConfirmDialog';

function useConfirmDialog<T extends unknown[]>({
  onConfirm,
  onCancel,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
}: Pick<
  Partial<ComponentProps<typeof ConfirmDialog>>,
  'title' | 'content' | 'cancelButtonText' | 'confirmButtonText'
> & {
  onConfirm: (...params: T) => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.dialog',
  });

  const [isDialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [confirmArgs, setConfirmArgs] = React.useState<T>();

  const openDialog = useCallback((...params: T) => {
    setConfirmArgs(params);
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const confirm = useCallback(() => {
    if (confirmArgs) onConfirm(...confirmArgs);
    closeDialog();
  }, [confirmArgs, onConfirm, closeDialog]);

  const cancel = useCallback(() => {
    if (onCancel) onCancel();
    closeDialog();
  }, [onCancel, closeDialog]);

  const Dialog = (
    <ConfirmDialog
      title={title ?? t('confirm.title', 'Are you sure?')}
      content={content}
      cancelButtonText={cancelButtonText ?? t('button.cancel', 'Cancel')}
      confirmButtonText={confirmButtonText ?? t('button.confirm', 'Confirm')}
      open={isDialogOpen}
      onClose={closeDialog}
      onCancel={cancel}
      onConfirm={confirm}
    />
  );

  return {
    Dialog,
    openDialog,
    closeDialog,
  };
}

export default useConfirmDialog;
