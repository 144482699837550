import { useEffect } from 'react';

function useBeforeUnloadGuard(shouldBlockNavigation: boolean) {
  useEffect(() => {
    const beforeUnloadHandler = (event: Event) => {
      if (shouldBlockNavigation) {
        event.returnValue = true;
      }
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () =>
      window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, [shouldBlockNavigation]);
}

export { useBeforeUnloadGuard };
