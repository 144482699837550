import * as yup from 'yup';
import type { BaseSchema } from 'yup';

export const PHONE_NUMBER_E164_REGEX = '\\+[0-9]{5,30}$';
export const REQUIRED_TYPE_ERROR = 'required';

export function validateUrl(url: string): string | undefined {
  try {
    return new URL(url).href;
  } catch {}
}

/**
 *
 * @param schema
 * @param options
 * @returns a yup.lazy() validation rule made of all keys: 'passed in sub-schema' pairs
 */
export const dynamicKeysSchema = (
  schema: BaseSchema,
  { optional }: { optional?: boolean } = {},
) =>
  yup.lazy((obj: Record<string, any>) => {
    return obj
      ? yup.object(
          Object.keys(obj).reduce(
            (newObj, key) => ({ ...newObj, [key]: schema }),
            {},
          ),
        )
      : optional
      ? yup.mixed().optional()
      : yup.mixed().required();
  });
