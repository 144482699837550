import React from 'react';

import * as yup from 'yup';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';

import { EditorTextFieldProps } from '../../types';

import { StyledUrlTextField } from './styled';

type Props = EditorTextFieldProps;

export const EditorUrlTextField: React.FC<Props> = ({ value, ...props }) => {
  const shouldShowOpenLink = yup.string().url().required().isValidSync(value);

  return (
    <StyledUrlTextField
      variant="outlined"
      fullWidth
      placeholder="https://..."
      value={value}
      InputProps={{
        endAdornment: shouldShowOpenLink && (
          // @ts-ignore
          <IconButton component="a" href={value} target="_blank" size="large">
            <OpenInNewIcon />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export default EditorUrlTextField;
