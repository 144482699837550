/**
 * This file contains overrides for the Believe central design system theme.
 *
 * this is needed to make the transition from the MKP DS theme to the Believe DS theme
 * as not all mui components are yet available in the Believe DS theme
 */
import React from 'react';

import { theme as designSystemTheme } from '@believe-front/react-components';

import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Lightbulb as LightbulbIcon,
  Warning as WarningIcon,
  South as SouthIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material';
import { createTheme } from '@mui/material';

/* 
  -- SPACING --
  map previous DS theme spacing to new DS spacing
  now 4px, was 20px
  so we don't have to change all the components that were developed with the previous DS theme
*/
const spacing = (factor: number) =>
  `${parseFloat(designSystemTheme.spacing(factor)) * 5}px`;

export const designSystemThemeOverrides = {
  spacing,
  // map legacy grey scale to design sytem surface colors for a better transition of components used with both themes but with grey scale colors
  palette: {
    grey: {
      100: designSystemTheme.palette.surface[90],
      300: designSystemTheme.palette.surface[90],
      400: designSystemTheme.palette.surface[80],
      450: designSystemTheme.palette.surface[70],
      475: designSystemTheme.palette.surface[60],
      480: designSystemTheme.palette.surface[50],
      500: designSystemTheme.palette.surface[40],
      600: designSystemTheme.palette.surface[40],
      700: designSystemTheme.palette.surface[30],
      750: designSystemTheme.palette.surface[10],
      800: designSystemTheme.palette.surface[10],
    },
  },
  typography: {
    ...designSystemTheme.typography,
    body1: {
      ...designSystemTheme.typography.body1,
      fontWeight: 500,
      lineHeight: 1.5,
      color: designSystemTheme.palette.textColors.dark.medium,
    },
    body2: {
      ...designSystemTheme.typography.body2,
      lineHeight: 1.5,
      color: designSystemTheme.palette.textColors.dark.medium,
    },
  },
  components: {
    ...designSystemTheme.components,

    // the main reason for overrideing the MuiCalendarPicker is that the Year button text is set as typography.subtitle1 in the component code
    // https://github.com/mui/mui-x/blob/f1b84a137e70e079b4e151187d0de49bef2adc2d/packages/x-date-pickers/src/YearCalendar/PickersYear.tsx#L67
    // the DS theme has typography.subtitle1 styles that do not fit the year button text as we want it
    // note: the DatePicker design is here https://www.figma.com/file/2tYOnHMu1mIdnPueSNeM7C/Harmony---Design-System?type=design&node-id=3492-18838&mode=dev
    // it needs to be implemented in the Believe DS theme
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '&&': {
            '.MuiPickersCalendarHeader-label': {
              fontWeight: 500,
              fontSize: '0.875rem',
              color: designSystemTheme.palette.textColors.dark.medium,
            },
            '.PrivatePickersYear-yearButton': {
              ...designSystemTheme.typography['body-medium'],
              color: designSystemTheme.palette.textColors.dark.weak,
            },
            '.PrivatePickersYear-yearButton.Mui-selected': {
              color: designSystemTheme.palette.textColors.light.strong,
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          ...designSystemTheme.components?.MuiCard?.styleOverrides,
          height: 'auto',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: designSystemTheme.spacing(4),
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        ...designSystemTheme.components?.MuiTabs?.styleOverrides,
        flexContainer: {
          gap: spacing(1.5), // wee need to override this because, event though the spacing declaration is the same in the DS theme, the value ends up being different because of the modified spacing scale
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ...(designSystemTheme.components?.MuiPaper?.styleOverrides
            ?.root as object),
          '&.MuiMenu-paper': {
            marginTop: spacing(0.5),
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: `0 ${designSystemTheme.spacing(2)}`,
        },
      },
    },
    MuiMenuItem: {
      ...designSystemTheme.components?.MuiMenuItem,
      styleOverrides: {
        root: {
          ...(designSystemTheme.components?.MuiMenuItem?.styleOverrides
            ?.root as object),
          '&.Mui-selected:hover': {
            backgroundColor: designSystemTheme.palette.primary.light,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '50%',
          padding: `${10 / 16}rem`,
          '& > .MuiSvgIcon-root': {
            fontSize: `${18 / 16}rem`,
          },

          '& > .MuiCircularProgress-root': {
            width: `${18 / 16}rem!important`,
            height: `${18 / 16}rem!important`,
          },
          '&:hover': {
            color: designSystemTheme.palette.primary.main,
            backgroundColor: designSystemTheme.palette.primary.light,
          },
        },
        sizeSmall: {
          padding: `${7 / 16}rem`,
          '& > .MuiSvgIcon-root': {
            fontSize: `${16 / 16}rem`,
          },
          '& > .MuiCircularProgress-root': {
            width: `${16 / 16}rem!important`,
            height: `${16 / 16}rem!important`,
          },
        },
        sizeLarge: {
          padding: `${12 / 16}rem`,
          '& > .MuiSvgIcon-root': {
            fontSize: `${20 / 16}rem`,
          },
          '& > .MuiCircularProgress-root': {
            width: `${20 / 16}rem!important`,
            height: `${20 / 16}rem!important`,
          },
        },
      },
    },
    // we temporarily override some components to use the values that will be available in the Believe central design system theme but are not yet available on the MKP version
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: designSystemTheme.palette.textColors.dark.strong,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: spacing(24 / 20),
          paddingRight: spacing(24 / 20),
          paddingTop: spacing(1),
          paddingBottom: spacing(1),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: spacing(1.2),
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <LightbulbIcon sx={{ color: 'info.main' }} />,
          success: <CheckCircleIcon sx={{ color: 'success.main' }} />,
          warning: <WarningIcon sx={{ color: 'warning.main' }} />,
          error: <ErrorIcon sx={{ color: 'error.main' }} />,
        },
      },
      styleOverrides: {
        root: {
          fontSize: designSystemTheme.typography.body1.fontSize,
          borderWidth: 0,
          color: designSystemTheme.palette.textColors.dark.strong,
          borderRadius: '8px',
          '.MuiAlertTitle-root': {
            color: designSystemTheme.palette.textColors.dark.strong,
            fontWeight: 700,
          },
          '.MuiAlert-action': {
            paddingTop: 1,
          },
        },
        message: {
          '.MuiAlertTitle-root': {
            marginTop: '-1.5px',
          },
        },
        filledInfo: {
          backgroundColor: designSystemTheme.palette.info.light,
          '.MuiSvgIcon-root': {
            fill: designSystemTheme.palette.info.main,
          },
        },
        filledSuccess: {
          backgroundColor: designSystemTheme.palette.success.light,
          '.MuiSvgIcon-root': {
            fill: designSystemTheme.palette.success.main,
          },
        },
        filledWarning: {
          backgroundColor: designSystemTheme.palette.warning.light,
          '.MuiSvgIcon-root': {
            fill: designSystemTheme.palette.warning.main,
          },
        },
        filledError: {
          backgroundColor: designSystemTheme.palette.error.light,
          '.MuiSvgIcon-root': {
            fill: designSystemTheme.palette.error.main,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${designSystemTheme.palette.divider}`,
          borderRadius: '20px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          border: `1px solid ${designSystemTheme.palette.divider}`,
          display: 'table',
          overflow: 'hidden',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: designSystemTheme.palette.surface[90],
            '&.MuiTableRow-head': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: `${14 / 16}rem`,
          borderBottom: 'none',
          borderTop: `1px solid ${designSystemTheme.palette.divider}`,
          padding: spacing(1),
        },
        head: {
          color: designSystemTheme.palette.textColors.dark.medium,
          fontWeight: 700,
          fontSize: `${11 / 16}rem`,
          lineHeight: `${16 / 16}rem`,
          padding: `${spacing(0.5)} ${spacing(1)}`,
          textTransform: 'uppercase',
        },
        paddingCheckbox: {
          padding: 0,
        },
      },
    },
    MuiTableSortLabel: {
      defaultProps: {
        IconComponent(props) {
          const isAscendingSort = props.className
            .split(' ')
            .includes('MuiTableSortLabel-iconDirectionAsc');

          return (
            <>
              <SouthIcon
                id="custom_sort-icon"
                color="primary"
                sx={{
                  transform: `rotate(${isAscendingSort ? '-180deg' : '0deg'})`,
                  transition: 'transform 100ms ease-in-out',
                }}
              />
              <UnfoldMoreIcon id="custom_sortable-icon" />
            </>
          );
        },
      },
      styleOverrides: {
        root: {
          '& > .MuiSvgIcon-root': {
            fontSize: `${14 / 16}rem`,
            marginLeft: spacing(0.5),
            opacity: 1,
          },
          '& > #custom_sort-icon': {
            display: 'none',
          },
          '& > #custom_sortable-icon': {
            display: 'inline',
          },
          '&.Mui-active > #custom_sort-icon': {
            display: 'inline',
          },
          '&.Mui-active > #custom_sortable-icon': {
            display: 'none',
          },
        },
      },
    },
    // the default Mui styles apply the typography body2 rules to the table pagination
    // we want to use the body1 rules insteadbecause we have redfined the body2 rules for some reason :)
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '&& *': {
            fontSize: designSystemTheme.typography.body1.fontSize,
          },
          '&& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        },
        displayedRows: {
          color: designSystemTheme.palette.textColors.dark.medium,
        },
        selectLabel: {
          '&&': {
            color: designSystemTheme.palette.textColors.dark.strong,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& > .MuiPaper-root': {
            padding: `${spacing(1.5)} ${spacing(2)}`,
          },
        },
      },
    },
  },
};

/**
 * the Mui theme with the design system theme and the overrides
 */
export const designSystemThemeWithOverrides = createTheme(
  designSystemTheme,
  designSystemThemeOverrides,
);
