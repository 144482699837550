import { HotReleaseResponse } from './release.api.types';
import { HotRelease } from './release.types';

export const HotReleaseModel = {
  transform(hotReleaseResponse: HotReleaseResponse): HotRelease {
    return {
      ...hotReleaseResponse,
      artistName:
        hotReleaseResponse.artistName || hotReleaseResponse.artist?.name || '',
      title: hotReleaseResponse.productVersion
        ? `${hotReleaseResponse.title} (${hotReleaseResponse.productVersion})`
        : hotReleaseResponse.title,
    };
  },
  transformAll(hotReleaseResponses: HotReleaseResponse[]): HotRelease[] {
    return hotReleaseResponses.map(HotReleaseModel.transform);
  },
  search({
    hotReleases,
    query,
  }: {
    hotReleases: HotReleaseResponse[];
    query: string;
  }): HotReleaseResponse[] {
    return hotReleases.filter(
      (hotRelease) =>
        hotRelease.title.toLowerCase().match(query?.toLowerCase()) ||
        hotRelease.artistName?.toLowerCase().includes(query?.toLowerCase()),
    );
  },
  getHotReleasesBeforeDate({
    hotReleases,
    date,
    pageSize = 10,
  }: {
    hotReleases: HotReleaseResponse[];
    date: string;
    pageSize: number;
  }): HotReleaseResponse[] {
    return hotReleases
      .filter((hotRelease) => {
        return new Date(hotRelease.digitalReleaseDate) < new Date(date);
      })
      .sort((a, b) => {
        return a.digitalReleaseDate > b.digitalReleaseDate ? -1 : 1;
      })
      .splice(0, pageSize);
  },
  getHotReleasesAfterDate({
    hotReleases,
    date,
    pageSize = 10,
  }: {
    hotReleases: HotReleaseResponse[];
    date: string;
    pageSize: number;
  }): HotReleaseResponse[] {
    return hotReleases
      .filter((hotRelease) => {
        return new Date(hotRelease.digitalReleaseDate) > new Date(date);
      })
      .sort((a, b) => {
        return a.digitalReleaseDate > b.digitalReleaseDate ? 1 : -1;
      })
      .splice(0, pageSize);
  },
};
