import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { TextField, ClickAwayListener } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';

import { fetchLocalesInfos } from 'shared/entities/backlinkSettings/backlinkSettings.locale';
import {
  AllowedLocale,
  LocaleInfos,
} from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';

import { EditorButton } from '../../../styled';
import { Container } from './styled';

export type LocaleSelectorProps = {
  onChange: (event: React.ChangeEvent<{}>, value?: AllowedLocale) => void;
  filterOptions?: AutocompleteProps<
    LocaleInfos,
    false,
    false,
    false
  >['filterOptions'];
  getOptionDisabled?: AutocompleteProps<
    LocaleInfos,
    false,
    false,
    false
  >['getOptionDisabled'];
};

const LocaleSelector = ({
  onChange,
  filterOptions,
  getOptionDisabled,
}: LocaleSelectorProps) => {
  const { t } = useTranslation();

  const [autocompleteIsVisible, setAutocompleteIsVisible] =
    React.useState(false);
  const [locales, setLocales] = useState<LocaleInfos[]>([]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const loading = !locales.length;

  useEffect(() => {
    /**
     * we need to fake cancelling the call
     * because in some cases we're unmounting the component
     * before the async operation is finished
     * using a variable declared in the useEffect scope
     * see: https://juliangaramendy.dev/use-promise-subscription/
     */
    let isCancelled = false;
    async function doFetchLocales() {
      try {
        const localesInfos = await fetchLocalesInfos();
        if (localesInfos) {
          if (!isCancelled) {
            setLocales(Object.values(localesInfos));
          }
        }
      } catch (error) {
        console.error(`Error trying to fetch locales: ${error}`);
      }
    }
    doFetchLocales();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <ClickAwayListener
        onClickAway={(event) => {
          /**
           * we should not close the autocomplete when clicking in the search field
           * so we check that we're clicking inside the container
           * clicking everywhere else should close the autocomplete
           */
          if (!containerRef.current?.contains(event.target as Node)) {
            setAutocompleteIsVisible(false);
          }
        }}
      >
        <EditorButton
          startIcon={<AddCircleRoundedIcon />}
          onClick={(event) => setAutocompleteIsVisible(true)}
        >
          {t(
            'ui.component.backlink_settings_data_editor.locale_selector.add_language',
            'Add new language',
          )}
        </EditorButton>
      </ClickAwayListener>
      {autocompleteIsVisible && (
        <Autocomplete
          open
          fullWidth
          /* the following is necessary to prevent TS complaining  about the typing of the onChange event ... */
          multiple={false}
          ListboxProps={{ style: { maxHeight: 200 } }}
          onOpen={() => setAutocompleteIsVisible(true)}
          getOptionLabel={(option) =>
            t(
              `misc.supportedLocale.${option.code}.languageName`,
              option.displayName,
            )
          }
          options={locales}
          filterOptions={filterOptions}
          getOptionDisabled={getOptionDisabled}
          loading={loading}
          renderInput={(params) => {
            return (
              <TextField
                fullWidth
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                InputLabelProps={params.InputLabelProps}
                label={
                  !loading ? (
                    t(
                      'ui.component.backlink_settings_data_editor.locale_selector.label',
                      'Select a language',
                    )
                  ) : (
                    <em>
                      {t(
                        'ui.component.backlink_settings_data_editor.locale_selector.loading_message',
                        'Select a language',
                      )}
                    </em>
                  )
                }
                variant="outlined"
              />
            );
          }}
          onChange={(event, value) => {
            onChange(event, value?.code);
            setAutocompleteIsVisible(false);
          }}
          popupIcon={<AddCircleRoundedIcon />}
        />
      )}
    </Container>
  );
};

export default LocaleSelector;
