import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

export type Props = {
  icon: React.ReactNode;
  backgroundColor: string;
  iconColor?: string;
  side?: number;
  radius?: number;
  sx?: SxProps<Theme>;
};

const BoxedIcon = ({
  backgroundColor,
  icon,
  iconColor,
  side = 36,
  radius = 10,
  sx,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: side,
        height: side,
        flex: `0 0 ${side}px`,
        backgroundColor,
        borderRadius: `${radius}px`,
        color: iconColor || 'white',
        ...sx,
      }}
    >
      {icon}
    </Box>
  );
};

export default BoxedIcon;
