import { GATrackers } from 'shared/analytics/analytics.types';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { development, staging, rvw, production, test } from './environments';
import * as monitoring from './monitoring';

// for development & production NODE_ENV and CONFIG_ENV can be the same
// for anything other than test, development or production specifying REACT_APP_CONFIG_ENV=yourEnvironment is needed as we can't override NODE_ENV set by CRA
// note: the REACT_APP_ prefix is needed for the CRA build to pass the env vars to the browser bundle
// see: https://create-react-app.dev/docs/adding-custom-environment-variables/
export const CONFIG_ENV =
  process.env.REACT_APP_CONFIG_ENV || process.env.NODE_ENV;

type Config = {
  marketingSuiteHostname: string;
  backstageMarketingHostname: string;
  backstageMarketingResourcesHostname: string;

  // we want to be able to access the current Believe Environment api base url
  // this is a function because we might need the api base url after it has been changed (e.g. in tests)
  getApiBaseUrl: () => string;

  marketingSuiteApiBaseUrl: string;
  backstageMarketingApiBaseUrl: string;

  imageBaseUrl: string;
  assetsBaseUrl: string;
  imageBucketName: string;
  backlinkBaseUrl: string;

  gaTrackers: GATrackers;

  backstageBaseUrl: string;

  artistsResourcesWordpressUrl: string;

  backstageMarketingOriginUrl: string;
  backstageMarketingResourcesOriginUrl: string;

  /** external backstage gtm tracking only on prod + sta */
  googleTagManagerContainerId?: string;

  gitlabFeatureFlagsProxy: {
    url: string;
    clientKey: string;
    refreshInterval: number;
    appName: string;
    environment: string;
  };

  creativeMediasGqlClientConfig: {
    env: 'sbx' | 'sta' | 'prd';
    uri: string;
  };

  zendeskGlobalMarketingKey: string;
  zendeskEditorialMarketingProductKey: string;
  zendeskCustomerHelpCenterKey: string;

  salesForceBelieveBaseUrl: string;
  salesForceBelieveHelpCenterUrl: string;
  salesForceBelieveKey: string;
  salesForceBackstageMarketingKey: string;
  salesForceMarketingSuiteKey: string;

  intranetBaseUrl: string;
  teamsChatBaseUrl: string;
  legacyBackstageAdsUrl: string;

  monitoring?: {
    datadog: typeof monitoring.datadog;
  };
};

const configs = { development, staging, rvw, production, test };

const baseConfig = configs[CONFIG_ENV as keyof typeof configs];

// allows selecting an api url
export const apiBaseUrlMap: Record<AppName, string> = {
  [AppName.marketingSuite]: baseConfig.marketingSuiteApiBaseUrl,
  [AppName.backstageMarketing]: baseConfig.backstageMarketingApiBaseUrl,
  [AppName.backstageMarketingResources]:
    baseConfig.backstageMarketingApiBaseUrl,
};

const config: Config = {
  ...baseConfig,
  getApiBaseUrl: () =>
    process.env.NODE_ENV === 'test' ? '' : apiBaseUrlMap[getAppName()],
};

export default config;
