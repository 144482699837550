import { api } from '../api';
import { EnginePoolKPIs } from './dspAction.api.types';
import { DSPActionTriggerStatus } from './dspAction.constants';
import { DSPActionSub } from './dspAction.types';
import { DSPAction } from './dspAction.types';

export const fetchDSPActions = async (
  params: {
    backlinkId?: string;
    withSubsKPI?: boolean;
    page: number;
    limit: number;
  } = {
    page: 0,
    limit: 20,
  },
): Promise<{ results: DSPAction[]; totalCount: number }> => {
  const results = await api.get('/actions', { params });
  return results.data;
};

export const fetchDSPAction = async (id: string): Promise<DSPAction> => {
  const results = await api.get(`/actions/${id}`);
  return results.data;
};

export const retryAllFailedDSPActionSub = async (
  actionId: string,
): Promise<DSPAction> => {
  const results = await api.post(`/actions/${actionId}/retryAllFailed`);
  return results.data;
};

export const fetchDSPActionSubs = async (
  params: {
    actionId?: string;
    activeTriggerStatus?: DSPActionTriggerStatus;
    withFanInfos?: boolean;
    withActiveTrigger?: boolean;
    page: number;
    limit: number;
  } = {
    withFanInfos: false,
    withActiveTrigger: false,
    page: 0,
    limit: 20,
  },
): Promise<{ results: DSPActionSub[]; totalCount: number }> => {
  const {
    page,
    limit,
    actionId,
    activeTriggerStatus,
    withFanInfos,
    withActiveTrigger,
  } = params;
  const results = await api.get(`/actions/${actionId}/subs`, {
    params: {
      activeTriggerStatus,
      withFanInfos,
      withActiveTrigger,
      page,
      limit,
    },
  });
  return results.data;
};

export async function fetchEnginePoolKPIs(): Promise<EnginePoolKPIs> {
  const results = await api.get('/actions/enginePool/locked');
  return results.data;
}

export async function purgeDSPActions(): Promise<void> {
  await api.post('/actions/enginePool/locked/purge');
}
