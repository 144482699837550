import faker from 'faker';

import { getArtist } from 'shared/entities/artist/mocks/fixtures/artist.fixture.all';
import { BacklinkSettings } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { backlinkBacklinkSettings } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.byParentEntity';
import { release } from 'shared/entities/release/mocks/fixtures/release.fixture.one';

import { Backlink } from '../../backlink.types';
import backlink from './backlink.fixture.one';

const generateSettings = (backlinkBacklinkSettings: BacklinkSettings) => {
  const settingsData = {
    ...backlinkBacklinkSettings.data,
    releaseStep: {
      ...backlinkBacklinkSettings.data.releaseStep,
      release: {
        ...backlinkBacklinkSettings.data.releaseStep.release,
        title: faker.random.words(3),
      },
    },
  };

  return {
    settingsData,
  };
};

export const backlinks: Backlink[] = [
  // we add the fixture from the .one file because it is the mocked backlink used by the e2e tests
  { ...backlink },
  {
    // keeping a fixed id for this backlink to be have one backlink we can target (e.g. in tests)
    id: '6caeae3d-d2de-4e59-971d-e03a4f3ca751',
    // same release for all backlinks for now
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-31T05:15:27.817Z',
    updatedAt: '2022-01-31T05:18:30.295Z',
    slug: 'an-aural-symbiotic-mystery',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-01-26T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 7,
      preSaved: 0,
      optins: 0,
      visited: 0,
      lastUpdate: '2022-02-02T15:07:03.712Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T04:34:47.788Z',
    updatedAt: '2022-01-21T06:12:55.221Z',
    slug: 'fallen-paradise',
    subDomain: 'vancouversleepclinic',
    digitalReleaseDate: '2022-04-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3,
      preSaved: 2,
      optins: 2,
      visited: 3,
      lastUpdate: '2022-01-21T12:05:47.502Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T03:55:18.500Z',
    updatedAt: '2022-02-03T09:24:23.317Z',
    slug: 'juliastevens',
    subDomain: 'holliecol',
    digitalReleaseDate: '2022-04-14T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1025,
      preSaved: 555,
      optins: 556,
      visited: 1282,
      lastUpdate: '2022-01-21T09:06:53.723Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-19T00:00:33.902Z',
    updatedAt: '2022-01-19T00:09:03.166Z',
    slug: 'tester',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-17T00:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 5,
      preSaved: 3,
      optins: 3,
      visited: 4,
      lastUpdate: '2022-01-19T06:05:22.137Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-10T05:59:46.047Z',
    updatedAt: '2022-01-28T05:55:31.636Z',
    slug: 'aplacetofallapart',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-04-21T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 184,
      preSaved: 45,
      optins: 50,
      visited: 289,
      lastUpdate: '2022-01-10T12:04:20.564Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:54:23.855Z',
    updatedAt: '2021-11-08T23:02:52.841Z',
    slug: '64bars',
    subDomain: 'nerve',
    digitalReleaseDate: '2021-11-25T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1,
      preSaved: 1,
      optins: 2,
      visited: 4,
      lastUpdate: '2021-11-09T09:04:05.219Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:22:03.375Z',
    updatedAt: '2021-12-01T04:41:54.216Z',
    slug: 'OutofLove',
    subDomain: 'JOY',
    digitalReleaseDate: '2021-11-17T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 288,
      preSaved: 15,
      optins: 23,
      visited: 666,
      lastUpdate: '2021-11-09T03:04:48.964Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-10-21T23:01:30.635Z',
    updatedAt: '2022-01-26T21:05:15.803Z',
    slug: 'obsidian',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-31T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 11041,
      preSaved: 1248,
      optins: 1347,
      visited: 12207,
      lastUpdate: '2021-10-22T03:03:21.569Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-09-08T06:22:26.164Z',
    updatedAt: '2021-10-19T00:25:27.641Z',
    slug: 'reruns',
    subDomain: 'holliecol',
    digitalReleaseDate: '2021-10-06T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3034,
      preSaved: 475,
      optins: 683,
      visited: 4397,
      lastUpdate: '2021-09-08T09:01:53.874Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-08-26T03:43:34.359Z',
    updatedAt: '2022-01-26T21:06:40.786Z',
    slug: 'Zombie',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2021-09-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1448,
      preSaved: 161,
      optins: 235,
      visited: 2790,
      lastUpdate: '2021-08-26T06:03:57.886Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-31T05:15:27.817Z',
    updatedAt: '2022-01-31T05:18:30.295Z',
    slug: 'an-aural-symbiotic-mystery',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-01-26T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 7,
      preSaved: 0,
      optins: 0,
      visited: 0,
      lastUpdate: '2022-02-02T15:07:03.712Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T04:34:47.788Z',
    updatedAt: '2022-01-21T06:12:55.221Z',
    slug: 'fallen-paradise',
    subDomain: 'vancouversleepclinic',
    digitalReleaseDate: '2022-04-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3,
      preSaved: 2,
      optins: 2,
      visited: 3,
      lastUpdate: '2022-01-21T12:05:47.502Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T03:55:18.500Z',
    updatedAt: '2022-02-03T09:24:23.317Z',
    slug: 'juliastevens',
    subDomain: 'holliecol',
    digitalReleaseDate: '2022-04-14T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1025,
      preSaved: 555,
      optins: 556,
      visited: 1282,
      lastUpdate: '2022-01-21T09:06:53.723Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-19T00:00:33.902Z',
    updatedAt: '2022-01-19T00:09:03.166Z',
    slug: 'tester',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-17T00:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 5,
      preSaved: 3,
      optins: 3,
      visited: 4,
      lastUpdate: '2022-01-19T06:05:22.137Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-10T05:59:46.047Z',
    updatedAt: '2022-01-28T05:55:31.636Z',
    slug: 'aplacetofallapart',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-04-21T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 184,
      preSaved: 45,
      optins: 50,
      visited: 289,
      lastUpdate: '2022-01-10T12:04:20.564Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:54:23.855Z',
    updatedAt: '2021-11-08T23:02:52.841Z',
    slug: '64bars',
    subDomain: 'nerve',
    digitalReleaseDate: '2021-11-25T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1,
      preSaved: 1,
      optins: 2,
      visited: 4,
      lastUpdate: '2021-11-09T09:04:05.219Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:22:03.375Z',
    updatedAt: '2021-12-01T04:41:54.216Z',
    slug: 'OutofLove',
    subDomain: 'JOY',
    digitalReleaseDate: '2021-11-17T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 288,
      preSaved: 15,
      optins: 23,
      visited: 666,
      lastUpdate: '2021-11-09T03:04:48.964Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-10-21T23:01:30.635Z',
    updatedAt: '2022-01-26T21:05:15.803Z',
    slug: 'obsidian',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-31T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 11041,
      preSaved: 1248,
      optins: 1347,
      visited: 12207,
      lastUpdate: '2021-10-22T03:03:21.569Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-09-08T06:22:26.164Z',
    updatedAt: '2021-10-19T00:25:27.641Z',
    slug: 'reruns',
    subDomain: 'holliecol',
    digitalReleaseDate: '2021-10-06T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3034,
      preSaved: 475,
      optins: 683,
      visited: 4397,
      lastUpdate: '2021-09-08T09:01:53.874Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-08-26T03:43:34.359Z',
    updatedAt: '2022-01-26T21:06:40.786Z',
    slug: 'Zombie',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2021-09-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1448,
      preSaved: 161,
      optins: 235,
      visited: 2790,
      lastUpdate: '2021-08-26T06:03:57.886Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-31T05:15:27.817Z',
    updatedAt: '2022-01-31T05:18:30.295Z',
    slug: 'an-aural-symbiotic-mystery',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-01-26T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 7,
      preSaved: 0,
      optins: 0,
      visited: 0,
      lastUpdate: '2022-02-02T15:07:03.712Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T04:34:47.788Z',
    updatedAt: '2022-01-21T06:12:55.221Z',
    slug: 'fallen-paradise',
    subDomain: 'vancouversleepclinic',
    digitalReleaseDate: '2022-04-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3,
      preSaved: 2,
      optins: 2,
      visited: 3,
      lastUpdate: '2022-01-21T12:05:47.502Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-21T03:55:18.500Z',
    updatedAt: '2022-02-03T09:24:23.317Z',
    slug: 'juliastevens',
    subDomain: 'holliecol',
    digitalReleaseDate: '2022-04-14T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1025,
      preSaved: 555,
      optins: 556,
      visited: 1282,
      lastUpdate: '2022-01-21T09:06:53.723Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-19T00:00:33.902Z',
    updatedAt: '2022-01-19T00:09:03.166Z',
    slug: 'tester',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-17T00:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 5,
      preSaved: 3,
      optins: 3,
      visited: 4,
      lastUpdate: '2022-01-19T06:05:22.137Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2022-01-10T05:59:46.047Z',
    updatedAt: '2022-01-28T05:55:31.636Z',
    slug: 'aplacetofallapart',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2022-04-21T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 184,
      preSaved: 45,
      optins: 50,
      visited: 289,
      lastUpdate: '2022-01-10T12:04:20.564Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:54:23.855Z',
    updatedAt: '2021-11-08T23:02:52.841Z',
    slug: '64bars',
    subDomain: 'nerve',
    digitalReleaseDate: '2021-11-25T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1,
      preSaved: 1,
      optins: 2,
      visited: 4,
      lastUpdate: '2021-11-09T09:04:05.219Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-11-08T22:22:03.375Z',
    updatedAt: '2021-12-01T04:41:54.216Z',
    slug: 'OutofLove',
    subDomain: 'JOY',
    digitalReleaseDate: '2021-11-17T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 288,
      preSaved: 15,
      optins: 23,
      visited: 666,
      lastUpdate: '2021-11-09T03:04:48.964Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-10-21T23:01:30.635Z',
    updatedAt: '2022-01-26T21:05:15.803Z',
    slug: 'obsidian',
    subDomain: 'northlane',
    digitalReleaseDate: '2022-03-31T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 11041,
      preSaved: 1248,
      optins: 1347,
      visited: 12207,
      lastUpdate: '2021-10-22T03:03:21.569Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-09-08T06:22:26.164Z',
    updatedAt: '2021-10-19T00:25:27.641Z',
    slug: 'reruns',
    subDomain: 'holliecol',
    digitalReleaseDate: '2021-10-06T13:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 3034,
      preSaved: 475,
      optins: 683,
      visited: 4397,
      lastUpdate: '2021-09-08T09:01:53.874Z',
    },
  },
  {
    id: faker.random.uuid(),
    releaseId: release.id,
    artist: getArtist('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
    createdAt: '2021-08-26T03:43:34.359Z',
    updatedAt: '2022-01-26T21:06:40.786Z',
    slug: 'Zombie',
    subDomain: 'lisamitchell',
    digitalReleaseDate: '2021-09-28T14:00:00.000Z',
    workspaceId: 'c9b58b48-ac88-4da7-bfcf-21945538c49b',
    isArchived: false,
    ...generateSettings(backlinkBacklinkSettings),
    analytics: {
      clickedToStore: 1448,
      preSaved: 161,
      optins: 235,
      visited: 2790,
      lastUpdate: '2021-08-26T06:03:57.886Z',
    },
  },
];

export const getBacklink = (backlinkId: string) => {
  const backlink = backlinks.find((backlink) => backlink.id === backlinkId);
  return backlink;
};
