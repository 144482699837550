import React from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import FieldSet from 'shared/components/FieldSet';
import { BACKLINK_SETTINGS_FALLBACK_LOCALE } from 'shared/entities/backlinkSettings/backlinkSettings.constants';
import {
  PrereleaseCTAMode,
  StoreLink,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { fields as fieldsConfigsTemplates } from 'shared/entities/backlinkSettings/templates/backlinkFormFields.template';

import { StepKey } from '../../state';
import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import { EditorRichTextField } from '../fields/EditorRichTextField';
import { EditorSwitch } from '../fields/EditorSwitch';
import { EditorTextField } from '../fields/EditorTextField';
import EditorUrlField from '../fields/EditorUrlTextField';
import EditorFormFieldsManager from '../managers/EditorFormFieldsManager';
import EditorLocaleManager from '../managers/EditorLocaleManager';
import OptinsManager from '../managers/EditorOptinsManager';
import { EditorStoreLinksManager } from '../managers/EditorStoreLinksManager';
import { TextFieldOptions } from '../types';

import { FieldRow, EditorFormLabel } from '../fields/styled';
import * as EditorStyled from '../styled';
import * as Styled from './styled';

export const PreReleaseFormStep = () => {
  const { t } = useTranslation();

  const { stepKey, getFieldProps, currentLocale } =
    useBacklinkSettingsDataEditorContext();

  const isActivePath = `${stepKey}.isActive`;
  const { value: isActive } = getFieldProps(isActivePath);

  const richTextPath = `${stepKey}.locale.${currentLocale}.richText`;

  const formFieldsPath = `${stepKey}.locale.${currentLocale}.form.fields`;
  const { value: formFields = [] } = getFieldProps(formFieldsPath);

  const formSubmitButtonLabelPath = `${stepKey}.locale.${currentLocale}.form.submitButton.label`;
  const formSkipButtonLabelPath = `${stepKey}.locale.${currentLocale}.form.skipButton.label`;

  const defaultRedirectUrlPath = `${stepKey}.redirect.default.url`;

  const useStoresRedirectsPath = `${stepKey}.redirect.useStoresRedirects`;
  const { value: useStoresRedirects = false } = getFieldProps(
    useStoresRedirectsPath,
  );

  /**
   * redirect urls per Store
   * we'll merge the store redirect urls from this step
   * with the Stores from the ReleaseStep
   * as it serves as the source of truth for all stores settings for a Backlink
   */
  const storesRedirectsPath = `${stepKey}.redirect.stores`;
  // the stores redirect urls
  const {
    value: storesRedirects = [] as StoreLink[],
    changeHandler: storesChangeHandler,
  } = getFieldProps(storesRedirectsPath);

  // Compute text fields options for store redirects, based on their CTA mode
  const CTAsPath = `${StepKey.PrereleaseLanding}.locale.${currentLocale}.callToActions`;
  const { value: CTAs } = getFieldProps(CTAsPath);
  let storeRedirectTextFieldsOptions = new Map<string, TextFieldOptions>();
  Object.keys(CTAs.stores.options).forEach((storeId) => {
    storeRedirectTextFieldsOptions.set(storeId, {
      disabled:
        CTAs.stores.options[storeId].mode === PrereleaseCTAMode.DIRECT_LINK,
    });
  });

  return (
    <>
      <Styled.StepContent>
        <Styled.StepTitle>
          {t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.collect_fans.title',
            'Collect Fans',
          )}
        </Styled.StepTitle>
        <Styled.StepDescription>
          {t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.collect_fans.description',
            'After selecting their favorite store, fans will have a chance to request an email notification on release, and optionally to subscribe to the artist newsletter or competition.',
          )}
        </Styled.StepDescription>

        <FieldRow>
          <EditorFormLabel id="show-form-label">
            <strong>
              {t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.show_form.label',
                'Show custom form',
              )}
            </strong>
            <small>
              {' '}
              {t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.show_form.description',
                'Allows fans to pre-save by email/sms notification.',
              )}
            </small>
          </EditorFormLabel>
          <EditorSwitch
            {...getFieldProps(isActivePath)}
            inputProps={{ 'aria-labelledby': 'show-form-label' }}
          />
        </FieldRow>

        {isActive && (
          <>
            <FieldSet
              title={t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.rich_text.title',
                'Customize text',
              )}
              variant="condensed"
            >
              <EditorLocaleManager />
              <FieldRow
                css={css`
                  > * {
                    flex: 1;
                  }
                `}
              >
                <EditorRichTextField
                  label={t(
                    'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.rich_text.label',
                    'Rich Text',
                  )}
                  id={richTextPath}
                  key={richTextPath}
                  {...getFieldProps(richTextPath, { replace: true })}
                  options={{
                    withReplacementTokenSelect: true,
                  }}
                />
              </FieldRow>
              <EditorStyled.InfoMessage>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.rich_text.info',
                  'If you plan to notify fans on release, make sure to explain it clearly in this area.',
                )}
              </EditorStyled.InfoMessage>
            </FieldSet>
            <FieldSet
              title={t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.form_fields.title',
                'Form fields',
              )}
              variant="condensed"
            >
              <EditorFormFieldsManager
                path={formFieldsPath}
                key={formFieldsPath}
                {...getFieldProps(formFieldsPath)}
                value={formFields}
                fieldsConfigsTemplatesByLocale={fieldsConfigsTemplates}
                locale={currentLocale || BACKLINK_SETTINGS_FALLBACK_LOCALE}
              />
              <EditorStyled.InfoMessage>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.form_fields.info',
                  'Only select fields that you will use to notify fans.',
                )}
              </EditorStyled.InfoMessage>
            </FieldSet>
            <OptinsManager />
            <FieldSet
              title={t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.form_buttons.title',
                'Form buttons',
              )}
              variant="condensed"
            >
              <FieldRow>
                <EditorTextField
                  label={t(
                    'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.submit_button.title',
                    'Submit button',
                  )}
                  name={formSubmitButtonLabelPath}
                  {...getFieldProps(formSubmitButtonLabelPath)}
                />
              </FieldRow>
              <FieldRow>
                <EditorTextField
                  label={t(
                    'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.skip_button.title',
                    'Skip button',
                  )}
                  name={formSkipButtonLabelPath}
                  {...getFieldProps(formSkipButtonLabelPath)}
                />
              </FieldRow>
            </FieldSet>
          </>
        )}

        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.destination.title',
            'Destination',
          )}
          description={t(
            'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.fieldset.destination.description',
            'Redirect fans to a playlist, an instagram profile, a private YouTube teaser, etc.',
          )}
          variant="condensed"
        >
          <FieldRow>
            <EditorUrlField
              label={t(
                'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.default_link.label',
                'Default link',
              )}
              name={defaultRedirectUrlPath}
              {...getFieldProps(defaultRedirectUrlPath)}
            />
          </FieldRow>
          <FieldRow>
            <EditorFormLabel id="use-store-redirects-label">
              <strong>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.prereleaseFormStep.form.field.use_store_redirects.label',
                  'Add conditional links',
                )}
              </strong>
            </EditorFormLabel>
            <EditorSwitch
              {...getFieldProps(useStoresRedirectsPath)}
              inputProps={{ 'aria-labelledby': 'use-store-redirects-label' }}
            />
          </FieldRow>
          {useStoresRedirects && (
            <EditorStoreLinksManager
              id={storesRedirectsPath}
              changeHandler={storesChangeHandler}
              value={storesRedirects}
              textFieldsOptions={storeRedirectTextFieldsOptions}
            />
          )}
        </FieldSet>
      </Styled.StepContent>
    </>
  );
};
