import { Box, TextField as DSTextField } from '@believe-front/react-components';
import styled from '@emotion/styled';

/** TODO: replace with DS Component Card when stable */
export const ReleaseCardContainer = styled(Box)`
  border-radius: 12px;
  padding: 15px;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  margin-top: 4px;
  &:hover {
    border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }
`;

export const TextField = styled(DSTextField)`
  & .MuiInputBase-root {
    width: 15rem;
    height: 2.25rem;
  }
  fieldset {
    border-radius: 1.125rem;
    padding: 0.5rem 0.75rem;
  }
`;
