import { api } from 'shared/entities/api';
import { AppName } from 'shared/types';

import { getAppName } from '../../utils/app';
import { AcceptLegalCguPayload } from './auth.api.types';
import { AuthUser, AuthBaseUser } from './auth.types';

export const authApiBaseRoutesMap: Record<AppName, string> = {
  [AppName.marketingSuite]: '/auth',
  [AppName.backstageMarketing]: '/auth',
  [AppName.backstageMarketingResources]: '/auth',
};

export const getBaseRoute = () => authApiBaseRoutesMap[getAppName()];

type AuthUserWithWorkspaceWithoutSettings = AuthBaseUser & {
  workspace: {
    settings: null;
  };
};
export const getMe = async (
  params?: Record<string, string>,
): Promise<AuthUser> => {
  const response = await api.get<
    AuthUser | AuthUserWithWorkspaceWithoutSettings
  >(
    `${getBaseRoute()}/me${
      getAppName() === 'backstageMarketingResources' ? '/resources' : ''
    }`,
    {
      params,
    },
  );
  const authUser = response.data;
  return authUser;
};

export const logout = async (): Promise<string> => {
  const response = await api.post(`${getBaseRoute()}/logout`);
  return response.data;
};

export const acceptLegalCgu = async (payload: AcceptLegalCguPayload) => {
  await api.post(`/users/acceptLegalCgu`, payload);
};

export const getWordpressAutoLoginUri = async (): Promise<string> => {
  let response;
  if (getAppName() === AppName.backstageMarketingResources)
    response = await api.get(`/artistResources/autologin-uri`);
  else if (getAppName() === AppName.backstageMarketing)
    response = await api.get(`/auth/artist-resources-autologin-uri`);
  return response?.data;
};

export const getOldBacklinkUrl = async (): Promise<string> => {
  const response = await api.get(`${getBaseRoute()}/oldBacklinkUrl`);
  return response.data?.url;
};
