import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  CTAOptions,
  PrereleaseCTAMode,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { Container, Label } from './styled';

type Props = {
  storeName: string;
  CTAOptions: CTAOptions;
  changeHandler: (storeName: string, ctaOptions: CTAOptions) => void;
};

const CTAModeSwitch: React.FC<Props> = ({
  storeName,
  CTAOptions,
  changeHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FormControl component="fieldset">
        <Label>
          {t(
            'ui.component.backlink_settings_data_editor.cta_editor.cta_mode_switch.label',
            'On click, fans should:',
          )}
        </Label>
        <RadioGroup
          aria-label={t(
            'ui.component.backlink_settings_data_editor.cta_editor.cta_mode_switch.options_group_name',
            'CTA Mode',
          )}
          name={`${storeName}.mode`}
          value={CTAOptions.mode || PrereleaseCTAMode.DEFAULT}
          onChange={(event) => {
            changeHandler(storeName, {
              ...CTAOptions,
              mode: event.target.value as PrereleaseCTAMode,
            });
          }}
        >
          <FormControlLabel
            value={PrereleaseCTAMode.DEFAULT}
            control={<Radio color="primary" />}
            label={
              t(
                'ui.component.backlink_settings_data_editor.cta_editor.cta_mode_switch.options.pre-save',
                'Pre-save (recommended)',
              ) as string
            }
          />
          <FormControlLabel
            value={PrereleaseCTAMode.DIRECT_LINK}
            control={<Radio color="primary" />}
            label={
              t(
                'ui.component.backlink_settings_data_editor.cta_editor.cta_mode_switch.options.direct_link',
                'Go to store (if a link is available)',
              ) as string
            }
          />
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default CTAModeSwitch;
