import styled from '@emotion/styled';

import { Typography } from '@mui/material';

export const HelpText = styled(Typography)`
  font-size: 13px;
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[600]};

  margin: ${({ theme }) => theme.spacing(1)} 0;

  a {
    color: ${({ theme }) => theme.palette.grey[600]};
    font-weight: 600;
  }
`;
