import { useMutation } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as backlinkSettingsApi from 'shared/entities/backlinkSettings/backlinkSettings.api';
import * as storeConfigApi from 'shared/entities/storeConfig/storeConfig.api';
import { useAppQuery, QueryOptions } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';

export function useStoreConfigs(options: QueryOptions<'storesConfigs'> = {}) {
  const { data: storesConfigs, ...rest } = useAppQuery(
    'storesConfigs',
    options,
  );
  return { storesConfigs, ...rest };
}

export function useUpdateStoreConfig() {
  const { notify } = useNotification();

  const { mutate: updateStoreConfig, ...rest } = useMutation(
    storeConfigApi.updateStoreConfig,
    {
      onSuccess: (_, storeName) => {
        notify({
          type: AppNotificationTypes.Success,
          message: `Store ${storeName} successfully updated`,
        });
      },
      onError: (error, storeName) => {
        console.error(error);
        notify({
          type: AppNotificationTypes.Error,
          message: `Error updating store ${storeName}`,
        });
      },
    },
  );

  return { updateStoreConfig, ...rest };
}

export function useDeleteStoreConfig() {
  const { notify } = useNotification();

  const { mutate: deleteStoreConfig, ...rest } = useMutation(
    storeConfigApi.deleteStoreConfig,
    {
      onSuccess: (_, storeName) => {
        notify({
          type: AppNotificationTypes.Success,
          message: `Store ${storeName} successfully deleted`,
        });
      },
      onError: (error, storeName) => {
        console.error(error);
        notify({
          type: AppNotificationTypes.Error,
          message: `Error deleting store ${storeName}`,
        });
      },
    },
  );

  return { deleteStoreConfig, ...rest };
}

export function useCreateStoreConfig() {
  const { notify } = useNotification();

  const { mutate: createStoreConfig, ...rest } = useMutation(
    storeConfigApi.createStoreConfig,
    {
      onSuccess: (_, storeName) => {
        notify({
          type: AppNotificationTypes.Success,
          message: `Store ${storeName} successfully created`,
        });
      },
      onError: (error, storeName) => {
        console.error(error);
        notify({
          type: AppNotificationTypes.Error,
          message: `Error creating store ${storeName}`,
        });
      },
    },
  );

  return { createStoreConfig, ...rest };
}

export function useEnableAutoscan() {
  const { notify } = useNotification();

  const { mutate: enableAutoscan, ...rest } = useMutation(
    backlinkSettingsApi.enableAutoscan,
    {
      onSuccess: (_, storeName) => {
        notify({
          type: AppNotificationTypes.Success,
          message: `Autoscan enabled on store ${storeName}`,
        });
      },
      onError: (error, storeName) => {
        console.error(error);
        notify({
          type: AppNotificationTypes.Error,
          message: `An error occured while enabling autoscan on store ${storeName}`,
        });
      },
    },
  );

  return { enableAutoscan, ...rest };
}
