import React, { ReactElement, useEffect } from 'react';

import { Grid, ThemeProvider } from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';

import { Release } from 'shared/entities/release/release.types';
import { NEW_BACKLINK_PATH_ID } from 'shared/routes';

import { Footer } from './Footer';
import { Header } from './Header';
import { SelectArtist } from './SelectArtist';
import { SelectProduct } from './SelectProduct';
import { ActionType } from './context/actions';
import {
  ReleaseEditorContextProvider,
  useReleaseEditorContext,
} from './context/context';
import { ReleaseEditorStep } from './types';

type Props = {
  releaseId?: string;
  onReleaseCreated?: (createdRelease: Release) => Promise<void>;
  OpenModalButton: ReactElement | null;
  OpenModalButtonHelpText?: () => ReactElement;
  skipLocationCheck?: boolean;
};

const ReleaseEditor = ({
  OpenModalButton,
  OpenModalButtonHelpText,
  skipLocationCheck = false,
}: Props) => {
  const {
    state: { currentStep, releaseId },
    dispatch,
  } = useReleaseEditorContext();
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor',
  });
  const location = useLocation();
  const isDirectUrlAccess =
    location.pathname.endsWith(NEW_BACKLINK_PATH_ID) || skipLocationCheck;

  const modalIsOpen =
    currentStep !==
    (ReleaseEditorStep.UNINITIALIZED || ReleaseEditorStep.SAVED);

  useEffect(() => {
    if (!modalIsOpen && !!isDirectUrlAccess) {
      dispatch({
        type: ActionType.CHANGE_STEP,
        payload: { currentStep: ReleaseEditorStep.PRODUCT },
      });
    }
    // opening modal on direct url access on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* add a release button */}
      {OpenModalButton &&
        React.cloneElement(OpenModalButton, {
          onClick: () => {
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: { currentStep: ReleaseEditorStep.PRODUCT },
            });
          },
        })}
      {/* add a release button helptext */}
      {OpenModalButtonHelpText && <OpenModalButtonHelpText />}
      {/* modal */}
      {modalIsOpen && (
        <ThemeProvider>
          <Dialog
            open={modalIsOpen}
            role="dialog"
            aria-label={
              !!releaseId
                ? t('modal.aria_label.editor', 'Release editor')
                : t('modal.aria_label.creator', 'Release creator')
            }
            onClose={() =>
              dispatch({
                type: ActionType.CHANGE_STEP,
                payload: { currentStep: ReleaseEditorStep.UNINITIALIZED },
              })
            }
            fullWidth
          >
            <Grid container height={583} direction="column">
              <Header />
              <Grid item xs>
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    overflowY: 'auto',
                    height: '100%',
                    maxHeight: '399px',
                  }}
                >
                  {currentStep === ReleaseEditorStep.PRODUCT && (
                    <SelectProduct />
                  )}
                  {currentStep === ReleaseEditorStep.ARTIST && <SelectArtist />}
                </Grid>
              </Grid>
              <Footer />
            </Grid>
          </Dialog>
        </ThemeProvider>
      )}
    </>
  );
};

const ReleaseEditorWrapper = ({
  releaseId,
  onReleaseCreated,
  ...props
}: Props) => {
  return (
    <ReleaseEditorContextProvider
      releaseId={releaseId}
      onReleaseCreated={onReleaseCreated}
    >
      <ReleaseEditor {...props} />
    </ReleaseEditorContextProvider>
  );
};

export default ReleaseEditorWrapper;
