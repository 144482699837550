import { defaultBacklinkSettingsEmployee } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.defaults.one';
import { DEFAULT_TIMEZONE } from 'shared/utils/date';

import {
  WorkspaceEmployee,
  WorkspaceCustomer,
  CustomerServiceLevel,
} from '../../workspace.types';

export const workspaceMarketingSuiteFixture: WorkspaceEmployee = {
  id: '59092373-739e-44b7-8635-4768ad8686f1',
  name: 'ASD GLOBAL',
  defaultBacklinkSettingsId: defaultBacklinkSettingsEmployee.id,
  useDefaultLinkSettings: false,
  settings: {
    producerIds: [],
    customerServiceLevels: [CustomerServiceLevel.LAS],
    trackPitchTargetMarketIds: [],
    facebookPixelId: '',
    googleAdsId: '',
    privacyPolicyUrl: '',
    competitionRulesTemplate: '',
    competitionMinimumAge: 15,
    territory: 'World',
    legalNoticeUrl: '',
    defaultTimezone: DEFAULT_TIMEZONE,
  },
  users: [],
};

export const workspaceCustomerFixture: WorkspaceCustomer = {
  useDefaultLinkSettings: true,
  settings: {
    legalIdentity: 'Believe',
    userLocale: 'en',
    contactEmail: 'contact@believe.com',
    privacyPolicyUrl: 'https://legal.believe.com',
    marketingServices: 'Believe Marketing',
    defaultTimezone: DEFAULT_TIMEZONE,
    facebookPixelId: '',
    googleAdsId: '',
  },
};
