import { CreativeGraphQLTypes } from '@creative/sdk';

import { api } from '../api';
import {
  FetchCreativeMediasPayload,
  FetchCreativeMediasResponse,
  RequestCreativeMediaListGenerationPayload,
  FetchCreativeSpotlightsResponse,
} from './media.api.types';

export const CREATIVE_MEDIAS_DEFAULT_QUERY_LIMIT = 20;

export async function fetchCreativeMediasApiToken() {
  const results = await api.post<{ token: string }>('/creative/token');

  return results.data.token;
}

export const fetchCreativeMedias = async (
  requestPayload: FetchCreativeMediasPayload,
) => {
  const results = await api.get<FetchCreativeMediasResponse>('/creative', {
    params: requestPayload,
  });

  return results.data;
};

/**
 * ask the backend to pass on a request to the Creative service
 * to create the suggested medias (initial medias) for a Release
 */
export const requestSuggestedCreativeMediasCreation = async ({
  releaseId,
}: {
  releaseId: string;
}) => {
  const results =
    await api.post<CreativeGraphQLTypes.CreateSuggestedMediaMutationGraphQL>(
      `/creative/${releaseId}/suggested`,
    );

  return results.data;
};

/**
 * ask the backend to pass on a request to the Creative service
 * to generate medias
 */
export const requestCreativeMediasGeneration = async (
  payload: RequestCreativeMediaListGenerationPayload,
) => {
  const results = await api.post<
    (
      | CreativeGraphQLTypes.GenerateImageMutationGraphQL
      | CreativeGraphQLTypes.GenerateVideoMutationGraphQL
    )[]
  >(`/creative/generated`, payload);

  return results.data;
};

/**
 * get medias images as a zip archive
 */
export const getCreativeMediasArchive = async (creativeMediasIds: string[]) => {
  const results = await api.get(`/creative/generated`, {
    responseType: 'arraybuffer',
    params: {
      mediaIds: creativeMediasIds,
    },
  });

  return results.data;
};

/**
 * get a single creative media image
 */
export const getGeneratedMediaImage = async ({
  mediaId,
}: {
  mediaId: string;
}) => {
  const results = await api.get(`/creative/${mediaId}/generated`, {
    responseType: 'blob',
  });

  return results.data;
};

/**
 * archive creative media
 */
export const archiveCreativeMedia = async (mediaId: string | null) => {
  const results =
    // using POST & query param for now - waiting to see if this is intentional (vs a body that feels more natural for a post request?)
    await api.post<CreativeGraphQLTypes.ArchiveMediaMutationVariablesGraphQL>(
      `/creative/archive`,
      null, // no body
      {
        params: { mediaId },
      },
    );

  return results.data;
};

/**
 * get spotlights
 */
export const getCreativeSpotlights = async () => {
  const results = await api.get<FetchCreativeSpotlightsResponse>(
    `/creative/spotlights`,
    {
      responseType: 'json',
    },
  );
  return results.data.spotlights;
};
