export const test = {
  marketingSuiteHostname: 'ams-dev.blv.cloud',
  backstageMarketingHostname: 'backstage.ams-dev.blv.cloud',
  backstageMarketingResourcesHostname: 'resources.backstage.ams-dev.blv.cloud',
  marketingSuiteApiBaseUrl: '',
  backstageMarketingApiBaseUrl: '',
  assetsBaseUrl: 'https://assets.ams-sta.blv.cloud',
  imageBaseUrl: '',
  imageBucketName: '',
  backlinkBaseUrl: '',
  backstageBaseUrl: '',
  gaTrackers: {
    marketingSuite: {
      ga4Trackers: [
        {
          trackingId: '',
        },
      ],
    },
    backstageMarketing: {
      ga4Trackers: [
        {
          trackingId: '',
        },
      ],
    },
  },
  artistsResourcesWordpressUrl:
    'https://wordpress-artist-resources.ams-sta.blv.cloud',
  backstageMarketingOriginUrl: 'https://ams-test.blv.cloud/admin/autologin',
  backstageMarketingResourcesOriginUrl:
    'https://ams-test.blv.cloud/admin/autologin',
  gitlabFeatureFlagsProxy: {
    url: 'https://features.ams-sta.blv.cloud/ams-frontend',
    clientKey: process.env
      .REACT_APP_GITLAB_FEATURE_FLAGS_PROXY_CLIENT_KEY as string,
    // Refresh interval is 6 hours in seconds
    refreshInterval: 60 * 60 * 6,
    appName: 'staging',
    environment: 'staging',
  },
  creativeMediasGqlClientConfig: {
    env: 'sbx',
    uri: '',
  },
  zendeskGlobalMarketingKey: '7847394b-3ddb-430e-b375-06bf4b7240b5',
  zendeskEditorialMarketingProductKey: 'af26ef05-998c-4482-a49d-12dcfb3e8237',
  zendeskCustomerHelpCenterKey: '1e50c9e7-c0eb-40f3-a5e2-c3db3bddef8e',
  salesForceBelieveBaseUrl: 'https://believe-group.my.salesforce.com',
  salesForceBelieveHelpCenterUrl:
    'https://believe-group.my.site.com/HelpCenter',
  salesForceBelieveKey: '00D3X000003K7VS',
  salesForceBackstageMarketingKey: 'BackStage',
  salesForceMarketingSuiteKey: '',
  intranetBaseUrl: 'https://intra-03.test.believe.fr',
  teamsChatBaseUrl: 'https://teams.microsoft.com/l/chat/0/0',
  legacyBackstageAdsUrl: 'https://staging.backstageads.com',
} as const;
