import qs from 'query-string';

import { SortDirection } from 'shared/types/sort';

import { api } from '../api';
import { ExportStatusPayload } from '../api.types';
import {
  AutoFixRulePayload,
  CreateChannelPlaylistResponse,
  ExportAdminYoutubeChannelsScoresResponse,
  ExportYoutubeChannelsFixesPayload,
  ExportYoutubeChannelsFixesResponse,
  FetchChannelPlaylistResponse,
  FetchChannelPlaylistsResponse,
  FetchChannelVideosPayload,
  FetchChannelsPayload,
  FetchFindAndReplaceVideosMetadataPayload,
  PaginatedChannelsResponse,
  PostScrapperRulesCsvPayload,
  ReplaceInVideosMetadataPayload,
  ReplaceInVideosMetadataResponse,
  ReviewChannelPayload,
  UpdateChannelPlaylistResponse,
  UpdateRuleMetaPayload,
  YouTubeChannelContexts,
  YoutubeChannelPlaylistBulkAddVideosRequestStatusResponse,
} from './youTubeChannel.api.types';
import {
  PaginatedYouTubeChannelVideos,
  YouTubeChannel,
  YouTubeListSortBy,
} from './youTubeChannel.types';

export const channelsApiBaseRoute = '/youtubeChannels';

//#region GET
export async function fetchChannels({
  query = '',
  page = 0,
  limit = 20,
  sortBy = YouTubeListSortBy.POTENTIAL_VIEW_GAINS,
  sortDirection = SortDirection.DESC,
  accountNames = [],
  channelOwners = [],
  clientClassifications = [],
  countryUnits = [],
  digitalMarketingManagers = [],
  onlyPriorityChannels = false,
}: FetchChannelsPayload): Promise<PaginatedChannelsResponse> {
  const results = await api.get(channelsApiBaseRoute, {
    params: {
      query,
      page,
      limit,
      sortBy,
      sortDirection,
      accountNames,
      channelOwners,
      clientClassifications,
      digitalMarketingManagers,
      countryUnits,
      onlyPriorityChannels,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'comma' }),
  });
  return results.data;
}

export async function fetchChannel(channelId): Promise<YouTubeChannel> {
  const results = await api.get(`${channelsApiBaseRoute}/${channelId}`);
  return results.data;
}

export async function fetchChannelVideos({
  channelId,
  query = '',
  page = 0,
  limit = 20,
  excludedVideoIds = [],
  withCardsOnly = false,
  withEndScreenOnly = false,
}: FetchChannelVideosPayload): Promise<PaginatedYouTubeChannelVideos> {
  const results = await api.get(`${channelsApiBaseRoute}/${channelId}/videos`, {
    params: {
      query,
      page,
      limit,
      excluded: excludedVideoIds,
      withCardsOnly,
      withEndScreenOnly,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'comma' }),
  });
  return results.data;
}

export async function fetchContexts(): Promise<YouTubeChannelContexts> {
  const results = await api.get(`${channelsApiBaseRoute}/contexts`);
  return results.data;
}

export async function exportAdminYoutubechannelsScoresStatus(
  params: ExportStatusPayload,
): Promise<ExportAdminYoutubeChannelsScoresResponse> {
  const response = await api.get('/exports/admin/youtubeChannelScore/status', {
    params,
  });
  return response.data;
}

export async function exportYoutubeChannelsFixesStatus(
  params: ExportYoutubeChannelsFixesPayload,
): Promise<ExportYoutubeChannelsFixesResponse> {
  const response = await api.get('/exports/vmoChannelSuggestedFixes/status', {
    params,
  });
  return response.data;
}

export async function fetchChannelPlaylists(
  channelId: string,
): Promise<FetchChannelPlaylistsResponse> {
  const response = await api.get(
    `${channelsApiBaseRoute}/${channelId}/playlists`,
  );
  return response.data;
}

export async function fetchChannelPlaylist(
  channelId: string,
  playlistId?: string,
): Promise<FetchChannelPlaylistResponse | undefined> {
  if (!playlistId) return;
  const response = await api.get(
    `${channelsApiBaseRoute}/${channelId}/playlists/${playlistId}`,
  );
  return response.data;
}

export const fetchScrapperRules = async () => {
  const response = await api.get(
    `${channelsApiBaseRoute}/admin/features/access`,
  );
  return response.data;
};
//#endregion

//#region PATCH
export async function updateChannel({
  channelId,
  data,
}: ReviewChannelPayload): Promise<YouTubeChannel> {
  const results = await api.patch(
    `${channelsApiBaseRoute}/${channelId}/infos`,
    data,
  );
  return results.data;
}

export async function reviewChannel({
  channelId,
  data,
}: ReviewChannelPayload): Promise<YouTubeChannel> {
  const results = await api.patch(
    `${channelsApiBaseRoute}/${channelId}/infos/review`,
    data,
  );
  return results.data;
}

export async function updateRuleMeta({
  channelId,
  data,
}: UpdateRuleMetaPayload): Promise<YouTubeChannel> {
  const results = await api.patch(
    `${channelsApiBaseRoute}/${channelId}/benchmark/rules/metas/status`,
    data,
  );
  return results.data;
}

export async function updateChannelPlaylist(
  channelId: string,
  payload: { title: string; description?: string; videos?: string[] },
  playlistId?: string,
): Promise<UpdateChannelPlaylistResponse | undefined> {
  if (!playlistId) return;
  const response = await api.patch(
    `${channelsApiBaseRoute}/${channelId}/playlists/${playlistId}`,
    payload,
  );
  return response.data;
}

export async function updatePriorityChannels(payload: {
  priorityChannels: Array<{ externalChannelId: string }>;
}): Promise<void> {
  const response = await api.patch(
    `${channelsApiBaseRoute}/admin/priority`,
    payload,
  );
  return response.data;
}
//#endregion

//#region POST
export async function autoFixRule(
  channelId: string,
  payload: AutoFixRulePayload,
): Promise<YouTubeChannel> {
  const response = await api.post(
    `${channelsApiBaseRoute}/${channelId}/fixRule`,
    payload,
  );
  return response.data;
}
export async function exportAdminYoutubeChannelsScores(): Promise<ExportAdminYoutubeChannelsScoresResponse> {
  const response = await api.post('/exports/admin/youtubeChannelScore');
  return response.data;
}

export async function exportYoutubeChannelsFixes(
  payload: ExportYoutubeChannelsFixesPayload,
): Promise<ExportYoutubeChannelsFixesResponse> {
  const response = await api.post('/exports/vmoChannelSuggestedFixes', payload);
  return {
    hashReference: payload.externalChannelId,
    ...response.data,
  };
}

export async function createChannelPlaylist(
  channelId: string,
  payload: { title: string; description?: string; videos: string[] },
): Promise<CreateChannelPlaylistResponse> {
  const response = await api.post(
    `${channelsApiBaseRoute}/${channelId}/playlists`,
    payload,
  );
  return response.data;
}

export async function insertVideoInPlaylists(
  channelId: string,
  payload: { videoId: string; playlistsIds: string[] },
) {
  await api.post(
    `${channelsApiBaseRoute}/${channelId}/playlists/videos`,
    payload,
  );
}

export async function insertVideosInPlaylists(
  channelId: string,
  payload: { videosIds: string[]; playlistsIds: string[] },
) {
  await api.post(
    `${channelsApiBaseRoute}/${channelId}/playlists/videos/bulk`,
    payload,
  );
}

export async function playlistsBulkAddVideosRequestStatus(
  channelId: string,
): Promise<YoutubeChannelPlaylistBulkAddVideosRequestStatusResponse> {
  const response = await api.get(
    `${channelsApiBaseRoute}/${channelId}/playlists/bulkAddVideosRequest/status`,
  );

  return response.data;
}

export async function validateChangesInMetadata(
  channelId: string,
  payload: FetchFindAndReplaceVideosMetadataPayload,
): Promise<ReplaceInVideosMetadataResponse> {
  const response = await api.post(
    `${channelsApiBaseRoute}/${channelId}/videos/metadata/findAndReplace/batch/validate`,
    payload,
  );
  return response.data;
}

export async function replaceInVideosMetaData(
  channelId: string,
  payload: ReplaceInVideosMetadataPayload,
): Promise<ReplaceInVideosMetadataResponse> {
  const response = await api.post(
    `${channelsApiBaseRoute}/${channelId}/videos/metadata/findAndReplace/batch`,
    payload,
  );
  return response.data;
}

export const postScrapperRulesCsv = async (
  payload: PostScrapperRulesCsvPayload,
) => {
  const response = await api.post(
    `${channelsApiBaseRoute}/admin/features/access/batch`,
    payload,
  );
  return response.data;
};
//#endregion
