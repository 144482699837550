import React from 'react';

import { css } from '@emotion/react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { BelieveMenuIcon } from '.';

const BelieveBackIcon = () => {
  return (
    <div
      css={css`
        position: relative;
        width: 24px;
        height: 24px;
        overflow: hidden;
        & > * {
          position: absolute;
          transform: translate(0);
          left: 0;
          top: 0;
          transition: transform 0.3s ease-out;
        }
        & > *:nth-of-type(2) {
          transform: translate(100%);
        }
        &:hover {
          & > *:nth-of-type(1) {
            transform: translate(-100%);
          }
          & > *:nth-of-type(2) {
            transform: translate(0);
          }
        }
      `}
    >
      <BelieveMenuIcon />
      <ArrowBackIcon />
    </div>
  );
};

export default BelieveBackIcon;
