import { AuthUserCustomer } from 'shared/entities/auth/auth.types';
import { workspaceSettingsCustomerDefault } from 'shared/entities/workspace/workspace.model';
import { initialState as sharedInitialState } from 'shared/state';
import { AppState } from 'shared/state/types';

export const initialState: AppState<AuthUserCustomer> = {
  ...sharedInitialState,
  user: {
    ...sharedInitialState.user,
    externalId: '',
    legalIdentity: '',
    workspace: {
      useDefaultLinkSettings: true,
      settings: workspaceSettingsCustomerDefault,
    },
    role: {},
    producerBackstagePrograms: [],
    programs: [],
  },
};
