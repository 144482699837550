import styled from '@emotion/styled';

import { transientOptions } from 'shared/utils/emotion-interop';

export const Container = styled('div', transientOptions)<{ $error?: boolean }>`
  position: relative;
  width: 100%;
  ${({ theme, $error }) =>
    `border: solid ${theme.palette.error.main} ${$error ? '1px' : '0px'}`}
`;
