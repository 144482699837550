import {
  BacklinkSettingsData,
  BacklinkSettingsParentEntityType,
  BacklinkSettingsStepsTemplate,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { StoresConfigs } from 'shared/entities/storeConfig/storeConfig.types';
import { ActionMap } from 'shared/state/types';

import { BacklinkSettingsDataUpdateSettingOptions } from './state';

export enum ActionsTypes {
  Init = 'INIT',
  UpdateSetting = 'UPDATE_SETTING',
  AddLocaleSettings = 'ADD_LOCALE_SETTINGS',
  DeleteLocaleSettings = 'DELETE_LOCALE_SETTINGS',
  CTAsReleaseStoresSyncRequired = 'CTAS_RELEASE_STORES_SYNC_REQUIRED',
}

export type InitPayload = {
  parentEntityType?: BacklinkSettingsParentEntityType;
  initialState: BacklinkSettingsData;
};

export type UpdateSettingPayload = {
  path: string;
  data: any;
  options?: BacklinkSettingsDataUpdateSettingOptions;
};

export type AddLocaleSettingsPayload = {
  locale: string;
  settings: BacklinkSettingsStepsTemplate;
  parentEntityType?: BacklinkSettingsParentEntityType;
  storesConfigs: StoresConfigs;
};

export type DeleteLocaleSettingsPayload = {
  locale: string;
};

export type CTAsReleaseStoresSyncRequiredPayload = {
  storesConfigs: StoresConfigs;
};

export type Payload = {
  [ActionsTypes.Init]: InitPayload;
  [ActionsTypes.UpdateSetting]: UpdateSettingPayload;
  [ActionsTypes.AddLocaleSettings]: AddLocaleSettingsPayload;
  [ActionsTypes.DeleteLocaleSettings]: DeleteLocaleSettingsPayload;
  [ActionsTypes.CTAsReleaseStoresSyncRequired]: CTAsReleaseStoresSyncRequiredPayload;
};

/** actions with their corresponding payload shape */
export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];
