import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import {
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
} from '@mui/material';

import { useClipboard } from 'shared/hooks/useClipboard';

import * as Styled from './styled';

type Props = {
  url: string;
  copyOnMount?: boolean;
  inputProps?: Partial<TextFieldProps>;
};

const ClipboardUrlTextfield = ({
  url,
  copyOnMount = false,
  inputProps = {},
}: Props) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useClipboard();

  useEffect(() => {
    if (copyOnMount) {
      copyToClipboard(url);
    }
  }, [copyOnMount, copyToClipboard, url]);

  return (
    <Styled.TextField
      fullWidth
      variant="outlined"
      value={url}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={
                t(
                  'ui.component.clipboard_url_field.button.label',
                  'Copy URL in your clipboard',
                ) as string
              }
            >
              <IconButton
                onClick={() => copyToClipboard(url)}
                aria-label={t(
                  'ui.component.clipboard_url_field.button.label',
                  'Copy URL in your clipboard',
                )}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...inputProps}
    />
  );
};

export default ClipboardUrlTextfield;
