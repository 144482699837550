import { timeZonesNames } from '@vvo/tzdb';

export const TIMEZONES = timeZonesNames;

export const HOURS = [
  { value: '00', display: '12 AM' },
  { value: '01', display: '1 AM' },
  { value: '02', display: '2 AM' },
  { value: '03', display: '3 AM' },
  { value: '04', display: '4 AM' },
  { value: '05', display: '5 AM' },
  { value: '06', display: '6 AM' },
  { value: '07', display: '7 AM' },
  { value: '08', display: '8 AM' },
  { value: '09', display: '9 AM' },
  { value: '10', display: '10 AM' },
  { value: '11', display: '11 AM' },
  { value: '12', display: '12 PM' },
  { value: '13', display: '1 PM' },
  { value: '14', display: '2 PM' },
  { value: '15', display: '3 PM' },
  { value: '16', display: '4 PM' },
  { value: '17', display: '5 PM' },
  { value: '18', display: '6 PM' },
  { value: '19', display: '7 PM' },
  { value: '20', display: '8 PM' },
  { value: '21', display: '9 PM' },
  { value: '22', display: '10 PM' },
  { value: '23', display: '11 PM' },
];

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Fetching = 'fetching',
  Saving = 'saving',
  Done = 'done',
}

export enum AppNotificationTypes {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}
