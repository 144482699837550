import React from 'react';

import { Toolbar, AppBarProps } from '@mui/material';

import * as Styled from './styled';

type Props = {
  children?: React.ReactNode;
  className?: string;
  titlePrefix?: React.ReactNode;
  title: React.ReactNode;
  content?: React.ReactNode;
  position?: AppBarProps['position'];
};

const Header: React.FC<Props> = ({
  titlePrefix,
  title,
  content,
  position = 'static',
  className,
}) => {
  return (
    <Styled.CustomAppBar position={position} className={className}>
      <Toolbar>
        {titlePrefix && <Styled.PreTitle>{titlePrefix}</Styled.PreTitle>}
        <Styled.Title>{title}</Styled.Title>
        <Styled.Content>{content}</Styled.Content>
      </Toolbar>
    </Styled.CustomAppBar>
  );
};

export default Header;
