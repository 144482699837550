import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';

import { Snackbar, SnackbarProps } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';

import { AppNotification } from '../../types';

const Line = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1 / 2)};
  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  notification: AppNotification;
} & SnackbarProps &
  AlertProps;

const AppNotificationBar = React.forwardRef(function (
  { notification }: Props,
  ref,
) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [notification]);
  const { type, message } = notification;

  return (
    <Snackbar
      ref={ref}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        elevation={6}
        variant="filled"
      >
        {message.split('\n').map((item, i) => (
          <Line key={i}>{item}</Line>
        ))}
      </Alert>
    </Snackbar>
  );
});

export default AppNotificationBar;
