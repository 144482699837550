import React, { useMemo } from 'react';

import { Box, TabsProps as MuiTabsProps } from '@mui/material';

import * as Styled from './styled';

type TabsComposition = {
  Tab: typeof Styled.Tab;
  Panel: React.FC<TabPanelProps>;
};

type TabsProps = Partial<MuiTabsProps> & {
  renderActions?: (props: { currentTab: number }) => React.ReactNode;
  renderRightSlot?: (props: { currentTab: number }) => React.ReactNode;
};

export const Tabs: React.FC<TabsProps> & TabsComposition = ({
  children,
  renderActions,
  renderRightSlot,
  ...props
}) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  let tabIndex = 0;
  let panelIndex = 0;

  const rightSlot = useMemo(() => {
    if (!renderRightSlot) {
      return null;
    }

    return renderRightSlot({ currentTab });
  }, [renderRightSlot, currentTab]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!!renderActions && <Box>{renderActions({ currentTab })}</Box>}
        <Styled.Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          textColor="primary"
          indicatorColor="primary"
          hideUnderline={!!rightSlot}
          sx={{
            flex: 1,
          }}
          {...props}
        >
          {React.Children.map(children, (child) => {
            const item = child as React.ReactElement<
              React.PropsWithChildren<TabsProps>
            >;
            if (item.type === Styled.Tab) {
              const tab = React.cloneElement(item, {
                id: `tab-${tabIndex}`,
                'aria-controls': `tabpanel-${tabIndex}`,
              });
              tabIndex++;
              return tab;
            }
          })}
        </Styled.Tabs>

        {renderRightSlot && <Box>{rightSlot}</Box>}
      </Box>
      {React.Children.map(children, (child) => {
        const item = child as React.ReactElement<
          React.PropsWithChildren<TabPanelProps>
        >;
        if (item.type === TabPanel) {
          const panel = React.cloneElement(item, {
            current: currentTab,
            index: panelIndex,
          });
          panelIndex++;
          return panel;
        }
      })}
    </>
  );
};

/**
 * Accessible tab panel
 * @note current and index should not be optional,
 * but they are provided by the Tabs component when
 * cloning the tab panel. This is a workaround to
 * use TabPanel without props while consuming the composition
 */
type TabPanelProps = {
  current?: number;
  index?: number;
  children?: React.ReactNode;
};
const TabPanel: React.FC<TabPanelProps> = ({
  current = 0,
  index = 0,
  children,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={current !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      {current === index && children}
    </div>
  );
};

Tabs.Tab = Styled.Tab;
Tabs.Panel = TabPanel;
