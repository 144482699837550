import moment, { Moment } from 'moment';

type DateRange = {
  maxDateTime?: Moment;
  minDateTime?: Moment;
};

export const getTimestamp = (date: Moment | undefined): number =>
  date?.clone().startOf('day')?.valueOf() || 0;

export const getHour = (date: Moment | undefined): number => date?.hours() || 0;

export const hasNotMinOrMaxDatesParams = ({
  maxDateTime,
  minDateTime,
}: DateRange): boolean => !maxDateTime || !minDateTime;

export const isDateOutsideBounds = ({
  date,
  maxDateTime,
  minDateTime,
}: DateRange & {
  date: string;
}): boolean => {
  const dateWithoutTime = moment(date).clone().startOf('day');
  const dateMinMinus1DayWithoutTime =
    minDateTime?.clone().startOf('day').add(-1, 'days')?.valueOf() || 0;

  const dateMaxPlus1DayWithoutTime =
    maxDateTime?.clone().startOf('day').add(1, 'days')?.valueOf() || 0;

  const dateInTimestamp = dateWithoutTime?.valueOf() || 0;

  return (
    dateInTimestamp < dateMinMinus1DayWithoutTime ||
    dateInTimestamp > dateMaxPlus1DayWithoutTime
  );
};

export const isStringDateEqualToMomentDate = ({
  date,
  momentDate,
}: {
  date: string;
  momentDate?: Moment;
}): boolean => {
  const dateWithoutTime = moment(date).clone().startOf('day');
  const date1InTimestamp = dateWithoutTime?.valueOf() || 0;
  const date2InTimestamp = getTimestamp(momentDate);

  return date1InTimestamp === date2InTimestamp;
};
