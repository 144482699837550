import React from 'react';

import { Route, RouteObject } from 'react-router-dom';

import { UserEmployeePrograms } from 'shared/entities/auth/auth.types';
import { AppName } from 'shared/types';

import { ProtectedRoute } from './ProtectedRoute';

export const BACKLINKS_BASE_PATH_ID = 'links';
export const BACKLINKS_MARKETING_SUITE_BASE_PATH = `/campaigns/${BACKLINKS_BASE_PATH_ID}`;
export const BACKLINKS_BACKSTAGE_MARKETING_BASE_PATH = `/campaigns/${BACKLINKS_BASE_PATH_ID}`;
export const NEW_BACKLINK_PATH_ID = 'new';
export const BACKLINK_EDIT_PATH_ID = 'edit';
export const BACKLINK_EDIT_V2_PATH_ID = 'edit-v2';
export const BACKLINK_ANALYTICS_PATH_ID = 'analytics';

/**
 * we need to be able to easily get the Links list path
 * e.g. to redirect back from the BacklinkSettings editor
 */
export const backlinkBaseUrlMap: Record<AppName, string> = {
  [AppName.marketingSuite]: BACKLINKS_MARKETING_SUITE_BASE_PATH,
  [AppName.backstageMarketing]: BACKLINKS_BACKSTAGE_MARKETING_BASE_PATH,
  [AppName.backstageMarketingResources]: `/${BACKLINKS_BASE_PATH_ID}`,
};

export interface RouteConfig {
  title: string;
  path: string;
  exact?: boolean;
  disabled?: boolean;
  isSecret?: boolean;
  adminOnly?: boolean;
  bypassAuth?: boolean;
  redirectPath?: string;
  Component?: React.FC;
  /**
   * the Believe User Program that corresponds to this Route
   * e.g.
   * 'backlink' program -> 'Links' route
   * this property should allow to easily configure what routes / navigation items exist for a User
   */
  userProgram?: UserEmployeePrograms;
  subRoutes?: RouteObject[];
}

export const buildRoutes = (
  routes: RouteConfig[],
  extraProps?: Record<string, any>,
) => {
  return routes
    .map((route: RouteConfig) => {
      // we want to be able to include 'external routes' in an array of routes
      // because we might build other lists (e.g. menu items) from it and use how it's ordered
      // but don't want an internal route to be created in those cases
      const isExternal = !!route.path.match(/^http/);

      const isValid = (!route.disabled || route.isSecret) && !isExternal;

      if (isValid && route.Component) {
        return (
          <Route
            key={route.path}
            path={`${route.path}${!route.exact && '/*'}`}
            element={
              <ProtectedRoute
                path={route.path}
                key={route.path}
                adminOnly={route.adminOnly || false}
                bypassAuth={route.bypassAuth || false}
                redirectPath={route.redirectPath}
              >
                <route.Component {...extraProps} />
              </ProtectedRoute>
            }
          />
        );
      }
      return <React.Fragment key={route.path} />;
    })
    .filter(Boolean);
};
