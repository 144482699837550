export const BACKLINKS_PER_PAGE_DEFAULT = 10;

export const BACKLINK_OPTIN_COMPETITION_MIN_AGE = 15;
export const BACKLINK_OPTIN_COMPETITION_MAX_AGE = 99;
export const BACKLINK_OPTIN_COMPETITION_AGES = [
  ...new Array(
    BACKLINK_OPTIN_COMPETITION_MAX_AGE - BACKLINK_OPTIN_COMPETITION_MIN_AGE + 1,
  ),
].map((_, index) => index + BACKLINK_OPTIN_COMPETITION_MIN_AGE);
export const BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT = 3;

export const VALID_SUBDOMAIN_REGEXP =
  /^(?:(?![()@:%_+.~#?&//=!$&*,;'[\]\s]).)+$/u;

export const VALID_SLUG_REGEXP = /^[^()@:%_+.~#?&//=!;'[\]\s]{1,}$/u;

export enum AutoNotifyStatus {
  WAITING = 'WAITING',
  STARTED = 'STARTED',
  WAITING_RETRY = 'WAITING_RETRY',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export const DEFAULT_DSP_ACTIONS_COUNT = {
  FOLLOW_ARTIST: 0,
  FOLLOW_USER: 0,
  ADD_PLAYLIST: 0,
  ADD_ALBUM: 0,
  ADD_TRACK: 0,
};
