import { useCallback } from 'react';

import { AppNotificationTypes } from 'shared/constants';
import { BacklinkAnalyticsEndpoints } from 'shared/entities/backlink/analytics/backlink.analytics.api';
import { backlinkSettingsDataForParentEntityFetchers } from 'shared/entities/backlinkSettings/backlinkSettings.api';
import { BacklinkSettingsParentEntityType } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import * as imageApi from 'shared/entities/image/image.api';
import * as releaseApi from 'shared/entities/release/release.api';

import { useNotification } from './useNotification';
import { useStatus } from './useStatus';

export { BacklinkAnalyticsEndpoints };

export function useApi() {
  const { notify } = useNotification();
  const [status] = useStatus();

  /** Releases */
  const fetchReleases = useCallback(async () => {
    let results;
    try {
      results = await releaseApi.fetchReleases({});
    } catch (error: any) {
      notify(
        {
          type: AppNotificationTypes.Error,
          message: `Error fetching Releases: ${error}`,
        },
        error,
      );
    }
    return results;
  }, [notify]);

  const fetchRelease = useCallback(
    async (releaseId) => {
      let results;
      try {
        results = await releaseApi.fetchRelease(releaseId);
      } catch (error: any) {
        notify(
          {
            type: AppNotificationTypes.Error,
            message: `Error fetching Release ${releaseId}: ${error}`,
          },
          error,
        );
      }
      return results;
    },
    [notify],
  );

  const refreshRelease = useCallback(
    async (releaseId) => {
      let results;
      try {
        results = await releaseApi.refreshRelease(releaseId);
      } catch (error: any) {
        notify(
          {
            type: AppNotificationTypes.Error,
            message: `Error refreshing Release ${releaseId}: ${error}`,
          },
          error,
        );
      }
      return results;
    },
    [notify],
  );

  /** Image */
  const uploadImage = useCallback(
    async (file: string) => {
      let results;
      try {
        results = await imageApi.uploadImage(file);
      } catch (error: any) {
        notify(
          {
            type: AppNotificationTypes.Error,
            message: `Error when uploading image : ${error}`,
          },
          error,
        );
      }
      return results;
    },
    [notify],
  );

  type FetchBacklinkSettingsForParentEntityPayload = {
    parentEntityType: BacklinkSettingsParentEntityType;
    parentEntityId: string;
  };
  const fetchBacklinkSettingsForParentEntity = useCallback(
    async ({
      parentEntityType,
      parentEntityId,
    }: FetchBacklinkSettingsForParentEntityPayload) => {
      const fetch =
        backlinkSettingsDataForParentEntityFetchers[parentEntityType];
      try {
        return fetch(parentEntityId);
      } catch (error: any) {
        notify(
          {
            type: AppNotificationTypes.Error,
            message: `Error when fetching Backlink Settings : ${error}`,
          },
          error,
        );
      }
    },
    [notify],
  );

  return {
    status,
    refreshRelease,
    fetchRelease,
    fetchReleases,
    fetchBacklinkSettingsForParentEntity,
    uploadImage,
  };
}
