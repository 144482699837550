import { useQueryClient } from 'react-query';

import { fetchersByQueryKey } from 'shared/entities/fetchersByQueryKey';

type Fetchers = typeof fetchersByQueryKey;
type QueryKeys = keyof Fetchers;

/**
 * Custom hook useInvalidateQueries
 *
 * Custom hook that simplifies the use of react-query invalidateQueries
 * for the app's common fetchers.
 *
 * Example Usage
 * const {invalidate: invalidateArtists} = useInvalidateQueries('artists')
 * invalidateArtists()
 */
export function useInvalidateQueries(key: QueryKeys) {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(key);
}
