import { Grid, MuiIcons, Typography } from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';

import { ButtonBase } from '@mui/material';

export const ErrorScreen = ({ refetch }: { refetch: () => void }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor',
  });
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography
        justifyContent="center"
        textAlign="center"
        color="secondary"
        sx={{ padding: '84px', lineHeight: '1.125rem' }}
      >
        {t('failure', "We couldn't load the content")}.
        <br />
        <ButtonBase
          onClick={(e) => {
            e.preventDefault();
            refetch();
          }}
        >
          <MuiIcons.Autorenew color="primary" />
          <Typography variant="body1" color="primary">
            {t('hint.try_again', 'Try again')}.
          </Typography>
        </ButtonBase>
      </Typography>
    </Grid>
  );
};
