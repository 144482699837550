import { api } from '../../api';
import { FetchBacklinkAnalyticsPayload } from './backlink.analytics.types';

const backlinkBaseApiPath = '/backlinks';

export enum BacklinkAnalyticsEndpoints {
  'overview' = 'overviewInsight',
  'sourcesByReferrer' = 'referrersSources',
  'sourcesByChannel' = 'channelsSources',
  'storeClicksByStore' = 'storesConversionClicks',
  'storeClicksByStoreType' = 'storesConversionTypes',
  'locationsByCountry' = 'countriesVisits',
  'locationsByCity' = 'citiesVisits',
  'devices' = 'devicesVisits',
  'presaves' = 'actionsConversionPresave',
  'fanNotification' = 'autonotify',
  'optins' = 'subscribersConversion',
}

export const makeFetchBacklinkAnalyticsDataFn = <TData>(
  endpoint: BacklinkAnalyticsEndpoints,
) => async ({
  backlinkId,
  startDay,
  endDay,
}: FetchBacklinkAnalyticsPayload): Promise<TData> => {
  const results = await api.get<TData>(
    `${backlinkBaseApiPath}/${backlinkId}/analytics/${endpoint}`,
    { params: { startDay, endDay } },
  );
  return results.data;
};
