import styled from '@emotion/styled';

export const FULLSCREEN_LAYOUT_Z_INDEX = 1200;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${FULLSCREEN_LAYOUT_Z_INDEX};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const ActionsContainer = styled.div`
  position: absolute;
  z-index: ${+FULLSCREEN_LAYOUT_Z_INDEX + 1};
  top: ${({ theme }) => theme.spacing(1)};
  left: ${({ theme }) => theme.spacing(1)};
`;

export const Content = styled.div`
  height: 100%;
  > * {
    height: 100%;
  }
`;
