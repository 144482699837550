import { LocaleInfosList } from './backlinkSettings.locale.types';

export const localesInfos: LocaleInfosList = {
  en: {
    code: 'en',
    displayName: 'English',
  },
  fr: {
    code: 'fr',
    displayName: 'French',
  },
  de: {
    code: 'de',
    displayName: 'German',
  },
  it: {
    code: 'it',
    displayName: 'Italian',
  },
  es: {
    code: 'es',
    displayName: 'Spanish',
  },
  pt: {
    code: 'pt',
    displayName: 'Portuguese',
  },
  ru: {
    code: 'ru',
    displayName: 'Russian',
  },
  ar: {
    code: 'ar',
    displayName: 'Arabic',
  },
  zh: {
    code: 'zh',
    displayName: 'Chinese',
  },
  'zh-Hans': {
    code: 'zh-Hans',
    displayName: 'Chinese (Simplified)',
  },
  'zh-Hant': {
    code: 'zh-Hant',
    displayName: 'Chinese (Traditional)',
  },
  hi: {
    code: 'hi',
    displayName: 'Hindi',
  },
  id: {
    code: 'id',
    displayName: 'Indonesian',
  },
  jv: {
    code: 'jv',
    displayName: 'Javanese',
  },
  su: {
    code: 'su',
    displayName: 'Sundanese',
  },
  fil: {
    code: 'fil',
    displayName: 'Filipino',
  },
  uk: {
    code: 'uk',
    displayName: 'Ukrainian',
  },
  th: {
    code: 'th',
    displayName: 'Thai',
  },
  vi: {
    code: 'vi',
    displayName: 'Vietnamese',
  },
  tr: {
    code: 'tr',
    displayName: 'Turkish',
  },
  pl: {
    code: 'pl',
    displayName: 'Polish',
  },
  ja: {
    code: 'ja',
    displayName: 'Japanese',
  },
  'zh-tw': {
    code: 'zh-tw',
    displayName: 'Chinese (Taiwan)',
  },
  'zh-sg': {
    code: 'zh-sg',
    displayName: 'Chinese (Singapore)',
  },
  ms: {
    code: 'ms',
    displayName: 'Malay',
  },
};

/* for i18next parser

t('misc.supportedLocale.en.languageName', 'English');
t('misc.supportedLocale.fr.languageName', 'French');
t('misc.supportedLocale.de.languageName', 'German');
t('misc.supportedLocale.it.languageName', 'Italian');
t('misc.supportedLocale.es.languageName', 'Spanish');
t('misc.supportedLocale.pt.languageName', 'Portuguese');
t('misc.supportedLocale.ru.languageName', 'Russian');
t('misc.supportedLocale.ar.languageName', 'Arabic');
t('misc.supportedLocale.zh.languageName', 'Chinese');
t('misc.supportedLocale.zh-Hans.languageName',  'Chinese (Simplified)');
t('misc.supportedLocale.zh-Hant.languageName',  'Chinese (Traditional)');
t('misc.supportedLocale.hi.languageName', 'Hindi');
t('misc.supportedLocale.id.languageName', 'Indonesian');
t('misc.supportedLocale.jv.languageName', 'Javanese');
t('misc.supportedLocale.su.languageName', 'Sundanese');
t('misc.supportedLocale.fil.languageName', 'Filipino');
t('misc.supportedLocale.uk.languageName', 'Ukrainian');
t('misc.supportedLocale.th.languageName', 'Thai');
t('misc.supportedLocale.vi.languageName', 'Vietnamese');
t('misc.supportedLocale.tr.languageName', 'Turkish');
t('misc.supportedLocale.pl.languageName', 'Polish');
t('misc.supportedLocale.ja.languageName', 'Japanese');
t('misc.supportedLocale.zh-tw.languageName', 'Chinese (Taiwan)');
t('misc.supportedLocale.zh-sg.languageName', 'Chinese (Singapore)');
t('misc.supportedLocale.ms.languageName', 'Malay');

*/

export const fetchLocalesInfos = (): Promise<typeof localesInfos> =>
  new Promise((resolve) => {
    resolve(localesInfos);
  });

export const makeLocalesInfosArrayFromLocaleCodesArray = (
  localesCodes: string[],
) => {
  return localesCodes.map((localeCode) => localesInfos[localeCode]);
};
