import React, { useEffect } from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Tooltip, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { FlatFab } from 'shared/components/Buttons/styled';
import FieldSet from 'shared/components/FieldSet';
import { AppNotificationTypes } from 'shared/constants';
import { Backlink } from 'shared/entities/backlink/backlink.types';
import {
  getBacklinkUrl,
  getDomainLabel,
} from 'shared/entities/backlink/backlink.utils';
import { useFetchBacklink } from 'shared/hooks/entities/backlink';
import { useNotification } from 'shared/hooks/useNotification';
import { useScreenSize } from 'shared/hooks/useScreenSize';

import useValidateUrl from '../../../hooks/useValidateUrl';
import { messages } from '../../../messages';
import { useBacklinkSettingsDataEditorContext } from '../../../state/context';
import EditorTextField from '../EditorTextField';
import SlugValidationMessage from './SlugValidationMessage';

type Props = {
  backlinkId: Backlink['id'];
  artist: Backlink['artist'];
};

/**
 * Type of the list of domains
 */
type DomainProps = {
  /**
   * label format: subDomain + backlinkBaseUrl without https
   */
  label: string;
  /**
   * value to send to the API (artist subDomain alone)
   */
  value: string;
};
/**
 * Used by Backlink Edior V1
 */
const EditorBacklinkUrlFields = ({ backlinkId, artist }: Props) => {
  const { t } = useTranslation();
  const { stepKey, getFieldProps } = useBacklinkSettingsDataEditorContext();
  const { notify } = useNotification();
  const { isDesktop } = useScreenSize();
  const { invalidateQuery: invalidateBacklinkQuery } =
    useFetchBacklink(backlinkId);

  const subDomainPath = `${stepKey}.url.subDomain`;
  const subDomainFieldProps = getFieldProps(subDomainPath);
  const slugPath = `${stepKey}.url.slug`;
  const { value: slugEditorValue = '' } = getFieldProps(slugPath);

  const artistSubdomain = artist?.data.subDomain;

  /**
   * Just added artist subdomain
   *
   * we might want to force the artist subdomain to be used
   * e.g. when the User has been adding a subdomain to the artist (in the artist settings panel)
   */
  const useArtistSubdomainRef = React.useRef(false);
  const useArtistSubdomain = useArtistSubdomainRef.current;
  const setUseArtistSubdomain = (value: boolean) => {
    useArtistSubdomainRef.current = value;
  };

  /**
   * we sometimes need to prevent url validation
   * using a ref to keep the value between renders and avoid re-rendering this component
   */
  const shouldValidateUrlRef = React.useRef(true);
  const shouldValidateUrl = shouldValidateUrlRef.current;
  const setShouldValidateUrl = (value: boolean) => {
    shouldValidateUrlRef.current = value;
  };

  const handleArtistPanelOpen = () => {
    // we want to prevent the url validation to occur before we have the updated artist data (with the added subdomain)
    // we will disable the url validation until we have the updated artist data
    // this is because even though the validation will actually occur with the new subdomain,
    // another validation with no subdomain was triggered before the one with the new subdomain
    // the response from this api call sometimes finishes after the one with the updated artist data (race condition)
    // resulting in a false negative
    setShouldValidateUrl(false);
  };
  const handleArtistPanelClose = () => {
    // we want to force the artist subdomain to be used
    setUseArtistSubdomain(true);

    // we need to invalidate the backlink query to get the updated artist data with the added subdomain
    invalidateBacklinkQuery();
  };

  // when the artist subdomain is updated, we want to update the subdomain field value
  // this will re-render this component and trigger the url validation
  useEffect(() => {
    if (artistSubdomain && useArtistSubdomain) {
      subDomainFieldProps.changeHandler(artistSubdomain);

      setUseArtistSubdomain(false);

      // now we may / should allow validation again (see handleArtistPanelOpen)
      // this is because the artist subdomain has been updated
      // and the url validation will occur with the new subdomain
      setShouldValidateUrl(true);
    }
  }, [artistSubdomain, subDomainFieldProps, useArtistSubdomain]);

  /**
   * Select domain
   */
  const domainSelectOptions = [
    { label: getDomainLabel(''), value: '' },
    ...(artist?.data?.subDomain
      ? [
          {
            label: getDomainLabel(artist.data.subDomain),
            value: artist.data.subDomain,
          },
        ]
      : []),
  ];

  const domainSelectValue = {
    label: getDomainLabel(subDomainFieldProps.value),
    value: subDomainFieldProps.value,
  };

  /**
   * URL validation
   */

  const { urlValidationInfos } = useValidateUrl({
    subDomain: subDomainFieldProps.value,
    slug: slugEditorValue,
    slugPath,
    backlinkId,
    isSubDomainConfigured: !!artistSubdomain,
    isSubDomainSelected: subDomainFieldProps.value === artistSubdomain,
    enabled: shouldValidateUrl,
  });

  /**
   * Copy URL to clipboard
   */
  const copyUrlToClipboard = async () => {
    if (slugEditorValue) {
      const url = getBacklinkUrl(subDomainFieldProps.value, slugEditorValue);
      await navigator.clipboard
        .writeText(`${url}`)
        .catch((e) => console.error(messages.URL_TO_CLIPBOARD_ERROR, e));
      notify({
        type: AppNotificationTypes.Success,
        message: t(
          'ui.component.backlink_settings_data_editor.backlink_url.url_copied_to_clipboard',
          messages.URL_COPIED_TO_CLIPBOARD,
        ),
      });
    }
  };

  return (
    <FieldSet
      title={t(
        'ui.component.backlink_settings_data_editor.backlink_url_fields.title',
        'URL Settings',
      )}
      variant="row"
      contentStyle={css`
        display: flex;
        flex-wrap: wrap;
      `}
    >
      <Autocomplete
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option: DomainProps) => option.label}
        options={domainSelectOptions}
        onChange={(e, selectedDomain) => {
          subDomainFieldProps.changeHandler(selectedDomain.value);
        }}
        fullWidth={false}
        disableClearable={true}
        value={domainSelectValue}
        css={css`
          flex: 1 1 auto;
        `}
        renderInput={(params) => (
          <TextField
            {...params}
            error={subDomainFieldProps.error}
            fullWidth={true}
            label={t(
              'ui.component.backlink_settings_data_editor.backlink_url_fields.field.domain.label',
              'Domain',
            )}
            variant="outlined"
            name={subDomainPath}
          />
        )}
      />
      <EditorTextField
        label={t(
          'ui.component.backlink_settings_data_editor.backlink_url_fields.field.slug.label',
          'Slug',
        )}
        name={slugPath}
        id={slugPath}
        {...getFieldProps(slugPath)}
        css={css`
          flex: 1 1 auto;
        `}
        value={slugEditorValue}
        fullWidth={true}
      />
      <Tooltip
        title={
          t(
            'ui.component.backlink_settings_data_editor.backlink_url.copy_link_to_clipboard_tooltip',
            messages.COPY_LINK_TO_CLIPBOARD_TOOLTIP,
          ) as string
        }
        placement="top"
        disableHoverListener={!isDesktop}
        disableTouchListener={!isDesktop}
      >
        <FlatFab
          aria-label="copy"
          color="primary"
          size="small"
          onClick={copyUrlToClipboard}
        >
          <FileCopyIcon />
        </FlatFab>
      </Tooltip>
      {!!urlValidationInfos && (
        <SlugValidationMessage
          validationInfos={urlValidationInfos}
          artistId={artist?.id}
          onArtistPanelOpen={handleArtistPanelOpen}
          onArtistPanelClose={handleArtistPanelClose}
        />
      )}
    </FieldSet>
  );
};

export default EditorBacklinkUrlFields;
