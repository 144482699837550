import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Album as AlbumIcon } from '@mui/icons-material';
import { CardHeader, Typography } from '@mui/material';

import { Thumbnail } from 'shared/components/Thumbnail';
import { ReleaseModel } from 'shared/entities/release/release.model';
import { useFetchRelease } from 'shared/hooks/entities/release';
import { theme } from 'shared/themes/legacy';

import * as Styled from './styled';

type Props = {
  releaseId: string;
};

const ReleaseStaticCard = ({ releaseId }: Props) => {
  const { t } = useTranslation();
  const { release } = useFetchRelease(releaseId);

  if (!release) {
    return null;
  }

  return (
    <Styled.Container
      role="group"
      aria-label={t(
        'ui.component.backlink_settings_data_editor.release_card.label',
        'release card - {{release_title}} - {{release_artistName}}',
        {
          release_title: release.title,
          release_artistName: release.artistName,
        },
      )}
    >
      <CardHeader
        avatar={
          <Thumbnail
            variant="square"
            alt={t(
              'ui.component.backlink_settings_data_editor.release_card.thumbnail.alt',
              'release card - {{release_title}} - {{release_artistName}}',
              {
                release_title: release.title,
                release_artistName: release.artistName,
              },
            )}
            src={ReleaseModel.getCoverUrl(release)}
            width="56px"
            height="56px"
            $borderRadius="4px"
            $backgroundColor={theme.palette.primary.main}
          >
            <AlbumIcon fontSize="large" />
          </Thumbnail>
        }
        title={
          <>
            <Styled.Title>{release.title}</Styled.Title>
            <Typography variant="subtitle2">{release.artistName}</Typography>
          </>
        }
      />
    </Styled.Container>
  );
};

export default ReleaseStaticCard;
