import React from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';

import { MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material';

type Props = {
  children: React.ReactNode;
  to: string;
  className?: string;
  isActive?: boolean;
  activeClassName?: string;
} & MenuItemProps &
  NavLinkProps;
// note: forwardRef is primarily used here because the item can be wrapped in a Mui Tooltip that needs to be able to pass a ref to the final element
const MenuItem: React.FC<Props> = React.forwardRef(
  ({ children, ...props }, ref) => {
    const { to, title, className, isActive, activeClassName, target, ...rest } =
      props;
    const isExternal = to.match(/^http/);
    return !isExternal ? (
      <MuiMenuItem component={NavLink} {...props} ref={ref}>
        {children}
      </MuiMenuItem>
    ) : (
      // @ts-ignore
      <MuiMenuItem
        component="a"
        href={to}
        rel="noopener noreferrer"
        className={className}
        ref={ref}
        target={target || '_blank'}
        {...rest}
        button="true"
      >
        {children}
      </MuiMenuItem>
    );
  },
);

export default MenuItem;
