import { ToFixAction } from 'apps/MarketingSuite/sections/Campaigns/sections/YouTubeChannels/components/YouTubeChannelDashboard/components/FixesModal/components/Tab/CtaToFix';

/**
 * This file contains the main types of YouTubeChannel
 * There are used to help APIs typing in `youTubeChannel.api.types.ts`& in components
 */

export enum YouTubeChannelType {
  ARTIST = 'ARTIST',
  LABEL = 'LABEL',
}

export enum YouTubeChannelRuleName {
  // CHANNEL RULES
  IS_OFFICIAL_CHANNEL = 'IS_OFFICIAL_CHANNEL',
  CHANNEL_NAME_REFERENCES_DB_CHANNEL_NAME = 'CHANNEL_NAME_REFERENCES_DB_CHANNEL_NAME',
  TAGS_REFERENCES_DB_CHANNEL_NAME = 'TAGS_REFERENCES_DB_CHANNEL_NAME',
  DESCRIPTION_REFERENCES_DB_CHANNEL_NAME_ON_1ST_LINE = 'DESCRIPTION_REFERENCES_DB_CHANNEL_NAME_ON_1ST_LINE',
  HAS_AVATAR = 'HAS_AVATAR',
  HAS_WATERMARK = 'HAS_WATERMARK',
  HAS_BANNER = 'HAS_BANNER',
  HAS_TRAILER = 'HAS_TRAILER',
  HAS_AT_LEAST_3_TAGS = 'HAS_AT_LEAST_3_TAGS',
  HAS_DESCRIPTION = 'HAS_DESCRIPTION',
  DESCRIPTION_IS_OPTIMIZED = 'DESCRIPTION_IS_OPTIMIZED',
  // MONETIZATION RULES
  HAS_NO_EXPLICIT_VIDEOS = 'HAS_NO_EXPLICIT_VIDEOS',
  HAS_GOOD_STANDING_STATUS = 'HAS_GOOD_STANDING_STATUS',
  HAS_ALL_ADS_FORMAT_ACTIVATED = 'HAS_ALL_ADS_FORMAT_ACTIVATED',
  EACH_VIDEO_HAS_NO_LIMITED_MONETIZATION = 'EACH_VIDEO_HAS_NO_LIMITED_MONETIZATION',
  // ACTIVITY RULES
  HAS_UPLOADED_VIDEO_LAST_28_DAYS = 'HAS_UPLOADED_VIDEO_LAST_28_DAYS',
  HAS_UPLOADED_SHORTS_LAST_28_DAYS = 'HAS_UPLOADED_SHORTS_LAST_28_DAYS',
  HAS_GOOD_UPLOAD_FREQUENCY_LAST_360_DAYS = 'HAS_GOOD_UPLOAD_FREQUENCY_LAST_360_DAYS',
  HAS_UPDATED_BANNER_LAST_90_DAYS = 'HAS_UPDATED_BANNER_LAST_90_DAYS',
  HAS_CREATED_PLAYLIST_LAST_28_DAYS = 'HAS_CREATED_PLAYLIST_LAST_28_DAYS',
  // VIDEOS RULES
  EACH_VIDEO_DESCRIPTION_IS_OPTIMIZED = 'EACH_VIDEO_DESCRIPTION_IS_OPTIMIZED',
  EACH_VIDEO_DESCRIPTION_HAS_AT_LEAST_3_HASHTAGS = 'EACH_VIDEO_DESCRIPTION_HAS_AT_LEAST_3_HASHTAGS',
  EACH_VIDEO_TITLE_REFERENCES_DB_CHANNEL_NAME = 'EACH_VIDEO_TITLE_REFERENCES_DB_CHANNEL_NAME',
  EACH_VIDEO_HAS_CARDS = 'EACH_VIDEO_HAS_CARDS',
  EACH_VIDEO_HAS_CUSTOM_THUMBNAIL = 'EACH_VIDEO_HAS_CUSTOM_THUMBNAIL',
  EACH_VIDEO_HAS_ENDSCREEN = 'EACH_VIDEO_HAS_ENDSCREEN',
  EACH_VIDEO_HAS_GOOD_THUMBNAIL_CLICK_THROUGH_RATE = 'EACH_VIDEO_HAS_GOOD_THUMBNAIL_CLICK_THROUGH_RATE',
  RECENT_VIDEO_TOP_COMMENTS_HAVE_A_REPLY = 'RECENT_VIDEO_TOP_COMMENTS_HAVE_A_REPLY',
  RECENT_VIDEOS_HAVE_OWNER_COMMENT = 'RECENT_VIDEOS_HAVE_OWNER_COMMENT',
  EACH_VIDEO_HAS_TOP_KEYWORDS = 'EACH_VIDEO_HAS_TOP_KEYWORDS',
  // PLAYLISTS
  EACH_PLAYLIST_TITLE_REFERENCES_DB_CHANNEL_NAME = 'EACH_PLAYLIST_TITLE_REFERENCES_DB_CHANNEL_NAME',
  EACH_PLAYLIST_DESCRIPTION_IS_OPTIMIZED = 'EACH_PLAYLIST_DESCRIPTION_IS_OPTIMIZED',
  EACH_PLAYLIST_HAS_AT_LEAST_7_VIDEOS = 'EACH_PLAYLIST_HAS_AT_LEAST_7_VIDEOS',
  EACH_VIDEO_IS_IN_PLAYLIST = 'EACH_VIDEO_IS_IN_PLAYLIST',
}

export enum YouTubeChannelFeatureFlagName {
  BULK_ADD_VIDEOS_TO_PLAYLISTS = 'BULK_ADD_VIDEOS_TO_PLAYLISTS',
}

/**
 * CTA: Display one CTA to autofix
 *
 * SELECT_VIDEO: Display component to select which video to autofix
 */
export enum YouTubeAutoFixAllType {
  CTA = 'CTA',
  SELECT_VIDEO = 'SELECT_VIDEO',
}

/**
 * Type that helps to declare parameters of the AutoFixAll depending on the type
 */
export type AutoFixAllRule = {
  type: YouTubeAutoFixAllType;
  description?: JSX.Element;
};

/**
 * CtaAutoFixAllRule displayed by the Cta component
 */
export interface CtaAutoFixAllRule extends AutoFixAllRule {
  type: YouTubeAutoFixAllType.CTA;
  ctaLabel: string;
}

export enum SearchVideosOptionInSelectVideo {
  ENDSCREEN_OPTION = 'withEndScreenOnly',
  CARD_OPTION = 'withCardsOnly',
}
/**
 * SelectVideoAutoFixAllRule displayed by the SelectVideo component
 * Helps to define specific instances of SelectVideo
 */
export interface SelectVideoAutoFixAllRule extends AutoFixAllRule {
  type: YouTubeAutoFixAllType.SELECT_VIDEO;
  /**
   * To suggest specific kind of videos to select to the user
   * Option to send to the GET videos API
   */
  searchVideosOption: SearchVideosOptionInSelectVideo;

  // List all labels needed by SelectVideo feature
  selectVideo: string;
  selectVideoToCopy: string;
  loadMoreVideos: string;
  fixCTA: (count) => string;
  searchVideoPlaceholder: string;
  noVideoFound: string;
}

export type YouTubeChannelRule = {
  label: string;
  ruleName: YouTubeChannelRuleName;
  description?: JSX.Element | string;

  isEnabled: boolean;
  /**
   * we have to specify whhether there is a feature flag for the fact that the rule should show
   * because AFAIK when a feature flag is off it does not even show in the feature flags list
   * preventing from checking its 'enabled' status
   */
  hasIsEnabledFeatureFlag?: boolean;
  /**
   * we have to specify whether there is a feature flag for the fact that the 'auto fix all' button should show
   * see comment abiut the 'isEnabled' property
   */
  hasAutoFixAllFeatureFlag?: boolean;


  /**
   * If true, the rule will be hidden for Record Label type channels.
   */
  shouldBeDisplayedOnlyForArtistChannels?: boolean;

  autoFixAll?: CtaAutoFixAllRule | SelectVideoAutoFixAllRule;

  useBatchSelection?: boolean;

  overrideToFixAction?: ToFixAction;
  overrideToFixLabel?: JSX.Element | string;
  readMoreUrl?: string;
  /**
   * Show the last updated date for a rule
   * Display under the FixModal header
   */
  shouldShowChannelLastUpdated?: boolean;
};

export type YouTubeChannelRulesMapping = Array<YouTubeChannelRule>;

export enum RuleScoreMetasStatus {
  SATISFIED = 'SATISFIED',
  MARKED_AS_FIXED = 'MARKED_AS_FIXED',
  TO_FIX = 'TO_FIX',
  IRRELEVANT = 'IRRELEVANT',
  EVALUATION_POSTPONED = 'EVALUATION_POSTPONED',
}

export type RuleStatus = 'OK' | 'ALMOST_OK' | 'KO' | 'NOT_EVALUATED';

export type YoutubeChannelMetasTagsList = {
  views: number;
  tag: string;
};

export type RuleMeta = {
  status: RuleScoreMetasStatus;
  fixCta: string; // URL to call for the fix
  lastReportSyncedAt?: string;
  resource: {
    id: string;
    label: string;
    thumbnailUrl: string;
    missingTopKeywords?: Array<YoutubeChannelMetasTagsList>;
  };
};

export type RuleBenchmark = {
  ruleName: YouTubeChannelRuleName;
  score: number;
  metas: RuleMeta[];
};

export type YouTubeChannelBenchmarkData = {
  aggregateScores: {
    average: number;
    optimization: number;
  };
  lastUpdate: Date;
  last30DaysViews: number;
  potentialViewsGain: number;
  scores: {
    attractivity: number;
    discovery: number;
    loyalty: number;
    dea: number; // DEA stands for “Daily engage Artist”. More info at: https://globalmarketing.zendesk.com/hc/en-us/articles/360020421419-What-is-a-DEA-score
  };
  status: {
    outOfSync: boolean; // outOfSync === true => channel is "boosted"
    totalToBeFixed: number;
  };
  rulesScores: RuleBenchmark[];
};

export type YouTubeChannel = {
  accountName: string | null;
  accessToFeatures?: Record<YouTubeChannelFeatureFlagName, boolean>;
  benchmarkData?: YouTubeChannelBenchmarkData;
  channelOwner: string | null;
  channelName: string;
  clientClassification: string | null;
  cmsName: string;
  countryUnit: string | null;
  digitalMarketingManager: string | null;
  externalChannelId: string;
  id: string;
  isPriority: boolean;
  metas: {
    channelType: YouTubeChannelType;
    isReviewed: boolean;
    avatarUrl?: string;
    playlistCount?: number;
    videoCount?: number;
    officialChannel?: boolean;
  };
};

export enum YouTubeListSortBy {
  VIEWS = 'last30DaysViews',
  AGGR_OPTIMIZATION_SCORE = 'aggrOptimizationScore',
  POTENTIAL_VIEW_GAINS = 'potentialViewGains',
}

/**
 * Efficiently loads channels by omitting the large
 * and unnecessary (in channels table) 'rulesScores' data
 */
export type YouTubeChannelTableItem = Omit<YouTubeChannel, 'benchmarkData'> & {
  benchmarkData?: Omit<YouTubeChannelBenchmarkData, 'rulesScores'>;
};

export enum SyncStatusEnum {
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum YoutubeFixSelectionMode {
  BULK = 'BULK',
  MANUAL = 'MANUAL',
}

export type YouTubePlaylistBaseProps = {
  externalPlaylistId: string;
  externalChannelId: string;
  title: string;
  description?: string;
  views: number;
  thumbnails: Record<string, any>;
};

export type YouTubePlaylist = YouTubePlaylistBaseProps & {
  videos?: string[];
};

export type YouTubeSync = {
  canPublish: boolean;
  syncStatus?: SyncStatusEnum;
  lastSuccessfulSync?: string;
};

export type YouTubePlaylistWithSyncInfos = YouTubePlaylist & {
  sync: YouTubeSync;
};

export type YoutubePlaylistWithVideos = YouTubePlaylistBaseProps & {
  videos: YouTubePlaylistVideo[];
};

export type YoutubePlaylistWithVideosAndSyncInfos =
  YoutubePlaylistWithVideos & {
    sync: YouTubeSync;
  };

export type YouTubePlaylistVideo = {
  externalId: string;
  videoId: string;
  title: string;
  position: number;
  thumbnailUrl: string;
};

export type YouTubeChannelVideo = {
  id: string;
  externalVideoId: string;
  title: string;
  description: string;
  thumbnailUrl: string;
};

export type PaginatedYouTubeChannelVideos = {
  videos: YouTubeChannelVideo[];
  totalCount: number;
};

export type YoutubeMetadataVideo = {
  title: string;
  description: string;
  replacedTitle: {
    value: string;
    isValid: boolean;
  };
  replacedDescription: {
    value: string;
    isValid: boolean;
  };
  videoId: string;
  externalVideoId: string;
  thumbnailUrl: string;
};
