import { rest } from 'msw';

import config from 'shared/config';

import {
  workspaceCustomerFixture,
  workspaceMarketingSuiteFixture,
} from '../../../workspace/mocks/fixtures/workspace.fixture.one';
import { authApiBaseRoutesMap } from '../../auth.api';
import { BackstagePrograms, UserEmployeePrograms } from '../../auth.types';
import { AuthUserCustomer, AuthUserEmployee } from '../../auth.types';

let hasLoggedOut = false;

export const customerMockedResponse: AuthUserCustomer = {
  userId: 'c10423eb-beef-49bc-951d-a107ebec39fc',
  legalIdentity: 'Some Producer',
  externalId: '123456',
  hasAcceptedCgu: true, // you can set this to false to test the onboarding flow
  workspace: workspaceCustomerFixture,
  role: { isEditor: true },
  programs: [BackstagePrograms.Ads],
};

/**
 * different programs for different users
 * we can use those arrays to easily mock different user programs in the /me handler response below
 */
// Digital manager
const DM_USER_PROGRAMS = [
  UserEmployeePrograms.Links,
  UserEmployeePrograms.Ads,
  UserEmployeePrograms.AdsCredits,
  UserEmployeePrograms.Fans,
  UserEmployeePrograms.CreativeHub,
];
// Channel manager
const CM_USER_PROGRAMS = [
  UserEmployeePrograms.VMO,
  UserEmployeePrograms.VideosManager,
  UserEmployeePrograms.b_Rights,
  UserEmployeePrograms.CM_Reports,
  UserEmployeePrograms.CM_Trends,
  UserEmployeePrograms.CreativeHub,
];
// Editorial Marketing Partnership - EMP people work in relationship with the various Digital Streaming Platforlms
const EMP_USER_PROGRAMS = [
  UserEmployeePrograms.TrackPitch,
  UserEmployeePrograms.Newsletters,
  UserEmployeePrograms.Reports,
  UserEmployeePrograms.Assets,
];
const CENTRAL_USER_PROGRAMS = [...Object.values(UserEmployeePrograms)];

const handlers = [
  // marketing suite
  rest.get(
    `${config.marketingSuiteApiBaseUrl}${authApiBaseRoutesMap.marketingSuite}/me`,
    (req, res, ctx) => {
      if (hasLoggedOut) {
        hasLoggedOut = false;
        return res(ctx.status(401));
      }

      // allow passing an array of programs in the query params
      // or a user category (DM, CM, EMP) to mock different user programs
      // defaults to CENTRAL_USER_PROGRAMS
      const userProgramsParam = req.url.searchParams.get('userPrograms');
      let userProgramsValidatedParam: UserEmployeePrograms[] | null = null;
      try {
        if (userProgramsParam) {
          userProgramsValidatedParam = JSON.parse(userProgramsParam);
        }
      } catch (e) {
        console.error('Error parsing userPrograms query param', e);
      }
      const userCategoryParam = req.url.searchParams.get('userCategory');
      const userPrograms =
        // allow program param of the form ?userPrograms=["backlink","ads"] (enum values)
        userProgramsValidatedParam?.filter((programParam) =>
          Object.values(UserEmployeePrograms).includes(programParam),
        ) ||
        ((userCategoryParam: string | null) => {
          switch (userCategoryParam) {
            case 'DM':
              return DM_USER_PROGRAMS;
            case 'CM':
              return CM_USER_PROGRAMS;
            case 'EMP':
              return EMP_USER_PROGRAMS;
            case 'CENTRAL':
              return CENTRAL_USER_PROGRAMS;
            default:
              return null;
          }
        })(userCategoryParam) ||
        CENTRAL_USER_PROGRAMS;

      const mockedResponse: AuthUserEmployee = {
        userId: 'c10423eb-beef-49bc-951d-a107ebec39fc',
        role: { isAdmin: true },
        programs: userPrograms,
        email: 'thomas.mery@believe.com',
        workspaces: [
          workspaceMarketingSuiteFixture,
          { ...workspaceMarketingSuiteFixture, id: '2', name: 'Workspace 2' },
        ],
        workspaceId: workspaceMarketingSuiteFixture.id,
        zendeskToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRob21hcy5tZXJ5QGJlbGlldmUuY29tIiwibmFtZSI6Ik1FUlkiLCJqdGkiOiI5MzQ5YTdlYS1kNzQzLTRiNzMtOWIyYi0wMGJkYmFkZTZjNzUiLCJpYXQiOjE2NzI4MzY4MDQsImV4cCI6MTY3Mjg0NDAwNH0._s9GCn5vJzklDunEs430yshWaSrW7Furg7As2KBirbI',
      };
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.post(
    `${config.marketingSuiteApiBaseUrl}${authApiBaseRoutesMap.marketingSuite}/logout`,
    (req, res, ctx) => {
      hasLoggedOut = true;
      return res(ctx.status(200), ctx.text('OK'));
    },
  ),
  // backstage marketing
  rest.get(
    `${config.backstageMarketingApiBaseUrl}${authApiBaseRoutesMap.backstageMarketing}/me`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(customerMockedResponse));
    },
  ),
];

export default handlers;
