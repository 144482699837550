import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MenuItem } from '@mui/material';

import { ColorDropIcon, NoColorDropIcon } from 'shared/components/Icons';

import { IconSelect } from '../styled';
import {
  styles,
  ColorPickerContainer,
  ColorPickerOverlay,
  StyledColorPicker,
} from './styled';

type Props = {
  value?: string;
  allowNoColor?: boolean;
  changeHandler: (color: string | undefined) => void;
  buttonLabelId: string;
  id: string;
};

/**
 * a customized color picker component
 * that will display a color palette & a no-color swatch
 * the no-color swatch is actually the 'transparent' swatch allowed by react-color SketchPicker
 * and the code reflects this 'hack'
 * TODO:  add a proper no-color swatch
 */
export const EditorColorPicker: React.FC<Props> = ({
  value: color,
  allowNoColor = true,
  changeHandler,
  buttonLabelId,
  id,
}) => {
  const [textColorPickerColor, setTextColorPickerColor] = useState(color);
  const [textColorPickerIsOpen, setTextColorPickerIsOpen] = useState(false);

  const swatches = allowNoColor ? ['transparent'] : [];

  return (
    <ColorPickerContainer>
      <IconSelect
        labelId={buttonLabelId}
        id={id}
        value="colorPicker"
        open={false}
        onOpen={() => setTextColorPickerIsOpen(!textColorPickerIsOpen)}
        IconComponent={
          textColorPickerIsOpen ? ArrowDropUpIcon : ArrowDropDownIcon
        }
      >
        <MenuItem value="colorPicker">
          {color ? (
            <ColorDropIcon htmlColor={color} css={styles.colorDropIcon} />
          ) : (
            <NoColorDropIcon />
          )}
        </MenuItem>
      </IconSelect>
      {/* TODO: maybe use an event listener on the body to detect a click outside the picker
      this would prevent havng to click twice to open another picker (once on the overlay, once on the other picker button) */}
      <ColorPickerOverlay
        onClick={() => {
          setTextColorPickerIsOpen(false);
        }}
        isActive={textColorPickerIsOpen}
        data-testid="color-picker-overlay"
      ></ColorPickerOverlay>
      <StyledColorPicker
        isOpen={textColorPickerIsOpen}
        noColor={textColorPickerColor === 'transparent'}
        disableAlpha
        presetColors={swatches}
        color={textColorPickerColor}
        // allow visual feedback when dragging in the picker
        onChange={(color) => {
          setTextColorPickerColor(color.hex);
        }}
        // propagating the color change to the outside is done once
        // rather than while dragging the cursors
        onChangeComplete={(colorObject) => {
          const color =
            colorObject.hex !== 'transparent' ? colorObject.hex : undefined;
          changeHandler(color);
        }}
      />
    </ColorPickerContainer>
  );
};
