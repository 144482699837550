import styled from '@emotion/styled';

import MuiDivider from '@mui/material/Divider';
import MuiFormControl from '@mui/material/FormControl';

export const FormControl = styled(MuiFormControl)`
  width: 100%;
  margin-top: 20px;
`;

FormControl.defaultProps = {
  variant: 'outlined',
};

export const Divider = styled(MuiDivider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
