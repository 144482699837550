import {
  RawCapiProduct,
  CapiProductsResponse,
  CapiProductsResponseTransformed,
} from './product.api.types';
import { Product } from './product.types';

export const CapiProductModel = {
  transform(capiProduct: RawCapiProduct): Product {
    return {
      ...capiProduct,
      titleWithProductVersion: capiProduct.productVersion
        ? `${capiProduct.title} (${capiProduct.productVersion})`
        : capiProduct.title,
    };
  },
  transformAll(
    capiProductsResponse: CapiProductsResponse,
  ): CapiProductsResponseTransformed {
    return {
      ...capiProductsResponse,
      capiProducts: capiProductsResponse.capiProducts.map(
        CapiProductModel.transform,
      ),
    };
  },
};
