import React, { useState, useEffect, useRef } from 'react';

import { useMutation } from 'react-query';
import { useDebounce } from 'react-use';

import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import * as heartbeat from 'shared/entities/heartbeat/heartbeat.api';
import { TargetType } from 'shared/entities/heartbeat/heartbeat.constants';
import { DSPInfos } from 'shared/entities/storeConfig/storeConfig.types';
import { DSP } from 'shared/entities/storeConfig/storeConfig.types';
import i18n from 'shared/i18n';

const getErrorByTypes = () => ({
  [TargetType.ALBUM]: i18n.t(
    'ui.component.platform_url_field.error.type.album',
    'album, single or EP',
  ),
  [TargetType.SOURCE]: i18n.t(
    'ui.component.platform_url_field.error.type.track',
    'track',
  ),
  [TargetType.ARTIST]: i18n.t(
    'ui.component.platform_url_field.error.type.artist',
    'artist',
  ),
  [TargetType.USER]: i18n.t(
    'ui.component.platform_url_field.error.type.profile',
    'profile',
  ),
  [TargetType.PLAYLIST]: i18n.t(
    'ui.component.platform_url_field.error.type.playlist',
    'playlist',
  ),
});

type Props = {
  storeName: DSP;
  targetType: TargetType;
  targetUrl?: string;
  onChange?: (value: string) => void;
  onUrlResolved: ({
    targetId,
    targetUrl,
  }: {
    targetId: string;
    targetUrl: string;
  }) => void;
  onUrlCleared?: () => void;
  onUrlNotResolved?: () => void;
  onUrlIsBeingChecked?: (value: boolean) => void;
  placeholder?: string;
  noLabel?: boolean;
  customLabel?: string;
  inputId?: string;
  inputProps?: InputBaseComponentProps;
  /**
   * whether to show the internal error message
   */
  shouldUseInternalErrorMessage?: boolean;
  /**
   * an additional error message to display
   * this is useful when the error message is not related to the URL validation
   */
  errorMessage?: string;
};

/**
 * a component for inputting and validating URLs for different resources (targets)
 * such as albums, tracks, artists, profiles, and playlists.
 * on digital service platforms (DSPs) like Spotify, Apple Music, and Deezer.
 *
 * The resolveUrl endpoint is also used to retrieve a valid id for the target
 * it will be used by the backend for the dspAction feature
 */
const DspUrlField = ({
  targetUrl,
  onUrlResolved,
  onUrlCleared,
  onUrlNotResolved,
  onUrlIsBeingChecked,
  onChange,
  storeName: platformName,
  targetType,
  placeholder,
  noLabel,
  customLabel,
  inputId,
  inputProps,
  shouldUseInternalErrorMessage = true,
  errorMessage,
}: Props) => {
  const [url, setUrl] = useState(targetUrl || '');
  const [internalErrorMessage, setInternalErrorMessage] = useState('');

  /**
   * we need a way to control whether we're going to signal the result of the URL check on the server
   * primary use case is the case when we clear the url field and a check has already been started for a previous field value
   * but the server response has not been received yet
   * when it is received we want to prevent setting a new url in the upper state, which would override the '' string that we just set
   * this would happen because the onUrlResolved handler triggers the url change in the upper state
   *
   */
  const shouldSignalUrlIsResolvedRef = useRef(true);

  useEffect(() => {
    return () => {
      shouldSignalUrlIsResolvedRef.current = false;
    };
  }, []);

  const { mutate: resolveUrl, isLoading: isFetching } = useMutation(
    heartbeat.resolveUrl,
    {
      onSuccess: (response) => {
        if (response) {
          if (
            targetType !== response.type ||
            platformName !== response.platform
          ) {
            onUrlNotResolved && onUrlNotResolved();

            if (shouldUseInternalErrorMessage) {
              setInternalErrorMessage(
                i18n.t(
                  'ui.component.platform_url_field.error.invalid_url',
                  'Paste a valid {{type}} link.',
                  { type: getErrorByTypes()[targetType] },
                ),
              );
            }
          } else {
            onUrlResolved({ targetUrl: url, targetId: response.id });
            setInternalErrorMessage('');
          }
        }
      },
      onError: () => {
        onUrlNotResolved && onUrlNotResolved();

        if (shouldUseInternalErrorMessage) {
          setInternalErrorMessage(
            i18n.t(
              'ui.component.platform_url_field.error.invalid_url',
              'Paste a valid {{type}} link.',
              { type: getErrorByTypes()[targetType] },
            ),
          );
        }
      },
      onMutate: () => {
        onUrlIsBeingChecked && onUrlIsBeingChecked(true);
      },
      onSettled: () => {
        onUrlIsBeingChecked && onUrlIsBeingChecked(false);
      },
    },
  );

  useDebounce(
    () => {
      if (!shouldSignalUrlIsResolvedRef.current || isFetching) {
        return;
      }
      resolveUrl(url);
    },
    1000,
    [url],
  );

  React.useEffect(() => {
    setUrl(targetUrl || '');
  }, [targetUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    shouldSignalUrlIsResolvedRef.current = true;
    const value = event.target.value;
    setUrl(value);
    if (value === '') {
      shouldSignalUrlIsResolvedRef.current = false;
      onUrlCleared && onUrlCleared();
    }
    onChange && onChange(value);
  };

  return (
    <div>
      <TextField
        id={inputId ?? `${platformName}-link`}
        sx={{
          width: '100%',
        }}
        label={
          !noLabel
            ? customLabel || `${DSPInfos[platformName].displayName} link`
            : undefined
        }
        placeholder={placeholder}
        variant="outlined"
        value={url}
        onChange={handleChange}
        error={!!internalErrorMessage || !!errorMessage}
        helperText={internalErrorMessage || errorMessage}
        inputProps={inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                href={url}
                target="_blank"
                disabled={!url}
                size="large"
              >
                <LaunchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default DspUrlField;
