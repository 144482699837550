import React, { useState } from 'react';

import { css } from '@emotion/react';
import moment, { Moment } from 'moment';

import { Today as TodayIcon } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { BACKLINK_SETTINGS_DATE_FORMAT } from 'shared/entities/backlinkSettings/backlinkSettings.constants';

import { EditorDayPickerProps } from '../types';

export const EditorDayPicker: React.FC<EditorDayPickerProps> = ({
  value,
  changeHandler,
  ...props
}) => {
  const date = value.endsWith('Z') ? moment(value).utc() : moment(value);

  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  return (
    <DatePicker
      value={date.format('YYYY-MM-DD')}
      inputFormat={BACKLINK_SETTINGS_DATE_FORMAT}
      onChange={(newDate: Moment | null) => {
        if (newDate) {
          changeHandler(newDate.format('YYYY-MM-DD'));
        }
      }}
      open={datePickerIsOpen}
      onClose={() => setDatePickerIsOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          aria-label="date"
          onClick={() => setDatePickerIsOpen(true)}
          InputProps={{
            startAdornment: (
              <TodayIcon
                css={(theme) => css`
                  margin-right: 0.5rem;
                  cursor: pointer;
                  color: ${theme.palette.grey[600]};
                `}
              />
            ),
          }}
        />
      )}
    />
  );
};
