import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { StepKey } from 'shared/components/BacklinkSettingsDataEditor/state';
import { Backlink } from 'shared/entities/backlink/backlink.types';
import {
  useCreateBacklink,
  useDeleteBacklink,
  useFetchBacklinks,
} from 'shared/hooks/entities/backlink';
import { useExportFans } from 'shared/hooks/entities/fan';
import useConfirmDialog from 'shared/hooks/useConfirmDialog';
import { BACKLINK_EDIT_PATH_ID, BACKLINK_EDIT_V2_PATH_ID } from 'shared/routes';

import useShareBacklinkDialog from '../backlink/useShareBacklinkDialog';

/**
 * a hook to centralize all the logic for the backlinks for a Release
 */
export default function useReleaseBacklinks({
  releaseId,
  mayCreateFirstBacklink = false,
}: {
  releaseId: string;
  /**
   * whether the hook is allowed to create the first backlink
   */
  mayCreateFirstBacklink?: boolean;
}) {
  const { t } = useTranslation('common', {
    keyPrefix: 'section.bfan_links.dialog.delete',
  });
  const navigate = useNavigate();
  const routeUrl = useResolvedPath('').pathname;
  const queryClient = useQueryClient();

  /**
   * Backlinks for the Release
   */
  useFetchBacklinks(
    {
      releaseId,
    },
    { onSuccess: (data) => setBacklinks(data.backlinks) },
  );
  const [backlinks, setBacklinks] = React.useState<Backlink[]>();

  const redirectToEditor = React.useCallback(
    function (backlinkId: string) {
      navigate(
        `${routeUrl}/link/${backlinkId}/${BACKLINK_EDIT_PATH_ID}/${StepKey.Release}`,
      );
    },
    [navigate, routeUrl],
  );

  const redirectToEditorV2 = React.useCallback(
    function (backlinkId: string) {
      navigate(
        `${routeUrl}/link/${backlinkId}/${BACKLINK_EDIT_V2_PATH_ID}/setup`,
      );
    },
    [navigate, routeUrl],
  );

  function refreshReleaseBacklinks() {
    queryClient.invalidateQueries(['backlinks', { releaseId }]);
  }

  /**
   * Backlink creation
   */
  const {
    createBacklink,
    isLoading: isCreatingBacklink,
    isError: backlinkCreationHasError,
  } = useCreateBacklink({ shouldNotifyOnsuccess: false });

  const createReleaseBacklink = React.useCallback(
    (params?: { shouldRedirectToEditor?: boolean }) => {
      const shouldRedirectToEditor = params?.shouldRedirectToEditor ?? false;
      createBacklink(
        { releaseId },
        {
          onSuccess: (backlink) => {
            setBacklinks([backlink, ...(backlinks || [])]);
            if (shouldRedirectToEditor) {
              redirectToEditor(backlink.id);
            }
          },
        },
      );
    },
    [backlinks, createBacklink, redirectToEditor, releaseId],
  );

  const shouldCreateFirstBacklink =
    mayCreateFirstBacklink &&
    backlinks?.length === 0 &&
    !isCreatingBacklink &&
    !backlinkCreationHasError;

  React.useEffect(() => {
    if (shouldCreateFirstBacklink) {
      createReleaseBacklink();
    }
  }, [shouldCreateFirstBacklink, createReleaseBacklink]);

  /**
   * share Backlink dialog
   */
  const { Dialog: ShareBacklinkDialog, openDialog: openShareBacklinkDialog } =
    useShareBacklinkDialog();

  /**
   * Backlink deletion dialog
   */
  const { deleteBacklink } = useDeleteBacklink();
  const { Dialog: DeleteBacklinkDialog, openDialog: openDeleteBacklinkDialog } =
    useConfirmDialog({
      onConfirm: ({ backlinkId }: { backlinkId?: Backlink['id'] }) => {
        if (backlinkId) {
          deleteBacklink(backlinkId, {
            onSuccess: () => refreshReleaseBacklinks(),
          });
        }
      },
      content: t(
        'confirm.content',
        'You are about to permanently delete this backlink? Fans will no longer be able to access it. Stats will be lost. Only collected fans will remain available.',
      ),
      confirmButtonText: t('button.confirm', 'Delete backlink'),
    });

  /**
   * Fans export
   */
  const { exportFans } = useExportFans();

  /**
   * event handlers for ReleaseBacklink cards
   */
  const backlinkEventHandlers = {
    onShare: (backlink: Backlink) => {
      openShareBacklinkDialog({
        slug: backlink.slug,
        subDomain: backlink.settingsData.sharingStep.url?.subDomain || '',
      });
    },
    onEdit: (backlink: Backlink) => {
      redirectToEditor(backlink.id);
    },
    onEditV2: (backlink: Backlink) => {
      redirectToEditorV2(backlink.id);
    },
    onDelete: (backlink: Backlink) => {
      openDeleteBacklinkDialog({ backlinkId: backlink.id });
    },
    onViewStats: (backlink: Backlink) => {
      navigate(`${routeUrl}/link/${backlink.id}/analytics/overview`);
    },
    onExportAudience: (backlink: Backlink) => {
      exportFans({ backlinkId: backlink.id });
    },
  };

  return {
    backlinks,
    createReleaseBacklink,
    isCreatingBacklink,
    refreshReleaseBacklinks,
    DeleteBacklinkDialog,
    ShareBacklinkDialog,
    backlinkEventHandlers,
  };
}
