import React, { useEffect } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import {
  ExportDataStatusEnum,
  POLLING_DEFAULT_INTERVAL,
  POLLING_LONG_INTERVAL,
} from 'shared/entities/common.constants';
import * as fanApi from 'shared/entities/fan/fan.api';
import {
  ExportFansPayload,
  ExportFansResponse,
} from 'shared/entities/fan/fan.api.types';
import { messages } from 'shared/entities/fan/messages';
import { useNotification } from 'shared/hooks/useNotification';
import { useAppContext, getCurrentWorkspaceId } from 'shared/state';
import { downloadFileAtUrl } from 'shared/utils/file';

export function useDeleteFans() {
  const { notify } = useNotification();

  const { mutate: deleteFans, ...rest } = useMutation(fanApi.deleteFans, {
    onSuccess: (response) => {
      const fansDeletedCount = response.outputAffectedNum;
      notify({
        type: AppNotificationTypes.Success,
        message: messages.DELETE_SUCCESS.replace(
          '{fansDeletedCount}',
          fansDeletedCount.toString(),
        ),
      });
    },
    onError: (error: AxiosError) => {
      // we rely on what the backend errors look like ATM
      // and try and display something meaningful
      const errorsMessages = error.response?.data.errors.join('\n');
      notify({
        type: AppNotificationTypes.Error,
        message: `An error occured while deleting your fans.\n${errorsMessages}`,
      });
    },
  });

  return {
    deleteFans,
    ...rest,
  };
}
/**
 * this hook is used to get a fans export
 * it will first call the route that will trigger the export
 * then periodically check the status of the export job via the dedicated route
 * and update a global variable to indicate whether the job is still running or if it's completed
 *
 * both export fans routes take a workspaceId and an optional backlinkId
 * both params need to be re-used on subsequent calls
 *
 * workspaceId is obtained from the current workspace
 * the backlinkId param is passed to the mutate function
 */
export function useExportFans() {
  const { t } = useTranslation();

  const { notify } = useNotification();

  const { state: appState } = useAppContext();

  // workspaceId might be undefined in case the user is a Customer (Backstage Marketing app)
  // the api routes take this into account
  const workspaceId = getCurrentWorkspaceId(appState);

  /**
   * when exporting for a Backlink, we'll need to store the backlinkId
   * and re-use it on each call to the api
   */
  const internalBacklinkIdRef = React.useRef<string>();

  // a global Export status
  const [exportStatus, setExportStatus] =
    React.useState<ExportDataStatusEnum>();

  // a global status to indicate whether the export job has been triggered and is still running
  const [isPreparingExport, setIsPreparingExport] = React.useState(false);

  // a ref for the timeout we'll need to be able to clear when the component unmounts or the export is retriggered
  const pollingTimeoutRef = React.useRef<number>();
  useEffect(() => {
    return () => {
      window.clearTimeout(pollingTimeoutRef.current);
    };
  }, []);

  // a ref to keep track of how many times we've polled the api
  // and adjust the polling interval accordingly
  const pollingCountRef = React.useRef(0);

  // common success/error callbacks for both trigger export and export status calls
  const callbacks = {
    // the bakend has responded with a 200 or a 201
    onSuccess: (response: ExportFansResponse) => {
      setExportStatus(response.lastStatus);

      // done but we need to call the route that will give us the export url
      if (
        response.lastStatus === ExportDataStatusEnum.COMPLETED &&
        !response.downloadUrl
      ) {
        exportFans({
          backlinkId: internalBacklinkIdRef.current,
        });
        // reset polling count
        pollingCountRef.current = 0;
      }
      // now we're done ...
      else if (
        response.lastStatus === ExportDataStatusEnum.COMPLETED &&
        response.downloadUrl
      ) {
        setIsPreparingExport(false);

        downloadFileAtUrl(response.downloadUrl);
        notify({
          type: AppNotificationTypes.Success,
          message: t(
            'entity.fan.message.export_fan_success',
            'Fans export successful',
          ),
        });
      }
      // in progress
      else if (
        response.lastStatus === ExportDataStatusEnum.QUEUED ||
        response.lastStatus === ExportDataStatusEnum.STARTED
      ) {
        const pollingInterval =
          pollingCountRef.current > 4
            ? POLLING_LONG_INTERVAL
            : POLLING_DEFAULT_INTERVAL;
        pollingCountRef.current += 1;
        pollingTimeoutRef.current = window.setTimeout(() => {
          exportFansStatus({
            backlinkId: internalBacklinkIdRef.current,
          });
        }, pollingInterval);
      } else if (response.lastStatus === ExportDataStatusEnum.FAILED) {
        setIsPreparingExport(false);
      } else if (response.lastStatus === ExportDataStatusEnum.CANCELED) {
        setIsPreparingExport(false);
        notify({
          type: AppNotificationTypes.Success,
          message: t(
            'entity.fan.message.export_fan_cancel',
            'Fans export has been canceled. You can now safely retry.',
          ),
        });
      }
    },
    onError: (error: AxiosError) => {
      setIsPreparingExport(false);

      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'entity.fan.message.export_fan_error',
          'An error occured while exporting your fans.',
        ),
      });
    },
  };

  // mutation to trigger the export and get the downloadUrl when it's done
  const { mutate: exportFans, ...rest } = useMutation(
    ({ backlinkId }: Pick<ExportFansPayload, 'backlinkId'>) => {
      internalBacklinkIdRef.current = backlinkId;
      return fanApi.exportFans({ workspaceId, backlinkId });
    },
    {
      onMutate: () => {
        setIsPreparingExport(true);
        window.clearTimeout(pollingTimeoutRef.current);
      },
      ...callbacks,
    },
  );

  // mutation to cancel all exports
  const { mutate: exportAdminFansCancel, ...cancelRequestData } = useMutation(
    ({ backlinkId }: Pick<ExportFansPayload, 'backlinkId'>) => {
      internalBacklinkIdRef.current = backlinkId;
      return fanApi.exportAdminFansCancel({ workspaceId, backlinkId });
    },
    {
      ...callbacks,
    },
  );

  // mutation to poll the export job status
  const { mutate: exportFansStatus } = useMutation(
    ({ backlinkId }: Pick<ExportFansPayload, 'backlinkId'>) =>
      fanApi.exportFansStatus({ workspaceId, backlinkId }),
    {
      ...callbacks,
    },
  );

  return {
    exportFans,
    isPreparingExport,
    exportStatus,
    exportAdminFansCancel,
    exportHasFailed: exportStatus === ExportDataStatusEnum.FAILED,
    cancelRequest: {
      ...cancelRequestData,
    },
    ...rest,
  };
}
