import moment from 'moment-timezone';

export const DEFAULT_TIMEZONE = 'Europe/London';
// as London has 1h of jet lag, we need a neutral hour timezone
export const NEUTRAL_TIMEZONE = 'Europe/Paris';

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const FRIDAY_INDEX = 5;

export const getFormattedDateTimeFromIsoDateTimeAndTimezone = (
  date: string,
  timezone: string,
  format: string = 'YYYY-MM-DD',
) => {
  return moment.tz(date, timezone).format(format);
};

/**
 * Transform formatted date (YYYY-MM-DD) into another one
 * TODO: unit test
 */
export const transformFormattedDateTime = (
  date: string,
  locale: string,
  format: string,
) => {
  return moment(date, 'YYYY-MM-DD').locale(locale).format(format);
};

export const getIsoDateFromElements = ({
  date,
  hour,
  timezone,
}: {
  date: string;
  hour: string;
  timezone: string;
}) => {
  return moment.tz(`${date}T${hour}:00:00.000`, timezone).utc().format();
};

// TODO: unit test
export function getLocalizedFormattedDate({
  date,
  locale,
  options,
}: {
  date: string | Date;
  locale: string;
  options?: Intl.DateTimeFormatOptions;
}) {
  const intl = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: DEFAULT_TIMEZONE,
    ...options,
  });
  try {
    return intl.format(new Date(date));
  } catch (error: any) {
    return date ? date.toString() : '-';
  }
}

/**
 * @returns today's js Date at 00:00:00 for a given timezone
 * TODO: unit test
 */
export function getTodaysDate(
  {
    timezone,
  }: {
    timezone: string;
  } = { timezone: DEFAULT_TIMEZONE },
) {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return moment(today).tz(timezone).toDate();
}

export function getFirstDayOfWeekDate(
  date: Date,
  { firstDayOfWeek }: { firstDayOfWeek: 'sunday' | 'monday' } = {
    firstDayOfWeek: 'monday',
  },
) {
  const day = date.getDay(); // day of the current week
  const diff =
    firstDayOfWeek === 'monday'
      ? date.getDate() - day + (day === 0 ? -6 : 1) // month day - day of the week + 1 or -6 if Sunday
      : date.getDate() - day; // month day - day of the week

  const firstDayOfWeekDate = new Date(date.setDate(diff));
  firstDayOfWeekDate.setHours(0, 0, 0, 0);
  return firstDayOfWeekDate;
}

// TODO: unit test
export function getLastDayOfWeekDate(
  date: Date,
  { firstDayOfWeek }: { firstDayOfWeek: 'sunday' | 'monday' } = {
    firstDayOfWeek: 'monday',
  },
) {
  const firstDayOfWeekDate = getFirstDayOfWeekDate(date, { firstDayOfWeek });
  const lastDayOfWeekDate = new Date(
    new Date(date).setDate(
      // day of the month + 6 days
      firstDayOfWeekDate.getDate() + 6,
    ),
  );
  lastDayOfWeekDate.setHours(23, 59, 59, 999);
  return lastDayOfWeekDate;
}

// TODO: unit test
export function getDifferenceInDays(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
}

// TODO: unit test
export function addDaysToDate(date: Date, days: number) {
  const res = new Date(date);
  res.setDate(date.getDate() + days);
  return res;
}

// TODO: unit test
export function formatEnglishDate(date: Date) {
  return date.toISOString().split('T')[0];
}

/**
 *
 * get the next week day for a given Date
 *
 * @param date the Date we want the Date in a week's time (next same week day)
 * @returns
 */
export function getNextSameWeekDay(date: Date) {
  const daysUntilNextWeekDayInMs = 7 * DAY_IN_MS;
  return new Date(date.getTime() + daysUntilNextWeekDayInMs);
}

/**
 *
 * get the Date for a given week day within the same week as the passed in Date
 *
 * @param dayIndex the index of the week day we want the Date for (sunday 0 -> saturday 6)
 * @param date the Date we use as a ref to get the desired week day Date
 * @returns Date
 */
export function getDateForWeekDay(dayIndex: number, date: Date) {
  const refDayIndex = new Date(date).getDay();
  const delta = dayIndex - refDayIndex;
  const deltaInMs = delta * DAY_IN_MS;
  return new Date(date.getTime() + deltaInMs);
}

/**
 * Determine whether the date falls before or after today
 * TODO: unit test
 */
export const hasDatePassed = (date: string): boolean =>
  Date.now() > new Date(date).getTime();

export const isDateOver3DaysOld = (date: string): boolean => {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const timeDifference = currentDate.getTime() - inputDate.getTime();
  const daysDifference = timeDifference / DAY_IN_MS;

  // Check if the difference is greater than 3 days
  return daysDifference > 3;
};
