import { StoreType } from 'shared/entities/storeConfig/storeConfig.types';

import { AllowedLocale } from '../backlinkSettings.locale.types';
import {
  CTATemplateOptions,
  PrereleaseCTAMode,
} from '../backlinkSettings.types';

export type CTASteps = 'prereleaseLanding' | 'postreleaseLanding';

export type CTAOptionsTemplatesType = {
  prereleaseLanding: Record<AllowedLocale, CTATemplateOptions>;
  postreleaseLanding: Record<AllowedLocale, CTATemplateOptions>;
};

export const CTAOptionsTemplates: CTAOptionsTemplatesType = {
  prereleaseLanding: {
    en: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pre-save',
        [StoreType.Download]: 'Pre-order',
        [StoreType.Buy]: 'Pre-order',
        [StoreType.Watch]: 'Pre-save',
        [StoreType.Follow]: 'Follow',
        [StoreType.Other]: 'Open',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    fr: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Enregistrer',
        [StoreType.Download]: 'Pré-commander',
        [StoreType.Buy]: 'Pré-commander',
        [StoreType.Watch]: 'Enregistrer',
        [StoreType.Follow]: 'Suivre',
        [StoreType.Other]: 'Ouvrir',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    ar: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'قبل الحفظ',
        [StoreType.Download]: 'النظام السابق',
        [StoreType.Buy]: 'النظام السابق',
        [StoreType.Watch]: 'قبل الحفظ',
        [StoreType.Follow]: 'يتبع',
        [StoreType.Other]: 'زيارة',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    de: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Vorspeichern',
        [StoreType.Download]: 'Vorbestellen',
        [StoreType.Buy]: 'Vorbestellen',
        [StoreType.Watch]: 'Vorspeichern',
        [StoreType.Follow]: 'Folgen',
        [StoreType.Other]: 'Besuch',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    es: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pre-guardar',
        [StoreType.Download]: 'Reservar',
        [StoreType.Buy]: 'Reservar',
        [StoreType.Watch]: 'Pre-guardar',
        [StoreType.Follow]: 'Seguir',
        [StoreType.Other]: 'Visitar',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    fil: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Paunang i-save',
        [StoreType.Download]: 'Paunang pag-order',
        [StoreType.Buy]: 'Paunang pag-order',
        [StoreType.Watch]: 'Paunang i-save',
        [StoreType.Follow]: 'Sundan',
        [StoreType.Other]: 'Pagbisita',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    hi: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'पूर्व-सहेजें',
        [StoreType.Download]: 'पूर्व आदेश',
        [StoreType.Buy]: 'पूर्व आदेश',
        [StoreType.Watch]: 'पूर्व-सहेजें',
        [StoreType.Follow]: 'पालन करना',
        [StoreType.Other]: 'मुलाकात',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    id: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pra-simpan',
        [StoreType.Download]: 'Pesan terlebih dahulu',
        [StoreType.Buy]: 'Pesan terlebih dahulu',
        [StoreType.Watch]: 'Pra-simpan',
        [StoreType.Follow]: 'Mengikuti',
        [StoreType.Other]: 'Mengunjungi',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    it: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pre-salvataggio',
        [StoreType.Download]: 'Preordinaree',
        [StoreType.Buy]: 'Preordinare',
        [StoreType.Watch]: 'Pre-salvataggio',
        [StoreType.Follow]: 'Seguire',
        [StoreType.Other]: 'Visitare',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    ja: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '事前保存',
        [StoreType.Download]: '予約注文',
        [StoreType.Buy]: '予約注文',
        [StoreType.Watch]: '事前保存',
        [StoreType.Follow]: '従う',
        [StoreType.Other]: '訪問',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    jv: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Kanggo njaga',
        [StoreType.Download]: 'Kanggo pesenan',
        [StoreType.Buy]: 'Kanggo pesenan',
        [StoreType.Watch]: 'Kanggo njaga',
        [StoreType.Follow]: 'Turutake',
        [StoreType.Other]: 'Dolan maring',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    pl: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Wstępne zapisywanie',
        [StoreType.Download]: 'Przed Sprzedaż',
        [StoreType.Buy]: 'Przed Sprzedaż',
        [StoreType.Watch]: 'Wstępne zapisywanie',
        [StoreType.Follow]: 'Podążać',
        [StoreType.Other]: 'Odwiedzać',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    pt: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pré-salvar',
        [StoreType.Download]: 'Pedido antecipado',
        [StoreType.Buy]: 'Pedido antecipado',
        [StoreType.Watch]: 'Pré-salvar',
        [StoreType.Follow]: 'Seguir',
        [StoreType.Other]: 'Visita',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    ru: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Предварительно сохранить',
        [StoreType.Download]: 'Предварительный заказ',
        [StoreType.Buy]: 'Предварительный заказ',
        [StoreType.Watch]: 'Предварительно сохранить',
        [StoreType.Follow]: 'Следовать',
        [StoreType.Other]: 'Визит',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    su: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pra-hemat',
        [StoreType.Download]: 'Bada pamesanan',
        [StoreType.Buy]: 'Bada pamesanan',
        [StoreType.Watch]: 'Pra-hemat',
        [StoreType.Follow]: 'Tuturkeun',
        [StoreType.Other]: 'Didatangan',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    th: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'บันทึกล่วงหน้า',
        [StoreType.Download]: 'สั่งซื้อล่วงหน้า',
        [StoreType.Buy]: 'สั่งซื้อล่วงหน้า',
        [StoreType.Watch]: 'บันทึกล่วงหน้า',
        [StoreType.Follow]: 'ติดตาม',
        [StoreType.Other]: 'เยี่ยม',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    tr: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Ön kayıt',
        [StoreType.Download]: 'Ön sipariş',
        [StoreType.Buy]: 'Ön sipariş',
        [StoreType.Watch]: 'Ön kayıt',
        [StoreType.Follow]: 'Takip et',
        [StoreType.Other]: 'Ziyaret',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    uk: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Попередньо збережіть',
        [StoreType.Download]: 'Попереднє замовлення',
        [StoreType.Buy]: 'Попереднє замовлення',
        [StoreType.Watch]: 'Попередньо збережіть',
        [StoreType.Follow]: 'Слідкуйте',
        [StoreType.Other]: 'Відвідайте',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    vi: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Lưu trước',
        [StoreType.Download]: 'Đặt hàng trước',
        [StoreType.Buy]: 'Đặt hàng trước',
        [StoreType.Watch]: 'Lưu trước',
        [StoreType.Follow]: 'Theo dõi',
        [StoreType.Other]: 'Thăm nom',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    'zh-Hans': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '预存',
        [StoreType.Download]: '预购',
        [StoreType.Buy]: '预购',
        [StoreType.Watch]: '预存',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    'zh-Hant': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '預存',
        [StoreType.Download]: '預購',
        [StoreType.Buy]: '預購',
        [StoreType.Watch]: '預存',
        [StoreType.Follow]: '跟隨',
        [StoreType.Other]: '訪問',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    'zh-sg': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '预存',
        [StoreType.Download]: '预购',
        [StoreType.Buy]: '预购',
        [StoreType.Watch]: '预存',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    'zh-tw': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '预存',
        [StoreType.Download]: '预购',
        [StoreType.Buy]: '预购',
        [StoreType.Watch]: '预存',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    zh: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '预存',
        [StoreType.Download]: '预购',
        [StoreType.Buy]: '预购',
        [StoreType.Watch]: '预存',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
    ms: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pra-jimat',
        [StoreType.Download]: 'Pra-pesanan',
        [StoreType.Buy]: 'Pra-pesanan',
        [StoreType.Watch]: 'Pra-jimat',
        [StoreType.Follow]: 'Ikut',
        [StoreType.Other]: 'Lawati',
      },
      text: '',
      mode: PrereleaseCTAMode.DEFAULT,
    },
  },
  postreleaseLanding: {
    en: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Play',
        [StoreType.Download]: 'Download',
        [StoreType.Buy]: 'Buy',
        [StoreType.Watch]: 'Watch',
        [StoreType.Follow]: 'Follow',
        [StoreType.Other]: 'Visit',
      },
      text: '',
    },
    fr: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Écouter',
        [StoreType.Download]: 'Télécharger',
        [StoreType.Buy]: 'Acheter',
        [StoreType.Watch]: 'Voir',
        [StoreType.Follow]: 'Suivre',
        [StoreType.Other]: 'Ouvrir',
      },
      text: '',
    },
    ar: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'للاستماع',
        [StoreType.Download]: 'تحميل',
        [StoreType.Buy]: 'يشترى',
        [StoreType.Watch]: 'شاهد',
        [StoreType.Follow]: 'يتبع',
        [StoreType.Other]: 'زيارة',
      },
      text: '',
    },
    de: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Zuhören',
        [StoreType.Download]: 'Herunterladen',
        [StoreType.Buy]: 'Kaufen',
        [StoreType.Watch]: 'Zu betrachten',
        [StoreType.Follow]: 'Folgen',
        [StoreType.Other]: 'Besuch',
      },
      text: '',
    },
    es: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Escuchar',
        [StoreType.Download]: 'Descargar',
        [StoreType.Buy]: 'Comprar',
        [StoreType.Watch]: 'Mirar',
        [StoreType.Follow]: 'Seguir',
        [StoreType.Other]: 'Visitar',
      },
      text: '',
    },
    fil: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Tumingin sa',
        [StoreType.Download]: 'Magdownload',
        [StoreType.Buy]: 'Bilhin',
        [StoreType.Watch]: 'Tumingin sa',
        [StoreType.Follow]: 'Sundan',
        [StoreType.Other]: 'Pagbisita',
      },
      text: '',
    },
    hi: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'सुनना',
        [StoreType.Download]: 'डाउनलोड करने के लिए',
        [StoreType.Buy]: 'खरीदने के लिए',
        [StoreType.Watch]: 'को देखने के लिए',
        [StoreType.Follow]: 'पालन करना',
        [StoreType.Other]: 'मुलाकात',
      },
      text: '',
    },
    id: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Untuk mendengarkan',
        [StoreType.Download]: 'Untuk mengunduh',
        [StoreType.Buy]: 'Untuk membeli',
        [StoreType.Watch]: 'Untuk melihat',
        [StoreType.Follow]: 'Mengikuti',
        [StoreType.Other]: 'Mengunjungi',
      },
      text: '',
    },
    it: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Ascoltare',
        [StoreType.Download]: 'Scaricare',
        [StoreType.Buy]: 'Comperare',
        [StoreType.Watch]: 'Guardare',
        [StoreType.Follow]: 'Seguire',
        [StoreType.Other]: 'Visitare',
      },
      text: '',
    },
    ja: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '聞くには',
        [StoreType.Download]: 'ダウンロードする',
        [StoreType.Buy]: '買う',
        [StoreType.Watch]: 'を見て',
        [StoreType.Follow]: '従う',
        [StoreType.Other]: '訪問',
      },
      text: '',
    },
    jv: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Kanggo ngrungokake',
        [StoreType.Download]: 'Kanggo ngunduh',
        [StoreType.Buy]: 'Kanggo tuku',
        [StoreType.Watch]: 'Kanggo ndeleng',
        [StoreType.Follow]: 'Turutake',
        [StoreType.Other]: 'Dolan maring',
      },
      text: '',
    },
    pl: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Pobrać',
        [StoreType.Download]: 'Pobrać',
        [StoreType.Buy]: 'Kupić',
        [StoreType.Watch]: 'Patrzeć na',
        [StoreType.Follow]: 'Podążać',
        [StoreType.Other]: 'Odwiedzać',
      },
      text: '',
    },
    pt: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Ouvir',
        [StoreType.Download]: 'Baixar',
        [StoreType.Buy]: 'Comprar',
        [StoreType.Watch]: 'Olhar para',
        [StoreType.Follow]: 'Seguir',
        [StoreType.Other]: 'Visita',
      },
      text: '',
    },
    ru: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Слушать',
        [StoreType.Download]: 'Скачивать',
        [StoreType.Buy]: 'Покупать',
        [StoreType.Watch]: 'Смотреть на',
        [StoreType.Follow]: 'Следовать',
        [StoreType.Other]: 'Визит',
      },
      text: '',
    },
    su: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Ngupingkeun',
        [StoreType.Download]: 'Pikeun ngaunduh',
        [StoreType.Buy]: 'Pikeun ngagaleuh',
        [StoreType.Watch]: 'Pikeun ningali',
        [StoreType.Follow]: 'Tuturkeun',
        [StoreType.Other]: 'Didatangan',
      },
      text: '',
    },
    th: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'เพื่อฟัง',
        [StoreType.Download]: 'ดาวน์โหลด',
        [StoreType.Buy]: 'ที่จะซื้อ',
        [StoreType.Watch]: 'ที่จะมองไปที่',
        [StoreType.Follow]: 'ติดตาม',
        [StoreType.Other]: 'เยี่ยม',
      },
      text: '',
    },
    tr: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Dinlemek',
        [StoreType.Download]: 'İndirmek',
        [StoreType.Buy]: 'Satın almak',
        [StoreType.Watch]: 'Bakmak için',
        [StoreType.Follow]: 'Takip et',
        [StoreType.Other]: 'Ziyaret',
      },
      text: '',
    },
    uk: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Слухати',
        [StoreType.Download]: 'Скачати',
        [StoreType.Buy]: 'Купити',
        [StoreType.Watch]: 'Шукати в',
        [StoreType.Follow]: 'Слідкуйте',
        [StoreType.Other]: 'Відвідайте',
      },
      text: '',
    },
    vi: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Nghe',
        [StoreType.Download]: 'Tải về',
        [StoreType.Buy]: 'Mua',
        [StoreType.Watch]: 'Nhìn',
        [StoreType.Follow]: 'Theo dõi',
        [StoreType.Other]: 'Thăm nom',
      },
      text: '',
    },
    'zh-Hans': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '听',
        [StoreType.Download]: '去下载',
        [StoreType.Buy]: '购买',
        [StoreType.Watch]: '看',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
    },
    'zh-Hant': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '聽',
        [StoreType.Download]: '去下載',
        [StoreType.Buy]: '購買',
        [StoreType.Watch]: '看',
        [StoreType.Follow]: '跟隨',
        [StoreType.Other]: '訪問',
      },
      text: '',
    },
    'zh-sg': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '听',
        [StoreType.Download]: '去下载',
        [StoreType.Buy]: '购买',
        [StoreType.Watch]: '看',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
    },
    'zh-tw': {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '听',
        [StoreType.Download]: '去下载',
        [StoreType.Buy]: '购买',
        [StoreType.Watch]: '看',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
    },
    zh: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: '听',
        [StoreType.Download]: '去下载',
        [StoreType.Buy]: '购买',
        [StoreType.Watch]: '看',
        [StoreType.Follow]: '跟随',
        [StoreType.Other]: '访问',
      },
      text: '',
    },
    ms: {
      isDisplayed: true,
      label: {
        [StoreType.Stream]: 'Untuk mendengar',
        [StoreType.Download]: 'Untuk muat turun',
        [StoreType.Buy]: 'Untuk membeli',
        [StoreType.Watch]: 'Untuk melihat',
        [StoreType.Follow]: 'Ikut',
        [StoreType.Other]: 'Lawati',
      },
      text: '',
    },
  },
};
