import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { BacklinkSettingsEditorDataProps } from 'shared/components/Backlink/components/BacklinkEditorV2/types';
import { useBacklinkSettingsDataEditorContext } from 'shared/components/BacklinkSettingsDataEditor/state/context';
import { AppNotificationTypes } from 'shared/constants';
import * as autonotifyApi from 'shared/entities/autonotify/autonotify.api';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';

/**
 * Get the autonotify status for the current entity
 * Default is PENDING
 */
export function useAutonotifyStatus() {
  const { parentEntityInfos } = useBacklinkSettingsDataEditorContext();
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : null;
  const autonotifyId = backlink?.autonotifyId;

  const { data: response, ...rest } = useAppQuery('autonotify', {
    fetcherArgs: [{ autonotifyId: autonotifyId as string }],
    enabled: !!autonotifyId,
  });

  return { autonotifyStatus: response?.status, ...rest };
}

export function useAutonotifyStatusById(
  parentEntityInfos?: BacklinkSettingsEditorDataProps['parentEntityInfos'],
) {
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : null;
  const autonotifyId = backlink?.autonotifyId || '';
  const { data: autonotify, ...rest } = useQuery(
    ['autonotify', autonotifyId],
    () => autonotifyApi.fetchAutonotify({ autonotifyId }),
    {
      enabled: !!backlink?.autonotifyId,
    },
  );

  return { autonotifyStatus: autonotify?.status, ...rest };
}

/**
 * Send a test mail
 */
export function useSendTestMail() {
  const { t } = useTranslation();
  const { notify } = useNotification();

  const {
    mutate: sendTestMail,
    isLoading: isSending,
    ...rest
  } = useMutation(autonotifyApi.sendTestEmail, {
    onSuccess: (_, { sendTo }) => {
      notify({
        type: AppNotificationTypes.Success,
        message: t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.notifications.success',
          {
            defaultValue: 'The test email has been sent to {{sendTo}}',
            sendTo,
          },
        ),
      });
    },
    onError: (error, { sendTo }) => {
      console.error(error);
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.notifications.error',
          {
            defaultValue:
              'An error occurred while sending the test email to {{sendTo}}',
            sendTo,
          },
        ),
      });
    },
  });

  return { sendTestMail, isSending, ...rest };
}
