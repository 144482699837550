import React from 'react';

import {
  Avatar,
  Grid,
  IconButton,
  MuiIcons,
  ThemeProvider,
  Typography,
} from '@believe-front/react-components';

import { CircularProgress, FormLabel, useTheme } from '@mui/material';

import {
  DEFAULT_IMAGE_FORMATS,
  ImageUploadError,
  useImageUpload,
} from 'shared/hooks/useImageUpload';
import { buildImageUrl } from 'shared/services/image.service';

export type ImageUploaderProps = {
  alt: string;
  changeHandler: (id: string) => void;
  inputId?: string;
  inputGroupLabel?: string;
  isRound?: boolean;
  value?: { url?: string; id?: string };
};

const UploadButton = ({
  inputId,
  uploadImage,
}: {
  inputId?: string;
  uploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const theme = useTheme();
  return (
    <>
      <IconButton
        negative
        color="secondary"
        edge="start"
        component="label"
        //@ts-expect-error TODO fix DS IconButton type
        htmlFor={inputId || 'image-uploader'}
        aria-label="upload"
        size="small"
      >
        <MuiIcons.UploadOutlined
          sx={{
            color: theme.palette.textColors.dark.medium,
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <input
        style={{ display: 'none' }}
        type="file"
        id={inputId || 'image-uploader'}
        name="upload"
        onChange={uploadImage}
        accept={DEFAULT_IMAGE_FORMATS.join(', ')}
      />
    </>
  );
};

const DeleteButton = ({ removeImage }: { removeImage: () => void }) => {
  const theme = useTheme();
  return (
    <IconButton
      negative
      color="error"
      edge="start"
      onClick={removeImage}
      aria-label="remove"
      size="small"
    >
      <MuiIcons.DeleteOutlined
        sx={{
          //    @ts-expect-error TODO FIX THEME TYPE
          color: theme.palette.danger.main,
          width: '24px',
          height: '24px',
        }}
      />
    </IconButton>
  );
};

const ErrorMessage = (error: ImageUploadError) => {
  const theme = useTheme();
  return (
    <FormLabel
      error={!!error}
      sx={{
        display: 'flex',
        gap: theme.spacing(0.4),
        marginTop: theme.spacing(0.4),
      }}
    >
      <MuiIcons.ErrorOutlineOutlined fontSize="small" />
      <Typography color="error">{error?.message}</Typography>
    </FormLabel>
  );
};

const ImageUploader: React.FC<ImageUploaderProps> = ({
  alt,
  changeHandler,
  inputGroupLabel,
  inputId,
  value: imageData,
  isRound = false,
}) => {
  const {
    error,
    isUploading,
    remove: removeImage,
    upload: uploadImage,
  } = useImageUpload(changeHandler);

  const imageURL =
    imageData?.url ||
    (imageData?.id && buildImageUrl({ imageId: imageData?.id })) ||
    '';
  return (
    // theme provider necessary until DS theme is used everywhere
    <ThemeProvider>
      <Grid
        container
        width="10.75rem"
        alignItems="flex-start"
        justifyContent="space-between"
        role="group"
        aria-label={inputGroupLabel || 'Image uploader'}
      >
        <Grid item>
          <Avatar
            alt={alt}
            src={!isUploading ? imageURL : ''}
            sx={{
              backgroundColor: '#ddddeb',
              borderRadius: isRound ? '50%' : '10%',
              width: 124,
              height: 124,
            }}
          >
            {!isUploading ? (
              <MuiIcons.Album fontSize="large" />
            ) : (
              <CircularProgress />
            )}
          </Avatar>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item>
              <DeleteButton removeImage={removeImage} />
            </Grid>
            <Grid item>
              <UploadButton inputId={inputId} uploadImage={uploadImage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!error && <ErrorMessage {...error} />}
    </ThemeProvider>
  );
};

export default ImageUploader;
