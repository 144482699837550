import * as yup from 'yup';

import {
  VALID_SLUG_REGEXP,
  VALID_SUBDOMAIN_REGEXP,
} from 'shared/entities/backlink/backlink.constants';
import i18next from 'shared/i18n';
import { dynamicKeysSchema } from 'shared/utils/validation';

import { isNotifyDateValid } from '../autonotify/autonotify.utils';
import {
  BacklinkSettingsParentEntityType,
  FormFieldName,
} from './backlinkSettings.types';

const URL_REGEXP = /^https?:\/\//;

type Params = {
  parentEntityType?: BacklinkSettingsParentEntityType;
};

export const makeValidationSchema = ({ parentEntityType }: Params) => {
  return yup.object({
    common: yup.object({
      defaultLocale: yup.string().required(),
      defaultActionId: yup.string(),
    }),
    releaseStep: yup.object({
      release: yup.object({
        title:
          parentEntityType === 'Backlink'
            ? yup.string().required()
            : yup.string(),
        stores: dynamicKeysSchema(
          yup.object({
            displayName: yup.string(),
            canUseAutoscan: yup.bool().default(true),
            useAutoscan: yup.bool().when('canUseAutoscan', {
              is: true,
              then: yup.bool().default(true),
              otherwise: yup.bool().default(false),
            }),
            links: dynamicKeysSchema(
              yup.object({
                url: yup.string().url(),
                id: yup.string(),
              }),
            ),
          }),
          { optional: true },
        ),
      }),
    }),
    designStep: yup.object({
      theme: yup.string(),
      typography: yup.object({
        paragraph: yup.object({
          color: yup.string(),
        }),
        link: yup.object({
          color: yup.string(),
        }),
      }),
      background: yup.object({
        direction: yup.number(),
        color01: yup.string(),
        color02: yup.string().optional(),
        image: yup.object({
          blur: yup.number(),
          imageId: yup.string(),
          overlay: yup.object({
            color: yup.string(),
            opacity: yup.number(),
          }),
        }),
      }),
    }),
    prereleaseLandingStep: yup.object({
      youtubeVideoId: yup.string().when('showVideo', {
        is: true,
        then: yup
          .string()
          .test(
            'Should NOT be a url',
            'Please enter a YouTube ID, not an URL',
            (value) => (value ? !URL_REGEXP.test(value) : true),
          ),
        otherwise: yup.string().optional(),
      }),
      locale: dynamicKeysSchema(
        yup.object({
          richText: yup.string().required(),
          callToActions: dynamicKeysSchema(
            yup.object({
              displayOrder: yup.array().of(yup.string()).optional(),
              options: dynamicKeysSchema(
                yup.object({
                  isDisplayed: yup.bool().default(true),
                  label: yup.string(),
                }),
              ),
            }),
          ),
        }),
      ),
    }),
    prereleaseFormStep: yup.object({
      locale: dynamicKeysSchema(
        yup.object({
          richText: yup.string().required(),

          form: yup.object({
            fields: yup
              .array()
              .of(
                yup.object({
                  name: yup.string().required(),
                  label: yup.string().required(),
                }),
              )
              .test(
                'has-one-of-mandatory-fields',
                'At least an email or phone number is required.',
                (fields) => {
                  return fields
                    ? fields.some(
                        (field) =>
                          field.name === FormFieldName.Mobile ||
                          field.name === FormFieldName.Email,
                      )
                    : false;
                },
              ),
            optins: yup.array().of(
              yup.object({
                name: yup.string().required(),
                label: yup.string().required(),
                metas: yup.object().when('name', {
                  is: FormFieldName.OptinCompetition,
                  then: yup.object({
                    territory: yup.string().required(),
                    winnersCount: yup.number().required(),
                    prizes: yup.string().required(),
                  }),
                }),
              }),
            ),
          }),
        }),
      ),
      redirect: yup.object({
        default: yup.object({
          url: yup.string().url(),
        }),
        useStoreRedirects: yup.bool().default(false),
        stores: yup.array().of(
          yup.object({
            url: yup.string().url().optional(),
          }),
        ),
      }),
    }),
    prereleaseAutonotifyStep: yup.object({
      scheduledAt:
        parentEntityType === 'Backlink'
          ? yup.string().when('isActive', {
              is: true,
              then: yup
                .string()
                .required('This field is required')
                .test(
                  'is-notify-date-valid',
                  i18next.t(
                    'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.date_and_time.fields.date_and_time.errors.invalid_date',
                    'Invalid date, notification date and time must from the release date to maximum 3 days after.',
                  ),
                  (value, context) => {
                    if (value) {
                      /**
                       * @note: context.from isn't correctly typed yet
                       * see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49512
                       */
                      const {
                        digitalReleaseDate,
                        selectedTimezone,
                        // @ts-ignore
                      } = context.from[1].value.releaseStep.release;
                      return isNotifyDateValid(
                        value,
                        digitalReleaseDate,
                        selectedTimezone,
                      );
                    }
                    return true;
                  },
                ),
              otherwise: yup.string().nullable().optional(),
            })
          : yup.string().nullable(),
      locale: yup.object().when('isActive', {
        is: true,
        then: dynamicKeysSchema(
          yup.object({
            subject: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.subject.errors.required',
                  'Email object is required',
                ),
              )
              .max(
                100,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.subject.errors.max_length',
                  'Email object must be 100 characters or less',
                ),
              ),
            title: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.title.errors.required',
                  'Header text is required',
                ),
              )
              .max(
                200,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.title.errors.max_length',
                  'Header text must be 200 characters or less',
                ),
              ),
            ctaText: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.ctaText.errors.required',
                  'Button label is required',
                ),
              )
              .max(
                15,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.ctaText.errors.max_length',
                  'Button label must be 15 characters or less',
                ),
              ),
            body: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.body.errors.required',
                  'Custom text is required',
                ),
              )
              .max(
                3000,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.body.errors.max_length',
                  'Custom text must be 3000 characters or less',
                ),
              ),
            footerText: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.footerText.errors.required',
                  'Footer text is required',
                ),
              )
              .max(
                200,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.footerText.errors.max_length',
                  'Footer text must be 200 characters or less',
                ),
              ),
            socialSectionTitle: yup
              .string()
              .required(
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.socialSectionTitle.errors.required',
                  'Section title is required',
                ),
              )
              .max(
                20,
                i18next.t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.socialSectionTitle.errors.max_length',
                  'Section title must be 20 characters or less',
                ),
              ),
          }),
        ),
      }),
    }),
    postreleaseLandingStep: yup.object({
      showArtwork: yup.boolean(),
      showVideo: yup.boolean(),
      youtubeVideoId: yup.string().when('showVideo', {
        is: true,
        then: yup
          .string()
          .test(
            'Should NOT be a url',
            'Please enter a YouTube ID, not an URL',
            (value) => (value ? !URL_REGEXP.test(value) : true),
          ),
        otherwise: yup.string().optional(),
      }),
      locale: dynamicKeysSchema(
        yup.object({
          richText: yup.string().required(),
          callToActions: dynamicKeysSchema(
            yup.object({
              displayOrder: yup.array().of(yup.string()).optional(),
              options: dynamicKeysSchema(
                yup.object({
                  isDisplayed: yup.bool().default(true),
                  label: yup.string(),
                }),
              ),
            }),
          ),
        }),
      ),
    }),
    sharingStep: yup.object({
      url: yup
        .object({
          slug: yup.string().matches(VALID_SLUG_REGEXP, {
            excludeEmptyString: true,
          }),
          subDomain: yup
            .string()
            .matches(VALID_SUBDOMAIN_REGEXP, { excludeEmptyString: true }),
        })
        .required(),
      socialCard: yup.object({
        title: yup.string().required(),
        description: yup.string().required(),
      }),
    }),
  });
};
