import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { AppNotificationTypes } from 'shared/constants';
import { useNotification } from 'shared/hooks/useNotification';

export const messages = {
  COPY_LINK_TO_CLIPBOARD_TOOLTIP: 'Copy link',
  URL_COPIED_TO_CLIPBOARD: 'Copied!',
  URL_TO_CLIPBOARD_ERROR: 'Could not copy text: ',
};

export function useClipboard() {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const [{ value, error }, _copyToClipboard] = useCopyToClipboard();

  const copyToClipboard = useCallback(
    (text: string) => {
      _copyToClipboard(text);
      if (error) {
        notify({
          type: AppNotificationTypes.Error,
          message: `${t(messages.URL_TO_CLIPBOARD_ERROR)} ${error}`,
        });
      } else {
        notify({
          type: AppNotificationTypes.Success,
          message: t(messages.URL_COPIED_TO_CLIPBOARD),
        });
      }
    },
    [_copyToClipboard, error, notify, t],
  );

  return {
    copyToClipboard,
    error,
    value,
  };
}
