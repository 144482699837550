import { api } from '../api';
import {
  ExportFansResponse,
  ExportFansPayload,
  DeleteFansApiFnParams,
  FanIdentifierType,
  DeleteFansByPhonNumberPayload,
  DeleteFansByEmailPayload,
  DeleteFansResponse,
} from './fan.api.types';
import { Fan, FanInfo, FanSyncIterableRecord } from './fan.types';

export const fetchFans = async ({
  page = 0,
  limit = 10,
  filterIterableSynced = false,
}): Promise<{
  fans: { fan: Fan; iterable?: FanSyncIterableRecord; infos: FanInfo }[];
  total: number;
}> => {
  const results = await api.get('/fans', {
    params: {
      page,
      limit,
      filterIterableSynced,
    },
  });
  return results.data;
};

/**
 * Fans Export
 *
 * it is comprised of 2 routes:
 *
 * one to trigger the export job & get the download url
 * the other one to poll the export job status
 *
 * when the status route indicates the job has COMPLETED
 * the main route must be called again to get the download url
 */

/**
 * POST /export/fans
 * will trigger a job to export all fans
 */
export const exportFans = async ({
  backlinkId,
  workspaceId,
}: ExportFansPayload): Promise<ExportFansResponse> => {
  const response = await api.post('/exports/fans', {
    backlinkId,
    workspaceId,
  });
  return response.data;
};

/**
 * GET export/fans/status
 * will get the status of a given export job
 */
export const exportFansStatus = async ({
  backlinkId,
  workspaceId,
}: ExportFansPayload): Promise<ExportFansResponse> => {
  const response = await api.get('/exports/fans/status', {
    params: {
      backlinkId,
      workspaceId,
    },
  });
  return response.data;
};

/**
 * POST /exports/admin/fans/cancel
 * Will cancel jobs that are exporting all fans
 */
export async function exportAdminFansCancel(
  payload: ExportFansPayload,
): Promise<ExportFansResponse> {
  const response = await api.post('/exports/admin/fans/cancel', payload);
  return response.data;
}

export const validateFanBatch = async (fans: any[]): Promise<any[]> => {
  const results = await api.post(`/fans/batch/validate`, fans);
  return results.data;
};

export const saveFanBatch = async (
  fans: any[],
  optins,
  artistId,
): Promise<any[]> => {
  const results = await api.post(`/fans/batch`, { fans, optins, artistId });
  return results.data;
};

export const deleteFans = async ({
  by,
  identifiers,
  workspaceId,
}: DeleteFansApiFnParams): Promise<DeleteFansResponse> => {
  const baseEndpoint = '/fans/anonymize';
  // endpoints names
  const endpointTypesByIdentifier: Record<FanIdentifierType, string> = {
    email: 'emails',
    phonenumber: 'phonenumbers',
  };

  let payload: DeleteFansByEmailPayload | DeleteFansByPhonNumberPayload;

  switch (by) {
    case 'email': {
      payload = {
        emails: identifiers,
        workspaceId,
      };
      break;
    }
    case 'phonenumber': {
      payload = {
        phonenumbers: identifiers,
        workspaceId,
      };
      break;
    }
  }

  const results = await api.post(
    `${baseEndpoint}/${endpointTypesByIdentifier[by]}`,
    payload,
  );
  return results.data;
};
