import { dateIO } from 'shared/utils/date-provider';

import { BacklinksPerformanceResponse } from '../../backlink.api.types';

const startDate = new Date();
export const backlinksPerformance: BacklinksPerformanceResponse = {
  performance: {
    visited: {
      count: 134568,
      dailyRows: Array.from({ length: 50 }, (e, i) => ({
        date: dateIO.addDays(startDate, i).toISOString(),
        count: Math.floor(i * Math.random()),
      })),
    },
    clickedToStore: {
      count: 73456,
      percentage: 45,
    },
    preSaved: {
      count: 8561,
      percentage: 12,
    },
    optins: {
      count: 6247,
      percentage: 8,
    },
  },
};
