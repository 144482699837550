import { useFlag } from '@unleash/proxy-client-react';

import { MOCKED_FEATURE_FLAGS } from 'shared/mocks/handlers/featureFlags';

/**
 *
 * a wrapper around the @unleash feature flag hook
 * to allow for returning flag values without having to make an actual request in some cases
 *
 * originally implemented to prevent Cypress tests not behaving as expected when using the @unleash feature flag hook directly
 *
 * @returns
 */
export function useAppFeatureFlag(name) {
  const mockedFeatureFlag = MOCKED_FEATURE_FLAGS[name];
  const featureFlag = useFlag(name);
  return window.Cypress ? mockedFeatureFlag : featureFlag;
}
