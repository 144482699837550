/**
 * Custom Hook useQuery
 * @author Yohann Legrand <yohann.legrand-ext@believe.com>
 * @author Thomas Mery <thomas.mery@believe.com>
 * @date 05/2021
 *
 * The main goal here is to wrap react-query's useQuery hook and
 * centralize calls to our API (= fetchers) in a type-safe manner.
 *
 * This allows us to centralize common operations like dispatching
 * error notifications to the User
 *
 * react-query provides caching and state management for queries,
 * which simplify our codebase and improve user experience
 */
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { fetchersByQueryKey } from 'shared/entities/fetchersByQueryKey';
import { PromiseData } from 'shared/types/utils';

/**
 * A query key is a unique identifier for a query.
 */
type Fetchers = typeof fetchersByQueryKey;
type QueryKeys = keyof Fetchers;

/**
 * To correctly type the custom hook we need to distinguish between
 * fetchers that take arguments and the ones that don't
 */
// prettier-ignore
type QueryFilter<T extends 'required' | 'optional'> = {
  [K in QueryKeys]: Parameters<Fetchers[K]> extends []
    ? (T extends 'required' ? never : K)
    : (T extends 'required' ? K : never)
};
type QueryKeysWithRequiredArgs =
  QueryFilter<'required'>[keyof QueryFilter<'required'>];
type QueryKeysWithOptionalArgs =
  QueryFilter<'optional'>[keyof QueryFilter<'optional'>];

type QueryBasicOptions = {
  errorMessage?: string;
};

/**
 * Dynamically get the type of the fetcher's arguments
 */
type QueryArgs<T extends QueryKeys> = Parameters<Fetchers[T]>;

/**
 * Build the type for the query options, which are made of:
 *  - react-query's useQuery options
 *  - our QueryBasicOptions defined above
 *  - the fetcher's arguments defined above
 */
export type QueryOptions<T extends QueryKeys> = UseQueryOptions<QueryData<T>> &
  QueryBasicOptions &
  (T extends QueryKeysWithRequiredArgs
    ? {
        /**
         * an array of arguments for the selected fetcher
         */
        fetcherArgs: QueryArgs<T>;
      }
    : {
        /**
         * an optional array of arguments for the selected fetcher
         */
        fetcherArgs?: QueryArgs<T>;
      });

/**
 * Util to extract the data type of a promise
 * We will need it to type the original call to react-query's useQuery
 */
type QueryData<T extends QueryKeys> = PromiseData<ReturnType<Fetchers[T]>>;

/**
 * useQuery overload for fetchers with optional arguments
 */
export function useAppQuery<T extends QueryKeysWithOptionalArgs>(
  key: T,
  options?: QueryOptions<T>,
): UseQueryResult<QueryData<T>>;

/**
 * useQuery overload for fetchers with required arguments
 *
 * @example
 * ```
 * const otherOptions = {
 *  onSuccess: () => { [your logic] },
 *  onError: (error) => { [your logic] },
 * }
 * const { data } = useAppQuery(
 *  'key',
 *  {
 *    fetcherArgs: [ arg1, arg2, ... ],
 *    ...otherOptions,
 *   });
 * ```
 * and add the `key` in the `shared>entities>fetchersByQueryKey.ts>fetchersByQueryKey` object
 */
export function useAppQuery<T extends QueryKeysWithRequiredArgs>(
  key: T,
  options?: QueryOptions<T>,
): UseQueryResult<QueryData<T>>;

/**
 * useAppQuery implementation
 *
 * Calls react-query's useQuery with the fetcher associated with the key,
 * and the options provided
 *
 * The query's dependencies for cache management are its key and the fetcher arguments
 */
export function useAppQuery<T extends QueryKeys>(
  key: T,
  options?: QueryOptions<T>,
) {
  const { fetcherArgs = [], ...useQueryOptions } = options ?? {};
  const fetcher = fetchersByQueryKey[key] as (...a: unknown[]) => any;

  return useQuery<QueryData<T>>(
    [key, ...fetcherArgs],
    async () => {
      try {
        return await fetcher(...(fetcherArgs ?? []));
      } catch (error: any) {
        if (options?.onError) {
          options.onError(error);
        }
        // We need to throw the error in order to trigger useQuery retries, as
        // configured in the global config
        throw error;
      }
    },
    // react-query additional options provided
    { ...useQueryOptions },
  );
}
