import React, { useState } from 'react';

import { css } from '@emotion/react';
import { ButtonBaseDanger } from 'App.styled';
import { Trans, useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, Collapse, Button, Divider } from '@mui/material';

import { ActionsTypes } from 'shared/components/BacklinkSettingsDataEditor/state/actions';
import { AppNotificationTypes } from 'shared/constants';
import { fetchBacklinkSettingsStepsTemplate } from 'shared/entities/backlinkSettings/backlinkSettings.api';
import { makeLocalesInfosArrayFromLocaleCodesArray } from 'shared/entities/backlinkSettings/backlinkSettings.locale';
import { AllowedLocale } from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';
import { useNotification } from 'shared/hooks/useNotification';
import { useAppWideData } from 'shared/state';

import { useBacklinkSettingsDataEditorContext } from '../../../state/context';
import EditorLocaleSwitcher from '../../menus/EditorLocaleSwitcher';
import LocaleSelector from './LocaleSelector';

import { EditorFormLabel, FieldRow } from '../../fields/styled';
import { Card, CardHeader } from './styled';

export const EditorLocaleManager: React.FC = () => {
  const { t } = useTranslation();

  const { storesConfigs } = useAppWideData();

  const {
    locales,
    editorState,
    dispatch,
    currentLocale,
    handleCurrentLocaleChange,
    parentEntityInfos,
  } = useBacklinkSettingsDataEditorContext();

  const { notify } = useNotification();
  const [isExpanded, setIsExpanded] = useState(false);
  const defaultLocale = editorState.common.defaultLocale;
  const allowDelete = locales.length > 1 && defaultLocale !== currentLocale;

  const localesInfosArray = makeLocalesInfosArrayFromLocaleCodesArray(locales);

  const onDefaultLocaleSet = (locale: string) => {
    dispatch({
      type: ActionsTypes.UpdateSetting,
      payload: { path: 'common.defaultLocale', data: locale },
    });
  };

  const handleAddLocale = async (locale: AllowedLocale) => {
    const settings = await fetchBacklinkSettingsStepsTemplate(
      locale,
      editorState.common.defaultLocale,
    );
    if (storesConfigs) {
      dispatch({
        type: ActionsTypes.AddLocaleSettings,
        payload: {
          locale,
          settings,
          parentEntityType: parentEntityInfos?.type,
          storesConfigs,
        },
      });
    }
    // quick and dirty fix for a race condition where the locale is not yet in the list
    // at the time we propagate the change
    // no idea why it was working before - appeared after the React 18 upgrade
    setTimeout(() => {
      handleCurrentLocaleChange(locale);
    }, 10); // Note: we use a very short timeout but not 0 to make sure Safari handes this properly - no idea why it would not accomodate a 0 timeout but it does not :)
  };

  const handleDeleteLocale = (locale: string) => {
    // we want at least one locale left & we don't allow removing the default locale
    if (locales.length === 1 || locale === editorState.common.defaultLocale) {
      return;
    }
    // we switch to the first locales of the remaining locales
    handleCurrentLocaleChange(
      locales.filter((_locale) => locale !== _locale)[0],
    );
    dispatch({ type: ActionsTypes.DeleteLocaleSettings, payload: { locale } });
    notify({
      type: AppNotificationTypes.Success,
      message: t(
        'ui.component.backlink_settings_data_editor.locale_manager.message.language_deleted',
        'Language deleted!',
      ),
    });
  };

  return (
    <div>
      <Card isExpanded={isExpanded}>
        <CardHeader
          disableTypography
          title={
            <EditorLocaleSwitcher
              localesInfos={localesInfosArray}
              currentLocale={currentLocale}
              onChange={handleCurrentLocaleChange}
              css={css`
                width: 100%;
              `}
            >
              <IconButton
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                size="large"
              >
                {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </EditorLocaleSwitcher>
          }
        />
        <Collapse in={isExpanded} timeout={100} unmountOnExit>
          <div className="fields-container">
            <FieldRow>
              <>
                <EditorFormLabel>
                  <Trans i18nKey="ui.component.backlink_settings_data_editor.locale_manager.default_language">
                    <strong>Default language</strong>
                    <small>
                      Used when a fan browser language is not configured on this
                      link.
                    </small>
                  </Trans>
                </EditorFormLabel>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={defaultLocale === currentLocale}
                  startIcon={
                    defaultLocale === currentLocale && <CheckOutlinedIcon />
                  }
                  onClick={() => {
                    onDefaultLocaleSet(currentLocale);
                  }}
                >
                  {defaultLocale === currentLocale
                    ? t(
                        'ui.component.backlink_settings_data_editor.locale_manager.button.is_default.label',
                        'Default',
                      )
                    : t(
                        'ui.component.backlink_settings_data_editor.locale_manager.button.set_as_default.label',
                        'Set',
                      )}
                </Button>
              </>
            </FieldRow>
            {allowDelete && (
              <FieldRow
                css={css`
                  align-items: center;
                `}
              >
                <EditorFormLabel>
                  <span>
                    {t(
                      'ui.component.backlink_settings_data_editor.locale_manager.button.delete.label',
                      'Delete',
                    )}
                  </span>
                </EditorFormLabel>
                <IconButton
                  component={ButtonBaseDanger}
                  onClick={() => handleDeleteLocale(currentLocale)}
                  size="large"
                >
                  <RemoveCircleIcon />
                </IconButton>
              </FieldRow>
            )}
          </div>
          <Divider />
          <FieldRow>
            <LocaleSelector
              onChange={(event, value) => {
                if (value) {
                  handleAddLocale(value);
                }
              }}
              getOptionDisabled={(localeInfos) =>
                locales.includes(localeInfos.code)
              }
            />
          </FieldRow>
        </Collapse>
      </Card>
    </div>
  );
};

export default EditorLocaleManager;
