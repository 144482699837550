import React from 'react';

import { useTranslation } from 'react-i18next';

import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import {
  BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT,
  BACKLINK_OPTIN_COMPETITION_MIN_AGE,
  BACKLINK_OPTIN_COMPETITION_MAX_AGE,
} from 'shared/entities/backlink/backlink.constants';
import {
  FormFieldConfig,
  OptinCompetitionConfig,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppContext, getCompetitionMinimumAge } from 'shared/state';

import { ValidationErrors } from '../../../hooks/useValidation';
import { EditorDayPicker } from '../../fields/EditorDayPicker';
import { EditorRichTextField } from '../../fields/EditorRichTextField';
import EditorTextField from '../../fields/EditorTextField';

import { FieldRow } from '../../fields/styled';

type Props = {
  fieldConfig: OptinCompetitionConfig;
  onChange: (fieldConfig: FormFieldConfig) => void;
  errors: ValidationErrors;
};
const OptinCompetitionMetasFields: React.FC<Props> = ({
  fieldConfig,
  onChange,
  errors,
}) => {
  const { t } = useTranslation();

  const { state: appState } = useAppContext();
  const getHandleChange = (key: keyof OptinCompetitionConfig['metas']) => (
    value: any,
  ) => {
    onChange({
      ...fieldConfig,
      metas: {
        ...fieldConfig.metas,
        [key]: value,
      },
    });
  };

  const minimumAge =
    getCompetitionMinimumAge(appState) || BACKLINK_OPTIN_COMPETITION_MIN_AGE;
  const ages = [
    ...new Array(BACKLINK_OPTIN_COMPETITION_MAX_AGE - minimumAge + 1),
  ].map((_, index) => index + minimumAge);

  return (
    <>
      <FieldRow>
        <EditorDayPicker
          id="competitionStartDate"
          label={t(
            'ui.component.backlink_settings_data_editor.form_field_editor.optin_competition.start_date.label',
            'Start Date',
          )}
          changeHandler={getHandleChange('startDate')}
          value={fieldConfig.metas.startDate}
          fullWidth
        />
      </FieldRow>
      <FieldRow>
        <EditorDayPicker
          id="competitionEndDate"
          label={t(
            'ui.component.backlink_settings_data_editor.form_field_editor.optin_competition.end_date.label',
            'End Date',
          )}
          changeHandler={getHandleChange('endDate')}
          value={fieldConfig.metas.endDate}
          fullWidth
        />
      </FieldRow>
      <FieldRow>
        <FormControl variant="outlined">
          <InputLabel id="competitionMinimumAge">
            {t(
              'ui.component.backlink_settings_data_editor.form_field_editor.optin_competition.min_age.label',
              'Minimum Age',
            )}
          </InputLabel>
          <Select
            label="Minimum Age"
            labelId="competitionMinimumAge"
            onChange={(event) =>
              getHandleChange('minimumAge')(event.target.value)
            }
            value={
              fieldConfig.metas.minimumAge || BACKLINK_OPTIN_COMPETITION_MIN_AGE
            }
            variant="outlined"
            fullWidth
            MenuProps={{ style: { maxHeight: 400 } }}
          >
            {ages.map((age) => (
              <MenuItem key={age} value={age}>
                {age}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>
      <FieldRow>
        <EditorTextField
          name="competitionTerritory"
          label={t(
            'ui.component.backlink_settings_data_editor.form_field_editor.optin_competition.territory.label',
            'Territory',
          )}
          changeHandler={getHandleChange('territory')}
          value={fieldConfig.metas.territory || ''}
          error={!!errors['metas.territory']}
        />
      </FieldRow>
      <FieldRow>
        <EditorTextField
          name="competitionWinners"
          type="number"
          InputProps={{ inputProps: { min: '0' } }}
          label={t(
            'ui.component.backlink_settings_data_editor.form_field_editor.optin_competition.winners_count.label',
            'Number of winners',
          )}
          changeHandler={(value) => {
            getHandleChange('winnersCount')(Number(value));
          }}
          value={
            fieldConfig.metas.winnersCount.toString() ||
            BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT.toString()
          }
          error={!!errors['metas.winnersCount']}
        />
      </FieldRow>
      <FieldRow>
        <EditorRichTextField
          value={fieldConfig.metas.prizes || ''}
          changeHandler={getHandleChange('prizes')}
          mode="minimal"
          error={!!errors['metas.prizes']}
          options={{
            withReplacementTokenSelect: true,
          }}
        />
      </FieldRow>
    </>
  );
};

export default OptinCompetitionMetasFields;
