import { RawCapiProduct } from './product.api.types';

export enum ProductTypeEnum {
  ALBUM = 'Album',
  SINGLE = 'Single',
  VIDEO = 'Video',
  UNKNOWN = 'Unknown',
}

export type ProductStatus = 'sellable' | 'unsellable';

export type ProductState = 'pending' | 'final';

export type Product = RawCapiProduct & {
  titleWithProductVersion?: string;
};

export const isProduct = (product: any): product is Product =>
  !!product.internalProductId;
