import React, { AriaAttributes, MouseEvent, ReactNode } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';

import { StyledSwitcher } from './styled';

type Props = {
  disabled?: boolean;
  onPrevButtonClick: (event: MouseEvent) => void;
  onNextButtonClick: (event: MouseEvent) => void;
  label: ReactNode;
  className?: string;
} & AriaAttributes;
export const Switcher: React.FC<Props> = ({
  disabled = false,
  onPrevButtonClick,
  onNextButtonClick,
  label,
  className,
  ...props
}) => {
  return (
    <StyledSwitcher
      className={className}
      role="group"
      aria-label={props['aria-label']}
    >
      <IconButton
        onClick={onPrevButtonClick}
        disabled={disabled}
        className="leftNavIcon"
        aria-label="previous"
        size="large"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <div className="labelContainer">{label}</div>
      <IconButton
        onClick={onNextButtonClick}
        disabled={disabled}
        className="rightNavIcon"
        aria-label="next"
        size="large"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </StyledSwitcher>
  );
};

export default Switcher;
