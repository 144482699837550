import { buildImageUrl } from 'shared/services/image.service';

import { Product } from '../product/product.types';
import { RawRelease } from './release.api.types';
import { Release } from './release.types';

export const ReleaseModel = {
  getCoverUrl(release: Release | undefined, edits?: object) {
    return release
      ? release.metadata.artworkResources?.uploadId
        ? buildImageUrl({
            imageId: release.metadata.artworkResources?.uploadId,
            edits,
          })
        : release.artworkUrl
      : '';
  },
  transform(release: RawRelease): Release {
    return {
      ...release,
      titleWithProductVersion:
        release.products &&
        release.products.length > 0 &&
        release.products[0].productVersion
          ? `${release.title} (${release.products[0].productVersion})`
          : release.title,
      products: release.products.map((product: Product) => {
        return {
          ...product,
          titleWithProductVersion: product.productVersion
            ? `${product.title} (${product.productVersion})`
            : product.title,
        };
      }),
    };
  },
  transformAll(releases: RawRelease[]): Release[] {
    return releases.map(ReleaseModel.transform);
  },
};
