import React, { useState } from 'react';

import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  MenuItem,
  InputAdornment,
  InputLabel,
  FormControl,
  Grid,
  Select,
  TextField,
  Autocomplete,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { TIMEZONES, HOURS } from 'shared/constants';
import { BACKLINK_SETTINGS_DATE_FORMAT } from 'shared/entities/backlinkSettings/backlinkSettings.constants';
import {
  getFormattedDateTimeFromIsoDateTimeAndTimezone,
  getIsoDateFromElements,
} from 'shared/utils/date';

import * as helpers from './datePickerWithTimezone.helper';

export type Props = {
  dateLabel: string;
  dateValue: string;
  dateChangeHandler: (newValue: string) => void;
  disabled?: boolean;
  maxDateTime?: Moment;
  minDateTime?: Moment;
  timeLabel?: string;
  timezoneLabel?: string;
  timezoneValue: string;
  timezoneChangeHandler: (newValue: string) => void;
};

const DatePickerWithTimezone: React.FC<Props> = ({
  dateLabel,
  dateValue,
  dateChangeHandler,
  disabled = false,
  maxDateTime,
  minDateTime,
  timeLabel,
  timezoneLabel,
  timezoneValue,
  timezoneChangeHandler,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.datepicker_with_timezone',
  });
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const timeTitle = timeLabel || t('field.time.label', 'Time');
  const timezoneTitle = timezoneLabel || t('field.timezone.label', 'TimeZone');

  const date = getFormattedDateTimeFromIsoDateTimeAndTimezone(
    dateValue,
    timezoneValue,
  );
  const hour = getFormattedDateTimeFromIsoDateTimeAndTimezone(
    dateValue,
    timezoneValue,
    'HH',
  );

  function handleTimezoneChange(newTimezone) {
    const newValue = getIsoDateFromElements({
      date,
      hour,
      timezone: newTimezone,
    });
    dateChangeHandler(newValue);
    timezoneChangeHandler(newTimezone);
  }

  function handleDateChange(newDate) {
    const newValue = getIsoDateFromElements({
      date: newDate,
      hour,
      timezone: timezoneValue,
    });
    if (newValue !== 'Invalid date') dateChangeHandler(newValue);
  }

  function handleHourChange(newHour) {
    const newValue = getIsoDateFromElements({
      date,
      hour: newHour,
      timezone: timezoneValue,
    });
    dateChangeHandler(newValue);
  }

  const isHourEnabled = (currentHour: string) => {
    if (
      helpers.hasNotMinOrMaxDatesParams({ maxDateTime, minDateTime }) ||
      helpers.isDateOutsideBounds({
        date,
        maxDateTime,
        minDateTime,
      })
    ) {
      return true;
    }

    const currentHourAsInteger = parseInt(currentHour, 10);
    if (
      helpers.isStringDateEqualToMomentDate({
        date: date,
        momentDate: minDateTime,
      })
    ) {
      return currentHourAsInteger >= helpers.getHour(minDateTime);
    }
    if (
      helpers.isStringDateEqualToMomentDate({
        date: date,
        momentDate: maxDateTime,
      })
    ) {
      return currentHourAsInteger <= helpers.getHour(maxDateTime);
    }

    return true;
  };

  return (
    <div
      role="group"
      aria-label={t('title', 'Release date picker with timezone')}
      css={{
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',
      }}
    >
      <div data-testid="date-picker">
        <DesktopDatePicker
          css={{ width: '100%' }}
          disabled={disabled}
          label={dateLabel}
          value={date}
          inputFormat={BACKLINK_SETTINGS_DATE_FORMAT}
          maxDate={maxDateTime}
          mask="____/__/__"
          minDate={minDateTime}
          onChange={(newDate: Moment | null) => {
            if (newDate) {
              handleDateChange(newDate.format('YYYY-MM-DD'));
            }
          }}
          open={datePickerIsOpen}
          onClose={() => setDatePickerIsOpen(false)}
          PopperProps={{
            placement: 'top-end',
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [32, 0],
                  },
                },
              ],
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onClick={() => setDatePickerIsOpen(true)}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'date',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon sx={{ fontSize: '1rem' }} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          )}
        />
      </div>
      <Grid container gap="16px">
        <Grid item xs>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="date-picker-select-hour">{timeTitle}</InputLabel>
            <Select
              disabled={disabled}
              label={timeTitle}
              value={hour}
              onChange={(event: any) => {
                handleHourChange(event.target.value as string);
              }}
              inputProps={{
                'aria-label': timeTitle,
              }}
              IconComponent={() => (
                <InputAdornment position="start">
                  <AccessTimeIcon sx={{ fontSize: '1rem' }} />
                </InputAdornment>
              )}
            >
              {HOURS.map((hour) => (
                <MenuItem
                  disabled={!isHourEnabled(hour.value)}
                  key={hour.value}
                  value={hour.value}
                >
                  {hour.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <Autocomplete
            disabled={disabled}
            data-testid="timezone"
            options={TIMEZONES}
            renderInput={(params) => (
              <TextField {...params} label={timezoneTitle} variant="outlined" />
            )}
            value={timezoneValue}
            css={{ flex: 2 }}
            disableClearable
            onChange={(event: any, newValue: string | null) => {
              handleTimezoneChange(newValue);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DatePickerWithTimezone;
