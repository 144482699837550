import React from 'react';

import { useTranslation } from 'react-i18next';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Button } from '@mui/material';

import { FormHasErrorsIcon } from '../Icons';

type Props = {
  showFetching: boolean;
  showSaving: boolean;
  showHasErrors: boolean;
  onClick: React.MouseEventHandler;
};

export const EditorSaveButton: React.FC<Props> = ({
  showFetching,
  showSaving,
  showHasErrors,
  onClick,
}) => {
  const { t } = useTranslation();

  const label = showFetching ? ( // prettier-ignore
    t('ui.message.fetching', 'Fetching ...')
  ) : showHasErrors ? (
    <FormHasErrorsIcon />
  ) : showSaving ? (
    t('ui.message.saving', 'Saving ...')
  ) : (
    t('ui.button.save', 'Save')
  );
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={showHasErrors}
      startIcon={!showHasErrors && <CheckOutlinedIcon />}
      aria-label={t('ui.button.save', 'Save')}
    >
      {label}
    </Button>
  );
};
