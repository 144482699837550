import axios, { AxiosError } from 'axios';

import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';
import { redirectAfterLoginFail } from 'shared/utils/redirect';

import config from '../config';

export const api = axios.create({
  baseURL: config.getApiBaseUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.data?.errorKey === 'api.auth.fail') {
      // Hack: For backstage marketing we want to redirect from the client call as we need to access
      // pathname to redirect with it in the path
      if (
        getAppName() === AppName.backstageMarketingResources ||
        getAppName() === AppName.backstageMarketing
      ) {
        return Promise.reject(new Error('ApiAuthFail'));
      }
      return redirectAfterLoginFail();
    } else {
      return Promise.reject(error);
    }
  },
);

/**
 * Determines whether the payload is an error thrown by Axios
 *
 * @param {*} payload The value to test
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 */
export function isAxiosError(error: any): error is AxiosError {
  return (
    typeof error === 'object' && (error as AxiosError).isAxiosError === true
  );
}
