import React, { useState, useEffect, useRef, useMemo } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash/debounce';

import { insertAtCaretPositionAndGetNewValue } from 'shared/utils/dom';

import { EditorTextFieldProps } from '../../types';
import ReplacementTokenSelect from '../ReplacementTokenSelect';

import { StyledTextField } from './styled';

export const EditorTextField: React.FC<EditorTextFieldProps> = ({
  name,
  value,
  changeHandler,
  fullWidth = true,
  error = false,
  showErrorMessage = false,
  errorMessage,
  options = {
    withReplacementTokenSelect: false,
  },
  className,
  ...props
}) => {
  /**
   * we need to memoize the debounced change handler to avoid re-creating it on every render as the local value change
   * which results in the text field being updated with old values on each key stroke
   * we merely want the debounced handler to be defined once when this component is 'mounted'
   */
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler],
  );

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (newValue) => {
    setLocalValue(newValue);
    debouncedChangeHandler(newValue);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedValue = event.currentTarget.value;
    handleChange(updatedValue);
  };

  return (
    <div
      css={css`
        position: relative;
      `}
      className={className}
    >
      <StyledTextField
        {...props}
        inputRef={inputRef}
        id={name}
        name={name}
        InputLabelProps={{ htmlFor: name }}
        value={localValue}
        onChange={handleTextFieldChange}
        variant="outlined"
        fullWidth={fullWidth}
        error={error}
        helperText={error && showErrorMessage ? errorMessage : ''}
      />
      {options?.withReplacementTokenSelect && (
        <ReplacementTokenSelect
          onChange={(token) => {
            const newValue = insertAtCaretPositionAndGetNewValue({
              inputElement: inputRef.current,
              stringToBeInserted: token,
            });
            handleChange(newValue);
          }}
          css={css`
            position: absolute;
            top: 0;
            right: -50px;
          `}
        />
      )}
    </div>
  );
};

export default EditorTextField;
