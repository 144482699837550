import { BacklinkReleaseMode } from 'shared/entities/backlink/backlink.types';
import { ProductTypeEnum } from 'shared/entities/product/product.types';
import {
  BacklinkReleaseStore,
  Release,
  ReleaseStore,
} from 'shared/entities/release/release.types';
import { StoreConfig } from 'shared/entities/storeConfig/storeConfig.types';
import i18n from 'shared/i18n';
import { validateUrl } from 'shared/utils/validation';

type Params = {
  releaseStore: BacklinkReleaseStore;
  storeConfig: StoreConfig;
  backlinkReleaseMode: BacklinkReleaseMode;
  release?: Release;
  releaseStoreFromRelease?: ReleaseStore;
  hasErrorInDspLinks?: boolean;
  errors?: {
    [iso: string]: { url: string | boolean };
  };
};

type Metas = {
  noProductsOfMatchingTypeInRelease: boolean;
  headerLabel: string;
  headerStatus: 'success' | 'warning' | 'error';
  fallbackUrl?: string;
};

function getFallbackUrl({
  release,
  storeConfig,
}: Pick<Params, 'release' | 'storeConfig'>) {
  if (!release) return;

  const canditateUrl =
    release.artist?.data?.storeProfileLinks?.[storeConfig.name]?.url; // intermediary var for TS
  const artistUrl = !!canditateUrl ? validateUrl(canditateUrl) : undefined;
  if (!storeConfig.releaseFallbackSearchUrlTemplate) return artistUrl;

  const releaseFallbackUrl = validateUrl(
    storeConfig.releaseFallbackSearchUrlTemplate
      .replace(/\{releaseTitle\}/i, release.title)
      .replace(/\{artistName\}/i, release.artistName),
  );
  return artistUrl ?? releaseFallbackUrl;
}

export function getStoreCardMetas({
  releaseStore,
  release,
  storeConfig,
  releaseStoreFromRelease,
  backlinkReleaseMode,
  hasErrorInDspLinks,
  errors,
}: Params): Metas {
  const releaseLinksNumber = Object.entries(releaseStore.links).filter(
    ([_, link]) => Boolean(link.url),
  ).length;

  // prettier-ignore
  const noProductsOfMatchingTypeInRelease = Boolean(release)
    ? storeConfig.name === 'youtube'
      ? !release?.products.some((product) => product.productType === ProductTypeEnum.VIDEO)
      : !release?.products.some((product) => product.productType !== ProductTypeEnum.VIDEO)
    : false;

  // prettier-ignore
  const headerLabel = !!releaseStore.useAutoscan
    ? !!releaseStoreFromRelease?.url && !noProductsOfMatchingTypeInRelease
      ? i18n.t(
        'ui.component.backlink_settings_data_editor.store_card.header.links_count',
          '{{count}} link',
          { count: 1 },
        )
      : i18n.t(
          'ui.component.backlink_settings_data_editor.store_card.header.auto_scan',
          'Auto-scan',
        )
    : releaseLinksNumber > 0
    ? i18n.t(
        'ui.component.backlink_settings_data_editor.store_card.header.links_count',
        '{{count}} link',
        { count: releaseLinksNumber },
      )
    : i18n.t(
        'ui.component.backlink_settings_data_editor.store_card.header.manual',
        'Manual',
      );

  // prettier-ignore
  const headerStatus = Boolean(releaseStore.useAutoscan)
    ? backlinkReleaseMode === 'pre-release'
      ? noProductsOfMatchingTypeInRelease ? 'warning' : 'success'
      : Boolean(releaseStoreFromRelease?.url) ? 'success' : 'warning'
    : hasErrorInDspLinks || Object.values(errors || {}).some((err) => Boolean(err.url))
      ? 'error'
      : releaseLinksNumber > 0 ? 'success' : 'warning';

  return {
    noProductsOfMatchingTypeInRelease,
    headerLabel,
    headerStatus,
    fallbackUrl: getFallbackUrl({ release, storeConfig }),
  };
}
