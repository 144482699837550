import { Artist } from 'shared/entities/artist/artist.types';
import { Product } from 'shared/entities/product/product.types';
import { ActionMap } from 'shared/state/types';

import { NewArtist, ReleaseEditorStep } from '../types';

export enum ActionType {
  CHANGE_STEP = 'CHANGE_STEP',
  UPDATE_ARTIST_QUERY = 'UPDATE_ARTIST_QUERY',
  UPDATE_ARTIST = 'UPDATE_ARTIST',
  UPDATE_PRODUCT_QUERY = 'UPDATE_PRODUCT_QUERY',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
}

type Payload = {
  [ActionType.CHANGE_STEP]: { currentStep: ReleaseEditorStep };
  [ActionType.UPDATE_ARTIST_QUERY]: { artistQuery: string };
  [ActionType.UPDATE_ARTIST]: { artist: Artist | NewArtist };
  [ActionType.UPDATE_PRODUCT_QUERY]: { productQuery: string };
  [ActionType.UPDATE_PRODUCT]: { product: Product };
};

export type Action = ActionMap<Payload>[keyof ActionMap<Payload>];
