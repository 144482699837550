import React from 'react';

import { MuiIcons } from '@believe-front/react-components';

import i18n from 'shared/i18n';

import { YoutubePlacementType, YoutubeTargetingType } from './ads.types';

export const ADS_DEFAULT_CURRENCY = 'EUR';
export const ADS_CGU_URL =
  'https://blv-legal-online-document.s3.eu-west-1.amazonaws.com/BackstageAds-Terms.pdf';
export const MIN_AGE_RANGE = 6;
export const MAX_AGE_VALUE = 100;
export const DEFAULT_START_AGE = 18;
export const DEFAULT_END_AGE = 65;
export const MINIMUM_DURATION_DAYS = 3;
export const MINIMUM_BUDGET_PER_DAY = 30;
export const DEFAULT_LANDING_PAGE_URL_VALUE = 'https://';

export const INSTA_FEED_WIDTH = '275px';
export const INSTA_FEED_HEIGHT = '343px';
export const INSTA_STORY_WIDTH = '275px';
export const INSTA_STORY_HEIGHT = '487px';
export const FACEBOOK_FEED_WIDTH = '275px';

export const FB_FEED_PREVIEW_TEST_ID = 'fb-feed-preview';
export const IG_FEED_PREVIEW_TEST_ID = 'insta-feed-preview';
export const IG_STORY_PREVIEW_TEST_ID = 'insta-story-preview';

export const CUSTOM_WARNING_COLOR = '#FF8A00';
export const HOW_TO_FB_INSTA_IDS_LINK =
  'https://facebook-linking.backstageads.com/';

export const ADSCAMPAIGNS_PER_PAGE_DEFAULT = 4;

export enum AdCampaignPlatform {
  YT = 'YT',
  IG = 'IG',
  FB_MIX = 'FB_MIX',
}

export enum AdCampaignStatus {
  /**
   * Campaign is created but not submitted
   */
  Draft = 'DRAFT',
  /**
   * Campaign is submitted and being created by the automation
   */
  Pending = 'PENDING',
  /**
   * Campaign has been created by the automation, ready to start
   */
  Scheduled = 'SCHEDULED',
  /**
   * Campaign is ongoing
   */
  Active = 'ACTIVE',
  /**
   * Campaign is finished
   */
  Finished = 'FINISHED',
  /**
   * Campaign has been rejected by the platform
   */
  AdsError = 'ADS_ERROR',
  /**
   * Automation error
   */
  CampaignError = 'CAMPAIGN_ERROR',
}

export enum AdCampaignPlacement {
  FB_FEED = 'FB_FEED',
  IG_FEED = 'IG_FEED',
  IG_STORY = 'IG_STORY',
  YT_SKIPPABLE = 'YT_SKIPPABLE',
}

export enum AdCampaignTargetingType {
  INTEREST = 'INTEREST',
}

export enum AdCampaignTargetingGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  ALL = 'ALL',
}

// We use the same regexes as Hearbeat (one of our backend service)
export const youtubeUrlRegexes = [
  {
    regex:
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|ytscreeningroom\?v=|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    idIndex: 1,
  },
  {
    regex: /(?:youtu(?:\.be|be\.com)\/(?:.*v(?:\/|=)|(?:.*\/)?)([\w'-]{11}))/i,
    idIndex: 1,
  },
];

/**
 * Mapping between status and icons
 */
export const statusIconMap: Record<AdCampaignStatus, React.ReactElement> = {
  [AdCampaignStatus.Active]: (
    <MuiIcons.Timelapse fontSize="small" color="success" />
  ),
  [AdCampaignStatus.Scheduled]: (
    <MuiIcons.Schedule fontSize="small" color="success" />
  ),
  [AdCampaignStatus.Finished]: (
    <MuiIcons.CheckCircle fontSize="small" color="success" />
  ),
  [AdCampaignStatus.Pending]: (
    <MuiIcons.HourglassTop fontSize="small" color="primary" />
  ),
  [AdCampaignStatus.AdsError]: (
    <MuiIcons.Error fontSize="small" color="error" />
  ),
  [AdCampaignStatus.CampaignError]: (
    <MuiIcons.Error fontSize="small" color="error" />
  ),
  [AdCampaignStatus.Draft]: <MuiIcons.Edit fontSize="small" color="info" />,
};

/**
 * Mapping between status and colors
 */
const success = {
  backgroundColor: 'hsl(144 62% 49% / 0.1)',
  color: 'hsl(144 62% 49%)',
};
const error = {
  backgroundColor: 'hsl(351 100% 62% / 0.05)',
  color: 'hsl(351 100% 62%)',
};
export const statusColorMap: Record<
  AdCampaignStatus,
  { backgroundColor: string; color: string }
> = {
  [AdCampaignStatus.Draft]: {
    backgroundColor: 'grey.100',
    color: 'grey.700',
  },
  [AdCampaignStatus.Pending]: {
    backgroundColor: 'grey.100',
    color: '#1A6AAF',
  },
  [AdCampaignStatus.Scheduled]: success,
  [AdCampaignStatus.Active]: success,
  [AdCampaignStatus.Finished]: success,
  [AdCampaignStatus.AdsError]: error,
  [AdCampaignStatus.CampaignError]: error,
};

export enum AvailablePlanStatus {
  LIVE = 'LIVE',
  SOON = 'SOON',
  FINISHED = 'FINISHED',
  DRAFT = 'DRAFT',
}

export enum SanityCheckPlanStatus {
  ALL_GOOD = 'ALL_GOOD',
  KEEP_WATCHING = 'KEEP_WATCHING',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

export const allPlanStatus = {
  ...AvailablePlanStatus,
  UNKNOWN: 'UNKNOWN',
};

export enum PlatformStatus {
  UNKNOWN = 'UNKNOWN',
  ERROR = 'ERROR',
  UNDER_VALIDATION = 'UNDER_VALIDATION',
  REJECTED = 'REJECTED',
  READY = 'READY',
  BROADCASTING = 'BROADCASTING',
  FINISHED = 'FINISHED',
}

export enum Currency {
  EURO = 'EURO',
}

export enum Objective {
  VIDEO_VIEWS = 'VIDEO VIEWS',
  AWARENESS = 'AWARENESS',
  ENGAGEMENT = 'ENGAGEMENT',
  TRAFFIC = 'TRAFFIC',
  CONVERSION = 'CONVERSION',
}

export const ObjectiveDefinitions: Record<Objective, string> = {
  [Objective.VIDEO_VIEWS]: 'Generate quality views for a specific video',
  [Objective.AWARENESS]: 'Increase notoriety among a selected audience',
  [Objective.ENGAGEMENT]: 'Increase consideration and interactions',
  [Objective.TRAFFIC]: 'Drive clicks towards a website',
  [Objective.CONVERSION]: 'Generate a specific action on a website',
};

export enum CampaignStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  READY_TO_PUSH = 'READY_TO_PUSH',
  PUSHING = 'PUSHING',
  PUSHED = 'PUSHED',
  ERROR = 'ERROR',
}

export enum CampaignSetupMethod {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum AvailablePlatforms {
  YOUTUBE = 'YOUTUBE',
  META = 'META',
}

export const AvailablePlatformMap: Record<AvailablePlatforms, string> = {
  [AvailablePlatforms.YOUTUBE]: 'YouTube',
  [AvailablePlatforms.META]: 'META',
};

export enum AvailableReleaseTypes {
  ALBUM = 'ALBUM',
  SINGLE = 'SINGLE',
  VIDEO = 'VIDEO',
  UNKNOWN = 'UNKNOWN',
}

export enum AvailableReleaseDates {
  NEXT_7_DAYS = 'Next_7_days',
  NEXT_30_DAYS = 'Next_30_days',
  NEXT_12_MONTHS = 'Next_12_months',
  LAST_7_DAYS = 'Last_7_days',
  LAST_30_DAYS = 'Last_30_days',
  LAST_12_MONTHS = 'Last_12_months',
}

export enum AvailableAdsUsersProfileTypes {
  DIGITAL_MANAGER = 'DIGITAL_MANAGER',
  DISTRIBUTION_MANAGER = 'DISTRIBUTION_MANAGER',
  GLOBAL_HEAD = 'GLOBAL_HEAD',
  HEAD_DIGITAL_MANAGER = 'HEAD_DIGITAL_MANAGER',
  HEAD_OF_ASD = 'HEAD_OF_ASD',
  MEDIA_BUYER = 'MEDIA_BUYER',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  ADMIN = 'ADMIN',
}

export enum OrderByParamsEnum {
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  END_DATE_ASC = 'END_DATE_ASC',
  END_DATE_DESC = 'END_DATE_DESC',
  CREATION_DATE_ASC = 'CREATION_DATE_ASC',
  CREATION_DATE_DESC = 'CREATION_DATE_DESC',
}

export enum RechargeIds {
  REC = 'REC',
  NRE = 'NRE',
  SHD = 'SHD',
}

export enum ServiceCodes {
  BRB = 'BRB',
  BRE = 'BRE',
  SYB = 'SYB',
  SYE = 'SYE',
  MER = 'MER',
  PUB = 'PUB',
  TOU = 'TOU',
  NER = 'NER',
  GRA = 'GRA',
  DDA = 'DDA',
  DDV = 'DDV',
  PDR = 'PDR',
  PDD = 'PDD',
  MED = 'MED',
}

export enum BusinessUnitTypes {
  MATURE = 'MATURE',
  EMERGING = 'EMERGING',
}

export const YoutubePlacementDescription: Record<YoutubePlacementType, string> =
  {
    [YoutubePlacementType.YOUTUBE_BUMPER]: i18n.t(
      'section.ads.ads_campaign.ad_customization.placement.youtube_bumper.label',
      'YouTube Video URL, Redirection URL, Display URL, Call to action, Headline',
    ),
    [YoutubePlacementType.YOUTUBE_IN_FEED]: i18n.t(
      'section.ads.ads_campaign.ad_customization.placement.youtube_in_feed.label',
      'YouTube Video URL, Headline, Description 1, Description 2',
    ),
    [YoutubePlacementType.YOUTUBE_SKIPPABLE]: i18n.t(
      'section.ads.ads_campaign.ad_customization.placement.youtube_skippable.label',
      'YouTube Video URL, Redirection URL, Display URL, Call to action, Headline',
    ),
    [YoutubePlacementType.YOUTUBE_NON_SKIPPABLE]: i18n.t(
      'section.ads.ads_campaign.ad_customization.placement.youtube_non_skippable.label',
      'YouTube Video URL, Redirection URL, Display URL, Call to action',
    ),
  };

export const AudienceDescription: Record<YoutubeTargetingType, string> = {
  [YoutubeTargetingType.SOCIO_DEMOGRAPHICS]: i18n.t(
    'section.ads.ads_campaign.ad_customization.audience.socio_demographics.label',
    'Location, Gender, Age, Interest',
  ),
  [YoutubeTargetingType.LINKS_VISITORS]: i18n.t(
    'section.ads.ads_campaign.ad_customization.audience.links_visitors.label',
    'Location',
  ),
  [YoutubeTargetingType.VIDEO_VIEWERS]: i18n.t(
    'section.ads.ads_campaign.ad_customization.audience.video_viewers.label',
    'Location',
  ),
  [YoutubeTargetingType.SIMILAR_ARTISTS]: i18n.t(
    'section.ads.ads_campaign.ad_customization.audience.similar_artists.label',
    'Artists',
  ),
};
