import {
  CTAOptionsTemplates,
  CTAOptionsTemplatesType,
  CTASteps,
} from 'shared/entities/backlinkSettings/templates/backlinkCTAs.template';
import { BacklinkReleaseStore } from 'shared/entities/release/release.types';

import {
  DSPActionScheduleType,
  DSPActionType,
} from '../dspAction/dspAction.constants';
import { DSPAction } from '../dspAction/dspAction.types';
import { StoresConfigs, StoreType } from '../storeConfig/storeConfig.types';
import {
  BacklinkSettingsDataReplacementTokenKey,
  BACKLINK_SETTINGS_FALLBACK_LOCALE,
  prereleaseCTAModeByStoreType,
  REPLACEMENT_TOKEN_KEY_PLACEHOLDER,
  REPLACEMENT_TOKEN_TEMPLATE,
} from './backlinkSettings.constants';
import {
  isAllowedLocale,
  AllowedLocale,
} from './backlinkSettings.locale.types';
import {
  StoreLink,
  CallToActions,
  BacklinkSettingsData,
} from './backlinkSettings.types';

/**
 * returns a DSP action infos object
 * made from an action
 *
 * those 'action infos' objects are used to display infos about an action
 * in a format that can be used by the Public Backlink
 *
 * this is useful here, in the backoffice/backlink editor side of the project
 * because we need to pass those infos to the Backlink Preview
 *
 */
export const makeDspActionInfos = (
  { id, type, scheduleType, targetName }: DSPAction,
  backlinkSettingsData: BacklinkSettingsData,
) => {
  return {
    id,
    displayName:
      // special display name in some cases
      type === DSPActionType.ADD_ALBUM &&
      scheduleType ===
        DSPActionScheduleType.SCHEDULED_ON_BACKLINK_RELEASE_DATE &&
      !targetName
        ? backlinkSettingsData.releaseStep.release.title || ''
        : targetName,
    url: '',
    // we only want to show a link when we are certain the action link will point to an existing resource (playlist, album, track ...)
    linkEnabled: scheduleType === DSPActionScheduleType.SCHEDULED_NOW,
  };
};

/**
 *
 * @param tokenKey
 * @returns an actual token built from the token key & its separators (i.e. {} or {{}} ... )
 */
export function makeReplacementToken(
  tokenKey: BacklinkSettingsDataReplacementTokenKey,
) {
  return REPLACEMENT_TOKEN_TEMPLATE.replace(
    REPLACEMENT_TOKEN_KEY_PLACEHOLDER,
    tokenKey,
  );
}

/**
 * get paths in backlink data at which we find CallToActions
 */
export function getCTAsPathsInfos(editorState: BacklinkSettingsData) {
  const ctaKey = 'callToActions';
  const paths: {
    step: CTASteps;
    locale: string;
    path: string;
  }[] = [];
  Object.keys(editorState.prereleaseLandingStep.locale).forEach((locale) =>
    paths.push({
      step: 'prereleaseLanding',
      locale,
      path: `prereleaseLandingStep.locale.${locale}.${ctaKey}`,
    }),
  );
  Object.keys(editorState.postreleaseLandingStep.locale).forEach((locale) =>
    paths.push({
      step: 'postreleaseLanding',
      locale,
      path: `postreleaseLandingStep.locale.${locale}.${ctaKey}`,
    }),
  );
  return paths;
}

/**
 * sync a StoreLink list
 * with a Store map
 * - creating StoreLink for missing stores
 * - removing StoreLink that are not in the Store map
 */
export function syncStoreLinksListWithStoresMap(
  storesMap: Record<string, BacklinkReleaseStore>,
  storeLinksList: StoreLink[],
) {
  const list: StoreLink[] = [];
  Object.keys(storesMap).forEach((storeName) => {
    let storeLink: StoreLink;
    const storeRedirectIndex = storeLinksList.findIndex(
      (storeRedirect) => storeName === storeRedirect.id,
    );
    if (storeRedirectIndex !== -1) {
      storeLink = {
        ...storeLinksList[storeRedirectIndex],
      };
    } else {
      storeLink = {
        id: storeName,
        url: '',
      };
    }
    list.push(storeLink);
  });
  return list;
}

/**
 * sync the Call To Actions stores
 * with a Store map
 * - creating store & CTA's options for missing stores
 * - removing stores & their CTA's options for those that are not in the Store map
 */
export function syncCTAsWithReleaseStores({
  releaseStores,
  storesConfigs,
  CTAs,
  step = 'prereleaseLanding',
  locale = 'en',
}: {
  releaseStores: Record<string, BacklinkReleaseStore>;
  storesConfigs: StoresConfigs;
  CTAs: CallToActions;
  step: CTASteps;
  locale: string;
}) {
  const syncedCTAs: CallToActions = {
    stores: {
      // only keep stores present in source
      displayOrder: CTAs.stores.displayOrder.filter((storeName) =>
        Object.keys(releaseStores).includes(storeName),
      ),
      options: {},
    },
  };
  (Object.keys(releaseStores) as string[]).forEach((storeName) => {
    // displayOrder
    if (!syncedCTAs.stores.displayOrder.includes(storeName)) {
      syncedCTAs.stores.displayOrder.push(storeName);
    }

    const storeType = storesConfigs[storeName]?.type || StoreType.Other;

    // options
    syncedCTAs.stores.options[storeName] =
      CTAs.stores.options[storeName] ||
      (CTAOptionsTemplates[step][locale]
        ? Object.assign(
            {
              ...CTAOptionsTemplates[step][locale],
              label: CTAOptionsTemplates[step][locale].label[storeType],
            },
            step === 'prereleaseLanding'
              ? {
                  mode:
                    prereleaseCTAModeByStoreType[storeType] ||
                    CTAOptionsTemplates[step][locale].mode,
                }
              : {},
          )
        : undefined) ||
      Object.assign(
        {
          ...CTAOptionsTemplates[step][BACKLINK_SETTINGS_FALLBACK_LOCALE],
          label:
            CTAOptionsTemplates[step][BACKLINK_SETTINGS_FALLBACK_LOCALE].label[
              storeType
            ],
        },
        step === 'prereleaseLanding'
          ? {
              mode:
                prereleaseCTAModeByStoreType[storeType] ||
                CTAOptionsTemplates[step][BACKLINK_SETTINGS_FALLBACK_LOCALE]
                  .mode,
            }
          : {},
      );
  });
  return syncedCTAs;
}

export const translateStoresOptions = (
  initialStoresOptions: CallToActions['stores']['options'],
  localeToAdd: string,
  storesConfigs: StoresConfigs,
  templateKey: keyof CTAOptionsTemplatesType,
): CallToActions['stores']['options'] => {
  const stores = Object.keys(initialStoresOptions);

  const storesOptions = stores.reduce((acc, store) => {
    const type = storesConfigs[store]?.type || StoreType.Other;

    return {
      ...acc,
      [store]: {
        ...CTAOptionsTemplates[templateKey][localeToAdd],
        label: CTAOptionsTemplates[templateKey][localeToAdd].label[type],
      },
    } as CallToActions['stores']['options'];
  }, {});

  return storesOptions;
};

/** returns an array of locale codes
 * they represent the languages that are available for a Backlink */
export const getLocalesCodes = (source: BacklinkSettingsData) => {
  const locales = Object.keys(source.prereleaseFormStep.locale);
  locales.forEach((locale) => {
    if (!isAllowedLocale(locale))
      throw new Error(`'${locale}' is not allowed.`);
  });
  return locales as AllowedLocale[];
};

/**
 * returns a locale code that represents the locale that we want to use first
 */
export const getMainLocaleCode = (source: BacklinkSettingsData) => {
  return getLocalesCodes(source)[0];
};
