import styled from '@emotion/styled';

export const Container = styled.div`
  > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const DragHandle = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const DraggableItem = styled.div`
  position: relative;
  > ${DragHandle} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
