import { backlinkBaseUrlMap } from 'shared/routes';
import { AppName } from 'shared/types';

export const INTRANET_BASE_URL = 'https://intranet.believedigital.com';

export const CAMPAIGNS_PATH = '/campaigns';
export const RESOURCES_PATH = '/resources';
export const SETTINGS_PATH = '/settings';
export const ADMIN_PATH = '/admin';

export const LINKS_PATH = backlinkBaseUrlMap[AppName.marketingSuite];
export const DEFAULT_HOME_SECTION = LINKS_PATH;
export const NO_WORKSPACE_HOME_SECTION = `${CAMPAIGNS_PATH}/creative-hub`;

export const APPLE_BASE_URL = 'https://music.apple.com/fr';
export const APPLE_ALBUM_BASE_URL = `${APPLE_BASE_URL}/album`;
export const SPOTIFY_BASE_URL = 'https://open.spotify.com';
export const SPOTIFY_ALBUM_BASE_URL = `${SPOTIFY_BASE_URL}/album`;
export const SPOTIFY_TRACK_BASE_URL = `${SPOTIFY_BASE_URL}/track`;

export const ASSETLESS_PITCHES_LINK =
  'https://believedigital.sharepoint.com/:x:/s/GlobalMarketinghub/ETY5ZC4tMp5JjHgFQru6-NUBzt4YjhplwFC23qIN9qbyuQ?e=Oyqfm2';

export const initialExternalKnowledgeBaseSectionPath =
  'https://believedigital.zendesk.com/access/sso?brand_id=360001601100&return_to=https%3A%2F%2Fglobalmarketing.zendesk.com%2Fhc%2Fen-us';

export const externalKnowledgeBaseDefaultUrl =
  'https://globalmarketing.zendesk.com/hc/en-us/p/hub';

export const sectionsPathsRegExps = {
  'track-pitch': /campaigns\/pitches(\/[a-z0-9-]+)?$/,
};

export const sectionsExternalKnowledgeBaseUrls = new Map([
  [
    sectionsPathsRegExps['track-pitch'],
    'https://tmstaffkb.zendesk.com/hc/en-us/',
  ],
]);

export const USER_NO_PROGRAM_HELP_URL_OLD =
  'https://support-tech.atlassian.net/servicedesk/customer/portal/43/group/204/create/772';

export const USER_NO_PROGRAM_HELP_URL =
  'https://believedigital.sharepoint.com/:p:/s/GlobalMarketinghub/Ed59Q2hv4aJCs03CDQq76MEBpepA-P6U6TNsEbev3YWaEw?e=CApj9X';
