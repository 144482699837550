import styled from '@emotion/styled';

import { TabPanel } from '@mui/lab';
import { Typography } from '@mui/material';

import { Tabs, Tab } from 'shared/components/Tabs/styled';

import * as BacklinkSettingsEditorStyled from '../../styled';

export const StepHeader = styled.div`
  margin: 0 auto;
  max-width: ${BacklinkSettingsEditorStyled.stepWidth}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StepTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

StepTitle.defaultProps = {
  variant: 'h2',
};

export const StepDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 0.875rem;
`;

StepDescription.defaultProps = {
  variant: 'subtitle2',
};

export const StepParagraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-bottom: 1rem;

  a {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

StepParagraph.defaultProps = {
  variant: 'body1',
};

export const StepContent = styled.div`
  margin: 0 auto;
  max-width: ${BacklinkSettingsEditorStyled.stepWidth}px;
  h3 {
    margin-bottom: 1rem;
  }
`;

export const StepTabs = styled(Tabs)`
  margin-top: -10px;
`;

export const StepTab = Tab;

export const StepTabPanel = styled(TabPanel)`
  padding: ${({ theme }) => theme.spacing(3)} 0 0;
`;
