import { DSP } from './dspAction.constants';
import {
  FrontendDSPActionStatus,
  FrontendDSPActionType,
} from './dspAction.frontend.constants';
import { DSPAction, DSPConfig } from './dspAction.types';

/**
 * a DSPAction representation that will be used by the frontend
 * it does not require all the properties of the DSPAction entity
 * and might have some additional properties
 */
export type FrontendDSPAction = {
  /**
   * the id of the DSPAction entity
   * it might be null if the DSPAction is not saved in the database yet
   * -> this will be used as the way to distinguish between 'frontend-only' DSPActions and 'backend' DSPActions
   */
  dspActionId: string | null;
  /**
   * Store/DSP Name
   * note: it's called storeName for consistency and because a DSP is a Store
   */
  storeName: DSP;
  /**
   * the type of the DSPAction
   */
  type: FrontendDSPActionType;
  /**
   * the target name of the DSPAction
   * this is the name of the artist, playlist, album, track, etc.
   */
  targetName: DSPAction['targetName'];
  /**
   * the target URL of the DSPAction
   */
  targetURL: DSPConfig['targetUrl'];
  /**
   * the target Id of the DSPAction
   * this is the id of the artist, playlist, album, track, etc. on each DSP
   * it can be retrieved by resolving the target URL via the heartbeat resolveUrl endpoint
   * and will be stored in the BaclinkSettings or sent along with the DSPAction when saving it
   */
  targetId: DSPConfig['targetId'];
  /**
   * the target Image URL of the DSPAction
   * this is the image of the artist, playlist, album, track, etc.
   */
  targetImageURL?: string;
  /**
   * the schedule type of the DSPAction
   */
  scheduleType: DSPAction['scheduleType'];
  /**
   * the schedule date of the DSPAction
   */
  scheduleDate?: DSPAction['schedulableOn'];
  /**
   * the timezone for the DSPAction
   */
  selectedTimezone?: DSPAction['config']['selectedTimezone'];
  /**
   * the status of the DSPAction
   */
  status: FrontendDSPActionStatus;
};

export const isFrontendDSPActionType = (
  type: string,
): type is FrontendDSPActionType => {
  return Object.values(FrontendDSPActionType).includes(
    type as FrontendDSPActionType,
  );
};
