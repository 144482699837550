import React from 'react';

import { useTranslation } from 'react-i18next';

import { LocaleIcon } from 'shared/components/Icons';
import {
  AllowedLocale,
  LocaleInfos,
} from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';

import { StyledLocaleSwitcher } from './styled';

type Props = {
  localesInfos: LocaleInfos[];
  currentLocale: string;
  onChange: (locale: AllowedLocale) => void;
  className?: string;
  children?: React.ReactNode;
};
export const EditorLocaleSwitcher = ({
  localesInfos,
  currentLocale,
  onChange,
  className,
  children,
}: Props) => {
  const { t } = useTranslation();

  const localesCodes = localesInfos.map((localeInfo) => localeInfo.code);
  const disabled = localesInfos.length <= 1;

  const currentLocaleDisplayName = t(
    `misc.supportedLocale.${currentLocale}.languageName`,
    localesInfos.find((info) => info.code === currentLocale)?.displayName || '',
  );

  const handleNextLocaleButtonClick = () => {
    const currentLocaleIndex = localesCodes.findIndex(
      (code) => code === currentLocale,
    );
    const nextLocaleIndex =
      currentLocaleIndex < localesInfos.length - 1 ? currentLocaleIndex + 1 : 0;
    onChange(localesCodes[nextLocaleIndex]);
  };
  const handlePrevLocaleButtonClick = () => {
    const currentLocaleIndex = localesCodes.findIndex(
      (code) => code === currentLocale,
    );
    const prevLocaleIndex =
      currentLocaleIndex > 0 ? currentLocaleIndex - 1 : localesInfos.length - 1;
    onChange(localesCodes[prevLocaleIndex]);
  };
  return (
    <StyledLocaleSwitcher
      className={className}
      onPrevButtonClick={handlePrevLocaleButtonClick}
      onNextButtonClick={handleNextLocaleButtonClick}
      disabled={disabled}
      label={
        <>
          <LocaleIcon className="icon" />
          {currentLocaleDisplayName}
          {children}
        </>
      }
      aria-label={t(
        'ui.component.backlink_settings_data_editor.loale_switcher.label',
        'Locale Switcher',
      )}
    />
  );
};

export default EditorLocaleSwitcher;
