import React, { useState } from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import ClipboardUrlTextfield from 'shared/components/ClipboardUrlTextfield';
import ContentDialog from 'shared/components/ContentDialog';
import * as StyledTabs from 'shared/components/Tabs/styled';
import { getBacklinkUrl } from 'shared/entities/backlink/backlink.utils';
import { useClipboard } from 'shared/hooks/useClipboard';
import { useAppWideData } from 'shared/state';

import ChannelSelector from './ChannelSelector';
import DirectLinkSelector from './DirectLinkSelector';

import * as Styled from './styled';

const itemsSelectorsStyles = {
  tableContainer: css`
    max-height: 300px;

    // a border and a border raidus are added to the table container by default in the design-system theme
    // we don't want them here (tp athc the legacy design for now)
    border: none;
    border-radius: 0;
  `,
};

enum TabIds {
  CHANNEL = 'channel',
  DIRECT_LINK = 'directLink',
}

type Props = {
  open: boolean;
  slug: string;
  subDomain: string;
  onClose: () => void;
};

const ShareBacklinkDialog = ({ open, slug, subDomain, onClose }: Props) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(TabIds.CHANNEL);

  const { storesConfigs, shareExtensions } = useAppWideData();

  const { copyToClipboard } = useClipboard();

  const backlinkUrl = getBacklinkUrl(subDomain, slug);

  return (
    <ContentDialog
      title={t('section.bfan_links.dialog.share.title', 'Get link')}
      onClose={onClose}
      open={open}
      fullWidth
    >
      <ClipboardUrlTextfield url={backlinkUrl} copyOnMount />
      <Styled.Container>
        <StyledTabs.Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          <StyledTabs.Tab
            value={TabIds.CHANNEL}
            label={t(
              'section.bfan_links.dialog.share.tab.channel.title',
              'CHANNEL',
            )}
          />
          <StyledTabs.Tab
            value={TabIds.DIRECT_LINK}
            label={t(
              'section.bfan_links.dialog.share.tab.direct_link.title',
              'DIRECT LINK',
            )}
          />
        </StyledTabs.Tabs>
      </Styled.Container>
      {selectedTab === TabIds.CHANNEL && (
        <ChannelSelector
          handleButtonClick={(additionalPath) =>
            copyToClipboard(`${backlinkUrl}${additionalPath ?? ''}`)
          }
          shareExtensions={shareExtensions}
          styles={itemsSelectorsStyles}
        />
      )}
      {selectedTab === TabIds.DIRECT_LINK && (
        <DirectLinkSelector
          handleButtonClick={(additionalPath) =>
            copyToClipboard(`${backlinkUrl}${additionalPath ?? ''}`)
          }
          storesConfigs={storesConfigs ? Object.values(storesConfigs) : []}
          styles={itemsSelectorsStyles}
        />
      )}
    </ContentDialog>
  );
};

export default ShareBacklinkDialog;
