import { GA4SubProducts } from 'shared/analytics/analytics.types';
import {
  authUserIsCustomer,
  authUserIsEmployee,
} from 'shared/entities/auth/auth.types';
import {
  GA4BacklinkSettingsEventNames,
  GA4UpdateBacklinkSettingsTemplateSettingsTrackingEventConfig,
} from 'shared/entities/backlinkSettings/backlinkSettings.analytics.types';
import { useAppState } from 'shared/hooks';
import { useGATracking } from 'shared/hooks/useGATracking';
import { AppName } from 'shared/types';

export const useParentEntityBacklinkSettingsAnalytics = () => {
  const { sendGA4TrackingEvent } = useGATracking();
  const { user } = useAppState();

  let workspaceId;
  if (authUserIsCustomer(user)) {
    workspaceId = `Workspace-of-${user.externalId}`;
  }
  if (authUserIsEmployee(user)) {
    workspaceId = user.workspaceId;
  }

  const sendUpdateBacklinkSettingsGA4TrackingEvent = ({
    artistId,
  }: {
    artistId?: string;
  }) => {
    const updateBacklinkSettingsGAEvent: GA4UpdateBacklinkSettingsTemplateSettingsTrackingEventConfig =
      {
        eventName: GA4BacklinkSettingsEventNames.updateLinksTemplate,
        eventParams: {
          product: AppName.backstageMarketing,
          subProduct: GA4SubProducts.links,
          workspaceId,
          ...(artistId ? { artistId } : {}),
        },
      };

    sendGA4TrackingEvent(updateBacklinkSettingsGAEvent);
  };

  return { sendUpdateBacklinkSettingsGA4TrackingEvent };
};
