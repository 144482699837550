import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Collapse, TextField, Typography } from '@mui/material';

import {
  CTAOptions,
  PrereleaseCTAMode,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { buildStoreLogoLabelUri } from 'shared/services/assets.service';

import { StepKey } from '../../../../state';
import CTAModeSwitch from './CTAModeSwitch';

import { FieldRow } from '../../../fields/styled';
import { Card, CardHeader } from '../../../styled';
import { CTAEditorContent } from './styled';

export type CTAEditorProps = {
  className?: string;
  storeName: string;
  ctaOptions: CTAOptions;
  changeHandler: (storeName: string, ctaOptions: CTAOptions) => void;
  isExpanded: boolean;
  expandHandler: (editorName: string) => void;
  errors: Record<string, string | boolean>;
};
const CTAEditor = ({
  className,
  storeName,
  ctaOptions,
  changeHandler,
  isExpanded,
  expandHandler,
  errors,
}: CTAEditorProps) => {
  const { t } = useTranslation();
  const { step: stepKeyParam }: { step?: string } = useParams();
  const isPrerelease = stepKeyParam === StepKey.PrereleaseLanding;
  const { label, text, isDisplayed, mode } = ctaOptions;
  const labelImageUri = buildStoreLogoLabelUri(storeName);
  const hasErrors = Object.keys(errors || {}).reduce(
    (hasErrors, key) => hasErrors || !!errors[key],
    false,
  );

  return (
    <Card
      isExpanded={isExpanded}
      hasErrors={hasErrors}
      className={className}
      aria-expanded={isExpanded}
      aria-label={`Call to action card - ${storeName}`}
      aria-invalid={hasErrors}
    >
      <CardHeader
        disableTypography
        sx={{
          '& .MuiCardHeader-action': { maxWidth: { sm: 300, md: 300 } },
        }}
        title={<img src={labelImageUri} alt={storeName} />}
        action={
          <>
            <Typography
              variant="body1"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {isPrerelease
                ? mode === PrereleaseCTAMode.DIRECT_LINK
                  ? t(
                      'ui.component.backlink_settings_data_editor.cta_editor.header.direct_link',
                      'Direct Link',
                    )
                  : t(
                      'ui.component.backlink_settings_data_editor.cta_editor.header.pre-save',
                      'Pre-save',
                    )
                : label}
            </Typography>
            <IconButton
              role="switch"
              aria-label={t(
                'ui.component.backlink_settings_data_editor.cta_editor.toggle_display',
                'Toggle display',
              )}
              aria-checked={isDisplayed}
              onClick={() => {
                changeHandler(storeName, {
                  ...ctaOptions,
                  isDisplayed: !isDisplayed,
                });
              }}
              size="large"
            >
              {isDisplayed ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              aria-label={t(
                'ui.component.backlink_settings_data_editor.cta_editor.expand_card',
                'Expand card',
              )}
              aria-expanded={isExpanded}
              onClick={() => {
                expandHandler(storeName);
              }}
              size="large"
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </>
        }
      />
      <Collapse in={isExpanded} timeout={100} unmountOnExit>
        <CTAEditorContent>
          <FieldRow>
            <TextField
              fullWidth
              id={`${storeName}-label`}
              name={`${storeName}.label`}
              label={t(
                'ui.component.backlink_settings_data_editor.cta_editor.button_label',
                'Button label',
              )}
              variant="outlined"
              onChange={(event) =>
                changeHandler(storeName, {
                  ...ctaOptions,
                  label: event.target.value,
                })
              }
              value={label || ''}
              error={hasErrors && !!errors.label}
            />
          </FieldRow>
          <FieldRow>
            <TextField
              fullWidth
              id={`${storeName}-text`}
              name={`${storeName}.text`}
              label={t(
                'ui.component.backlink_settings_data_editor.cta_editor.additional_text',
                'Additional text',
              )}
              variant="outlined"
              onChange={(event) =>
                changeHandler(storeName, {
                  ...ctaOptions,
                  text: event.target.value,
                })
              }
              value={text || ''}
            />
          </FieldRow>
          {isPrerelease && (
            <CTAModeSwitch
              storeName={storeName}
              CTAOptions={ctaOptions}
              changeHandler={changeHandler}
            />
          )}
        </CTAEditorContent>
      </Collapse>
    </Card>
  );
};

export default CTAEditor;
