import { useState, useRef } from 'react';

import { useApi } from 'shared/hooks/useApi';

export enum UploadStatus {
  Idle = 'idle',
  Uploading = 'uploading',
  Done = 'done',
}

export enum UploadErrorTypes {
  Error = 'ERROR',
  MaxSizeExceeded = 'MAX_SIZE_EXCEEDED',
  FormatNotAllowed = 'FORMAT_NOT_ALLOWED',
}

export type ImageUploadError = {
  type: UploadErrorTypes;
  message: string;
};

const DEFAULT_IMAGE_MAX_SIZE = 2048; // in KB
export const DEFAULT_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type UploadOptions = {
  maxSize?: number;
  imageFormats?: string[];
};

/**
 * Custom hook to manage image uploads
 *
 * Handle file validation and upload states
 * Forward image changes to the changeHandler
 */
export function useImageUpload(
  onChange: (id: string) => void,
  {
    maxSize = DEFAULT_IMAGE_MAX_SIZE,
    imageFormats = DEFAULT_IMAGE_FORMATS,
  }: UploadOptions = {},
) {
  const { uploadImage } = useApi();

  /**
   * Upload status
   * note: we need State and a Ref
   * ref has to be used inside the upload handler to get an accurate report on upload status
   * as the handler, once triggered, will always have the uploadStatus that was set when the handler was created
   */
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.Idle);
  const uploadStatusRef = useRef(uploadStatus);
  uploadStatusRef.current = uploadStatus;

  const [error, setError] = useState<ImageUploadError | null>(null);

  /**
   * Validate the file by checking its size and format
   */
  function validateFile(file: File) {
    const fileSizeInKB = file.size / 1024;
    let error: any = null;

    if (fileSizeInKB > maxSize) {
      error = {
        type: UploadErrorTypes.MaxSizeExceeded,
        message: `The image you have chosen is too large, please choose an image of less than: ${maxSize} KB`,
      };
    }

    if (!imageFormats.includes(file.type)) {
      error = {
        type: UploadErrorTypes.FormatNotAllowed,
        message: `Wrong file format - The format of the image must be one in the following list: ${imageFormats.join(
          ', ',
        )}`,
      };
    }

    if (error) {
      setError(error);
      setUploadStatus(UploadStatus.Done);
      return false;
    }
    return true;
  }

  /**
   * Handling image upload
   */
  function upload(event: React.ChangeEvent<HTMLInputElement>) {
    setError(null);

    /** selected file */
    const files: FileList | null = event.target.files;
    if (!files || !files.length) {
      return;
    }

    setUploadStatus(UploadStatus.Uploading);
    uploadStatusRef.current = UploadStatus.Uploading;

    const file: File = files.item(0) as File;

    /** validation */
    if (!validateFile(file)) {
      setUploadStatus(UploadStatus.Idle);
      return;
    }

    /** read selected file */
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('error', async () => {
      setError({
        type: UploadErrorTypes.Error,
        message: `an error occurred reading file: ${file.name}`,
      });
      console.error(`an error occurred reading file: ${file.name}`);
      setUploadStatus(UploadStatus.Done);
    });

    // allow selecting same image twice - in case we have removed it by in the same editing pass
    // note: file input won't trigger a a change event if path to file is the same
    event.target.value = '';

    /** upload when file is successfully loaded in memory */
    reader.addEventListener('load', async () => {
      try {
        const imageData = await uploadImage(reader.result as string);
        // we might have cancelled the operation
        // the upload is still performed but we don't change the image
        if (!imageData || uploadStatusRef.current !== UploadStatus.Uploading) {
          return;
        }
        onChange(imageData.id);
        setUploadStatus(UploadStatus.Done);
      } catch (error: any) {
        console.error(`Error occurred uploading image: ${file.name}`);
      }
    });
  }

  function remove() {
    setUploadStatus(UploadStatus.Idle);
    onChange('');
  }

  return {
    isUploading: uploadStatus === UploadStatus.Uploading,
    error,
    remove,
    upload,
  };
}
