import { useContext } from 'react';

import { AuthUserEmployee } from 'shared/entities/auth/auth.types';
import { AppContext, AppContextType } from 'shared/state';

import { Actions } from './actions';

/** all we do there is allowing to use a correctly typed AppContext for the MarketingSuite */
export function useMarketingSuiteAppContext() {
  const context = useContext<AppContextType<AuthUserEmployee, Actions>>(
    AppContext,
  );

  return context;
}
