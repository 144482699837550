import { registerLocale as registerI18nIsoCountriesLocale } from 'i18n-iso-countries';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

import { CONFIG_ENV } from 'shared/config';

import { i18nextConfig } from './config';
import { isoCountriesOverrides } from './isoCountriesOverrides';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled:
        CONFIG_ENV !== 'production' &&
        localStorage.getItem('phraseEnabled') === 'true',
      projectId: '7c2e26e750d2f01839615e4dd90d36e9',
      accountId: 'eb24dd1db511bcdab90b6758a027f51f',
      autoLowercase: false, // we have keys with mixed case
    }),
  )
  .init(i18nextConfig);

/**
 * i18n for yup default validation messages
 * see https://github.com/jquense/yup/blob/master/src/locale.ts
 * for a complete list
 */
function yupSetLocale() {
  setLocale({
    mixed: {
      required: i18next.t(
        'ui.form.validation.required',
        'This field is required.',
      ),
    },
  });
}

/**
 * loading supported locales Moment's locale files
 *
 * we want to load all the required  moment's locales at once on init
 * because trying to load each file with import(`moment/locale/${locale}.js`);
 * in the i18next 'languageChanged' event callback
 * will not trigger the necessary re-render after the moment locale file has been loaded
 * thus failing to update the ui in the case of a locale change
 */
async function momentLoadLocales(locales: readonly string[]) {
  return await Promise.all(
    locales
      ? locales.map(async (locale) => {
          try {
            await import(`moment/locale/${locale}.js`);
            await import(`moment/locale/${locale}.js`);
          } catch (error: any) {}
        })
      : [],
  );
}

function momentSetLocale(locale: string) {
  moment.locale(locale);
}

/**
 * countries names
 *
 * loading all translations at once
 * since it seems difficult to re-render the app after each file would have been loaded via a dynamic import
 * inside a 'languageChanged' i18next event callback
 */
async function isoCountriesLoadAndRegisterLocales(
  locales: readonly string[] = [],
) {
  return await Promise.all(
    locales.map(async (locale) => {
      try {
        const countriesForLocale = await import(
          `i18n-iso-countries/langs/${locale}.json`
        );
        // we want to be able to override the countries names
        if (locale in isoCountriesOverrides) {
          countriesForLocale.default.countries = {
            ...countriesForLocale.default.countries,
            ...isoCountriesOverrides[locale],
          };
        }
        registerI18nIsoCountriesLocale(countriesForLocale);
      } catch (error: any) {}
    }),
  );
}

/**
 * i18next events
 */
i18next.on('initialized', async (options) => {
  yupSetLocale();

  const supportedLngs = options.supportedLngs || [];
  await momentLoadLocales(supportedLngs);
  await isoCountriesLoadAndRegisterLocales(supportedLngs);

  momentSetLocale(i18next.language);

  i18next.on('languageChanged', (locale) => {
    yupSetLocale();
    momentSetLocale(locale);
  });
});

export default i18next;
