import { useState } from 'react';

import { Grid, Release, Typography } from '@believe-front/react-components';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { InfiniteData, useInfiniteQuery } from 'react-query';

import { ButtonBase, useTheme } from '@mui/material';

import {
  fetchCAPIProducts,
  fetchCAPIProductsByAlbumId,
} from 'shared/entities/product/product.api';
import {
  CapiProductsResponse,
  RawCapiProduct,
} from 'shared/entities/product/product.api.types';
import { Product } from 'shared/entities/product/product.types';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';
import { getLocalizedFormattedDate } from 'shared/utils/date';

import { ErrorScreen } from './ErrorScreen';
import { Loader } from './Loader';
import { ActionType } from './context/actions';
import { useReleaseEditorContext } from './context/context';
import { ReleaseEditorStep } from './types';

import { ReleaseCardContainer } from './styled';

const InitialScreen = ({
  searchHint,
  canGoToArtistStep,
  goToArtistStep,
}: {
  searchHint: string;
  canGoToArtistStep?: boolean;
  goToArtistStep?: () => void;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.release',
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography
          justifyContent="center"
          textAlign="center"
          color="secondary"
        >
          {searchHint}
        </Typography>
      </Grid>
      <Grid item>
        {canGoToArtistStep && goToArtistStep && (
          <ButtonBase
            onClick={(e) => {
              e.preventDefault();
              goToArtistStep();
            }}
          >
            <Typography
              justifyContent="center"
              textAlign="center"
              color="secondary"
            >
              {t('hint.or', 'or')}&nbsp;
            </Typography>
            <Typography variant="body1" color="primary">
              {t('hint.create_link', 'create a link from scratch')}
            </Typography>
          </ButtonBase>
        )}
      </Grid>
    </Grid>
  );
};
const ProductSearchResults = ({
  products,
  onProductSelected,
  productSelected,
  loadMore,
  hasMore,
  isFetching,
}: {
  products: Product[];
  onProductSelected: (product: Product) => void;
  productSelected: Product | null;
  loadMore: () => void;
  hasMore?: boolean;
  isFetching?: boolean;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.release',
  });
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{ padding: '16px' }}
      direction="column"
      alignItems="stretch"
      aria-label={t('search_results', 'search results')}
    >
      {products.map((product) => (
        <ReleaseCardContainer
          aria-label={`${t('select_release', 'Select release')} “${
            product.title
          } (${product.productVersion}, ${product.artistName})”`}
          key={product.internalProductId}
          sx={{
            backgroundColor:
              product === productSelected
                ? theme.palette.primary.light
                : theme.palette.primary.contrastText,
          }}
          onClick={() => onProductSelected(product)}
        >
          <Release
            shouldWrapTitle
            shouldWrapExtraInfo
            coverSrc={product.thumbArtworkUrl}
            artist={product.artistName}
            title={product.title}
            version={product.productVersion || ''}
            extraInfos={[
              product.productType,
              getLocalizedFormattedDate({
                date: product.digitalReleaseDate,
                locale: i18next.language,
              }),
            ]}
            size="medium"
          />
        </ReleaseCardContainer>
      ))}
      {!!hasMore && !isFetching && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <ButtonBase onClick={() => loadMore()}>
            <Typography color={theme.palette.secondary.dark}>
              {t('load_more', 'Load more')}
            </Typography>
          </ButtonBase>
        </Grid>
      )}
      {isFetching && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Loader />
        </Grid>
      )}
    </Grid>
  );
};

const NoResultsScreen = ({
  productQuery,
  canGoToArtistStep,
  goToArtistStep,
}: {
  productQuery: string;
  canGoToArtistStep?: boolean;
  goToArtistStep?: () => void;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.release',
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Trans
          i18nKey="ui.component.release_editor.step.release.no_results"
          values={{ searchValue: productQuery }}
        >
          <Typography
            justifyContent="center"
            textAlign="center"
            color="secondary"
          >
            No results for {productQuery} try another release
          </Typography>
        </Trans>
      </Grid>
      <Grid item>
        {canGoToArtistStep && goToArtistStep && (
          <ButtonBase
            onClick={(e) => {
              goToArtistStep();
            }}
          >
            <Typography
              justifyContent="center"
              textAlign="center"
              color="secondary"
            >
              {t('hint.or', 'or')}&nbsp;
            </Typography>
            <Typography variant="body1" color="primary">
              {t('hint.create_link', 'create a link from scratch')}
            </Typography>
          </ButtonBase>
        )}
      </Grid>
    </Grid>
  );
};

export const SelectProduct = () => {
  const {
    state: { releaseId, productQuery },
    dispatch,
  } = useReleaseEditorContext();
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.step.release',
  });
  const [availableOptions, setAvailableOptions] = useState<
    Array<RawCapiProduct>
  >([]);
  const appName = getAppName();

  const { isFetching, hasNextPage, isError, refetch, fetchNextPage, data } =
    useInfiniteQuery<CapiProductsResponse>(
      [
        'products',
        {
          productQuery,
        },
      ],
      ({ pageParam = 0 }) => {
        if (appName === AppName.backstageMarketing) {
          return fetchCAPIProducts({
            query: productQuery,
            page: pageParam,
            limit: 4,
          });
        } else {
          return fetchCAPIProductsByAlbumId({ albumId: productQuery });
        }
      },
      {
        enabled: !!productQuery,
        getNextPageParam: ({ pagination }: CapiProductsResponse) =>
          pagination.hasMore ? pagination.page + 1 : undefined,
        onSuccess: ({ pages }: InfiniteData<CapiProductsResponse>) => {
          const allResults = pages.reduce<Array<RawCapiProduct>>(
            (acc: Array<RawCapiProduct>, page: CapiProductsResponse) => [
              ...acc,
              ...page.capiProducts,
            ],
            [],
          );
          setAvailableOptions(allResults);
        },
      },
    );

  const [productSelected, setProductSelected] = useState<Product | null>(null);
  const isFirstPage = !data?.pages.length;
  const displayInitialScreen = !productQuery;
  const displayErrorScreen = !!productQuery && isError;
  const displayNoResultsScreen =
    !!productQuery && availableOptions.length === 0;
  const displaySearchResults = !!productQuery && availableOptions.length > 0;

  if (isFirstPage && isFetching) {
    return <Loader />;
  }

  if (displayErrorScreen) {
    return <ErrorScreen refetch={refetch} />;
  }

  if (displayInitialScreen && appName === AppName.marketingSuite) {
    return (
      <InitialScreen
        searchHint={t(
          'marketingSuite.search_hint',
          'Search a release by album ID',
        )}
        canGoToArtistStep={!releaseId}
        goToArtistStep={() => {
          dispatch({
            type: ActionType.CHANGE_STEP,
            payload: { currentStep: ReleaseEditorStep.ARTIST },
          });
        }}
      />
    );
  }
  if (displayInitialScreen && appName === AppName.backstageMarketing) {
    return (
      <InitialScreen
        searchHint={t(
          'backstageMarketing.search_hint',
          'Search a release by title or artist',
        )}
        canGoToArtistStep={!releaseId}
        goToArtistStep={() => {
          dispatch({
            type: ActionType.CHANGE_STEP,
            payload: { currentStep: ReleaseEditorStep.ARTIST },
          });
        }}
      />
    );
  }

  if (displayNoResultsScreen) {
    return (
      <NoResultsScreen
        productQuery={productQuery}
        canGoToArtistStep={!releaseId}
        goToArtistStep={() => {
          dispatch({
            type: ActionType.CHANGE_STEP,
            payload: { currentStep: ReleaseEditorStep.ARTIST },
          });
        }}
      />
    );
  }
  if (displaySearchResults) {
    return (
      <ProductSearchResults
        products={availableOptions}
        onProductSelected={(product: Product) => {
          setProductSelected(product);
          dispatch({
            type: ActionType.UPDATE_PRODUCT,
            payload: { product },
          });
        }}
        isFetching={isFetching}
        productSelected={productSelected}
        hasMore={hasNextPage}
        loadMore={fetchNextPage}
      />
    );
  }
  return null;
};
