import { rest } from 'msw';

const handlers = [
  /**
   * we need to mock the i18n translation file GET route used by the i18next backend strategy
   * because the i18next instance is sometimes imported into tests files
   * causing the i18next instance init function to run and
   * the i18next backend mechanism trying and get the translation files
   * resulting in test failing because MSW complains that this route is not mocked
   *
   * we also use this mechanism to get the translations in the e2e tests
   */
  rest.get(`/locales/:locale/:namespace`, async (req, res, ctx) => {
    const translations = await import(
      `../../../../../public/locales/${req.params.locale}/${req.params.namespace}`
    );
    return res(ctx.status(200), ctx.json(translations.default));
  }),
];

export default handlers;
