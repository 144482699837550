import React from 'react';

import { AxiosError } from 'axios';
import { MutationCache, QueryCache, QueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import { useNotification } from 'shared/hooks/useNotification';

/**
 * this is a custom meta object that we can pass to the useQuery or useMutation hook
 *
 * AFAIK there is no way to set the type of the meta object
 * so we will assert the type wherever we use it
 */
export type AppQueryAndMutationMeta = {
  /**
   * indicate that we want to skip the default onError callback
   */
  skipGlobalOnError?: boolean;
  /**
   * customize messages
   */
  messages?: {
    error?: string;
  };
};

/**
 * a hook to get a react-query QueryClient instance
 */
export const useAppQueryClient = () => {
  const RETRY_COUNT = 3;
  const { notify } = useNotification();

  const queryClientRef = React.useRef<QueryClient | null>(null);

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
            const axiosError = error as AxiosError;
            if (axiosError?.response?.status === 404) return false;
            else if (failureCount < RETRY_COUNT - 1) return true;
            else return false;
          },
          retryDelay: 1500,
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          const meta = query.meta
            ? (query.meta as AppQueryAndMutationMeta)
            : undefined;

          if (meta?.skipGlobalOnError) return;

          notify({
            type: AppNotificationTypes.Error,
            message: meta?.messages?.error || 'An error occurred!',
          });
        },
      }),
      /**
       * we want to customize the default behavior of the mutation
       *
       * note: we do not want a global onSuccess callback as it would be too intrusive
       * we ony want a global onError callback
       * so that we have feedback when a mutation fails
       * regardless of how the mutation has been configured
       */
      mutationCache: new MutationCache({
        onError: (error, variables, context, mutation) => {
          const meta = mutation.meta
            ? (mutation.meta as AppQueryAndMutationMeta)
            : undefined;

          if (meta?.skipGlobalOnError || mutation.options.onError) return;

          notify({
            type: AppNotificationTypes.Error,
            message: meta?.messages?.error || 'An error occurred!',
          });
        },
      }),
    });
  }

  return queryClientRef.current;
};
