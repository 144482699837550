import React from 'react';

import { Interpolation } from '@emotion/react';

import { Typography, Theme } from '@mui/material';

import {
  FieldSetVariant,
  StyledFieldSet,
  FieldSetHeader,
  FieldSetContent,
  FieldSetDescription,
  Legend,
} from './styled';

type Props = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  hidden?: boolean;
  variant?: FieldSetVariant;
  contentStyle?: Interpolation<Theme> | string;
  hideTooltip?: boolean;
};

const FieldSet = ({
  children,
  className,
  title,
  description,
  actions,
  hidden,
  variant,
  contentStyle,
  hideTooltip = false,
}: Props) => {
  return (
    <StyledFieldSet
      className={className}
      hidden={hidden}
      variant={variant}
      title={hideTooltip ? undefined : title}
      aria-label={title}
    >
      {title && (
        <FieldSetHeader>
          {title && <Legend variant={variant}>{title}</Legend>}
          {actions}
        </FieldSetHeader>
      )}
      {description && (
        <FieldSetDescription>
          <Typography>{description}</Typography>
        </FieldSetDescription>
      )}
      <FieldSetContent css={contentStyle} variant={variant}>
        {children}
      </FieldSetContent>
    </StyledFieldSet>
  );
};

export default FieldSet;
