import styled from '@emotion/styled';

import { Card, Typography } from '@mui/material';

export const Container = styled(Card)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
`;

export const Title = styled(Typography)`
  font-size: 1rem;
  line-height: 1.3;
`;
