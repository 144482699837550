import { api } from '../api';
import {
  FetchAutonotifyPayload,
  FetchAutonotifyResponse,
  FetchAutonotifyPreviewPayload,
  FetchAutonotifyPreviewResponse,
  SendTestEmailPayload,
} from './autonotify.api.types';

export const fetchAutonotify = async ({
  autonotifyId,
}: FetchAutonotifyPayload): Promise<FetchAutonotifyResponse> => {
  const response = await api.get(`/autonotify/${autonotifyId}`);
  return response.data;
};

export async function fetchAutonotifyPreview(
  payload: FetchAutonotifyPreviewPayload,
): Promise<FetchAutonotifyPreviewResponse> {
  const response = await api.post('/autonotify/previewEmail', payload);
  return response.data;
}

export async function sendTestEmail(payload: SendTestEmailPayload) {
  await api.post('/autonotify/sendTestEmail', payload);
}
