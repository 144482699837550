import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { ReactComponent as LogoExportFans } from 'shared/components/Logos/logo-export-fans.svg';
import { DATA_TEST_IDS } from 'shared/entities/autonotify/autonotify.constants';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { getReleaseMode } from '../../../state';
import { useBacklinkSettingsDataEditorContext } from '../../../state/context';
import AutonotifyForm from './components/AutonotifyForm';
import AutonotifyToggle from './components/AutonotifyToggle';

import * as Styled from '../styled';

/**
 * Pre-release Autonotify Step - Main component
 */
export const PrereleaseAutonotifyStep = () => {
  const { t } = useTranslation();

  const { stepKey, getFieldProps, editorState } =
    useBacklinkSettingsDataEditorContext();
  const isPreRelease = getReleaseMode(editorState) === 'pre-release';
  const isBackstage = getAppName() === AppName.backstageMarketing;

  const isActivePath = `${stepKey}.isActive`;
  const { value: isActive } = getFieldProps(isActivePath);

  return (
    <Styled.StepContent>
      <Styled.StepTitle>
        {t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.title',
          'Fan Notification',
        )}
      </Styled.StepTitle>
      <Styled.StepDescription sx={{ marginBottom: 1 }}>
        {t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.description',
          'Automatically send an email to your fans on release day to boost your streams and redirect them on the platform of their choice.',
        )}
      </Styled.StepDescription>
      {isActive ? (
        <AutonotifyForm />
      ) : (
        <>
          <AutonotifyToggle />
          {isBackstage ? (
            <div data-testid={DATA_TEST_IDS.DISABLED_MESSAGE}>
              {isPreRelease ? (
                <Styled.StepParagraph>
                  {t(
                    'ui.component.backlink_settings_data_editor.steps.autonotify.intros.backstage.prerelease',
                    'Activate this option to notify your fans automatically on release. It is the easiest and fastest way to inform your fans!',
                  )}
                </Styled.StepParagraph>
              ) : (
                <Styled.StepParagraph>
                  {t(
                    'ui.component.backlink_settings_data_editor.steps.autonotify.intros.backstage.postrelease',
                    `You didn't activate this option for this release and your fans didn't receive automatic Fan Notification on release day.`,
                  )}
                </Styled.StepParagraph>
              )}
              <Styled.StepParagraph>
                <Trans i18nKey="ui.component.backlink_settings_data_editor.steps.autonotify.intros.backstage.common1">
                  If you prefer to do it on your own, you can manually export
                  these fans whenever you want from{' '}
                  <Link to="/campaigns/links">My Links</Link> or from{' '}
                  <Link to="/audience">Audience</Link> and import them in the
                  emailing solution of your choice.
                </Trans>
              </Styled.StepParagraph>
              <Styled.StepParagraph>
                {t(
                  'ui.component.backlink_settings_data_editor.steps.autonotify.intros.backstage.common2',
                  `Once fans are notified, don’t forget to delete their information, unless they subscribed to the newsletter.`,
                )}
              </Styled.StepParagraph>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '60px',
                }}
              >
                <LogoExportFans />
              </Box>
            </div>
          ) : (
            <Styled.StepParagraph data-testid={DATA_TEST_IDS.DISABLED_MESSAGE}>
              <Trans i18nKey="ui.component.backlink_settings_data_editor.steps.autonotify.intros.mkt_suite">
                If you want to plan a custom email/sms communication for your
                artist, please contact{' '}
                <a href="mailto:cms@believe.com">cms@believe.com</a>
              </Trans>
            </Styled.StepParagraph>
          )}
        </>
      )}
    </Styled.StepContent>
  );
};
