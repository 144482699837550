import {
  AdCampaignPlatform,
  AdCampaignTargetingType,
  AdCampaignTargetingGender,
  AdCampaignPlacement,
  AdCampaignStatus,
  AvailablePlatforms,
  Currency,
  Objective,
  CampaignSetupMethod,
  CampaignStatus,
} from './ads.constants';
import type { Interest, Location, AdCampaignPeriod } from './ads.types';

/**
 * Because our backend is just behaving as a proxy for the external service API (from SKills),
 * some objects don't respect our naming conventions.
 *
 * Those DTOs are the types of the objects we get from their API,
 * which we will transform to suit our needs.
 */

export type AdCampaignDTO = {
  sub_campaign_key: string;
  sub_campaign_name: string;
  platform: AdCampaignPlatform;
  budget?: number;
  spend?: number;
  periode?: AdCampaignPeriod;
  placement?: AdCampaignPlacement[];
  targeting?: Array<{
    targeting_key: string;
    type: AdCampaignTargetingType;
    interests: Interest[];
    locations: Location[];
    gender: AdCampaignTargetingGender;
    age?: { min: string; max: string }; // Required for FB/IG/FB_MIX platform
    age_range?: string[]; // Required for YT platform
  }>;
  creatives?: Array<AdCampaignCreativeYTDTO | AdCampaignCreativeFBInstaDTO>;
  status?: AdCampaignStatus;
};

type AdCampaignCreativeYTDTO = {
  creative_key: string;
  youtube_url: string;
  final_url: string;
  display_url: string;
  call_to_action: string;
  head_line: string;
};
export function isCreativeForYoutubeDTO(
  dto: any,
): dto is AdCampaignCreativeYTDTO {
  return !!dto.youtube_url;
}

type AdCampaignCreativeFBInstaDTO = {
  creative_key: string;
  primary_text: string;
  link_to_promote: string;
  link_to_display: string;
  head_line: string;
  sub_title: string;
  call_to_action: string;
  video_url: {
    facebook: string;
    instagram: string;
  };
};
export function isCreativeForFacebookAndInstagramDTO(
  dto: any,
): dto is AdCampaignCreativeFBInstaDTO {
  return !!dto.video_url && !!dto.primary_text;
}

export type AdCampaignStatsDTO = {
  budget: number;
  opt: number;
  spend: number;
  day_stats: Array<{
    day: string; // dd/mm/yyyy => '23/08/2021'
    spend: number;
    impression: number;
    video_view: number;
    clicks: number;
    thruplay: number;
    cpc: number;
    ctr: number;
    cpv: number;
  }>;
  impression: number;
  video_view: number;
  clicks: number;
  thruplay: number;
  cpc: number;
  ctr: number;
  cpv: number;
};

export type AdsPlanCampaignDto = {
  id: number;
  name: string;
  platform: AvailablePlatforms;
  budget: number;
  spend?: number;
  currency: Currency;
  periods: AdsCampaignPeriodDto[];
  objective: Objective;
  metadata?: {
    [key: string]: string;
  };
  setupMethod?: CampaignSetupMethod;
  platformOptions?: {
    [key: string]: string;
  };
  status: CampaignStatus;
  pushedName?: string;
  timezone?: string;
};

export type AdsCampaignPeriodDto = {
  datetimeStart: Date;
  datetimeEnd: Date;
};
