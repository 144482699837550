import { Box } from '@believe-front/react-components';

import { BoxProps } from '@mui/material';

type Props = {
  ariaLabel?: string;
  disabled?: boolean;
  children: React.ReactNode;
} & BoxProps;

export const DisabledContainer = ({
  ariaLabel,
  disabled = false,
  children,
  sx = {},
  ...rest
}: Props) => {
  return (
    <Box
      aria-label={ariaLabel}
      sx={
        disabled
          ? {
              ...sx,
              position: 'relative',
              pointerEvents: 'none',
              '&:after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
              },
            }
          : sx
      }
      {...rest}
    >
      {children}
    </Box>
  );
};
