import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import * as artistApi from 'shared/entities/artist/artist.api';
import { Artist } from 'shared/entities/artist/artist.types';

export function useFetchArtist(
  artistId?: string,
  queryOptions?: Omit<
    UseQueryOptions<Artist, unknown, Artist, ['artist', string | undefined]>,
    'enabled'
  >,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    ['artist', artistId],
    () => artistApi.fetchArtist(artistId || ''),
    {
      enabled: !!artistId,
      staleTime: 1000 * 60 * 60,
      ...queryOptions,
    },
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries(['artist', artistId]);

  return { data, invalidateQuery, rest };
}
