import { api } from '../api';
import { HotReleaseModel } from './hotRelease.model';
import {
  CreateReleasePayload,
  HotReleasesFetchPayload,
  HotReleasesResponse,
  UpdateReleasePayload,
} from './release.api.types';
import { HOTRELEASES_DEFAULT_FETCH_MAX_ITEMS } from './release.constants';
import { ReleaseModel } from './release.model';
import { Release } from './release.types';

export const createRelease = async ({
  artistPayload,
  internalProductIds,
  workspaceId,
}: CreateReleasePayload) => {
  const results = await api.post<Promise<Release>>('/releases', {
    artist: artistPayload,
    internalProductIds,
    workspaceId,
  });
  return results.data;
};

export const fetchRelease = async (releaseId: string): Promise<Release> => {
  const result = await api.get(`/releases/${releaseId}`);
  return ReleaseModel.transform(result.data);
};

export const updateRelease = async (
  releaseId: string,
  { internalProductIds, workspaceId }: UpdateReleasePayload,
): Promise<Release> => {
  const results = await api.patch(`/releases/${releaseId}`, {
    internalProductIds,
    workspaceId,
  });
  return ReleaseModel.transform(results.data);
};

// This route will force the refresh of data associated to the release products (from external sources)
// And then return updated release
export const refreshRelease = async (releaseId: string): Promise<Release> => {
  const results = await api.post(`/releases/${releaseId}/refresh`);
  return ReleaseModel.transform(results.data);
};

export const fetchReleases = async ({
  query = '',
  artistId = '',
}): Promise<Release[]> => {
  const results = await api.get('/releases', {
    params: {
      query,
      artistId,
    },
  });
  return results.data;
};

export const fetchHotReleases = async (params: HotReleasesFetchPayload) => {
  const defaultParams = {
    maxItemsAfterDate: HOTRELEASES_DEFAULT_FETCH_MAX_ITEMS,
    maxItemsBeforeDate: HOTRELEASES_DEFAULT_FETCH_MAX_ITEMS,
    withCounts: true,
  };

  const results = await api.get<HotReleasesResponse>('/releases/hot', {
    params: { ...defaultParams, ...params },
  });

  return {
    ...results.data,
    hotReleases: HotReleaseModel.transformAll(results.data.hotReleases),
  };
};

export const fetchReleasesAdmin = async ({
  backlinkId,
  releaseId,
  page = 0,
  limit = 10,
}: {
  backlinkId?: string;
  releaseId?: string;
  page?: number;
  limit?: number;
}): Promise<{ results: Release[]; total: number }> => {
  const results = await api.get('/releases/admin', {
    params: {
      backlinkId,
      releaseId,
      page,
      limit,
    },
  });
  return results.data;
};

export const refreshReleaseAdmin = async ({
  releaseId,
  applyNewArtworkToBacklink,
}: {
  releaseId?: string;
  applyNewArtworkToBacklink?: boolean;
}): Promise<Release> => {
  const results = await api.post(`/releases/admin/${releaseId}/refresh`, {
    applyNewArtworkToBacklink,
  });
  return results.data;
};
