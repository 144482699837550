import React, { useState, useEffect, useMemo } from 'react';

import { css } from '@emotion/react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import produce from 'immer';
import debounce from 'lodash/debounce';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Divider, Typography } from '@mui/material';

import { Backlink } from 'shared/entities/backlink/backlink.types';
import { AllowedLocale } from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';
import {
  FormFieldConfig,
  FormFieldConfigRecord,
  FormFieldsConfigsByLocale,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import {
  addDependentFields,
  isDependentFromAFieldInFormConfig,
} from 'shared/entities/backlinkSettings/backlinkSettings.utils.formFields';

import { useValidationContext } from '../../../hooks/useValidation';
import { EditorFieldProps } from '../../types';
import { FormFieldEditor } from './FormFieldEditor';
import { FormFieldConfigSelector } from './FormFieldSelector';

import { Container, DragHandle, DraggableItem } from './styled';

export type { FormFieldConfig, FormFieldConfigRecord };

/**
 * disable all development only warnings for react-beautiful-dnd library
 * it would complain (as a warning only) that:
 *  Droppable: unsupported nested scroll container detected.
 *  A Droppable can only have one scroll parent (which can be itself) Nested scroll containers are currently not supported.
 *
 * this does not seem to be a problem here for us though
 * TODO: investigate (sometime ...)
 *
 */
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

export type EditorFormFieldsManagerProps = EditorFieldProps<
  Backlink['settingsData']['prereleaseFormStep']['locale'][string]['form']['fields']
> & {
  /**
   * the path to the form fields configs in the editor state
   */
  path: string;

  /**
   * an object used to extract Fields configs as a User adds new ones
   * we pass it as a prop to be able to configure the manager with different sets of fields configs
   */

  fieldsConfigsTemplatesByLocale: FormFieldsConfigsByLocale;

  /**
   * locale to select fields configs
   */
  locale: AllowedLocale;

  /**
   * generic options
   * some of which we'll be used for the fields and others for the field selector
   * TODO: group by function(?)
   */
  options?: {
    useRichTextEditor?: boolean;
    allowEdit?: boolean;
    selectorLabel?: string;
  };
};

/**
 * A component to configure a set of fields for the Form Step of the Backlink
 *
 * it is responsible for fields adding, deleting, editing and ordering
 * within its own state that can get synced with the outsde world onChange
 */
const EditorFormFieldsManager = ({
  path,
  value: initialFieldsConfig,
  changeHandler,
  fieldsConfigsTemplatesByLocale,
  locale,
  options,
}: EditorFormFieldsManagerProps) => {
  const { errors } = useValidationContext();
  const validationError = errors[path];

  const fieldsConfigsTemplates = fieldsConfigsTemplatesByLocale[locale];

  const { useRichTextEditor, allowEdit, selectorLabel } = {
    useRichTextEditor: options?.useRichTextEditor || false,

    // we want the option to be true it is undefined only (as it is the default behaviour)
    // but take its set boolean value if defined
    allowEdit:
      typeof options?.allowEdit === 'undefined' ? true : options?.allowEdit,

    selectorLabel: options?.selectorLabel,
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler],
  );

  /** manipulate the fields configs array locally */
  const [fieldsConfigs, setFieldsConfigs] = useState(initialFieldsConfig);

  function addFieldConfig(fieldConfig: FormFieldConfig) {
    // add the field config
    let nextFieldsConfigs = produce(fieldsConfigs, (draft) => {
      draft.push(fieldConfig);
    });

    // add the dependent fields
    nextFieldsConfigs = addDependentFields({
      fieldConfig,
      fieldsConfigs: nextFieldsConfigs,
      fieldsConfigsTemplates,
    });

    setFieldsConfigs(nextFieldsConfigs);
  }

  function updateFieldConfig(fieldConfig: FormFieldConfig, index: number) {
    setFieldsConfigs(
      produce(fieldsConfigs, (draft) => {
        draft[index] = fieldConfig;
      }),
    );
  }

  function reorderFieldsConfigs(startIndex: number, endIndex: number) {
    setFieldsConfigs(
      produce(fieldsConfigs, (draft) => {
        const [removed] = draft.splice(startIndex, 1);
        draft.splice(endIndex, 0, removed);
      }),
    );
  }

  function removeFieldConfig(index: number) {
    const fieldConfig = fieldsConfigs[index];

    if (
      isDependentFromAFieldInFormConfig({
        fieldsConfigs,
        fieldName: fieldConfig.name,
      })
    ) {
      return;
    }

    // remove the field config
    let nextFieldsConfigs = produce(fieldsConfigs, (draft) => {
      draft.splice(index, 1);
    });

    setFieldsConfigs(nextFieldsConfigs);
  }

  /**
   * This will update the whole fields configs array in the Editor State
   */
  useEffect(() => {
    debouncedChangeHandler(fieldsConfigs);
  }, [debouncedChangeHandler, fieldsConfigs]);

  /**
   * Fields Configurations list
   * we only make available the fields configs that have not been added before
   */
  const [availableFieldsConfigs, setAvailableFieldsConfigs] = useState(
    fieldsConfigsTemplates,
  );

  useEffect(() => {
    setAvailableFieldsConfigs(() => {
      let nextAvailableConfigs = {};
      Object.keys(fieldsConfigsTemplates).forEach((fieldName) => {
        // only add config if not already here
        if (!fieldsConfigs.find((config) => config.name === fieldName)) {
          nextAvailableConfigs[fieldName] = fieldsConfigsTemplates[fieldName];
        }
      });
      return nextAvailableConfigs;
    });
  }, [fieldsConfigs, fieldsConfigsTemplates]);

  /**
   * handle the list once we are finished with the DnD
   */
  const handleDragEnd = (result) => {
    if (result.source && result.destination) {
      const startIndex = result.source.index;
      const endIndex = result.destination.index;
      reorderFieldsConfigs(startIndex, endIndex);
    }
  };

  /** FormFieldEditor panel state */
  const [expandedFormFieldEditorName, setExpandedFormFieldEditorName] =
    useState<string | boolean>(false);
  const handleFormFieldEditorExpansion = (editorName: string) => {
    if (expandedFormFieldEditorName === editorName) {
      setExpandedFormFieldEditorName(false);
    } else {
      setExpandedFormFieldEditorName(editorName);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={path}>
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {validationError && (
              <Typography color="error" variant="body1">
                {validationError}
              </Typography>
            )}

            {fieldsConfigs.map((fieldConfig, index) => {
              const allowRemove =
                allowEdit &&
                !isDependentFromAFieldInFormConfig({
                  fieldsConfigs,
                  fieldName: fieldConfig.name,
                });

              return (
                <Draggable
                  draggableId={fieldConfig.name}
                  index={index}
                  key={fieldConfig.name}
                >
                  {(provided) => (
                    <DraggableItem
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {/* note:
                        - the drag handle is located outside of the FormFieldEditor to avoid having to pollute it with beautiful-dnd props (dragHandleProps)
                        - the inline styles are backlinked to the fact that the drag handler is absolutely positioned above the FormFieldEditor
                          and should override the defauts set in the styled component (thus the use of the style prop)
                          reasoning: it felt like they would be better suited here where the actual dragged item is located */}
                      <DragHandle
                        {...provided.dragHandleProps}
                        style={{
                          top: '1rem',
                          left: '1rem',
                        }}
                      >
                        <DragHandleIcon />
                      </DragHandle>
                      <FormFieldEditor
                        css={css`
                          padding-left: 2.125rem; /* this style is inlined because it is here only to make room for the drag handle */
                        `}
                        fieldConfig={fieldConfig}
                        onChange={(fieldConfig: FormFieldConfig) =>
                          updateFieldConfig(fieldConfig, index)
                        }
                        onRemove={
                          allowRemove
                            ? () => removeFieldConfig(index)
                            : undefined
                        }
                        isExpanded={
                          fieldConfig.name === expandedFormFieldEditorName
                        }
                        onToggle={
                          allowEdit ? handleFormFieldEditorExpansion : undefined
                        }
                        path={`${path}.${index}`}
                        errors={errors}
                        useRichTextEditor={useRichTextEditor}
                      />
                    </DraggableItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
      {allowEdit && !!Object.keys(availableFieldsConfigs).length && (
        <>
          <Divider />
          <FormFieldConfigSelector
            fieldsConfigs={availableFieldsConfigs}
            onSelect={addFieldConfig}
            label={selectorLabel}
          />
        </>
      )}
    </DragDropContext>
  );
};

export default EditorFormFieldsManager;
