import {
  createContext,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import { ValidationError } from 'yup';

import {
  BacklinkSettingsData,
  BacklinkSettingsParentEntityType,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { makeValidationSchema } from 'shared/entities/backlinkSettings/backlinkSettings.validation';

import { getCustomValidationErrors } from '../validation';

/**
 * some keys have specific validation (async for instance - namely: slug availability)
 * and we set the error a different way than going through the validationSchema validate method (direcly in the component)
 * & as the errors are cleared, then re-set on each editor state update
 * the special error is cleared but not re-set by the regular validation
 * so we exclude the special cases from being cleared
 */
const excludeFromBeingCleared = ['sharingStep.url.slug'];

export type ValidationErrors = Record<string, string>;
export type SetErrorsType = Dispatch<SetStateAction<ValidationErrors>>;

export const Context = createContext<{
  errors: ValidationErrors;
  setErrors: SetErrorsType;
}>({ errors: {}, setErrors: () => {} });
Context.displayName = 'ValidationContext';

export { Context as ValidationContext };

type Params = {
  parentEntityType?: BacklinkSettingsParentEntityType;
};

function useValidation({ parentEntityType }: Params) {
  const validationSchema = useMemo(
    () => makeValidationSchema({ parentEntityType }),
    [parentEntityType],
  );

  const [errors, setErrors] = useState<ValidationErrors>({});

  // has errors if empty
  // note: we remove the key/value errors pair if they are set to false
  // because it might happen that we have remaining properties in the errors object
  // i.e not cleared by the validate mechanism - @see excludeFromBeingCleared above
  const hasErrors = !isEmpty(omitBy(errors, (error) => !error));

  const clearErrors = useCallback(() => {
    setErrors((errors) => {
      // keep special errors
      let clearedErrors = pick(errors, excludeFromBeingCleared);
      return clearedErrors;
    });
  }, []);

  const validate = useCallback(
    async (state: BacklinkSettingsData) => {
      try {
        await validationSchema.validate(state, {
          abortEarly: false,
        });
      } catch (error: any) {
        setErrors((errors) => {
          const newErrors = getCustomValidationErrors(error as ValidationError);
          return {
            ...errors,
            ...newErrors,
          };
        });
      }
    },
    [validationSchema],
  );
  return {
    Context,
    errors,
    setErrors,
    hasErrors,
    validate,
    clearErrors,
  };
}

export const useValidationContext = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error(
      `useValidationContext must be used inside ValidationContext Provider`,
    );
  return context;
};

export { useValidation };
