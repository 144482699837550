import { useLocation, useNavigate } from 'react-router-dom';

import { StepKey, BacklinkSettingsEditorStepConfig } from '../../../state';

export function useActiveStep(steps: BacklinkSettingsEditorStepConfig[]) {
  const location = useLocation();
  const historyPush = useNavigate();

  const firstStepKey = StepKey.Release;

  const matcher = new RegExp(`(${Object.values(StepKey).join('|')})$`);
  const activeStepKeyMatch = location.pathname.match(matcher);
  const activeStepKey =
    (activeStepKeyMatch && activeStepKeyMatch[0]) || firstStepKey;
  const activeStep = steps.find((step) => {
    return step.key === activeStepKey;
  });
  const activeStepIndex = steps.findIndex((step) => {
    return step.key === activeStepKey;
  });

  const navigate = (direction: 'prev' | 'next') => {
    // prettier-ignore
    const stepIndex =
      direction === 'prev'
        ? activeStepIndex > 0
          ? activeStepIndex - 1
          : steps.length - 1
        : activeStepIndex < steps.length - 1
          ? activeStepIndex + 1
          : 0;
    const step = steps[stepIndex];
    // we need to take the bare /edit pathname into account & add the stepKey rather than replacing it
    const stepPath = activeStepKeyMatch
      ? location.pathname.replace(activeStepKey, step.key)
      : `${location.pathname}/${step.key}`;
    historyPush(stepPath);
  };

  return {
    activeStepKey,
    activeStepIndex,
    activeStep,
    navigate,
  };
}
