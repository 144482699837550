import styled from '@emotion/styled';

export const StyledSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey[700]};
  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    > * {
      margin: 0 ${({ theme }) => theme.spacing(1)};
    }
  }
`;
