import React, { useEffect, useReducer } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import DatePickerWithTimezone from 'shared/components/DatePickerWithTimezone';
import {
  DSPActionType,
  DSPActionScheduleType,
} from 'shared/entities/dspAction/dspAction.constants';
import { DSPAction } from 'shared/entities/dspAction/dspAction.types';
import { DSP } from 'shared/entities/storeConfig/storeConfig.types';

import {
  TYPE_OPTIONS,
  SCHEDULE_TYPE_OPTIONS,
  PLATFORM_TYPE_OPTIONS,
} from '../constants';
import UrlField from './components/UrlField';
import { ActionsTypes, reducer } from './state';

import * as Styled from './styled';

const actionValidationSchema = yup.object({
  type: yup.string().oneOf(
    Object.values(TYPE_OPTIONS).map((opt) => opt.value),
    'Incorrect action type',
  ),
  targetName: yup.string().required('This field is required'),
  scheduleType: yup
    .string()
    .oneOf(Object.values(DSPActionScheduleType), 'Incorrect schedule type'),
  hasAtLeastOneUrl: yup.bool().oneOf([true], 'At least one URL is required'),
  hasScheduledDateIfSchedulable: yup
    .bool()
    .oneOf([true], 'A schedule date is required for a scheduled action'),
});

const validateAction = async (
  actionFormData: DSPAction & {
    hasAtLeastOneUrl: boolean;
    hasScheduledDateIfSchedulable: boolean;
  },
) => {
  await actionValidationSchema.validate(actionFormData, {
    abortEarly: false,
  });
};

const emptyAction = {
  type: '',
  targetName: '',
  scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
  schedulableOn: `${moment().add(1, 'days').format('YYYY-MM-DD')}T00:00:00`,
  config: {
    selectedTimezone: undefined,
    platforms: {
      spotify: undefined,
      appleMusic: undefined,
      deezer: undefined,
    },
  },
};

export type EditionDialogProps = {
  action?: DSPAction;
  isOpen: boolean;
  defaultTimezone: string;
  onEditionCanceled: () => void;
  onCreateAction: (createdAction: DSPAction) => void;
  onUpdateAction: (updatedAction: DSPAction) => void;
};

const EditionDialog = ({
  action,
  isOpen,
  onEditionCanceled,
  onCreateAction,
  onUpdateAction,
  defaultTimezone,
}: EditionDialogProps) => {
  const { t } = useTranslation();

  const [{ editedAction, errors, platformUrlIsBeingChecked }, dispatch] =
    useReducer(reducer, {
      editedAction: action,
      errors: [],
      platformUrlIsBeingChecked: {
        spotify: false,
        deezer: false,
        appleMusic: false,
      },
    });

  // Derived state
  const hasError = !!errors.length;
  const allowSaving =
    !hasError &&
    !platformUrlIsBeingChecked.appleMusic &&
    !platformUrlIsBeingChecked.deezer &&
    !platformUrlIsBeingChecked.spotify;

  const {
    type,
    targetName,
    scheduleType,
    schedulableOn,
    config: {
      selectedTimezone,
      platforms: { spotify, appleMusic, deezer },
    },
  } = {
    ...emptyAction,
    ...editedAction,
  };

  const handleCreateAction = () => {
    if (!editedAction) return;
    onCreateAction(editedAction);
  };

  const handleUpdateAction = async () => {
    if (!editedAction) return;
    action && onUpdateAction(editedAction);
  };

  const handleCancel = () => {
    onEditionCanceled();
  };

  useEffect(() => {
    const doValidateAction = async () => {
      if (editedAction) {
        // reset errors
        dispatch({ type: ActionsTypes.SetErrors, payload: [] });
        try {
          const {
            scheduleType,
            schedulableOn,
            config: {
              platforms: { spotify, deezer, appleMusic },
            },
          } = editedAction;

          const hasAtLeastOneUrl = !!(
            spotify?.targetId ||
            deezer?.targetId ||
            appleMusic?.targetId
          );

          const hasScheduledDateIfSchedulable =
            (scheduleType === DSPActionScheduleType.SCHEDULED_WITH_DATE &&
              !!schedulableOn) ||
            scheduleType !== DSPActionScheduleType.SCHEDULED_WITH_DATE;

          await validateAction({
            ...editedAction,
            hasAtLeastOneUrl,
            hasScheduledDateIfSchedulable,
          });
        } catch (error: any) {
          dispatch({
            type: ActionsTypes.SetErrors,
            payload: error.inner.map((error) => error.path),
          });
        }
      }
    };

    doValidateAction();
  }, [editedAction]);

  return (
    <Dialog
      fullWidth
      data-testid="temp-dialog"
      maxWidth="xs"
      open={isOpen}
      onClose={() => {
        handleCancel();
      }}
      onBackdropClick={() => {
        handleCancel();
      }}
    >
      <DialogTitle>
        {t('ui.component.dsp_action_editor.edit_action', 'Edit action')}
      </DialogTitle>
      <DialogContent>
        <Styled.FormControl>
          <InputLabel id="select-action-type">
            {t('ui.component.dsp_action_editor.select_action', 'Select action')}
          </InputLabel>
          <Select
            label={t(
              'ui.component.dsp_action_editor.select_action',
              'Select action',
            )}
            value={type}
            labelId="select-action-type"
            onChange={(event: any) => {
              const value: DSPActionType = event.target.value;
              dispatch({ type: ActionsTypes.SetType, payload: value });
            }}
          >
            {Object.values(TYPE_OPTIONS).map(({ value, displayName }) => (
              <MenuItem key={value} value={value}>
                {t(
                  `ui.component.dsp_action_editor.action_type.${value}.displayName`,
                  displayName,
                )}
              </MenuItem>
            ))}
          </Select>
        </Styled.FormControl>
        {type && (
          <>
            <Styled.Divider />
            <TextField
              fullWidth
              id="targetName"
              label={t(
                `ui.component.dsp_action_editor.action_type.${type}.form_field_label`,
                TYPE_OPTIONS[type].targetLabel as string,
              )}
              variant="outlined"
              value={targetName}
              onChange={(event: any) => {
                dispatch({
                  type: ActionsTypes.SetTargetName,
                  payload: event.target.value,
                });
              }}
              error={!!errors.includes('targetName')}
            />
            <Styled.FormControl>
              <InputLabel id="schedule-type">Launched</InputLabel>
              <Select
                label="launched"
                value={scheduleType}
                labelId="schedule-type"
                onChange={(event: any) => {
                  const value: DSPActionScheduleType = event.target.value;
                  dispatch({
                    type: ActionsTypes.SetScheduleType,
                    payload: value,
                  });
                }}
              >
                {Object.values(SCHEDULE_TYPE_OPTIONS).map(
                  ({ value, displayName }) => (
                    <MenuItem key={value} value={value}>
                      {t(
                        `ui.component.dsp_action_editor.schedule_type.${value}.displayName`,
                        displayName,
                      )}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Styled.FormControl>
            {scheduleType === DSPActionScheduleType.SCHEDULED_WITH_DATE &&
              schedulableOn && (
                <Styled.DatePickerContainer>
                  <DatePickerWithTimezone
                    dateLabel="Date"
                    dateValue={schedulableOn}
                    dateChangeHandler={(newValue) => {
                      dispatch({
                        type: ActionsTypes.SetSchedulableOn,
                        payload: newValue,
                      });
                    }}
                    timezoneValue={selectedTimezone || defaultTimezone}
                    timezoneChangeHandler={(newValue) => {
                      dispatch({
                        type: ActionsTypes.SetSelectedTimezone,
                        payload: newValue,
                      });
                    }}
                  />
                </Styled.DatePickerContainer>
              )}
            <Styled.Divider />
            {PLATFORM_TYPE_OPTIONS[DSP.SPOTIFY][type] && (
              <UrlField
                type={type}
                platformName={DSP.SPOTIFY}
                platformConfig={spotify}
                dispatch={dispatch}
              />
            )}
            {PLATFORM_TYPE_OPTIONS[DSP.APPLE_MUSIC][type] && (
              <UrlField
                type={type}
                platformName={DSP.APPLE_MUSIC}
                platformConfig={appleMusic}
                dispatch={dispatch}
              />
            )}
            {PLATFORM_TYPE_OPTIONS[DSP.DEEZER][type] && (
              <UrlField
                type={type}
                platformName={DSP.DEEZER}
                platformConfig={deezer}
                dispatch={dispatch}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => (action ? handleUpdateAction() : handleCreateAction())}
          color="primary"
          disabled={!allowSaving}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditionDialog;
