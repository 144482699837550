/* eslint-disable react/function-component-definition */
import React from 'react';

import { Interpolation } from '@emotion/serialize';

import { Theme } from '@mui/material';

import { Portal } from 'shared/components/Portal';

import * as Styled from './styled';

export type Props = {
  children?: React.ReactNode;
  /**
   * will be inserted in the actions slot
   * it can for instance be used to add a 'close' button
   * that will take the user back to a given url */
  actions?: React.ReactNode;

  /**
   * makes it possible to tweak the actions container styles,
   * can be useful to correctly position the actions when
   * the layout is used with a header for instance
   */
  actionsContainerStyles?: Interpolation<Theme>;

  /**
   * Extra styles to modify container styles
   */
  containerStyles?: Interpolation<Theme>;

  /**
   * an optional name to identify what is contained in this fullscreen presentation
   */
  name?: string;
};

const Fullscreen = ({
  children,
  actions,
  actionsContainerStyles,
  containerStyles,
  name,
}: Props) => {
  return (
    <Portal>
      <Styled.Container
        role="presentation"
        aria-label={name || ''}
        css={containerStyles}
      >
        <Styled.Content>{children}</Styled.Content>
        {!!actions && (
          <Styled.ActionsContainer css={actionsContainerStyles}>
            {actions}
          </Styled.ActionsContainer>
        )}
      </Styled.Container>
    </Portal>
  );
};

export default Fullscreen;

export { default as WithCloseLinkButton } from './FullscreenWithCloseLinkButton';
