import styled from '@emotion/styled';

import { EditorButton } from '../BacklinkSettingsDataEditor/components/styled';

export const Container = styled.div`
  > *  {
    margin-bottom: ${({ theme }) => theme.spacing(1 / 2)};
  }
`;

export const AddStoreButton = styled(EditorButton)`
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 45px;
`;
