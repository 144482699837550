import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SketchPicker } from 'react-color';

export const styles = {
  colorDropIcon: (theme) => css`
    stroke: ${theme.palette.grey[500]};
  `,
};

export const ColorPickerContainer = styled.span`
  position: relative;
`;

type ColorPickerOverlayProps = {
  isActive: boolean;
};
export const ColorPickerOverlay = styled.div<ColorPickerOverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

type StyledColorPickerProps = {
  isOpen: boolean;
  noColor: boolean;
};
export const StyledColorPicker = styled(SketchPicker)<StyledColorPickerProps>`
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  /* using the 'transparent' built-in SketchPicker swatch as a no-color swatch for now */
  & [title='transparent'] > div {
    /* draw a diagonal red line on a white bg */
    background: white !important;
    background-image: repeating-linear-gradient(
      135deg,
      transparent,
      transparent 10px,
      red 10px,
      red 12px
    ) !important;
  }

  & input {
    color: ${({ noColor }) => (noColor ? 'white' : 'inherit')};
  }
`;
