/**
 * using a common js format
 * to be able to use the exported vars in the i18next locales utilities configuration files (like sync or parser)
 * (as the node utilities can not easily use esm modules)
 */

/**
 * @type {string}
 */
const DEFAULT_LOCALE = 'en';

/**
 * @type {string[]}
 */
const allowedLocales = [
  'en',
  'fr',
  'de',
  'it',
  'es',
  'pt',
  'ru',
  'ar',
  'hi',
  'id',
  'jv',
  'su',
  'fil',
  'uk',
  'th',
  'vi',
  'tr',
  'pl',
  'ja',
  'ms',
  'zh',
  'zh-Hans',
  'zh-Hant',
  'zh-tw',
  'zh-sg',
];

/**
 * @type {string}
 */
const defaultNamespace = 'common';

module.exports = { DEFAULT_LOCALE, allowedLocales, defaultNamespace };
