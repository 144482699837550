import { Backlink } from 'shared/entities/backlink/backlink.types';

import { Artist } from '../../../artist/artist.types';
import {
  WorkspaceCustomer,
  WorkspaceEmployee,
} from '../../../workspace/workspace.types';

/**
 * BacklinkSettings Parent Entity
 */
export type BacklinkSettingsParentEntityData = {
  Backlink: Partial<Backlink>;
  Workspace: Partial<WorkspaceEmployee> | Partial<WorkspaceCustomer>;
  Artist: Partial<Artist>;
};

export type BacklinkSettingsParentEntityType =
  keyof BacklinkSettingsParentEntityData;

export type BacklinkSettingsParentEntityInfos<
  TParentEntityType extends BacklinkSettingsParentEntityType,
> = {
  type: TParentEntityType;
  entity: TParentEntityType extends BacklinkSettingsParentEntityType
    ? BacklinkSettingsParentEntityData[TParentEntityType]
    : never;
};

type BacklinkSettingsParentEntityTypesMap<
  M extends BacklinkSettingsParentEntityData,
> = {
  [Key in keyof M]: Key extends BacklinkSettingsParentEntityType
    ? BacklinkSettingsParentEntityInfos<Key>
    : never;
};

export type BacklinkSettingsParentEntityMappedTypes =
  BacklinkSettingsParentEntityTypesMap<BacklinkSettingsParentEntityData>[keyof BacklinkSettingsParentEntityTypesMap<BacklinkSettingsParentEntityData>];

export const parentEntityIsBacklink = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Backlink'> => {
  return typeof parentEntityInfos === 'undefined'
    ? false
    : parentEntityInfos && parentEntityInfos.type === 'Backlink';
};

export const parentEntityIsWorkspace = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Workspace'> => {
  return typeof parentEntityInfos === 'undefined'
    ? false
    : parentEntityInfos && parentEntityInfos.type === 'Workspace';
};

export const parentEntityIsArtist = (
  parentEntityInfos?: BacklinkSettingsParentEntityMappedTypes,
): parentEntityInfos is BacklinkSettingsParentEntityInfos<'Artist'> => {
  return typeof parentEntityInfos === 'undefined'
    ? false
    : parentEntityInfos && parentEntityInfos.type === 'Artist';
};
