import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as backlinkApi from 'shared/entities/backlink/backlink.api';
import { useNotification } from 'shared/hooks/useNotification';

const BACKLINK_V2_QUERY_KEY_BASE = 'backlinkV2';

/**
 * Fetch a specific backlink given @id
 * return the version 2 of the backlinkSettings format
 */
export function useFetchBacklinkV2(backlinkId: string) {
  const queryClient = useQueryClient();

  const queryKey = [BACKLINK_V2_QUERY_KEY_BASE, backlinkId];

  const { data: backlink, ...rest } = useQuery(
    queryKey,
    () => backlinkApi.v2.fetchBacklink(backlinkId),
    {
      // let's not refetch automatically
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries([queryKey, backlinkId]);

  return { backlink, invalidateQuery, ...rest };
}

/**
 * Fetch Backlink V2 DSP Actions
 */
export function useFetchBacklinkV2DSPActions(
  params: Parameters<typeof backlinkApi.v2.fetchBacklinkDSPActions>[0],
  options?: Pick<UseQueryOptions, 'enabled'>,
) {
  return useQuery(
    ['backlinkDSPActions', params],
    () => backlinkApi.v2.fetchBacklinkDSPActions(params),
    {
      ...options,
    },
  );
}

/**
 * Update Backlink Mutation Hook
 */
export const useUpdateBacklinkV2 = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const {
    mutate: updateBacklink,
    status: updateStatus,
    ...rest
  } = useMutation(backlinkApi.v2.updateBacklink, {
    onSuccess: (data) => {
      notify({
        type: AppNotificationTypes.Success,
        message: t('link_updated', 'Link updated!'),
      });

      queryClient.invalidateQueries(BACKLINK_V2_QUERY_KEY_BASE);
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'updating_link_error',
          'An error occured while updating your link',
        ),
      });
    },
  });

  return {
    updateBacklink,
    updateStatus,
    ...rest,
  };
};

/**
 * Create Backlin DSP Action Mutation Hook
 */
export const useCreateBacklinkDSPAction = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const { notify } = useNotification();

  const { mutate: createBacklinkDSPAction, ...rest } = useMutation(
    backlinkApi.v2.createBacklinkDSPAction,
    {
      onSuccess: (data) => {
        notify({
          type: AppNotificationTypes.Success,
          message: t('dsp_action_created', 'Fan Automation created!'),
        });
      },
      onError: () => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'creating_dsp_action_error',
            'An error occured while creating your Fan Automation',
          ),
        });
      },
    },
  );

  return {
    createBacklinkDSPAction,
    ...rest,
  };
};

/**
 * Update Backlink DSP Action Mutation Hook
 */
export const useUpdateBacklinkDSPAction = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const { notify } = useNotification();

  const { mutate: updateBacklinkDSPAction, ...rest } = useMutation(
    backlinkApi.v2.updateBacklinkDSPAction,
    {
      onSuccess: (data) => {
        notify({
          type: AppNotificationTypes.Success,
          message: t('dsp_action_updated', 'Fan Automation updated!'),
        });
      },
      onError: () => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'updating_dsp_action_error',
            'An error occured while updating your Fan Automation',
          ),
        });
      },
    },
  );

  return {
    updateBacklinkDSPAction,
    ...rest,
  };
};
