import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as creativeMediaApi from 'shared/entities/creative/media.api';
import {
  FetchCreativeMediasPayload,
  FetchCreativeMediasResponse,
  RequestSingleCreativeMediaGenerationPayload,
} from 'shared/entities/creative/media.api.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';
import { downloadFileFromBinaryResponse } from 'shared/utils/file';

export { default as useCreativeMediaDeleteConfirmDialog } from './useCreativeMediaDeleteConfirmDialog';
export { default as useSingleCreativeMediaGenerationAndDownload } from './useSingleCreativeMediaGenerationAndDownload';

export function useFetchCreativeMediasApiToken() {
  const { data: creativeMediasApiToken, ...rest } = useAppQuery(
    'creativeMediasApiToken',
    {
      // this option should make sure that we don't refetch the data and only use the cache
      staleTime: Infinity,
    },
  );

  return { creativeMediasApiToken, ...rest };
}

/**
 * Fetch creative medias
 */
export function useFetchCreativeMedias(
  params?: FetchCreativeMediasPayload,
  options?: UseQueryOptions<FetchCreativeMediasResponse>,
) {
  const queryClient = useQueryClient();

  const { data: paginatedResults, ...rest } = useAppQuery('creativeMedias', {
    fetcherArgs: [params || {}],
    ...options,
  });

  const creativeMedias = paginatedResults?.creativeMedias;
  const totalCount = paginatedResults?.totalCount;

  const invalidateQuery = () => {
    queryClient.invalidateQueries(['creativeMedias', params]);
  };

  return { creativeMedias, totalCount, invalidateQuery, ...rest };
}

/**
 * suggested medias creation request
 */
export function useRequestSuggestedCreativeMediasCreation() {
  const { mutate: requestSuggestedCreativeMediasCreation, ...rest } =
    useMutation(creativeMediaApi.requestSuggestedCreativeMediasCreation);

  return {
    requestSuggestedCreativeMediasCreation,
    ...rest,
  };
}

/**
 * medias generation request
 */
export function useRequestCreativeMediasGeneration() {
  const { mutate: requestCreativeMediasGeneration, ...rest } = useMutation(
    creativeMediaApi.requestCreativeMediasGeneration,
  );

  return {
    requestCreativeMediasGeneration,
    ...rest,
  };
}

/**
 * single media generation request
 */
export function useRequestSingleCreativeMediaGeneration() {
  const { requestCreativeMediasGeneration } =
    useRequestCreativeMediasGeneration();

  const { mutate: requestSingleCreativeMediaGeneration, ...rest } = useMutation(
    async (creativeMediaInfos: RequestSingleCreativeMediaGenerationPayload) => {
      return requestCreativeMediasGeneration({
        mediaInfoListWithType: [creativeMediaInfos],
      });
    },
  );

  return {
    requestSingleCreativeMediaGeneration,
    ...rest,
  };
}

/**
 * get medias images as a zip archive
 */
export function useGetCreativeMediasArchive() {
  const { mutate: getCreativeMediasArchive, ...rest } = useMutation(
    creativeMediaApi.getCreativeMediasArchive,
  );

  return {
    getCreativeMediasArchive,
    ...rest,
  };
}
/**
 * get a single media
 */
export function useDownloadCreativeMedia() {
  const { t } = useTranslation('common', {
    keyPrefix: 'section.creative.dialog.download',
  });

  const { notify } = useNotification();

  const { mutate: downloadCreativeMedia, ...rest } = useMutation(
    async ({ mediaId }: { mediaId: string; filename: string }) =>
      creativeMediaApi.getGeneratedMediaImage({ mediaId }),
    {
      onSuccess: (data, params) => {
        // download file
        downloadFileFromBinaryResponse(data, params.filename);

        notify({
          type: AppNotificationTypes.Success,
          message: t('link_downloaded', 'Creative downloaded!'),
        });
      },
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'downloading_link_error',
            'An error occured while downloading your creative.',
          ),
        });
      },
    },
  );

  return {
    downloadCreativeMedia,
    ...rest,
  };
}

/**
 * archive a media
 */
export function useDeleteCreativeMedia() {
  const { t } = useTranslation();
  const { notify } = useNotification();

  const { mutate: deleteCreativeMedia, ...rest } = useMutation(
    creativeMediaApi.archiveCreativeMedia,
    {
      onSuccess: async () =>
        notify({
          type: AppNotificationTypes.Success,
          message: t(
            'entity.creative.message.link_deleted',
            'Creative deleted!',
          ),
        }),
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'entity.creative.message.deleting_link_error',
            'An error occured while deleting your creative.',
          ),
        });
      },
    },
  );

  return {
    deleteCreativeMedia,
    ...rest,
  };
}
