import React from 'react';

import { TYPE_OPTIONS } from 'shared/components/ActionEditor/constants';
import DspUrlField from 'shared/components/DspUrlField';
import { DSPConfig } from 'shared/entities/dspAction/dspAction.types';
import { DSP } from 'shared/entities/storeConfig/storeConfig.types';

import { Actions, ActionsTypes } from '../../state';

import * as Styled from './styled';

type Props = {
  type: string;
  platformName: DSP;
  platformConfig?: DSPConfig;
  dispatch: React.Dispatch<Actions>;
};

const UrlField: React.FC<Props> = ({
  type,
  platformName,
  platformConfig,
  dispatch,
}) => (
  <Styled.Container>
    <DspUrlField
      targetUrl={platformConfig?.targetUrl}
      onUrlResolved={({ targetId, targetUrl }) => {
        dispatch({
          type: ActionsTypes.SetPlatformConfig,
          payload: {
            platform: platformName,
            config: {
              targetUrl,
              targetId,
            },
          },
        });
      }}
      onUrlCleared={() => {
        dispatch({
          type: ActionsTypes.RemovePlatform,
          payload: platformName,
        });
      }}
      onUrlIsBeingChecked={(value) =>
        dispatch({
          type: ActionsTypes.SetUrlIsBeingChecked,
          payload: {
            platform: platformName,
            value: value,
          },
        })
      }
      storeName={platformName}
      targetType={TYPE_OPTIONS[type].targetType}
    />
  </Styled.Container>
);

export default UrlField;
