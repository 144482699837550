import config, { CONFIG_ENV } from 'shared/config';
import { AppName } from 'shared/types';

export const DEFAULT_MAIN_APP_NAME: AppName = AppName.marketingSuite;

let appNameFromSearchParams;

/**
 * get the name of the main app
 */
export const getAppName = () => {
  switch (window.location.hostname) {
    case config.marketingSuiteHostname: {
      return AppName.marketingSuite;
    }
    case config.backstageMarketingHostname: {
      return AppName.backstageMarketing;
    }
    case config.backstageMarketingResourcesHostname: {
      return AppName.backstageMarketingResources;
    }
    default: {
      // we might need to set the appName for testing purposes
      const isTesting =
        CONFIG_ENV === 'development' &&
        process.env.REACT_APP_USE_MOCKS === 'true';
      const searchParams = new URLSearchParams(window.location.search);
      appNameFromSearchParams =
        appNameFromSearchParams ??
        (searchParams.get('appName') !== null &&
        (Object.values(AppName) as string[]).includes(
          searchParams.get('appName') as string,
        )
          ? searchParams.get('appName')
          : null);

      let appName =
        window.localStorage.getItem('MkpAppName') || AppName.marketingSuite;

      if (appNameFromSearchParams) {
        appName = appNameFromSearchParams;
        window.localStorage.setItem('MkpAppName', appNameFromSearchParams);
      }

      return isTesting ? appName : '';
    }
  }
};
