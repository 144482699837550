import React from 'react';

import { useTranslation } from 'react-i18next';
import ReactRouterPrompt from 'react-router-prompt';

import ConfirmDialog from 'shared/components/ConfirmDialog';

type RouteLeavingGuardProps = {
  when?: boolean;
  shouldBlockNavigation: (string) => boolean;
};

const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  when,
  shouldBlockNavigation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ReactRouterPrompt
        when={(args) => {
          if (!when) {
            return false;
          }

          // in some edge cases args is undefined so we can show the popup only based on when.
          if (!args) {
            return when;
          }

          return shouldBlockNavigation(args.nextLocation) || false;
        }}
      >
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmDialog
            open={isActive}
            title={t(
              'ui.component.route_leaving_guard.dialog.title',
              'Leave without saving?',
            )}
            content={t(
              'ui.component.route_leaving_guard.dialog.content',
              'You have unsaved changes. Are you sure you want to leave this page without saving?',
            )}
            cancelButtonText={t(
              'ui.component.route_leaving_guard.dialog.button.cancel',
              'Stay on page',
            )}
            confirmButtonText={t(
              'ui.component.route_leaving_guard.dialog.button.confirm',
              'Leave without saving',
            )}
            onClose={onCancel}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>
    </>
  );
};

export default RouteLeavingGuard;
