import {
  Button,
  Grid,
  LoadingButton,
  Typography,
  theme,
} from '@believe-front/react-components';
import { useTranslation } from 'react-i18next';

import {
  useCreateRelease,
  useFetchRelease,
  useUpdateRelease,
} from 'shared/hooks/entities/release';
import { getCurrentWorkspaceId, useAppContext } from 'shared/state';

import { ActionType } from './context/actions';
import { useReleaseEditorContext } from './context/context';
import { ReleaseEditorStep } from './types';
import { artistIsCreated } from './types.utils';

export const Footer = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor',
  });
  const {
    state: { currentStep, artist, product, releaseId, onReleaseCreated },
    dispatch,
  } = useReleaseEditorContext();

  const { release } = useFetchRelease(releaseId);
  const { createRelease } = useCreateRelease();
  const { updateRelease } = useUpdateRelease();

  const { state: appState } = useAppContext();
  const workspaceId = getCurrentWorkspaceId(appState);

  const disabled =
    (currentStep === ReleaseEditorStep.PRODUCT &&
      typeof product === 'undefined') ||
    (currentStep === ReleaseEditorStep.ARTIST && typeof artist === 'undefined');

  const loading = currentStep === ReleaseEditorStep.SAVING;

  const handleSubmit = (e) => {
    const shouldUpdateRelease = releaseId && release && product; //no artist in edit mode
    const shouldCreateRelease = !releaseId && artist; //product is optional in create mode
    if (shouldUpdateRelease) {
      dispatch({
        type: ActionType.CHANGE_STEP,
        payload: { currentStep: ReleaseEditorStep.SAVING },
      });
      updateRelease(
        {
          releaseId,
          internalProductIds: [
            ...release.products.map((product) => product.internalProductId),
            product?.internalProductId,
          ],
          workspaceId,
        },
        {
          onSuccess: (release) => {
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: {
                currentStep: ReleaseEditorStep.SAVED,
              },
            });
          },
          onError: (error) => {
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: {
                currentStep: ReleaseEditorStep.UNINITIALIZED,
              },
            });
          },
        },
      );
    } else if (shouldCreateRelease) {
      const artistPayload = artistIsCreated(artist)
        ? { artistId: artist.id }
        : { isNew: true, newArtistName: artist.newArtistName };
      dispatch({
        type: ActionType.CHANGE_STEP,
        payload: { currentStep: ReleaseEditorStep.SAVING },
      });
      createRelease(
        {
          artistPayload,
          internalProductIds: product?.internalProductId
            ? [product?.internalProductId]
            : [],
          workspaceId,
        },
        {
          onSuccess: async (release) => {
            if (onReleaseCreated) {
              await onReleaseCreated(release);
            }
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: { currentStep: ReleaseEditorStep.SAVED },
            });
          },
          onError: (error) => {
            dispatch({
              type: ActionType.CHANGE_STEP,
              payload: {
                currentStep: ReleaseEditorStep.UNINITIALIZED,
              },
            });
          },
        },
      );
    } else {
      dispatch({
        type: ActionType.UPDATE_ARTIST_QUERY,
        payload: { artistQuery: product?.artistName || '' },
      });
      dispatch({
        type: ActionType.CHANGE_STEP,
        payload: { currentStep: ReleaseEditorStep.ARTIST },
      });
    }
  };
  return (
    <Grid
      aria-label={t('footer.aria_label', 'dialog footer')}
      container
      height={48}
      justifyContent="flex-end"
      alignContent="flex-start"
      gap={2.5}
      sx={{ padding: theme.spacing(0, 6) }}
    >
      <Button
        variant="text"
        onClick={() =>
          dispatch({
            type: ActionType.CHANGE_STEP,
            payload: { currentStep: ReleaseEditorStep.UNINITIALIZED },
          })
        }
        color="secondary"
      >
        <Typography color="inherit">
          {t('ui.button.cancel', 'Cancel')}
        </Typography>
      </Button>
      <LoadingButton
        sx={{
          /** necessary to reset styles after onClick when moving on to next step */
          '&:hover': {
            cursor: 'default',
          },
          /** TODO fix disabled background color in DS */
          '&.Mui-disabled&:not(.MuiLoadingButton-loading)': {
            background: theme.palette.surface[80],
          },
        }}
        disabled={disabled}
        loading={loading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        <Typography color="inherit">
          {!releaseId && currentStep === ReleaseEditorStep.PRODUCT
            ? t('ui.button.next', 'Next')
            : t('ui.button.save', 'Save')}
        </Typography>
      </LoadingButton>
    </Grid>
  );
};
