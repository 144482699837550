/**
 * This file should containe utils that are used to allow some styled-components feature to be simulated in emotion
 */
import { CreateStyled } from '@emotion/styled';

/**
 * This is a workaround for the fact that emotion does not support transient props
 * see https://github.com/emotion-js/emotion/issues/2193#issuecomment-766173118
 *
 * usage:
 * const SomeComponent = styled(
 *    Typography,
 *    transientOptions,
 * )<{ $customProp: boolean }>(({ theme, $customProp }) => ({...}));
 */
export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};
