import Fuse from 'fuse.js';

import {
  isCreativeForYoutubeDTO,
  AdCampaignStatsDTO,
} from './ads.api.dtos.types';
import type { AdCampaignDTO } from './ads.api.dtos.types';
import type { ReferentialAgeRangeResponse } from './ads.api.types';
import { AdCampaignStatus } from './ads.constants';
import {
  AdCampaign,
  AdCampaignStats,
  ReferentialAgeRanges,
  Interest,
  Location,
  TermObject,
  isCreativeForYoutube,
  DraftAdCampaign,
  YoutubeLocation,
  YoutubeInterest,
} from './ads.types';

/**
 * Ad Campaign Model
 */
export const AdCampaignModel = {
  transformFromDTO(campaign: AdCampaignDTO): AdCampaign {
    return {
      key: campaign.sub_campaign_key,
      name: campaign.sub_campaign_name,
      platform: campaign.platform,
      budget: campaign.budget,
      spent: campaign.spend,
      period: campaign.periode,
      placement: campaign.placement || [],
      targeting: campaign.targeting?.map((target) => ({
        ...target,
        key: target.targeting_key,
        ageRange: AgeRangeModel.transformYoutube(target.age_range || []),
        age: target.age
          ? {
              min: Number.parseInt(target.age.min),
              max: Number.parseInt(target.age.max),
            }
          : undefined,
      })),
      creatives: AdCampaignCreativeModel.transformFromDTO(campaign.creatives),
      status: campaign.status ?? AdCampaignStatus.Draft,
    };
  },
  transformToDTO(campaign: DraftAdCampaign): AdCampaignDTO {
    return {
      sub_campaign_key: campaign.key,
      sub_campaign_name: campaign.name,
      platform: campaign.platform,
      budget: campaign.budget,
      spend: campaign.spent,
      periode: campaign.period,
      placement: campaign.placement,
      targeting: campaign.targeting?.map((target) => ({
        targeting_key: target.key,
        type: target.type,
        interests: target.interests,
        locations: target.locations,
        gender: target.gender,
        age_range: target.ageRange
          ? target.ageRange.map((range) => range.key)
          : undefined,
        age: target.age
          ? { min: target.age.min.toString(), max: target.age.max.toString() }
          : undefined,
      })),
      creatives: AdCampaignCreativeModel.transformToDTO(campaign.creatives),
      status: campaign.status,
    };
  },
};

/**
 * Ad Campaign Model for Creatives (YouTube / Fb & Insta)
 */
const AdCampaignCreativeModel = {
  transformFromDTO(
    creatives: AdCampaignDTO['creatives'],
  ): AdCampaign['creatives'] {
    return creatives?.map((creative) =>
      isCreativeForYoutubeDTO(creative)
        ? {
            key: creative.creative_key,
            youtubeUrl: creative.youtube_url,
            finalUrl: creative.final_url,
            displayUrl: creative.display_url,
            CTA: creative.call_to_action,
            headline: creative.head_line,
          }
        : {
            key: creative.creative_key,
            primaryText: creative.primary_text,
            linkToPromote: creative.link_to_promote,
            linkToDisplay: creative.link_to_display,
            headline: creative.head_line,
            subtitle: creative.sub_title,
            CTA: creative.call_to_action,
            videoSources: {
              feed: creative.video_url.facebook || '',
              story: creative.video_url.instagram || '',
            },
          },
    );
  },
  transformToDTO(
    creatives: AdCampaign['creatives'],
  ): AdCampaignDTO['creatives'] {
    return creatives?.map((creative) =>
      isCreativeForYoutube(creative)
        ? {
            creative_key: creative.key,
            youtube_url: creative.youtubeUrl,
            final_url: creative.finalUrl,
            display_url: creative.finalUrl
              .replace('https://', '')
              .replace('http://', ''),
            call_to_action: creative.CTA,
            head_line: creative.headline,
          }
        : {
            creative_key: creative.key,
            primary_text: creative.primaryText,
            link_to_promote: creative.linkToPromote,
            link_to_display: creative.linkToPromote // We use the same link for both
              .replace('https://', '')
              .replace('http://', ''),
            head_line: creative.headline,
            sub_title: creative.subtitle,
            call_to_action: creative.CTA,
            video_url: {
              facebook: creative.videoSources.feed,
              instagram: creative.videoSources.story,
            },
          },
    );
  },
};

/**
 * Ad Campaign Model for the campaign stats
 */
export const AdCampaignStatsModel = {
  transform: (stats: AdCampaignStatsDTO): AdCampaignStats => {
    const parseFloat = (value: number) => Number(value.toFixed(2));

    return {
      budget: stats.budget,
      opt: stats.opt,
      spent: stats.spend,
      dayStats: stats.day_stats.map((dayStat) => {
        // We get the date in the format "dd/mm/yyyy" from the API
        const [day, month, year] = dayStat.day.split('/');
        return {
          // normalize date format so it's easier to convert to date object later
          day: `${year}-${month}-${day}`,
          spent: dayStat.spend,
          impressions: dayStat.impression,
          videoViews: dayStat.video_view,
          clicks: dayStat.clicks,
          thruplay: dayStat.thruplay,
          cpc: parseFloat(dayStat.cpc),
          ctr: parseFloat(dayStat.ctr),
          cpv: parseFloat(dayStat.cpv),
        };
      }),
      impressions: stats.impression,
      videoViews: stats.video_view,
      clicks: stats.clicks,
      thruplay: stats.thruplay,
      cpc: parseFloat(stats.cpc),
      ctr: parseFloat(stats.ctr),
      cpv: parseFloat(stats.cpv),
    };
  },
};

/**
 * Referential: Interests
 */
export const InterestModel = {
  transformAllToTerms(data: Interest[]): TermObject[] {
    return data.map((interest) => ({
      id: interest.key,
      label: interest.label,
    }));
  },
  transformFromTerm(data: TermObject): Interest {
    return {
      key: data.id,
      label: data.label,
    };
  },
  search(data: Interest[], query: string): Interest[] {
    const fuse = new Fuse(data, {
      shouldSort: true,
      keys: ['label'],
    });
    return fuse.search(query).map((result) => result.item);
  },
};

/**
 * Referential: Locations
 */
export const LocationModel = {
  transformAllToTerms(data: Location[]): TermObject[] {
    return data.map((location) => ({
      id: location.key,
      label: location.name,
      type: location.type,
    }));
  },
  transformFromTerm(data: TermObject): Location {
    return {
      key: data.id,
      name: data.label,
      type: data.type,
    };
  },
  search(data: Location[], query: string): Location[] {
    const fuse = new Fuse(data, {
      shouldSort: true,
      keys: ['name'],
    });
    return fuse.search(query).map((result) => result.item);
  },
};

export const YoutubeLocationModel = {
  searchByName(data: YoutubeLocation[], query: string): YoutubeLocation[] {
    const fuse = new Fuse(data, {
      shouldSort: true,
      keys: ['name'],
    });
    return fuse.search(query).map((result) => result.item);
  },

  searchById(data: YoutubeLocation[], query: string): YoutubeLocation[] {
    return data.filter((item) => item.id === parseInt(query, 10));
  },
};

export const YoutubeInterestModel = {
  searchByName(data: YoutubeInterest[], query: string): YoutubeInterest[] {
    const fuse = new Fuse(data, {
      shouldSort: true,
      keys: ['name'],
    });
    return fuse.search(query).map((result) => result.item);
  },

  searchById(data: YoutubeInterest[], query: string): YoutubeInterest[] {
    return data.filter((item) => item.id === parseInt(query, 10));
  },
};

/**
 * Referential: Age Ranges
 */
export const AgeRangeModel = {
  transform: (response: ReferentialAgeRangeResponse): ReferentialAgeRanges => {
    const facebookData = response.data.facebook_data;

    return {
      youtube: AgeRangeModel.transformYoutube(
        response.data.youtube_data.age_range,
      ),
      facebook: {
        min: Number.parseInt(facebookData.min),
        max: Number.parseInt(facebookData.max),
      },
    };
  },
  transformYoutube: (data: string[]): ReferentialAgeRanges['youtube'] => {
    const getYTMinMax = (range: string) => range.split('_').slice(2, 4);
    return data
      .filter((range) => {
        const [min, max] = getYTMinMax(range);
        return !range.endsWith('UNDETERMINED') && min && max;
      })
      .map((range) => {
        const [min, max] = getYTMinMax(range);
        return {
          min: Number.parseInt(min),
          max: max === 'UP' ? undefined : Number.parseInt(max),
          key: range,
        };
      });
  },
};
