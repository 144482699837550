import styled from '@emotion/styled';

import { Card, CardProps, CardHeader } from '@mui/material';

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !['isDefaultAction'].includes(prop),
})<CardProps & { isDefaultAction?: boolean }>`
  background-color: ${({ theme, isDefaultAction }) =>
    isDefaultAction ? theme.palette.primary.main : theme.palette.grey[300]};
  color: ${({ isDefaultAction }) => (isDefaultAction ? 'white' : 'inherit')};
`;

export const StyledCardHeader = styled(CardHeader)`
  min-height: 56px;
  font-weight: bold;
  text-transform: capitalize;
  .MuiCardHeader-action {
    display: flex;
    align-items: center;
    &,
    .MuiTypography-body1 {
      margin-right: 10px;
    }
    .MuiIconButton-root {
      color: ${({ theme }) => theme.palette.grey[600]};
      padding: ${({ theme }) => theme.spacing(1 / 2)};
    }
  }
`;
