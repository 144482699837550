import { StoreType } from '../storeConfig/storeConfig.types';
import { PrereleaseCTAMode } from './backlinkSettings.types';

export const BACKLINK_SETTINGS_IDS = {
  default: 'default',
} as const;

export const BACKLINK_SETTINGS_FALLBACK_LOCALE = 'en';

export enum RelatedBacklinkSettingsMode {
  'DEFAULT' = 'useDefaultBacklinkSettings',
  'CUSTOM' = 'useCustomBacklinkSettings',
}

export const REPLACEMENT_TOKEN_KEY_PLACEHOLDER = 'tokenKey';
export const REPLACEMENT_TOKEN_TEMPLATE = `{${REPLACEMENT_TOKEN_KEY_PLACEHOLDER}}`;

/**
 * warning: keys are used as replacement tokens and so should not be changed
 * and values as display names (in a selector for instance)
 */
export enum REPLACEMENT_TOKENS_ENUM {
  artistName = 'Artist Name',
  releaseTitle = 'Release Title',
  digitalReleaseDate = 'Release Date (day & month)',
  digitalReleaseDateFull = 'Release Date (with year)',
}

/* for i18next parser

t('ui.component.backlink_settings_data_editor.replacement_token_select.item.artistName', 'Artist Name');
t('ui.component.backlink_settings_data_editor.replacement_token_select.item.releaseTitle', 'Release Title');
t('ui.component.backlink_settings_data_editor.replacement_token_select.item.digitalReleaseDate', 'Release Date (day & month)');
t('ui.component.backlink_settings_data_editor.replacement_token_select.item.digitalReleaseDateFull', 'Release Date (with year)');

*/

export type BacklinkSettingsDataReplacementTokenKey =
  keyof typeof REPLACEMENT_TOKENS_ENUM;
export const isReplacementTokenKey = (
  str: string,
): str is BacklinkSettingsDataReplacementTokenKey => {
  return Object.keys(REPLACEMENT_TOKENS_ENUM).includes(str);
};

export const BACKLINK_SETTINGS_DATE_FORMAT = 'YYYY/MM/DD';

export const prereleaseCTAModeByStoreType: Record<
  StoreType,
  PrereleaseCTAMode
> = {
  [StoreType.Stream]: PrereleaseCTAMode.DEFAULT,
  [StoreType.Download]: PrereleaseCTAMode.DIRECT_LINK,
  [StoreType.Buy]: PrereleaseCTAMode.DIRECT_LINK,
  [StoreType.Follow]: PrereleaseCTAMode.DEFAULT,
  [StoreType.Watch]: PrereleaseCTAMode.DIRECT_LINK,
  [StoreType.Other]: PrereleaseCTAMode.DEFAULT,
};

export enum BacklinkSettingsType {
  GLOBAL_DEFAULT = 'global_default',
  DEFAULT_EMPLOYEE = 'default_employee',
  DEFAULT_CUSTOMER = 'default_customer',
  REL_BACKLINK = 'rel_backlink',
  REL_ARTIST = 'rel_artist',
  REL_WORKSPACE = 'rel_workspace',
}

export enum FormFieldName {
  Firstname = 'firstname',
  Lastname = 'lastname',
  Mobile = 'phonenumber',
  Email = 'email',
  Birthdate = 'birthdate',
  Address = 'address',
  ZipCode = 'zipCode',
  City = 'city',
  Country = 'country',
  OptinCompetition = 'optin_competition',
  OptinSubscription = 'optin_subscription',
}

export enum FormFieldType {
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Checkbox = 'checkbox',
  Country = 'country',
  Date = 'date',
}

export enum BacklinkMode {
  PRERELEASE = 'preRelease',
  POSTRELEASE = 'postRelease',
}
