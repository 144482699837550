import {
  DSP,
  DSPActionScheduleType,
} from 'shared/entities/dspAction/dspAction.constants';
import {
  FrontendDSPActionStatus,
  FrontendDSPActionType,
} from 'shared/entities/dspAction/dspAction.frontend.constants';
import { FrontendDSPAction } from 'shared/entities/dspAction/dspAction.frontend.types';

export const dspActions: FrontendDSPAction[] = [
  {
    dspActionId: null,
    targetName: '',
    type: FrontendDSPActionType.FOLLOW_MAIN_ARTIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    storeName: DSP.SPOTIFY,
    targetURL: '',
    targetId: '',
    status: FrontendDSPActionStatus.DRAFT,
  },
  {
    dspActionId: null,
    targetName: 'Maddslinky Playlist',
    type: FrontendDSPActionType.ADD_MAIN_PLAYLIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    storeName: DSP.SPOTIFY,
    targetURL: '',
    targetId: '',
    status: FrontendDSPActionStatus.DRAFT,
  },
  {
    dspActionId: null,
    targetName: 'Maddslinky Playlist',
    type: FrontendDSPActionType.ADD_MAIN_PLAYLIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    storeName: DSP.DEEZER,
    targetURL: '',
    targetId: '',
    status: FrontendDSPActionStatus.DRAFT,
  },
  {
    dspActionId: null,
    targetName: 'Maddslinky Playlist',
    type: FrontendDSPActionType.ADD_MAIN_PLAYLIST,
    scheduleType: DSPActionScheduleType.SCHEDULED_NOW,
    storeName: DSP.APPLE_MUSIC,
    targetURL: '',
    targetId: '',
    status: FrontendDSPActionStatus.DRAFT,
  },
];
