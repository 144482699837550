import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Tabs } from 'shared/components/Tabs';
import { UpdateArtistProfileDataPayload } from 'shared/entities/artist/artist.api.types';
import useUpdateArtistProfile from 'shared/hooks/entities/artist/useUpdateArtistProfile';
import { useArtistSettingsPanel } from 'shared/hooks/useArtistSettingsPanel';

import ArtistBacklinkSettings from './tabs/ArtistBacklinkSettings';
import Profile, {
  FormValues,
  getArtistProfileSchema,
} from './tabs/ArtistProfile';

import * as Styled from './styled';

const InnerPanel: React.FC = () => {
  const { t } = useTranslation();

  const { isOpen, close, artist, profileOnly } = useArtistSettingsPanel();

  const methods = useForm<FormValues>({
    resolver: yupResolver(getArtistProfileSchema()),
    defaultValues: {
      profilePictureId: artist?.data?.profilePictureId || '',
      name: artist?.name || '',
      socialHandles: {
        instagramHandle: artist?.data?.instagramHandle || '',
        facebookHandle: artist?.data?.facebookHandle || '',
        twitterHandle: artist?.data?.twitterHandle || '',
        tiktokHandle: artist?.data?.tiktokHandle || '',
        VK: artist?.data?.VK || '',
        youtubeChannelName: artist?.data?.youtubeChannelName || '',
      },
      /**
       * note: we need to transform the Artist.data.storeProfileLinks array
       * from what is received from the backend
       * storeProfileLinks: {
       *   [storeName: string]: {
       *     url: string;
       *     id?: string;
       *   };
       * };
       * to the shape used in this form
       */
      storeProfileLinks: Object.entries(
        (artist?.data?.storeProfileLinks as Record<
          string,
          {
            url: string;
            id?: string;
          }
        >) || {},
      ).map(([storeName, { url, id }]) => {
        return {
          storeName,
          url,
          id,
        };
      }),
      storeOfficialPlaylists: Object.entries(
        artist?.data?.storeOfficialPlaylists || {},
      ).reduce((acc: any[], [storeName, { url, id }]) => {
        if (!url) return acc;
        return [
          ...acc,
          {
            storeName,
            url,
            id,
          },
        ];
      }, []),
      subDomain: artist?.data?.subDomain || '',
    },
  });

  const { updateArtistProfile, updateArtistProfileStatus } =
    useUpdateArtistProfile({ artistId: artist?.id });
  const isUpdatingArtist = updateArtistProfileStatus === 'loading';

  // Submit form and close the panel
  function onSubmit(data: FormValues) {
    if (!artist || Object.keys(methods.formState.errors).length > 0) return;
    const {
      name,
      socialHandles,
      storeProfileLinks,
      storeOfficialPlaylists,
      ...rest
    } = data;
    const payload: UpdateArtistProfileDataPayload = {
      name,
      workspaceId: artist.workspaceId,
      data: {
        ...socialHandles,
        ...rest,
        storeProfileLinks: Object.fromEntries(
          storeProfileLinks.map(({ storeName, url, id }) => [
            storeName,
            { url, id },
          ]),
        ),
        storeOfficialPlaylists: (storeOfficialPlaylists || []).reduce(
          (acc, playlist) => ({
            ...acc,
            [playlist.storeName]: {
              url: playlist.url,
              id: playlist.id,
            },
          }),
          {},
        ),
      },
    };
    updateArtistProfile({ artistId: artist.id, data: payload });
  }

  return (
    <Styled.Drawer
      open={isOpen}
      anchor="right"
      onClose={close}
      // forced focus on the modal will prevent fields to be accessible in BacklinkSettingsData editor
      disableEnforceFocus
      aria-label="artist-settings-panel"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Styled.Header>
            <Styled.HeaderPart>
              <Box marginRight={1}>
                <IconButton
                  aria-label={t('ui.button.close', 'Close')}
                  onClick={close}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Styled.PanelTitle>
                {t(
                  'ui.component.artist_settings_panel.title',
                  'Artist settings',
                )}
              </Styled.PanelTitle>
            </Styled.HeaderPart>
            <Styled.HeaderPart>
              <Button variant="text" onClick={close}>
                {t('ui.form.button.cancel', 'Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isUpdatingArtist}
              >
                <CheckIcon /> {t('ui.form.button.save', 'Save')}{' '}
                {isUpdatingArtist ? '...' : null}
              </Button>
            </Styled.HeaderPart>
          </Styled.Header>

          <Styled.Content>
            <Tabs
              aria-label={t(
                'ui.component.artist_settings_panel.tabs.title',
                'Artist settings sections',
              )}
            >
              <Tabs.Tab
                label={t(
                  'ui.component.artist_settings_panel.tabs.tab.profile.title',
                  'Profile',
                )}
              />
              {!profileOnly && (
                <Tabs.Tab
                  label={t(
                    'ui.component.artist_settings_panel.tabs.tab.link_settings.title',
                    'Link settings',
                  )}
                />
              )}

              <Tabs.Panel>
                <Profile />
              </Tabs.Panel>
              {!profileOnly && (
                <Tabs.Panel>
                  {!!artist && <ArtistBacklinkSettings artistId={artist.id} />}
                </Tabs.Panel>
              )}
            </Tabs>
          </Styled.Content>
        </form>
      </FormProvider>
    </Styled.Drawer>
  );
};

const ArtistSettingsPanel = () => {
  const { artist } = useArtistSettingsPanel();
  return <InnerPanel key={artist?.id} />;
};

export default ArtistSettingsPanel;
