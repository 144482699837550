import { useTranslation } from 'react-i18next';

import { CreativeMediaListItem } from 'shared/entities/creative/media.types';
import useConfirmDialog from 'shared/hooks/useConfirmDialog';

import { useDeleteCreativeMedia } from '.';

/**
 * a hook to delete a creative media with confirm dialog
 */
export default function useCreativeMediaDeleteConfirmDialog() {
  const { t } = useTranslation('common', {
    keyPrefix: 'section.creative.dialog.delete',
  });

  const {
    deleteCreativeMedia,
    variables: creativeMediaBeingDeletedId,
    isError: errorDeletingCreativeMedia,
  } = useDeleteCreativeMedia();

  /**
   * Creative deletion dialog
   */
  const {
    Dialog: DeleteCreativeMediaDialog,
    openDialog: openCreativeMediaDeleteDialog,
  } = useConfirmDialog<
    [
      {
        /**
         * the id of the Creative Media we want to delete
         */
        creativeMediaId: CreativeMediaListItem['id'];
        /**
         * we want to be able to react to the media being in the process of being deleted
         * e.g. to disable the Creative Media card and prevent interactions
         */
        onCreativeMediaBeingDeleted?: (creativeMediaId: string) => void;
        /**
         * event handler to act as the Creative Media has been deleted
         */
        onCreativeMediaDeleted?: (creativeMediaId: string) => void;
      },
    ]
  >({
    onConfirm: ({
      creativeMediaId,
      onCreativeMediaBeingDeleted,
      onCreativeMediaDeleted,
    }) => {
      if (!creativeMediaId) return;

      onCreativeMediaBeingDeleted &&
        onCreativeMediaBeingDeleted(creativeMediaId);
      deleteCreativeMedia(creativeMediaId, {
        onSuccess: () => {
          onCreativeMediaDeleted && onCreativeMediaDeleted(creativeMediaId);
        },
      });
    },
    content: t(
      'confirm.content',
      'You are about to permanently delete this creative.',
    ),
    confirmButtonText: t('button.confirm', 'Delete creative'),
  });

  return {
    DeleteCreativeMediaDialog,
    openCreativeMediaDeleteDialog,
    creativeMediaBeingDeletedId,
    errorDeletingCreativeMedia,
  };
}
