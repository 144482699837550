import React, { useState, useEffect } from 'react';

import produce from 'immer';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useResolvedPath } from 'react-router-dom';

import ErrorIcon from '@mui/icons-material/Error';
import { Divider, useTheme } from '@mui/material';

import ArtistEditRouteSetup from 'shared/components/ArtistEditor';
import { useBacklinkSettingsDataEditorContext } from 'shared/components/BacklinkSettingsDataEditor/state/context';
import { AppNotificationTypes } from 'shared/constants';
import {
  BacklinkSettingsData,
  parentEntityIsBacklink,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { RELEASE_LINK_DEFAULT_KEY } from 'shared/entities/release/release.constants';
import {
  BacklinkReleaseStore,
  ReleaseStore,
} from 'shared/entities/release/release.types';
import { StoreConfig } from 'shared/entities/storeConfig/storeConfig.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useNotification } from 'shared/hooks/useNotification';
import { useAppWideData } from 'shared/state';
import { isDefinedAndNotNull } from 'shared/utils/typeguards';

import StoreSelector from '../../../../StoreSelector';
import { useValidationContext } from '../../../hooks/useValidation';
import { getReleaseMode } from '../../../state';
import { EditorFieldProps } from '../../types';
import StoreCard from './StoreCard';
import { getStoreCardMetas } from './utils/storeUrlsValidation';

import * as ManagerStyled from '../../styled';
import * as Styled from './styled';

type Props = EditorFieldProps<Record<string, BacklinkReleaseStore>> & {
  path: string;
  storesFromRelease?: Record<string, ReleaseStore>;
  changeHandler: (
    newReleaseStores: Record<string, BacklinkReleaseStore>,
  ) => void;
  editorState: BacklinkSettingsData;
  releaseId?: string;
};

export const EditorStoreManager = ({
  path,
  value: releaseStores,
  storesFromRelease,
  changeHandler,
  editorState,
  releaseId,
}: Props) => {
  const { t, i18n } = useTranslation();

  const [expandedCardName, setExpandedCardName] = useState<string | boolean>(
    false,
  );
  const [availableReleaseStoresNames, setAvailableReleaseStoresNames] =
    useState<string[]>([]);

  const { storesConfigs } = useAppWideData();

  const theme = useTheme();
  const { errors } = useValidationContext();
  const { notify } = useNotification();
  const { parentEntityInfos } = useBacklinkSettingsDataEditorContext();
  const routeUrl = useResolvedPath('').pathname;

  const artistFromBacklink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity?.artist
    : null;
  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [artistFromBacklink?.id as string],
    enabled: !!artistFromBacklink,
  });

  const backlinkReleaseMode = getReleaseMode(editorState);

  const addableStoresConfig = Object.values(storesConfigs ?? {}).filter(
    (storeConfig) => !releaseStores || !releaseStores[storeConfig.name],
  );

  const storesWithMissingUrls = availableReleaseStoresNames
    .filter(
      (storeName) =>
        !!releaseStores[storeName] && !releaseStores[storeName].useAutoscan,
    )
    .filter((storeName) => {
      const store = releaseStores[storeName];
      const { headerStatus } = getStoreCardMetas({
        releaseStore: store,
        storeConfig: (storesConfigs ?? {})[storeName],
        backlinkReleaseMode,
        releaseStoreFromRelease: storesFromRelease?.[storeName],
      });
      return ['warning'].includes(headerStatus);
    });

  const { digitalReleaseDate, selectedTimezone } =
    editorState.releaseStep.release;

  const handleStoreCardExpansion =
    (cardName: string) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedCardName(isExpanded ? cardName : false);
    };

  function handleAddStore(storeConfig: StoreConfig) {
    if (isDefinedAndNotNull(storeConfig)) {
      const releaseStore: BacklinkReleaseStore = {
        name: storeConfig.name,
        useAutoscan: storeConfig.canUseAutoscan,
        links: {
          [RELEASE_LINK_DEFAULT_KEY]: {
            url:
              storeConfig.canUseAutoscan &&
              storesFromRelease &&
              storesFromRelease[storeConfig.name]
                ? storesFromRelease[storeConfig.name].url
                : '',
          },
        },
      };
      changeHandler({
        ...releaseStores,
        [storeConfig.name]: releaseStore,
      });
    }
  }

  function handleUpdateStore(storeName: string, store: BacklinkReleaseStore) {
    const previousStore = releaseStores[storeName];
    const storeFromRelease = storesFromRelease && storesFromRelease[storeName];
    let storeToSave = { ...store };

    // If we just switched to manual and there is no URL set for the default link
    // we set the default link url to the one from the release
    if (
      storeFromRelease &&
      previousStore.useAutoscan &&
      !store.useAutoscan &&
      !store.links[RELEASE_LINK_DEFAULT_KEY].url
    ) {
      storeToSave = {
        ...storeToSave,
        links: {
          ...storeToSave.links,
          [RELEASE_LINK_DEFAULT_KEY]: {
            url: storeFromRelease.url,
          },
        },
      };
    }

    changeHandler({
      ...releaseStores,
      [storeName]: storeToSave,
    });
  }

  function handleRemoveStore(storeName: string) {
    const nextStores = produce(releaseStores, (draft) => {
      if (draft) delete draft[storeName];
    });
    changeHandler(nextStores ?? {});
  }

  useEffect(() => {
    if (!releaseStores) return;
    const newAvailableReleaseStoresNames: string[] = [];
    const missingStoresNames: string[] = [];
    Object.keys(releaseStores).forEach((storeName) => {
      if (storesConfigs?.[storeName])
        newAvailableReleaseStoresNames.push(storeName);
      else missingStoresNames.push(storeName);
    });

    setAvailableReleaseStoresNames(newAvailableReleaseStoresNames);
    if (missingStoresNames.length)
      notify({
        type: AppNotificationTypes.Warning,
        message: `Store${
          missingStoresNames.length === 1 ? '' : 's'
        } ${missingStoresNames
          .map((x) => `"${x}"`)
          .join(', ')} missing from the Stores repository list`,
      });
  }, [storesConfigs, notify, releaseStores]);

  return (
    <Styled.Container role="group" aria-label="Store Manager">
      {storesWithMissingUrls.length > 0 &&
        backlinkReleaseMode === 'pre-release' && (
          <>
            <ManagerStyled.WarningMessage icon={<ErrorIcon />}>
              {t(
                'ui.component.backlink_settings_data_editor.store_manager.message.some_store_urls_missing',
                'Some store URLs are missing. Make sure to come back on {{ releaseDate }} ({timeZone}) to add them manually.',
                {
                  releaseDate: new Intl.DateTimeFormat(i18n.language, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: selectedTimezone,
                  }).format(new Date(digitalReleaseDate)),
                  timeZone: selectedTimezone,
                },
              )}

              {!!artist &&
                storesWithMissingUrls.some(
                  (storeName) =>
                    !Object.keys(artist.data.storeProfileLinks || {}).includes(
                      storeName,
                    ),
                ) && (
                  <p>
                    <Trans i18nKey="ui.component.backlink_settings_data_editor.store_manager.message.artist_urls_fallback">
                      Feels like too much pressure? Set{' '}
                      <Link to={`${routeUrl}/artist/${artist.id}/edit`}>
                        Artist URLs
                      </Link>{' '}
                      where fans will be redirected until you can fill missing
                      store URLs.
                    </Trans>
                  </p>
                )}
            </ManagerStyled.WarningMessage>
            <ArtistEditRouteSetup />
          </>
        )}
      <Styled.StoreCardsContainer role="list" aria-label="Stores">
        {availableReleaseStoresNames.map((storeName) => {
          if (releaseStores[storeName])
            return (
              <StoreCard
                role="listitem"
                aria-label={`Store Card - ${storeName}`}
                key={storeName}
                name={storeName}
                value={releaseStores[storeName]}
                releaseValue={storesFromRelease?.[storeName]}
                store={(storesConfigs ?? {})[storeName]}
                changeHandler={handleUpdateStore.bind(null, storeName)}
                isExpanded={expandedCardName === storeName}
                onExpand={handleStoreCardExpansion(storeName)}
                onRemoveStore={handleRemoveStore.bind(null, storeName)}
                errors={Object.entries(releaseStores[storeName].links).reduce(
                  (linkErrors, [iso, link]) => ({
                    ...linkErrors,
                    [iso]: {
                      url: errors[`${path}.${storeName}.links.${iso}.url`],
                    },
                  }),
                  {},
                )}
                backlinkReleaseMode={backlinkReleaseMode}
                releaseId={releaseId}
              />
            );
          return null;
        })}
      </Styled.StoreCardsContainer>
      <Divider />
      <StoreSelector
        availableStoresConfigs={addableStoresConfig}
        onChange={handleAddStore}
        css={{ marginBottom: theme.spacing(1 / 2) }}
      />
    </Styled.Container>
  );
};
