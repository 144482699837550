import React from 'react';

import { useLocation } from 'react-router-dom';

import { ListItemIcon, ListItemText, MenuListProps } from '@mui/material';

import { useScreenSize } from 'shared/hooks/useScreenSize';
import { RouteConfig } from 'shared/routes';
import { makeNavItemIsActiveMatcher } from 'shared/utils/navigation';
import { isDefined } from 'shared/utils/typeguards';

import {
  StyledMenuList,
  StyledMenuItem,
  MenuTitle,
  MenuTooltip,
} from './styled';

export type MenuItemOptions = {
  path: string;
  title: string;
  icon?: React.ReactElement | null;
  activeIcon?: React.ReactElement;
  /**
   * special cases where we want the activeIcon even though we're NOT on the active path
   * BUT we don't want to use activePaths to NOT hightlight the menu item
   * this should not be done like that ...
   */
  activeIconExtraRoutes?: string[];
  iconOnly?: boolean;
  activePaths?: Array<string | RegExp>;
  disabled?: boolean;
  target?: '_blank' | '_self';
  key?: string;
};

export type MenuItemsOptionsByRoute = Record<
  string,
  Omit<MenuItemOptions, 'path' | 'title'>
>;

export const makeMenuItemsFromRoutes = (
  routes: RouteConfig[],
  menuItemsOptionsByRoute: MenuItemsOptionsByRoute,
): (MenuItemOptions | null)[] => {
  return routes
    .map((route) => {
      return menuItemsOptionsByRoute[route.path]
        ? {
            ...route,
            ...menuItemsOptionsByRoute[route.path],
          }
        : null;
    })
    .filter(Boolean);
};

type Props = {
  title?: React.ReactNode;
  name?: string;
  items: (MenuItemOptions | null)[];
  className?: string;
  menuItemComponent?: React.ComponentType<any>; // this sould be better typed but I can't figure out how to do it ;) // TODO
} & MenuListProps;

const Menu = ({ title, name, items, className, menuItemComponent }: Props) => {
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const MenuItem = menuItemComponent || StyledMenuItem;

  return (
    <div className={className}>
      {title && <MenuTitle>{title}</MenuTitle>}
      <StyledMenuList aria-label={name}>
        {items.map((item, index) => {
          const Icon =
            item &&
            ((item.path === location.pathname ||
              item.activeIconExtraRoutes?.includes(location.pathname)) &&
            item.activeIcon
              ? item.activeIcon
              : item.icon);
          return (
            item && (
              <MenuTooltip
                title={item.iconOnly ? item.title : ''}
                placement="right"
                key={index}
                disableHoverListener={!isDesktop}
                disableTouchListener={!isDesktop}
              >
                <li>
                  <MenuItem
                    to={item.path}
                    className={
                      makeNavItemIsActiveMatcher(item, location)
                        ? 'active Mui-selected'
                        : ''
                    }
                    disabled={item.disabled || false}
                    /* this is to allow tooltips on disabled elements
                  see the comment in the styled file */
                    onClick={
                      item.disabled ? (e) => e.preventDefault() : undefined
                    }
                    target={item.target}
                    title={item.key || undefined}
                  >
                    {item.iconOnly && isDefined(item.icon) ? (
                      Icon
                    ) : (
                      <>
                        {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
                        <ListItemText primary={item.title} />
                      </>
                    )}
                  </MenuItem>
                </li>
              </MenuTooltip>
            )
          );
        })}
      </StyledMenuList>
    </div>
  );
};

export default Menu;
