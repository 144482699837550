import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import FlagProvider from '@unleash/proxy-client-react';
import { GlobalStyles } from 'App.styled';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';

import { Notifier } from 'shared/components/Notifier';
import ScreenLoader from 'shared/components/ScreenLoader';
import config from 'shared/config';
import { useAppQueryClient } from 'shared/hooks/useAppQueryClient';
import { useScreenSize } from 'shared/hooks/useScreenSize';
import { AppContextProvider } from 'shared/state';
import { AppState, Reducer } from 'shared/state/types';
import { designSystemThemeWithOverrides } from 'shared/themes/design-system-overrides';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { appSetupMap } from './App';

/**
 * we need to wrap the QueryClientProvider in a component
 * so that we can insert it below the AppContextProvider
 * this is because we want to use the useAppQueryClient hook
 * this hook needs access to the AppContext (and its state/dispatch features)
 */
const QueryClientProviderWrapper = ({ children }) => {
  const queryClient = useAppQueryClient();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

const ThemeProviderWrapper = ({ children }) => {
  const appTheme = designSystemThemeWithOverrides;

  return (
    <StyledEngineProvider injectFirst>
      <EmotionThemeProvider theme={appTheme}>
        <MuiThemeProvider theme={appTheme}>
          <GlobalStyles />
          <CssBaseline />
          <ScreenLoader>{children}</ScreenLoader>
        </MuiThemeProvider>
      </EmotionThemeProvider>
    </StyledEngineProvider>
  );
};

const AppProviders = ({ children }) => {
  const { i18n } = useTranslation();
  const { isDesktop } = useScreenSize();
  const showReactQueryDevtools =
    process.env.NODE_ENV === 'development' && isDesktop;

  /* No AppName No App */
  if (!getAppName()) {
    return null;
  }

  /**
   * different initial global state
   */
  const initialState = appSetupMap[getAppName() as AppName].initialState;

  /**
   * we can add a specific reducer
   */
  const additionalReducer = appSetupMap[getAppName() as AppName].reducer;

  /**
   * we want to tell ts what kind of AuthUser to expect
   * NOTE: the base app context ts is somewhat a WIP
   * the goal was to have one AppContext with different AuthUser depending on the Believe Environment
   */
  type MainAppAuthUserType = typeof initialState.user;
  return (
    <LocalizationProvider
      dateAdapter={DateAdapter}
      adapterLocale={i18n.language}
    >
      <AppContextProvider<MainAppAuthUserType>
        initialState={initialState}
        additionalReducer={
          additionalReducer as Reducer<AppState<MainAppAuthUserType>>
        }
      >
        <FlagProvider config={config.gitlabFeatureFlagsProxy}>
          <ThemeProviderWrapper>
            <QueryClientProviderWrapper>
              <>{children}</>
              <>
                <Notifier />
                {showReactQueryDevtools && (
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                  />
                )}
              </>
            </QueryClientProviderWrapper>
          </ThemeProviderWrapper>
        </FlagProvider>
      </AppContextProvider>
    </LocalizationProvider>
  );
};

export default AppProviders;
